import React from 'react'
import {pluginsPostApis} from '../../../../../Model/Network/Config/Axios';


class Connect extends React.Component {

	state = {
		connect: false
	}

	componentDidMount() {
		if (this.props.handle) {
			this.props.handle(this);
		}
	}

	connect = () => {

		pluginsPostApis(`plugins/custom/bajaj/hrms/install`, {}, (e, r) => {
			if (r) {
				this.props.onRefresh()
			}
		})
	}

	


	render() {
        let item = this.props.item;
		if (item && item.installed) {
			return (
				<div style={{
					padding: 12,
					width: "25%"
				}}>
					<div style={{
						padding: 18,
						background:"var(--white-color)",
						position: 'relative',
						borderRadius:8,
						boxShadow:"7px 4px 8px rgba(1,1,1,.05)",
						border: '1px solid var(--border-color)',
					}}>
						{
							item.status === "ERRROR" ? <p style={{
								color: 'red',
								position: 'absolute',
								right: 12,
								fontWeight: 700,
								fontSize: 11,
								top: 12
							}}>Error</p> : <p style={{
								color: 'var(--primary-color)',
								position: 'absolute',
								right: 12,
								fontWeight: 700,
								fontSize: 11,
								top: 12
							}}>{item.status}</p>
						}
						<img src={item.logo} alt={item.name} style={{
                            height: 30,
                            maxWidth:150
						}}/>
						<p style={{
							fontWeight: 700,
							fontSize: 19,
							marginTop: 15,
							color: "var(--text-color)"
						}}>
							{item.name}
						</p>
						<p style={{
							fontWeight: 400,
							fontSize: 11,
							marginTop: 12,
							height: 65,
							marginBottom: 12,
							color: "var(--light-color)"
						}}>
							{item.description}
						</p>
						<div style={{
							display: 'flex',
							marginTop: 20,
							flexDirection: 'row'
						}}>
							<p onClick={() => {
								this.props.history.push("/app/apps/bajaj")
							}} style={{
								fontSize: 14,
								fontWeight: 600,
								cursor: 'pointer',
								color: "var(--primary-color)"
							}}>
								View Plugin
							</p>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div key={item.pluginType} style={{
				padding: 12,
				width: "25%"
			}}>
				<div style={{
					padding: 18,
					background:"var(--white-color)",
					border: '1px solid var(--border-color)',
				}}>
					<img src={item.logo} alt={item.name} style={{
						height: 30
					}}/>
					<p style={{
						fontWeight: 700,
						fontSize: 19,
						marginTop: 15,
						color: "var(--text-color)"
					}}>
						{item.name}
					</p>
					<p style={{
						fontWeight: 400,
						fontSize: 11,
						marginTop: 12,
						height: 65,
						marginBottom: 12,
						color: "var(--light-color)"
					}}>
						{item.description}
					</p>
					<div style={{
						display: 'flex',
						marginTop: 20,
						flexDirection: 'row'
					}}>
						<p onClick={this.connect.bind(this)} style={{
							fontSize: 14,
							fontWeight: 600,
							cursor: 'pointer',
							color: "var(--primary-color)"
						}}>
							Install Plugin
						</p>
					</div>
				</div>
			</div>
		)
	}

}

export default Connect
