import React from 'react'
import {pluginsGetApi, pluginsPostApis} from '../../../../../Model/Network/Config/Axios';
import {handleError, showToast} from '../../../../Common/fToast/ToastCallback';
import {Title as Header, TitleTopHeader} from "../../../../Header/TopHeader";

class index extends React.Component {

	constructor(props) {
		super(props);
		this.state  = {
			syncedWithWorkLine: 0,
			workLineError:{
				remarks:"No error while syncing"
			}
		}

		pluginsGetApi(`plugins/custom/carlsberg/masters/info`,(e,r)=>{
			if(r){
				this.setState({
					syncedWithWorkLine:r.syncedWithWorkLine
				})
			}
		})
	}




	_sellerMaster = () => {
		pluginsPostApis(`plugins/custom/carlsberg/masters/syncWithSellerMaster`, {}, (e, r) => {
			if (r) {
				showToast({
					type:'success',
					message:"Event triggered successfully!"
				})
			}else{
				handleError(e)
			}
		})
	}

	_syncWithOutlet = () => {
		pluginsPostApis(`plugins/custom/carlsberg/masters/syncWithOutletMaster`, {}, (e, r) => {
			if (r) {
				showToast({
					type:'success',
					message:"Event triggered successfully!"
				})
			}else{
				handleError(e)
			}
		})
	}

	render() {
		return (
			<div >
				<TitleTopHeader items={[]} title="Master Sync" des="Syncing of data with master."/>
				<Header title="Master Sync" des="Syncing of data with master."/>
				<div className="title-container" style={{
					padding: 18
				}}>

					<div className="flex">
						<button onClick={this._sellerMaster} className="btn btn-success" style={{marginTop:12,marginRight:20}}>
							Sync With Seller Master
						</button>
						<div style={{marginLeft:20}}>
							<button onClick={this._syncWithOutlet} className="btn btn-success" style={{marginTop:12}}>
								Sync With Outlet Master
							</button>
						</div>

					</div>
				</div>
			</div>
		)
	}

}

export default index
