import React from 'react'
import Props from 'prop-types'
import {useHistory} from 'react-router-dom'

function Buttons(props) {
	let HISTORY = useHistory();
	if(!props.buttons.length){
		return (<div/>)
	}
	return (
		<div style={{display:'flex',paddingBottom:20,marginBottom:30,borderBottom:'1px solid var(--border-color)'}}>
			{
				props.buttons.map(item=>{
					return (
						<div onClick={()=>{
							if(item.click){
								item.click()
							}else{
								HISTORY.push(item.path)
							}
						}} key={item.name} style={{
							background:"var(--border-color)",
							marginRight:12,
							padding:'12px 30px',
							color:"var(--text-color)",
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							fontSize:13,
							borderRadius:8,
							cursor:'pointer',
							border:'1px solid var(--border-color)',
							boxShadow:'4px 5px 3px rgba(1,1,1,.015)',
							...item.style
						}}>
							{item.name}
						</div>
					)
				})
			}
		</div>
	)
}

Buttons.propTypes = {
	buttons:Props.array
}

export {
	Buttons
}
