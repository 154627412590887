import React, { useState} from 'react'
import TabSwtich from "../../../../Common/fTabSwitch";
import {SearchableSelect} from "../../../../Common/Class/Select/SearchableSelec2";
import {get, post} from "../../../../../Model/Network/Config/Axios";
import {FloatInputButton} from "../../../../Common/fInputs";
import {FileUpload} from "../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../Common/fPhoto";
import Track from "../../../../Common/fTrack";
import Moment from "moment";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import Invoice from "./fInvoice";
import _ from 'lodash'

export  default function India(props) {
	let {details,onReload,id} = props;
	const [getTab, setTab] = useState(1);
	const [getVendor, setVendor] = useState('');
	const [getVendors, setVendors] = useState([]);
	const [getBillOffice, setBillOffice] = useState('');
	const [getShipOffice, setShipOffice] = useState('');
	const [getOffices, setOffices] = useState([]);

	const updateInvoice = (field, value) => {

		let key = '';

		switch (field) {
			case 'vendor':
				post(`/vendor/invoicing/${id}/vendor.change`, {vendor: getVendor.value}, (e, r) => {
					if (r) {
						setVendor('');
						onReload()
						showToast({
							type: 'success',
							message: 'Vendor Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'id':
				key = 'invoice';
				break;
			case 'bill':
				post(`/vendor/invoicing/${id}/change`, {
					key: 'billing',
					value: String(getBillOffice.value)
				}, (e, r) => {
					if (r) {
						onReload()
						setBillOffice('');
						showToast({
							type: 'success',
							message: 'Billing Address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'ship':
				post(`/vendor/invoicing/${id}/change`, {
					key: 'address',
					value: String(getShipOffice.value)
				}, (e, r) => {
					if (r) {
						onReload()
						setShipOffice('');
						showToast({
							type: 'success',
							message: 'Shipping Address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'base':
				key = 'base';
				break;
			case 'tax':
				key = 'tax';
				break;
			default:
				return;
		}

		post(`/vendor/invoicing/${id}/change`, {'key': key, 'value': value}, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Field Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const uploadFile = (data) => {
		post(`/vendor/invoicing/${id}/attachment`, data, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'File uploaded'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const renderTags = (item, index) => {
		return (
			<div key={`Tag-${index}`} style={{display: 'flex', alignItems: 'center'}}>
				<FloatInputButton style={{width: '100%'}} onSubmit={(val)=>{updateInsight(item[0], val)}} title={item[0]} placeholder={item[1]}/>
			</div>
		)
	}

	const updateInsight = (key, val)=>{
		post(`/vendor/invoicing/${id}/change`, {'key': key, 'value': val}, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Insight Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	return (
		<SidePane button={false} onClose={() => props.onClose(false)}
				  column={
					  <Invoice data={{
						  vendor: details.vendor.name,
						  email: details.vendor.email,
						  vendor_id: details.vendor.gstin,
						  date: details.invoice.date,
						  due: details.dueOn?details.dueOn:undefined,
						  Invoice_id: details.invoice.invoiceId,
						  bill_add: details.address.address,
						  ship_add: details.deliverTo.address,
						  table: [
							...details.invoice.items.map((item => {
								return ({
									desc: item.name?item.name:item.service,
									price: item.price,
									qtty: item.qtty,
									amount: item.amount,
									hsn: item.hsn
								})
							}))
							],
						  currency:details.taxInformation.currency,
						  tax: details.taxInformation.tax,
						  discount:details.taxInformation.discountAmount,
						  base: details.taxInformation.base,
						  total:details.taxInformation.amount,
					  }}/>
				  }>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
				<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}}>Invoice</div>
				<div style={{
					color: 'var(--text-color)',
					marginBottom: '35px',
					fontWeight: 600,
					fontSize: '24px',
					lineHeight: '1.3'
				}}>{details.invoice.counterId}</div>

				<TabSwtich tabs={[
					{
						name: 'Format', active: getTab === 1 , onClick: () => {
							setTab(1)
						}
					},
					{
						name: 'Insight', active: getTab === 2 , onClick: () => {
							setTab(2)
						}
					},
					{
						name: 'Timeline', active: getTab === 3 , onClick: () => {
							setTab(3)
						}
					}
				]}/>

				{getTab === 1 ?
					<div id="Invoice_Format" className='FadeRight-Effect'>
						{
							details.status==='POHOLD'?
							<div style={{fontSize: '12px',background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center'}}>
								<svg style={{marginRight:10, fontSize: '16px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
									<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
								</svg>
								<span>This Invoice is on hold because <b>PO Approval is pending</b>.</span>
							</div>
							:<></>
						}
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Details
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: '#666', marginRight: '3px'}}>Invoice ID:</span>{details.invoice.invoiceId}
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: '#666', marginRight: '3px'}}>Category:</span> {details.category}</div>
						<div style={{marginTop: '5px'}}><span style={{
							color: '#666',
							marginRight: '3px'
						}}>Amount:</span> {`${details.taxInformation.currency} ${details.taxInformation.amount}`}</div>
						{
							_.includes(["COMPLETED","CANCELLED","FAILED","DECLINED"],details.status)?
								<>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Information
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Vendor Name:</span> {details.vendor.name}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Invoice Date:</span> {details.invoice.date}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Invoice ID:</span> {details.invoice.invoiceId}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Email:</span> {details.vendor.email}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Billing Address:</span> {details.address.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Shipping Address:</span> {details.deliverTo.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Tax:</span> {details.taxInformation.tax}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Base Amount:</span> {details.taxInformation.base}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Amount:</span> {details.taxInformation.amount}</div>
								</>
								: <>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Update Invoice
									</div>
									<div style={{display: 'flex', width: '100%', alignItems: 'end'}}>
										<SearchableSelect
											label="Vendor Name"
											placeholder="Select vendor"
											value={getVendor}
											reset={() => {
												setVendor('');
											}}
											select={vendorModal => {
												setVendor(vendorModal)
											}}
											add={{
												name: "Add New vendor",
												link: "/app/vendor/contacts/new"
											}}
											onChange={val => {
												if (val.length > 1) {
													get(`/vendor/contacts/search?q=${val}`, (e, r) => {
														setVendors(r.contacts.map(item => ({
															value: item.id,
															label: item.title,
															des: item.email
														})))
													})
												} else {
													setVendors([])
												}
											}}
											items={getVendors}/>
										{
											getVendor === '' ? <></> :
												<div onClick={() => {
													updateInvoice('vendor')
												}} style={{
													height: '45px',
													width: '45px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: '16px',
													cursor: 'pointer',
													color: 'var(--white-color)',
													backgroundColor: 'var(--primary-color)',
													zIndex: '111',
													marginBottom: '8px',
													marginLeft: '-45px'
												}}>
													<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
														 width="1em" height="1em" fill="currentColor"
														 viewBox="0 0 16 16">
														<path
															d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
													</svg>
												</div>
										}
									</div>


									<div style={{display: 'flex', width: '100%', alignItems: 'end'}}>
										<SearchableSelect
											label="Billing Address"
											placeholder="Select Office"
											value={getBillOffice}
											reset={() => {
												setBillOffice('');
											}}
											select={office => {
												setBillOffice(office)
											}}
											onChange={q => {
												if (q.length > 0) {
													get(`/search/v1/offices`, (e, r) => {
														if (r) {
															setOffices(r.offices.map(item => ({
																label: item.name,
																value: item.id
															})))
														}
													}, {
														q
													})
												} else {
													setOffices([])
												}
											}}
											items={getOffices}/>
										{
											getBillOffice === '' ? <></> :
												<div onClick={() => {
													updateInvoice('bill')
												}} style={{
													height: '45px',
													width: '45px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: '16px',
													cursor: 'pointer',
													color: 'var(--white-color)',
													backgroundColor: 'var(--primary-color)',
													zIndex: '111',
													marginBottom: '8px',
													marginLeft: '-45px'
												}}>
													<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
														 width="1em" height="1em" fill="currentColor"
														 viewBox="0 0 16 16">
														<path
															d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
													</svg>
												</div>
										}
									</div>

									<div style={{display: 'flex', width: '100%', alignItems: 'end'}}>
										<SearchableSelect
											label="Shipping Address"
											placeholder="Select Office"
											value={getShipOffice}
											reset={() => {
												setShipOffice('');
											}}
											select={office => {
												setShipOffice(office)
											}}
											onChange={q => {
												if (q.length > 0) {
													get(`/search/v1/offices`, (e, r) => {
														if (r) {
															setOffices(r.offices.map(item => ({
																label: item.name,
																value: item.id
															})))
														}
													}, {
														q
													})
												} else {
													setOffices([])
												}
											}}
											items={getOffices}/>
										{
											getShipOffice === '' ? <></> :
												<div onClick={() => {
													updateInvoice('ship')
												}} style={{
													height: '45px',
													width: '45px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: '16px',
													cursor: 'pointer',
													color: 'var(--white-color)',
													backgroundColor: 'var(--primary-color)',
													zIndex: '111',
													marginBottom: '8px',
													marginLeft: '-45px'
												}}>
													<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
														 width="1em" height="1em" fill="currentColor"
														 viewBox="0 0 16 16">
														<path
															d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
													</svg>
												</div>
										}
									</div>


									<FloatInputButton onSubmit={(val) => {
										updateInvoice('id', val)
									}} title='Invoice ID' placeholder={details.invoice.invoiceId}/>

									<div style={{display: 'flex'}}>
										<div style={{width: '50%'}}><FloatInputButton onSubmit={(val) => {
											updateInvoice('base', val)
										}} title='Base Amount ' placeholder={details.taxInformation.base}/>
										</div>
										<div style={{width: '50%',paddingLeft:15}}><FloatInputButton onSubmit={(val) => {
											updateInvoice('tax', val)
										}} title='Tax Amount ' placeholder={details.taxInformation.tax}/>
										</div>
									</div>
								</>
						}
						<div
							style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment
						</div>
						<div style={{
							display: 'flex',
							marginLeft: '-15px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>

							<FileUpload onUpload={uploadFile}/>
							{
								details.invoice.files.length > 0 ?
									details.invoice.files.map((item, index) => {
										return (
											<PhotoTile key={`Photo-${index}`} link={item}/>
										)
									}) : <></>
							}
						</div>
					</div> : <></>
				}

				{getTab === 2 ?
					<>
						<div id="Invoice_Insight" className='FadeRight-Effect'>
							<div
								style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Insights
							</div>
							{
								details.tags ? Object.entries(details.tags).map(renderTags) : <></>
							}
						</div>
					</>
					: <></>
				}

				{getTab === 3 ?
					<div id="Invoice_Timeline" className='FadeRight-Effect'>
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline
						</div>
						<Track stages={
							[...details.timelines.map((log) => {

								let DESC = <div><b>{log.name}</b><br/>
									<div style={{marginTop: '5px'}}>Type: {log.type}</div>
									<div style={{marginTop: '5px'}}>Sent
										on: {Moment(log.time).format("hh:mm a DD MMM YY")}
										<br/>{log.actionTime ? `Approved on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
									</div>
								</div>

								return (
									{
										title: log.email,
										desc: DESC,
										status: log.type === 'start' ? true : log.status ? log.status === 'COMPLETED' : false
									})
							})]
						}/>
					</div> : <></>
				}

				<div style={{height: '50px'}}/>
			</div>
		</SidePane>
	)

}
