import { set,get as fetch } from "../../Utils/Crypto";

function useUrlConfig () {

    let previousUrl = fetch('previousUrl');

    let setUrl = (url) => {
        set('previousUrl',fetch('location'));
        set('location',url)
    }

    return {previousUrl,setUrl};
}


export {useUrlConfig}