import React, {useRef, useState} from 'react';
import Moment from 'moment';
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import SearchVendor from "../../../../Common/fSubmitInvoice/common/SearchVendorNormal";
import {SearchOffice2} from "../../../../Common/fSubmitInvoice/common/SearchOffice";
import {FileUpload} from "../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../Common/fPhoto";
import {FunctionalInput} from "../../../../Common/fInputs";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {post} from "../../../../../Model/Network/Config/Axios";
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';

export default function Render(props) {
	let {data} = props;
	if (!data)
		data = {}
	const [inputData, setInputData] = useState({
		vendor: data.vendor,
		billingTo: data.billingTo,
		shippingTo: data.shipTo,
		billDate: undefined,
		due: undefined,
		billNumber: undefined,
	});
	const [files, setFiles] = useState([]);
	let Base = useRef(), Tax = useRef(), Total = useRef();
	let submit = () => {
		let {billDate, due, billNumber, vendor, shippingTo, billingTo} = inputData;
		let base = Base.current.value;
		let tax = Tax.current.value;
		let total = Total.current.value;
		if (!billNumber) {
			showToast({
				type: 'error',
				message: "Please enter the bill number"
			})
			return;
		}

		if (!billDate) {
			showToast({
				type: 'error',
				message: "Please enter the bill date"
			})
			return;
		}
		if (!base) {
			showToast({
				type: 'error',
				message: 'Please enter the base amount'
			})
			return;
		}

		if (!Tax) {
			showToast({
				type: 'error',
				message: 'Please enter the Tax amount'
			})
			return;
		}

		if (!total) {
			showToast({
				type: 'error',
				message: 'Please enter the total amount'
			})
			return;
		}

		if (!props.poId) {
			if (!vendor) {
				showToast({
					type: 'error',
					message: "Please select the vendor"
				})
				return;
			}
			if (!shippingTo) {
				showToast({
					type: 'error',
					message: "Please select the ship to address"
				})
				return;
			}

			if (!billingTo) {
				showToast({
					type: 'error',
					message: "Please select the bill to address "
				})
				return;
			}
		}


		if (billDate) {
			billDate = Moment(billDate, 'YYYY-MM-DD');
			billDate = Moment(billDate).valueOf();
		}
		if (due) {
			due = Moment(due, 'YYYY-MM-DD');
			due = Moment(due).valueOf();
		}
		
		if(!files.length){
			showToast({
				type: 'error',
				message: "Attachment is required"
			})
			return;
		}

		if (props.poId) {

			let data = {
				currency: props.currency,
				billDate,
				due,
				billNumber,
				attachment: files,
				poId: +props.poId,
				tax,
				total,
				base,
			}
			data.categoryId = props.categoryId;
			if(props.superId) {
				data.superId=props.superId
			}

			post(`vendor/submit/invoice/direct/po`, {data}, (e, r) => {
				if (r) {
					showToast({
						type: 'success',
						message: 'Invoice uploaded successfull'
					})
					props.onClose(false);
					props.init();
				} else {
					handleError(e)
				}
			})

		} else {
			let data = {
				currency: props.currency,
				billDate,
				due,
				billNumber,
				billTo: billingTo,
				shipTo: shippingTo,
				vendor,
				tax,
				total,
				base,
				attachment: files,
				addressId: vendor.key
			}
			data.service = props.categoryId;
			if(props.superId) {
				data.superId=props.superId
			}

			post(`vendor/submit/invoice/direct`, {data}, (e, r) => {
				if (r) {
					showToast({
						type: 'success',
						message: 'Invoice uploaded successfull'
					})
					props.onClose(false)
					props.init();
				} else {
					handleError(e)
				}
			})

		}

	}

	return (
		<SidePane button={false} onClose={() => props.onClose(false)} width={1000}>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
				<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3', marginBottom: 30}}>Submit Invoice
				</div>
				<div id="Invoice_Format" className='FadeRight-Effect'>
					{
						props.poId ?
							<div/> : <div>

								<SearchVendor
									onSelect={(vendor) => {
										setInputData({
											...inputData,
											vendor
										})
									}}
								/>
								<SearchOffice2 
									entityId={(props && props.entityId)?props.entityId:undefined}
									p2p={true} title="Bill To" office={inputData.billingTo} onSelect={(billingTo) => {
									setInputData({
										...inputData,
										billingTo: {
											...billingTo,
											name: billingTo.label
										}
									})
								}}/>
								<SearchOffice2 
									entityId={(props && props.entityId)?props.entityId:undefined}
									p2p={true} office={inputData.shippingTo} title="Ship To" onSelect={(shippingTo) => {
									setInputData({
										...inputData,
										shippingTo: {
											...shippingTo,
											name: shippingTo.label
										}
									})
								}}/>

							</div>
					}
					<div style={{display: 'flex'}}>
						<FunctionalInput type="text" title="Bill Number" ref={() => {
						}} onChange={(e) => {
							let billNumber = e.target.value;
							setInputData({
								...inputData,
								billNumber
							})
						}}/>
					</div>
					<div style={{display: 'flex'}}>
						<FunctionalInput type="date" title="Bill Date" ref={() => {
						}} onChange={(e) => {
							let billDate = e.target.value;
							setInputData({
								...inputData,
								billDate
							})
						}}/>
						<div style={{marginLeft: 12}}>
							<FunctionalInput type="date" title="Due Date" ref={() => {
							}} onChange={(e) => {
								let due = e.target.value;
								setInputData({
									...inputData,
									due
								})
							}}/>
						</div>
					</div>

					<div style={{
						display: 'flex',
						flex: 1,
						marginTop: 20
					}}>
						<div style={{flex: 1, marginRight: 20}}>
							<FunctionalInput type="text" title="Base Amount" ref={Base}/>
						</div>
						<div style={{flex: 1}}>
							<FunctionalInput type="text" title="Tax" ref={Tax}/>
						</div>
					</div>

					<div style={{
						display: 'flex',
						flex: 1,
						marginTop: 20
					}}>
						<FunctionalInput type='text' title="Total Amount" ref={Total}/>
					</div>
					<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment</div>
					<div style={{
						display: 'flex',
						marginLeft: '-15px',
						flexWrap: 'wrap',
						maxHeight: '250px',
						overflowY: 'scroll'
					}}>
					<ReadOnly>
						<FileUpload onUpload={({file}) => {
							setFiles(prev => [...prev, file]);
						}}/>
					</ReadOnly>
						{
							files.length > 0 ?
								files.map((item, index) => {
									return (
										<PhotoTile key={`Photo-${index}`} link={item}/>
									)
								}) : <></>
						}
					</div>
					<div className="btn btn-portlet" onClick={submit}
						 style={{maxWidth: '100%', height: 40, marginTop: 20}}>Upload
					</div>

				</div>
			</div>
		</SidePane>
	)
}
