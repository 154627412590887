import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {get, post} from '../../../../../../Model/Network/Config/Axios'
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import {StateSwitch} from '../../../../../../Components/Common/fSwitch'

function Index(props) {

    const {appId} = useParams();
    const [Owner, setOwner] = useState(false);
    const [Roles, setRoles] = useState({
        "expense.create": false,
        "invoice.write": false,
        "pr.create": false,
        "po.create": false,
        "rfq.create": false,
        "expense.read": false,
        "invoice.read": false,
        "pr.read": false,
        "po.read": false,
    });

    useEffect(() => {
        fetchDetails()
    }, []);

    const fetchDetails = () => {
        get(`market/${appId}/details`, (e,r)=>{
            if(r){
                setOwner(r.owner)

                let RolesObj = Roles;

                Object.keys(RolesObj).forEach(element => {
                    if(r.roles.includes(element)){
                        RolesObj[element] = true;
                    }else{
                        RolesObj[element] = false;
                    }
                });

                setRoles(prev=>({...prev, RolesObj}))

            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading details'
                })
            }
        },{})
    }

    const deleteRole = (role) => {
        if(Owner){
            post(`market/user/${appId}/role/remove`, {role: role}, (e,r)=>{
                if(r){
                    fetchDetails();

                }else if(e){
                    showToast({
                        type:'error',
                        message: 'Problem in deleting role'
                    })
                }
            })
        }else{
            showToast({
                type:'error',
                message: 'You do not have rights'
            })
        }
    }

    const addRole = (role) => {
        if(Owner){
            post(`market/user/${appId}/role/add`,{role: role}, (e,r)=>{
                if(r){
                    fetchDetails();

                }else if(e){
                    showToast({
                        type:'error',
                        message: 'Problem in adding role'
                    })
                }
            })
        }
    }

    const toggelRole = (role) =>{
        if(Roles[role]){
            deleteRole(role)
        }else{
            addRole(role)
        }
    }

    return (<>
        <div className='CustomApp' style={{marginTop: 40, display: 'flex', width: '100%', backgroundColor: 'var(--white-color)', boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 0.2rem 1rem', borderRadius: 20, justifyContent: 'space-between'}}>
            <div className="FadeLeft-Effect" style={{display: 'flex', width: '100%',alignItems: 'stretch'}}>
                <div style={{display: 'flex', width: '100%',alignItems: 'stretch'}}>
                    <div style={{width: '100%',padding: 40}}>
                        <div>
                            <span id="Login_Heading"style={{fontSize: '32px', fontWeight: 'bold'}}>Roles</span><br/>
                            <span id="Login_Heading"style={{fontSize: '14px'}}>You can add or remove permissions for your App</span>
                            <div style={{width: '400px', marginTop: '40px', border: '1px solid var(--border-color)', padding: '20px', borderRadius: '15px'}}>
                                <StateSwitch checked={Roles["expense.read"]} callback={bool=>{toggelRole('expense.read',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to read Expenses' onlabel='App can read Expenses'/>
                                <StateSwitch checked={Roles["expense.create"]} callback={bool=>{toggelRole('expense.create',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to create Expenses' onlabel='App can create Expenses'/>
                                <StateSwitch checked={Roles["invoice.read"]} callback={bool=>{toggelRole('invoice.read',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to read Invoices' onlabel='App can read Invoices'/>
                                <StateSwitch checked={Roles["invoice.write"]} callback={bool=>{toggelRole('invoice.write',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to create Invoices' onlabel='App can create Invoices'/>
                                <StateSwitch checked={Roles["pr.read"]} callback={bool=>{toggelRole('pr.read',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to read PR' onlabel='App can read PR'/>
                                <StateSwitch checked={Roles["pr.create"]} callback={bool=>{toggelRole('pr.create',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to create PR' onlabel='App can create PR'/>
                                <StateSwitch checked={Roles["po.read"]} callback={bool=>{toggelRole('po.read',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to read PO' onlabel='App can read PO'/>
                                <StateSwitch checked={Roles["po.create"]} callback={bool=>{toggelRole('po.create',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to create PO' onlabel='App can create PO'/>
                                <StateSwitch checked={Roles["rfq.create"]} callback={bool=>{toggelRole('rfq.create',bool)}} style={{marginTop: '10px'}} offlabel='Allow app to create RFQ' onlabel='App can create RFQ'/>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </>);
}

export default Index;
