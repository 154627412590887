import React, {useEffect} from "react";
import Portal from "../Portal";

function SidePane(props) {
    useEffect(() => {
        if(document.forms[0]){
            document.forms[0].elements[0].focus();
        }
    }, []);

    const CloseEvent = (e) => {
        if(e.target.id === "OVERLAY") props.onClose();
    }
    return (
           <Portal>
               <div id="OVERLAY" onClick={(e)=>CloseEvent(e)} style={{display: 'flex', justifyContent: 'end', top: 0,left: 0,bottom:0,width: '100%', height: '100%',zIndex: '1043',position: 'fixed',outline: 'none !important'}}>
                   {props.column?
                       <div id="RightPane" onClick={(e)=>e.stopPropagation()} className="FadeRight-Effect" style={{position: 'relative', display: 'flex',height: '100vh',width:'60%', overflowY: 'scroll', overflowX: 'hidden', background: 'var(--white-color)', marginLeft: '-10px', boxShadow: '0rem 0rem 2rem rgba(1,1,1,0.05)', padding: '50px 50px'}}>
                           {props.column}
                       </div>
                       :<></>}

                   <div id="RightPane" className="FadeRight-Effect" style={{position: 'relative',height: '100vh', overflowY: 'scroll', overflowX: 'hidden', background: 'var(--white-color)', width: '40%', maxWidth: 650, marginLeft: '-10px', boxShadow: '0rem 0rem 2rem rgba(1,1,1,0.05)', padding: '50px 50px',...props.container}}>
                       {props.children}
                       {
                           props.button ?
                               <button className={`btn btn_black btn_wide ${props.className}`} style={{
                                   marginTop: 10
                               }} type="submit">
                                   {props.buttonName}
                               </button>
                               : null
                       }
                     
                   </div>
                   {
                       props.showButton ?
                           <div onClick={()=>props.onClose()} title="Close (Esc)" style={{position: 'absolute', top: '20px', right: '30px', cursor:'pointer'}} type="button">
                               <svg style={{fontSize: '24px'}} className="bi bi-x-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                   <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                               </svg>
                           </div>
                           : null
                   }
               </div>
           </Portal>
    )
}

SidePane.defaultProps = {
    title: 'Modal',
    showButton: true,
    buttonName: 'Submit',
    button: true,
    container:{}
}


export {
    SidePane
};
