import React from 'react'
import {pluginsGetApi} from '../../../../../Model/Network/Config/Axios';
import {Title as Header, TitleTopHeader} from "../../../../Header/TopHeader";
// import JSONInput from 'react-json-editor-ajrm';
// import locale    from 'react-json-editor-ajrm/locale/en';

class index extends React.Component {

	constructor(props) {
		super(props);
		this.state  = {
			data:{}
		}

	}




	componentDidMount() {

		// pluginsGetApi(`plugins/quickbooks/oauthRedirection`,(e,r)=>{
		// 	if(r){
		// 		window.open(r.oAuth2RedirectUrl)
		// 	}
		// })

		pluginsGetApi(`plugins/quickbooks/getQuickbookCompanyInfo`,(e,r)=>{
			if(r){
				this.setState({
					data:r
				})
			}
		})
	}



	render() {
		return (
			<div >
				<TitleTopHeader items={[]} title="WorkLine Sync" des="Syncing of data with workline."/>
				<Header title="WorkLine Sync" des="Syncing of data with workline."/>
				<div className="title-container" style={{
					padding: 18
				}}>
					
				</div>
			</div>
		)
	}

}

export default index
