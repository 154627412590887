import React from "react";

export default function Footer() {
    return (
        <div style={{ fontSize: 12, textAlign: "center", padding: "20px 0px" }}>
            <h2>ZippMat Private Limited </h2>
            <h3>CIN: U51909MH2021PTC364119 GSTIN: 29AABCZ7789D1ZO</h3>
            <p>Mumbai: 74/II, "C" Cross Road, Opp Gate no 2, SEEPZ, Andheri East, Mumbai - 400093</p>
            <p>Bengalure: Muinicipal No. 7 (Old No. 333), PID No. 99-27-7, 14th Main Road,</p>
            <p>6th 'A' Cross, Rajamahal Vilas Extendsion, Sadashivnagar, Bengaluru - 560 080</p>
        </div>
    )
}