import React from 'react'
import PaymentCondition from './PaymentCondition'

const Terms = (props) => {

	return (<PaymentCondition {...props} conditions={props.data.payments}/>)

}

export default Terms
