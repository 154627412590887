import React from 'react';
import Header from './Header';
import Vendor from './Vendor';
import Buyer from './Buyer';
import Items from './Items'
import Address from './Address';
import Annexure from './Annexure/index'
import {PDFExport} from "@progress/kendo-react-pdf";

class Nykaa extends React.Component {

    render() {
        let {data} = this.props;
        return(
            <>
            <div style={{
                display:'flex',
                justifyContent:'flex-end'
            }}>
                <div style={{
                    cursor:'pointer',
                    color:'var(--primary-color)',
                    fontSize:16
                }} onClick={()=>{
                    this.pdf.save()
                }}>Download</div>
            </div>
           <PDFExport title="Order Document" padding="40" fileName={`Order-${data.orderId}.pdf`} ref={r => this.pdf = r}>
            <div style={{border:'1px solid var(--border-color)'}}>
                <Header data={data} />
                <div style={{display:'flex'}}>
                    <div style={{minHeight:200,borderRight:'1px solid var(--border-color)',flex:1}} >
                        <Vendor vendor={data.vendor} />
                    </div>
                    <div style={{flex:1}}>
                        <Buyer data={data} />
                    </div>
                </div>
                <Items data={data} />
                <Address data={data} />
                <Annexure />
            </div>
          </PDFExport>
            </>
        )
    }
}

export default Nykaa;