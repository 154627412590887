import React, { useState, useEffect } from 'react';
import { showToast ,handleError} from '../../../../Common/fToast/ToastCallback';
import { TitleTopHeader,Title as Header } from '../../../../Header/TopHeader';
import { pluginsGetApi,pluginsPostApis } from '../../../../../Model/Network/Config/Axios';
import { StateButton } from '../../../../Common/fStateButton';


const Index = () => {

  const [loading,setLoading] = useState(false);


  useEffect(() => {
    pluginsGetApi('plugins/custom/piramal/hrms/info', (e, r) => {
      if (r) {
       
      }
    });
  }, []);

 

  const syncWithDarwin = () => {

    
	  setLoading(true);
    pluginsPostApis('plugins/custom/piramal/hrms/syncWithHrms', {}, (e, r) => {
      if (r) {
		    setLoading(false);
        showToast({
          type: 'success',
          message: 'Event triggered successfully!',
        });
      } else {

	    	setLoading(false);
        handleError(e);
      }
    });
  };

  

  return (
    <div>
      <TitleTopHeader items={[]} title="PIRAMAL HRMS  Sync" des="Syncing of data with PIRAMAL HRMS." />
      <Header title="HRMS Sync" des="Syncing of data with HRMS." />
      <div
        className="title-container"
        style={{
          padding: 18,
        }}
      >
         {
			    loading ?
			    <StateButton />
		      	:
          <button onClick={syncWithDarwin} className="btn btn-success" style={{ marginTop: 12 }}>
            Sync
          </button>

		  }
         
        
        <div>
		 
        </div>
      </div>
    </div>
  );
};

export default Index;
