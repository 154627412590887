import React from "react";
import './index.css'
import Logo from './logo_white.png'

function Loader(){
    return(
        <>
            <div style={{background: 'var(--primary-color)', color: 'var(--white-color)', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={Logo} style={{width: '80px'}} alt="Dice Logo" />
                    <p style={{fontSize: '18px', marginTop: '20px'}}>Loading...</p>
                </div>
            </div>
        </>
    )
}

function Spinner(props){
    return(
    <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center',padding: '1rem', ...props.style}}>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>)
}

export {Loader, Spinner};
export default Loader;
