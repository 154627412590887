import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../../../Model/Network/Config/Axios';
import { Modal } from '../../fModal/Modal';
import { showToast } from '../../fToast/ToastCallback';


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function MultipleAttachmentUploader(props) {


  const [attachments, setAttachments] = useState([]);
  const [buttonLoading,setButtonLoading] = useState(false);
  const onDrop = (acceptedFiles) => {
    setAttachments([...attachments, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps,isFocused,isDragAccept,isDragReject } = useDropzone({ 
    onDrop, 
    multiple: true,
    maxSize:50000000,
    accept: '.jpg,.jpeg,.pdf,.png,.xlsx,.csv,.eml,.txt,.doc,.docx,'
  });


  

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  const handleSubmit = async () => {


    if(!attachments.length) {
        showToast({
          type:'error',
          message:"Please select atleast 1 attachment"
        })
        return;
    }

    setButtonLoading(true);
    let tempItems = [];
    for (let i = 0; i < attachments.length; i++) {
      const formData = new FormData();
      formData.append('attachments[]', attachments[i]);
      const url = await axios({
        method: 'post',
        url: 'https://bifrost.eka.io/file',
        data: formData,
        headers: {
          clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
          clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
        }
      })
        .then(res => {
          return res.data.url
        })
        .catch(()=>{
          
        })
         tempItems.push({
          url,
          name:attachments[i].name
         });
        if(tempItems.length===attachments.length) {
           setButtonLoading(false);
           props.onUpload(tempItems);
           setAttachments([]);
           
        }
      
    }

   

    
  };



  return (
    <Modal 
        title="Upload"
        show={true}
        close={props.close}
        des="Select the file you want to upload"
        onSave={()=>{
           handleSubmit();
        }}
        stateLoading={buttonLoading}
        buttonName="Upload Document"
    >
      <div {...getRootProps({style})}>
        <input 
          {...getInputProps()} 
          accept=".jpg,.jpeg,.pdf,.png,.xlsx,.csv,.eml"

          />
        <p>Drag and drop some files here, or click to select files</p>
        <ul>
          {attachments.map((attachment, index) => (
            <li key={index}>{attachment.name}</li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}

export {MultipleAttachmentUploader}
