import React, {useEffect, useState} from 'react';
import { get, post } from '../../../../../../Model/Network/Config/Axios';
import {useParams} from 'react-router-dom';
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import {EmployeeSearchable} from "../../../../../Common/Class/Select/EmployeeSearchable";

function Index(props) {

    const {appId} = useParams();

    const [Secret, setSecret] = useState('');
    const [AppId, setAppId] = useState('');
    const [ClientId, setClientId] = useState('');
    const [Owner, setOwner] = useState(false);
    const [Loader, setLoader] = useState(true);
    const [Employee, setEmployee] = useState(undefined);
    const [Token, setToken] = useState('Loading....');

    useEffect(() => {
        fetchDetails()
        fetchToken()
    }, []);

    const fetchDetails = () => {
        get(`/settings/apps/apps/${appId}`, (e,r)=>{
            if(r){
                setSecret(r.clientSecret)
                setAppId(r.appId)
                setClientId(r.clientId)
                setOwner(r.owner)
                setLoader(false);

            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading details'
                })
            }

        },{})
    }

    const regenerate=()=>{
        setLoader(true);
        if(Owner){
            post(`/settings/apps/apps/${appId}/regenerate`,{}, (e,r)=>{
                if(r){
                    fetchDetails();
                    setLoader(false);
                }else if(e){
                    showToast({
                        type:'error',
                        message: 'Problem in regenerating credentials'
                    })
                }

            })
        }
    }

    const fetchToken = () => {
        get(`/settings/apps/apps/${appId}/token`, (e,r)=>{
            if(r){
                if(r.token){
                    setToken(r.token)
                }else{
                    setToken(undefined)
                }
            }else if(e){
                setToken(undefined)
                showToast({
                    type:'error',
                    message: 'Problem in fetching token'
                })
            }

        })
    }

    const generateToken = () => {
        if(Employee){
            post(`/settings/apps/apps/${appId}/token`, {code: Employee.value}, (e,r)=>{
                if(r){
                    fetchToken()
                    setEmployee(undefined);
                }else if(e){
                    setEmployee(undefined);
                    showToast({
                        type:'error',
                        message: 'Problem in creating token'
                    })
                }

            })
        }
    }

    return (<>
        <div className='CustomApp' style={{marginTop: 40, display: 'flex', width: '100%', backgroundColor: 'var(--white-color)', boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 0.2rem 1rem', borderRadius: 20, justifyContent: 'space-between'}}>
            <div className="FadeLeft-Effect" style={{display: 'flex', width: '100%',alignItems: 'stretch'}}>
                <div style={{display: 'flex', width: '100%',alignItems: 'stretch'}}>
                    <div style={{width: '100%',padding: 40, display: 'flex'}}>
                        <div style={{paddingRight: '30px', width: '50%'}}>
                            <span id="Login_Heading"style={{fontSize: '22px', fontWeight: 'bold'}}>App Credentials</span><br/>
                                <div className='tile' style={{background: 'var(--primary-color)', fontSize: '12px', color: '#fff', display: 'inline-flex', justifyContent: 'start',marginTop: '30px', borderRadius: '15px', marginLeft:'0px'}}>
                                    <svg style={{fontSize: '18px',marginRight: '20px'}} className="bi bi-question-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
                                    </svg>
                                    Use these credential inside your app to connect your App
                            </div>
                            <div style={{marginTop: '20px', marginLeft: '-8px', maxWidth: '500px'}}>
                                <div style={{backgroundColor: 'var(--lite-color)', borderRadius: 8, padding: '10px', margin: '8px', fontSize: '12px', color: 'var(--text-color)', wordBreak: 'break-all'}}>
                                    App ID :&nbsp;&nbsp;<span style={{color: '#00aff0', fontFamily: 'Courier'}}>{Loader?'':AppId}</span>
                                </div>
                                <div style={{backgroundColor: 'var(--lite-color)', borderRadius: 8, padding: '10px', margin: '8px', fontSize: '12px', color: 'var(--text-color)', wordBreak: 'break-all'}}>
                                    Client ID :&nbsp;&nbsp;<span style={{color: '#00aff0', fontFamily: 'Courier'}}>{Loader?'':ClientId}</span>
                                </div>
                                <div style={{backgroundColor: 'var(--lite-color)', borderRadius: 8, padding: '10px', margin: '8px', fontSize: '12px', color: 'var(--text-color)', wordBreak: 'break-all'}}>
                                    Secret :&nbsp;&nbsp;<span style={{color: 'var(--primary-color)', fontFamily: 'Courier'}}>{Loader?'':Secret}</span>
                                </div>
                            </div>
                           {
                               Owner?
                               <div onClick={regenerate} className='CustomApp-tile' style={{width: '220px', height: '30px', borderRadius: '8px', marginLeft:'0px', marginTop: '30px'}}>
                                <svg style={{marginRight: '10px', fontSize: '16px'}} className="bi bi-arrow-counterclockwise" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path>
                                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path>
                                </svg>
                                    Regenerate Credentials
                                </div>
                                :<></>
                           }
                        </div>
                        {
                            Owner?
                            <div style={{paddingLeft:' 40px', borderLeft: '1px solid var(--border-color)', width: '50%'}}>
                                <span id="Login_Heading"style={{fontSize: '22px', fontWeight: 'bold'}}>User Token</span><br/>
                                {
                                    Token?
                                    <div style={{marginTop: '30px', marginLeft: '-8px', maxWidth: '500px'}}>
                                        <div style={{backgroundColor: 'var(--lite-color)', borderRadius: 8, padding: '10px', margin: '8px', fontSize: '12px', color: 'var(--text-color)', wordBreak: 'break-all'}}>
                                            Token :&nbsp;&nbsp;<span style={{color: '#fd7e14', fontFamily: 'Courier'}}>{Token}</span>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                        <div onClick={()=>{navigator.clipboard.writeText(Token); showToast({type:'success', message: 'Copied'})}} className='CustomApp-tile' style={{width: '100px', height: '30px', borderRadius: '8px', marginTop: '30px'}}>
                                            <svg style={{fontSize: '16px', marginRight: '10px'}} className="bi bi-files" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"></path>
                                            </svg>
                                            Copy
                                        </div>
                                        <div onClick={()=>setToken(undefined)} className='CustomApp-tile' style={{width: '150px', height: '30px', borderRadius: '8px', marginTop: '30px'}}>
                                            New User Token
                                        </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                    <div style={{marginTop: '20px'}}>
                                        <EmployeeSearchable onSelect={setEmployee} employee={Employee} />
                                    </div>
                                    <div onClick={generateToken} className='CustomApp-tile' style={{width: '150px', height: '30px', borderRadius: '8px', marginLeft:'0px', marginTop: '30px'}}>
                                            Generate Token
                                    </div>
                                    </>
                                }
                            </div>
                            :<></>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Index;
