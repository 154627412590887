import React from 'react';



const PhotoName = ({fileNames={},attachments=[]}) => {


    let mapFileName = (item,index) => {

		if(!fileNames[item]) {
            return <div key={index} />
        }
		return(
			<div style={{
				display:'flex',
				alignItems:'center',
				fontSize:12,
				fontWeight:'bold',
				marginBottom:10,
				cursor:'pointer'
			}} onClick={()=>{
				window.open(item,"_blank")
			}} key={index}>
				 #{index+1}.
				<p style={{marginLeft:5}}>
					{fileNames[item]}
				</p>
			</div>
		)
	}


    return(
        <>
		{Object.keys(fileNames).length>0?<p style={{marginTop:5,marginBottom:5,fontWeight:'bold'}}>File Names :</p>:null}
        {
			attachments.length>0 ?
				<div style={{
					marginTop:10
				}}>
					{attachments.map(mapFileName)}
				</div>
				:null
		}
        </>
    )
}

export {PhotoName}