import React, { useState } from 'react';
import {connect} from 'react-redux'
import _ from 'lodash'
import Down from './down.png'
import ICONS from './icons'

const Sidebar  = (props)=> {

    const [getKey, setKey] = useState(undefined);

    const mapItem = (data) => {
        let config = props.config;
        if (data.module && !_.includes(config.modules, data.module)) {
            return;
        }
        if (data.needSuper && !config.isSuper) {
            return;
        }
        if(data.items && data.items.length>0){
            let isOpen = getKey && getKey === data.key;
            if(!isOpen && data.active){
                setKey(data.key)
            }
            return (
               <div key={data.value}>
                   <div style={{position:'relative'}} className={`item normal ${data.active ? "active" : null}`}
                        onClick={()=>{
                            if(isOpen){
                                setKey(undefined)
                            }else{
                                setKey(data.key)
                                props.route.push(data.link)
                            }
                        }}
                        key={data.value}>
                       <div>
                           <div style={{ position: 'relative' }}>
                               <div className={`${data.cname} icon`} />
                           </div>
                       </div>
                       <img style={{
                           width:14,
                           height:14,
                           right:20,
                           position:'absolute',
                           top:15,
                           transform:isOpen?'rotate(180deg)':'none'
                       }} src={Down} alt="down" />
                   </div>
                   {
                       isOpen?
                           <div style={{
                               margin:20,
                               borderRadius:12,
                               marginTop:-20,
                               marginBottom:12,
                               padding:15,
                               paddingTop:18,
                               background:"#f6f6fa"
                           }}>
                               {
                                   data.items.map(item=>{
                                       return (
                                           <div key={item.path} onClick={()=>{
                                               props.route.push(item.path)
                                           }} style={{
                                                cursor:'pointer',
                                               display:'flex',
                                               padding:"7px",
                                               alignItems:'center',
                                               ...item.active(data.current)?{
                                                    color:"var(--primary-color)",
                                                   fontWeight:600
                                               }:{
                                                   color:"var(--text-color)",
                                                   fontWeight:600,
                                               }
                                           }}>
                                               <p style={{
                                                   fontSize:12,
                                                   textTransform:'uppercase'
                                               }}>
                                                   {
                                                       item.name
                                                   }
                                               </p>
                                           </div>
                                       )
                                   })
                               }
                           </div>:undefined
                   }
               </div>
            )
        }
        return (
            <div data-tip={data.value} style={{borderRadius: '50px', border: '1px solid var(--border-color)', padding: '15px', margin: '8px 10px', marginTop: '10px'}} className={`item normal ${data.active ? "active" : null}`} onClick={() => {
                setKey(undefined)
                if (data.link) {
                    props.route.push(data.link)
                } else {
                    if (data.onClick)
                        data.onClick()
                }
            }} key={data.value}>
                <div>
                    <div style={{ position: 'relative', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {data.active?<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fill: 'var(--white-color)'}}>{ICONS[data.cname]}</div>:
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fill: 'var(--light-color)'}}>{ICONS[data.cname]}</div>}
                    </div>
                </div>
            </div>
        )
    }


        const { common } = props;
        if(common.length===0)
            return (<div/>);
        return (
            <div className="sidebar-container" style={{paddingTop: '10px'}}>
                <div className="csidebar">
                    <div className="sideArea">
                        {
                            common.map(mapItem)
                        }
                    </div>
                </div>
                <div style={{borderBottom: '1px solid var(--border-color)', paddingTop: '10px', width: '70%', margin: 'auto'}}/>
            </div>
        )
    }

const Csidebar = connect(state => ({
    config: state.auth.config
}))(Sidebar)

export default Csidebar
