import React from 'react'
import {useSelector} from "react-redux";
import MultiSubmitInvoice from './MultiSubmitInvoice'
import SingleLine from './SingleLine'

export default function Render(props) {
	let {config} = useSelector(state => ({config:state.auth.config}))
	let {plugins} = config;
	let {vendorMultiLine} = plugins;
	if(vendorMultiLine){
		return  (<MultiSubmitInvoice {...props}/>)
	}else{
		return (<SingleLine {...props}/>)
	}
}
