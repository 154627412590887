import createReactClass from 'create-react-class';
import React from 'react';

const inject = (view, presenter, model) => createReactClass({
	displayName: `ConnectComponent(${view.name}, ${presenter.name})`,
	render: function () {
		const _presenter = new presenter(new model());
		return React.createElement(view, {...this.props, presentor: _presenter})
	},
});


export {
	inject
}
