import React from 'react';

const Items = (props) => {

    let header = (value,style) => {
        return(
            <div style={{
                borderRight:'1px solid var(--border-color)',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                padding:'0px 5px',
                minHeight:80,
                fontSize:13,
                ...style
            }}>
                {value}
            </div>
        )
    }
    let {procured} = props.data;
    return(
        <>
            <p style={{
                fontWeight:600,
                height:50,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}>Delivery Details</p>
            <div style={{
                display:'flex',
                flex:1,
                border:'1px solid var(--border-color)'
            }}>
               {header('S.No',{width:50})}
               {header('Item Code',{flex:1})}
               {header('Description',{flex:1})}
               {header('Location',{flex:1})}
               {header('Location Address',{flex:1})}
               {header('Quantity',{flex:1})}
            </div>
            {procured.map((val,index)=>{
                return(
                    <div key={index} style={{
                        display:'flex',
                        flex:1,
                        border:'1px solid var(--border-color)'
                    }}>
                        {header(index+1,{width:50})}
                        {header(val.id,{flex:1})}
                        {header(val.description,{flex:1})}
                        {header(val.officeName,{flex:1})}
                        {header(`${val.officeAddress}`,{flex:1})}
                        {header(val.qtty,{flex:1})}

                    </div>
                )
            })}

        </>
    )
}

export default Items;