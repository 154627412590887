import React from 'react'
import Props from 'prop-types'
import './index.css'
import PhotoTile from '../fPhoto'
import { useReadAccessConfig } from '../../Hooks/ReadAccessConfig'
const Card=React.memo((props)=>{
	let readOnly = useReadAccessConfig();

	const renderPairs = (item, index)=>{
		return(
			<div key={`lineItems-${index}`} className='Voucher-lineItems'><span style={{color: '#666', marginRight: '3px'}}>{item.label}</span>: {item.value}</div>
		)
	}

	return(
		<div id={`voucher-${props.id}`} onClick={props.onClick} className='tile FadeUp-Effect' style={{borderRadius: props.flag&& props.flag.value!==''?'0 1.2rem 1.2rem 1.2rem':'1.2rem', marginTop: props.flag?'30px':'10px', padding: '1.2rem',  minWidth: '275px', maxWidth: '275px',  minHeight: '90px', alignItems: 'center', position: 'relative', ...props.style}}>
			<div style={{maxHeight: '100%', overflow: 'hidden',...props.innerStyle}}>
				<div style={{fontSize: '14px', fontWeight:600, marginBottom: '10px'}}>
					{props.headings.map((item,index)=>{
						return (<div key={`heading-${index}`}>{item}<br/></div>)
					})}
				</div>

				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '10px'}}>
				<PhotoTile onClick={()=>{
					if(props.attachment.includes('pdf') && props.onPdfClick){
						props.onPdfClick();
					}
					else if(props.attachment.includes('pdf')||props.attachment.includes('xlsx')||props.attachment.includes('csv')) {
						window.open(props.attachment,'_blank')
					} else {
						if(props.onPhotoClick){
							props.onPhotoClick();
						} else {
							window.open(props.attachment,'_blank')
						}
					}
				}}  link={props.attachment}/>

				<div style={{marginLeft: '10px'}}>
					{props.details?props.details.filter(i=>i!==undefined).map(renderPairs):<></>}
				</div>

				</div>
				{props.flag && props.flag.value!==''?
				<div className='capsule' style={{fontSize: '9px', position: 'absolute', borderRadius: '0.6rem 0.6rem 0px 0px', top: -30, left: '0', padding: '4px 15px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '10px', marginLeft: 0, border: 0, background: props.flag.color?props.flag.color:'var(--blue-color)', color: 'var(--force-white)'}}>{props.flag.value.toUpperCase()}</div>
				:<></>}
				{props.chip?
					<div className='capsule' style={{fontSize: '9px', position: 'absolute', borderRadius: '0.6rem 0.6rem 0px 0px', top: -30, right: '20px', padding: '4px 15px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '10px', marginLeft: 0, border: 0, background: props.flag.color?props.flag.color:'var(--red-color)', color: 'var(--force-white)'}}>{props.chip.toUpperCase()}</div>
				:<></>}
				{props.children}
			</div>
			{
				props.onEdit?
					<div onClick={(e)=>{e.stopPropagation();props.onEdit()}} className='flex-center delete_icon' style={{position: 'absolute', top:"-14px", right: "30px", width: '35px', height: '35px'}}>
						<div className='flex-center' style={{color: 'var(--white-color)', background: '#6c5dd3', borderRadius: '50px', fontSize: '10px', padding: '4px 14px'}}>
							Edit
						</div>
					</div>:undefined
			}
			{
				props.onDelete?
					<div onClick={(e)=>{e.stopPropagation();props.onDelete()}} className='flex-center delete_icon' style={{position: 'absolute', top:"-14px", right: "-11px", width: '35px', height: '35px'}}>
						<svg style={{fontSize: "22px", color: "var(--text-color)"}} className="bi bi-x-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
							<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
						</svg>
					</div>:undefined
			}

			{
				props.menu && !readOnly?
				<div onClick={(e)=>e.stopPropagation()} className='cards-dropdown'>
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
							<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
						</svg>
					</span>
					<div className="dropdown-content">
						<div className='dropdown-items'>
							{
								props.menu.map((item)=>{
									return (
										<div onClick={(e)=>{e.stopPropagation();item.onClick()}}>
											{item.icon?item.icon:<></>}
											{item.name}
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
				:<></>
			}
		</div>
	)
});


Card.propTypes = {
	name: Props.string
}

export {
	Card
}
