/* 
FloatFile common component for taking input file
*/


import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { showToast } from '../../fToast/ToastCallback';
import { Modal } from '../../fModal/Modal';


class FloatFile extends React.Component {


    state = {
        addModal: false,
        stateLoading: false,
        image: this.props.fileUrl || undefined,
        fileName:this.props.fileUrl || undefined
    }

    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    _upload = () => {
        let file = this.file_ref.files[0];

        const fileSize = this.file_ref.files[0].size / 1024 / 1024; // in MiB

        if (fileSize > 50) {
            showToast({
                type: 'error',
                message: "File size above 50mb is not accepted"
            })
            return
        }
        else if (file) {
            const formData = new FormData();
            formData.append('file', file);

            this.setState({
                ...this.state,
                stateLoading: true
            })

            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            })
                .then(res => {
                    showToast({
                        type: 'success',
                        message: 'File uploaded successfully'
                    })
                    this.setState({
                        ...this.state,
                        image: res.data.url,
                        fileName:file.name,
                        stateLoading: false,
                        addModal: false
                    })
                    this.props.onUpload && this.props.onUpload(res.data.url);
                })

        } else {
            showToast({
                type: 'error',
                message: 'Please select valid image'
            })
        }
    }


    getValue = () => {
        return this.state.image;
    }

    setValue = () => {
        this.setState({
            ...this.state,
            image: null,
            fileName:null
        })
    }

    render() {
        let { theme } = this.props.auth;
        let dark = theme === 'dark';

        return (
            <div style={{
                marginBottom: 20,
                ...this.props.style
            }}>
                <p style={{
                    color: 'var(--title-desc-color)',
                    fontSize: 15,
                }}>{this.props.title}</p>

                <div className="flex flex-v-center" style={{
                    backgroundColor: '#fff',
                    borderRadius: 12,
                    height: 50,
                    marginTop: 10,
                    color: 'var(--sidebar-active-color)',
                    cursor: 'pointer',
                    padding:16,
                    border:'1px solid var(--border-color)'
                }} onClick={() => {
                    this.setState({
                        ...this.state,
                        addModal: true
                    })
                }}>
                    <div>Select File</div>
                </div>

                {this.state.image ?
                    <a href={this.state.image} target="_blank" rel="noopener noreferrer" style={{
                        marginTop: 10,
                        marginBottom: 10,
                        textDecoration: 'none',
                        fontWeight: 600,
                        display: 'block'
                    }}>{this.state.fileName}</a> : null
                }
                <Modal
                    show={this.state.addModal}
                    title="Add Attachment"
                    desc="Please select the attachment you want to select"
                    close={() => {
                        this.setState({
                            addModal: false
                        })
                    }}
                    style={{
                        save: {
                            height: 45,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 600,
                            ...this.state.stateLoading ?
                                {
                                    ...dark ?
                                        {
                                            backgroundColor: 'var(--right-backgroundColor)'
                                        }
                                        :
                                        {
                                            backgroundColor: '#fff'
                                        }
                                } : null
                        }
                    }}
                    stateLoading={this.state.stateLoading}
                    buttonName="Upload Attachment"
                    onSave={this._upload}
                >
                    <div style={{
                        border: '1px solid var(--border-color)',
                        padding: '10px 20px',
                        marginBottom: 20,
                        marginTop: 20,
                        borderRadius: 4
                    }}>

                        <input
                            type="file"
                            accept=".jpg,.jpeg,.pdf,.png,.xlsx,.csv"
                            onChange={() => { }} ref={ref => this.file_ref = ref} />
                    </div>
                </Modal>


            </div>
        )
    }
}

export default connect(state => {
    return {
        auth: state.auth
    }
}, null)(FloatFile);
