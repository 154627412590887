import React, { useEffect, useState } from 'react';
import { PageLoader } from '../../../../../Common/Components/PageLoader';
import { SidePane } from '../../../../../Common/fSidePane/RightPane';
import Details from './Details';


const RenewalTrackingDetails = (props) => {


    const [data,setData] = useState({});
    const [loading,setLoading] = useState(true);
    const [getTab] = useState(1);

    useEffect(()=>{
        if(props.data) {
            setData(props.data);
            setLoading(false);
        }
       
    },[props.data])

    
    
    if(loading) {
        return <SidePane button={false} onClose={()=>props.onClose(false)}>
                <PageLoader />
        </SidePane>
    }

    return(
        <SidePane button={false} onClose={()=>props.onClose(false)}>
        <div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
        <div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}} >Renewal Tracking</div>
        <div style={{color: 'var(--text-color)',marginBottom: '35px', fontWeight: 600, fontSize: '24px', lineHeight: '1.3'}}>All Renewal Tracking Details</div>

        <div className='FadeRight-Effect'>
            {
                getTab===1?<Details data={data} />:null
            }
        
        </div>
        </div>
    </SidePane>   
    )
}

export {RenewalTrackingDetails};
export default RenewalTrackingDetails;