import React, { useState } from 'react';
import Moment from 'moment';
import { Card } from '../../../../Common/fCards';
import { ViewInvoice } from '../../../fCommon/ViewInvoice';
import Empty from '../../../../Common/fCard/Empty';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';



const PastInvoices = (props) => {

    const [getInvoice,setInvoice] = useState(undefined);
    const [getInvoiceID,setInvoiceID] = useState(undefined);


    let mapCard = (item,index) =>{
        return(
        <Card  key={`Invoice-${index}`}
            attachment={item.file}
            flag={item.status==='COMPLETED'?{value: item.status, color: 'var(--green-color)'}:item.status==='APPROVAL'?{value: item.status, color: '#6f42c1'}:{value: item.status}}
            headings={[`${item.currency} ${numberWithCommas(item.currencyAmount)}`, item.owner.ownerName]}
            details={[{label: 'Date', value: Moment(item.createdAt).format(" hh:mm a DD MMM YY")}, {label: 'Invoice ID', value: item.invoiceId}, {label: 'Category', value: item.category},{label: 'Service', value: item.service}]}
            onClick={()=>{window.open(`/app/banking/invoice/${item.invoiceId}`,"_blank");setInvoice(item.invoiceId);setInvoiceID(item.id)}}>
          </Card>
        )
    }

    return(
        <div>
            {props.invoices && props.invoices.length>0 ?
                <div style={{marginLeft:20,display:'flex',overflowX: 'auto',overflowY: 'hidden'}}>
                    {props.invoices.map(mapCard)}
                </div>:
                <div style={{marginLeft:20}}>
                     <Empty />
                </div>
            }
            {
				getInvoice?<ViewInvoice id={getInvoice} invoiceId={getInvoiceID} onClose={()=>{setInvoice(undefined)}}/>:<></>
			}
        </div>
    )
}

export default PastInvoices
