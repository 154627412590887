import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'
import { convertToLakh } from '../../../Utils/NumberToComma';

/*
	<StackGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/

function StackGraph(props) {
	return (
		<div>
			<ReactApexChart options={{
				chart: {
					type: 'bar',
					height: props.height,
					stacked:true,
					toolbar: {
						tools: {
							download: true,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom',
						show:true
					},
					
				},
				legend:{
					markers:{
						fillColors:props.colors
					}
				},
				title: {
					text: props.title,
					align: 'left'
				},
				plotOptions: {
					bar: {
						horizontal: props.horizontal,
						endingShape: 'rounded',
						columnWidth:'55%',
						dataLabels: {
						  total: {
							enabled: true,
							formatter: (value) => convertToLakh(value),
							// function (value) {
							// 	if (value >= 100000) {
							// 	  return (value / 100000).toFixed(1) + 'lakh';
							// 	} else if (value >= 1000) {
							// 	  return (value / 1000).toFixed(1) + 'k';
							// 	}
							// 	return value;
							//   },
							style: {
							  fontSize: '13px',
							  fontWeight: 900
							}
						  }
						}
					  },
				},
				dataLabels: {
				 enabled: props.innerLabelEnable,
					formatter: function (value) {
						if (value >= 100000) {
						  return (value / 100000).toFixed(1) + 'lakh';
						} else if (value >= 1000) {
						  return (value / 1000).toFixed(1) + 'k';
						}
						return value;
					  }
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						opacity: 0.5
					},
				},
				yaxis: {
					categories: props.items,
					labels: {
					  formatter: function (value) {
						if (value >= 100000) {
						  return (value / 100000).toFixed(1) + 'lakh';
						} else if (value >= 1000) {
						  return (value / 1000).toFixed(1) + 'k';
						}
						return value;
					  }
					}
				  },
				xaxis: {
					categories: props.items,
				},
				fill: {
					opacity: 1
				},
			}} series={props.data} type="bar" height={props.height} />
		</div>
	)
}


StackGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

StackGraph.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:800,
	horizontal:true
}

export {
	StackGraph
}

export default StackGraph
