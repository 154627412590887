import React, {useState} from 'react';
import moment from 'moment';
import ViewGRN from './ViewGrn'

function PoGrns(props){
	const [GRNModal, setGRNModal] = useState(undefined);

    return (
        <>
        <div style={{padding: '20px 40px', borderRadius: '6px', marginTop: '20px'}}>
        <div style={{fontSize: '14px', fontWeight: 'bold'}}>GRNs</div>
           <table style={{border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px', fontSize: '12px', width: '100%'}}>
               <tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
                   <th style={{padding: '15px'}}>GRN ID</th>
                   <th style={{padding: '15px'}}>Date</th>
                   <th style={{padding: '15px'}}>Remarks</th>
                   <th style={{padding: '15px'}}>Status</th>
                   <th style={{padding: '15px'}}>Action</th>
               </tr>
               {props.data && props.data.length?
                   props.data.map(row=>{
                       return (
                           <tr style={{background: '#fff'}}>
                               <td style={{padding: '15px'}}>
                                   <b>{row.reference}</b>
                               </td>
                               <td style={{padding: '15px'}}>{moment(row.date).format('DD-MM-YYYY')}</td>
                               <td style={{padding: '15px'}}>{row.remarks || '-'}</td>
                               <td style={{padding: '15px'}}>
                               <div style={{ padding: '3px 7px',borderRadius: '5px',fontSize: '10px',color: '#fff',display: 'inline-flex',marginTop: '5px', background: row.status==='COMPLETED'?'#198754':'#0d6efd'}}>{row.status|| '-'}</div>
                               </td>
                               <td onClick={()=>setGRNModal(row.id)} style={{fontWeight: 'bold', padding: '15px', cursor: 'pointer', color: 'var(--primary-color)'}}>
                                    <svg style={{marginRight: '10px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                                    </svg>
                                   OPEN
                                </td>
                           </tr>
                       )
                   }):
                   <tr style={{background: '#fff', textAlign: 'center'}}>
                        <td style={{padding: '15px'}} colSpan={4}>
                            No GRNs
                        </td>
                    </tr>

               }
           </table>
        </div>
        {
            GRNModal?
            <ViewGRN id={GRNModal} onClose={()=>setGRNModal(undefined)}/>
            :<></>
        }
        </>
    )
}

export default PoGrns;