import React from 'react'
import Props from 'prop-types'
import {Link} from 'react-router-dom'
import ImageZoom from 'react-medium-image-zoom';
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';

function KeyValue(props){
	return (
		<div  style={{ marginBottom: 14, marginRight: 8, ...props.style, }}>
			<div style={{
				position:'relative'
			}}>
				<p style={{
					fontSize: 9,
					letterSpacing:1,
					marginBottom: 4,
					textTransform: props.titleStyle ? props.titleStyle.textTransform ? props.titleStyle.textTransform : 'uppercase' : 'uppercase',
				}} className="bottom">
					{props.title}
				</p>
				{
					props.button?
					  <ReadOnly>
						<p onClick={props.button.onClick} style={{
							color:props.button.color,
							fontSize:11,
							fontWeight:600,
							right:2,
							top:4,
							textTransform:'uppercase',
							position:'absolute',
							cursor:'pointer'
						}}>
							{props.button.name}
						</p>
					  </ReadOnly>
						:undefined
				}
			</div>
			<div style={{
				border:'1px solid var(--border-color)',
				background: 'var(--off-white)',
				padding:'10px 15px',
				borderRadius:4,
				marginRight:12,
				marginTop:8
			}}>
				{
					props.to ?
						<Link target="_blank" to={props.to} className="top" style={{
							fontSize:12,
							fontWeight:500,
							letterSpacing:1,
							color:"var(--primary-color)"
						}}>
							{props.value ? props.value : "-"}
						</Link> :
						props.value && typeof props.value === 'string' && props.value.includes('jpeg') ?
							<ImageZoom zoomImage={{
								src: `${props.value}`,
								alt: 'object-fit',
								style: {
									objectFit: 'contain'
								}
							}} image={{
								src: `${props.value}`, alt: 'object-fit',
								style: {
									objectFit: 'cover',
									width: 80,
									marginRight: 12,
									height: 80,
									marginTop: 4,
									marginBottom: 4
								}
							}} />
							:
							(
								<p className="top" style={{
									fontSize:12,
									fontWeight:500,
									letterSpacing:1,
									color:"#666",
									whiteSpace:'nowrap',
									overflow:'hidden',

								}}>
									{props.value ? props.value : "-"}
								</p>
							)
				}
			</div>
		</div>
	)
}

KeyValue.propTypes = {
	title: Props.string.isRequired,
	value: Props.any
}

export {
	KeyValue
}
