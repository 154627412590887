import React from 'react';
import './fInvoice.css'

const Invoice = (props) => {


    let data = props.data;
    let {billingTo,shippingTo, taxAmt} = data;
    let {discount} = props;
    let {vendor} = props;

    let base = 0;

    if(!taxAmt)
        taxAmt = 0;

    data.lineItems.forEach(item=>{
        base = base + parseFloat(item.base);
    })

    let discountAmount = base * (discount/100);

    let total = base - discountAmount;

    const renderTable = () => {
        return (
           <div>
               {
                   data.lineItems.map(item=>{
                       return (
                           <div key={item.name} style={{display: 'flex', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                               <div style={{width: '160px'}}>{item.name}<br></br><span style ={{fontSize:10}}>{item.service.label}<br/>Code: {item.hsn}</span></div>
                               <div style={{width: '100px',}}> {item.qtty}</div>
                               <div style={{width: '100px',}}> {item.price}</div>
                               <div style={{width: '100px', textAlign: 'right'}}> {item.base}</div>
                           </div>
                       )
                   })
               }
           </div>
        )
    }
    return (
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
            <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>INVOICE</div>

            <div style={{border: '1px solid var(--border-color)', padding: '20px', borderRadius: '15px', marginTop: '30px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <b>Vendor</b><br/>
                        {
                            vendor?
                                <div>
                                    {vendor.name}<br/>
                                    Email: {vendor.email}<br/>
                                    GSTIN: {vendor.gstin}
                                </div>:<div>
                                    Select vendor to continue
                                </div>
                        }
                    </div>
                    <div style={{textAlign: 'right'}}>
                        Date:{data.billDate ? data.billDate : ''}<br/>
                        Due Date:{data.due ? data.due : ''}<br/>
                        Order ID: {data.billNumber}<br/>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <div style={{paddingRight: '20px'}}>
                        <b>Billing Address</b><br/>
                        {
                            billingTo?
                                <div>
                                    {billingTo.name}<br/>
                                    {billingTo.address}<br></br>
                                    Gstin : {billingTo.gstin}
                                </div>:<div>
                                    Select billing to
                                </div>
                        }
                    </div>
                    <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                        <b>Shipping Address</b><br/>
                        {
                            shippingTo?
                                <div>
                                    {shippingTo.name}<br/>
                                    Gstin : {shippingTo.gstin ? shippingTo.gstin : 'NA'}
                                </div>:<div>
                                    Select ship to
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '30px',
                fontWeight: 'bold',
                paddingBottom: '10px',
                borderBottom: '1px solid var(--border-color)'
            }}>
                <div style={{width: '160px'}}>Description</div>
                <div style={{width: '100px',}}>Qty</div>
                <div style={{width: '100px',}}>Price</div>
                <div style={{width: '100px', textAlign: 'right'}}>Amount</div>
            </div>

            {renderTable()}

            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Base Amt:</div>
                    <div> {base}</div>
                </div>
            </div>
            <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
                <div className='wrap'>
                    <div>Discount Amt:</div>
                    <div> {discountAmount}</div>
                </div>
            </div>

            <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
                <div className='wrap'>
                    <div>Tax Amt:</div>
                    <div> {taxAmt}</div>
                </div>
            </div>

            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Total Amt:</div>
                    <div> {parseInt(total)+parseInt(taxAmt)}</div>
                </div>
            </div>


            <div style={{height: '40px'}}/>
        </div>
    )
}

export default Invoice;
