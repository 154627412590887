import React from 'react'
import {pluginsPostApis} from '../../../../../Model/Network/Config/Axios';
import {Modal} from '../../../../Common/fModal/Modal';
import {FloatInput} from '../../../../Common/Class/FloatLabelInput';
import {showToast} from '../../../../Common/fToast/ToastCallback';


class Connect extends React.Component {

    state = {
        connect: false,
        modal: false,
        emails: []
    }

    componentDidMount() {
        if (this.props.handle) {
            this.props.handle(this);
        }
    }

    connect = () => {
        this.setState({
            connect: true
        })
    }

    _connect = () => {
        let primaryEmail = this.state.emails[0];
        let secondaryEmails = [];
        this.state.emails.forEach((val, index) => {
            if (index > 0) {
                secondaryEmails.push(val)
            }
        })
        pluginsPostApis(`plugins/advanceLedgerReport/install`, { primaryEmail, secondaryEmails }, (e, r) => {
            if (r) {
                this.props.onRefresh()
            }
        })
    }


    render() {
        let item = this.props.item;
        if (item && item.installed) {
            return (
                <div style={{
                    padding: 12,
                    width: "25%"
                }}>
                    <div style={{
                        padding: 18,
                        background:"var(--white-color)",
                        borderRadius:8,
                        boxShadow:"7px 4px 8px rgba(1,1,1,.05)",
                        position: 'relative',
                        border: '1px solid var(--border-color)',
                    }}>
                        {
                            item.status === "ERRROR" ? <p style={{
                                color: 'red',
                                position: 'absolute',
                                right: 12,
                                fontWeight: 700,
                                fontSize: 11,
                                top: 12
                            }}>Error</p> : <p style={{
                                color: 'var(--primary-color)',
                                position: 'absolute',
                                right: 12,
                                fontWeight: 700,
                                fontSize: 11,
                                top: 12
                            }}>{item.status}</p>
                        }
                        <img src={item.logo} alt={item.name} style={{
                            height: 30
                        }} />
                        <p style={{
                            fontWeight: 700,
                            fontSize: 19,
                            marginTop: 15,
                            height: 23,
                            overflow: 'hidden',
                            color: "var(--text-color)"
                        }}>
                            {item.name}
                        </p>
                        <p style={{
                            fontWeight: 400,
                            fontSize: 11,
                            marginTop: 12,
                            height: 65,
                            marginBottom: 12,
                            color: "var(--light-color)"
                        }}>
                            {item.description}
                        </p>
                        <div style={{
                            display: 'flex',
                            marginTop: 20,
                            flexDirection: 'row'
                        }}>
                            <p onClick={() => {
                                this.props.history.push("/app/apps/advanceledger")
                            }} style={{
                                fontSize: 14,
                                fontWeight: 600,
                                cursor: 'pointer',
                                color: "var(--primary-color)"
                            }}>
                                View Plugin
							</p>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div key={item.pluginType} style={{
                padding: 12,
                width: "25%"
            }}>
                <div style={{
                    padding: 18,
                    background:"var(--white-color)",
                    border: '1px solid var(--border-color)',
                }}>
                    <img src={item.logo} alt={item.name} style={{
                        height: 30
                    }} />
                    <p style={{
                        fontWeight: 700,
                        fontSize: 19,
                        overflow: 'hidden',
                        height: 23,
                        whiteSpace: 'nowrap',
                        marginTop: 15,
                        color: "var(--text-color)"
                    }}>
                        {item.name}
                    </p>
                    <p style={{
                        fontWeight: 400,
                        fontSize: 11,
                        marginTop: 12,
                        height: 65,
                        marginBottom: 12,
                        color: "var(--light-color)"
                    }}>
                        {item.description}
                    </p>
                    <div style={{
                        display: 'flex',
                        marginTop: 20,
                        flexDirection: 'row'
                    }}>
                        <p onClick={() => {
                            this.setState({
                                ...this.state,
                                modal: true
                            })
                        }} style={{
                            fontSize: 14,
                            fontWeight: 600,
                            cursor: 'pointer',
                            color: "var(--primary-color)"
                        }}>
                            Install Plugin
						</p>
                    </div>
                </div>
                {
                    this.state.modal ?
                        <Modal
                            show={this.state.modal}
                            title="Enter your Email"
                            des="Enter your mail whom you want to sent ledger report"
                            close={() => {
                                this.setState({
                                    ...this.state,
                                    modal: false
                                })
                            }}
                            hideButton={true}
                            onSave={() => { }}
                        >

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 15
                            }}>
                                <FloatInput type="text" title="Email" handleRef={ref => this.email = ref} />
                                <div style={{
                                    marginLeft: 18,
                                    marginTop: 28,
                                    width: 150,
                                    fontWeight: 600,
                                    textTransform: 'uppercase'
                                }} onClick={() => {
                                    let emails = this.state.emails;
                                    let email = this.email.value();
                                    if (!email) {
                                        showToast({
                                            type: 'error',
                                            message: 'Please enter a condition'
                                        })
                                        return;
                                    }
                                    emails = emails.filter(i => i.toLowerCase() !== email.toLowerCase());
                                    emails.push(email);
                                    this.email.setValue("");
                                    this.setState({
                                        ...this.state,
                                        emails
                                    })
                                }} className="btn btn-portlet">
                                    Add
                                </div>
                            </div>


                            {this.state.emails.map((item, index) => {
                                return (
                                    <div style={{
                                        borderBottom: '1px solid var(--border-color)',
                                        paddingBottom: 8,
                                        marginBottom: 10,

                                    }} key={index}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <div>
                                                <p style={{
                                                    fontSize: 13,
                                                    color: 'var(--light-color)'
                                                }}>Email {index + 1}</p>
                                                <p>{item}</p>
                                            </div>
                                            <img src={require(`./close.png`)} style={{
                                                height: 14,
                                                width: 14,
                                                cursor: 'pointer',
                                                marginTop: 10
                                            }} alt="" onClick={() => {
                                                let emails = this.state.emails.filter(i => i.toLowerCase() !== item.toLowerCase());
                                                this.setState({
                                                    ...this.state,
                                                    emails
                                                })
                                            }} />
                                        </div>


                                    </div>
                                )
                            })}

                            <div onClick={() => {
                                let emails = this.state.emails;
                                if (emails.length === 0) {
                                    showToast({
                                        type: 'error',
                                        message: 'Please add a mail id'
                                    })
                                    return;
                                }

                                this._connect();

                            }} style={{
                                width: '100%',
                                height: 45,
                                textAlign: 'center',
                                padding: 12,
                                backgroundColor: 'var(--primary-color)',
                                color: 'var(--white-color)',
                                textTransform: 'uppercase',
                                marginTop: 20,
                                cursor: 'pointer'
                            }}>
                                Continue
                            </div>

                        </Modal> : null
                }
            </div>
        )
    }

}

export default Connect
