import React, {useEffect, useState} from 'react';
import { TitleTopHeader } from '../../../../Header/TopHeader';
import {useLocation, useParams, Switch, Route} from 'react-router-dom'
import Credentials from './Credentials'
import Roles from './Roles'
import Deployment from './Deployment'
import Settings from './Settings'
import {get} from '../../../../../Model/Network/Config/Axios'
import { showToast } from '../../../../Common/fToast/ToastCallback';

function Index(props) {

	const {appId} = useParams()
	const [owner,setOwner] = useState(false);
	let route = useLocation().pathname

	useEffect(() => {
        fetchDetails()
    }, []);

    const fetchDetails = () => {
		get(`market/${appId}/details`, (e,r)=>{
            if(r){
				setOwner(r.self)
            }else if(e){
				showToast({
					type: 'error',
					messge: 'Error in loading details'
				})
            }

        },{})

		
    }

	let items = [{
		active: route === `/app/user/${appId}/details`,
		name: `Details`,
		link:`/app/user/${appId}/details`
	}];
	if(owner) {
		items.push(...[
			{
				active: route === `/app/user/${appId}`,
				name: `Deployment`,
				link:`/app/user/${appId}`
			},
			{
				active: route === `/app/user/${appId}/credentials`,
				name: `Credentials`,
				link:`/app/user/${appId}/credentials`
			},
			{
				active: route === `/app/user/${appId}/roles`,
				name: `Roles`,
				link:`/app/user/${appId}/roles`
			},
		])
	}

    return (<>
        <div style={{padding: '50px'}}>
            <TitleTopHeader title="Custom Apps" des="Third Party Apps" items={items}/>
			<div style={{padding: '0px 0px 40px 40px'}}>
				<Switch>
					<Route path="/app/user/:appId" exact component={Deployment}/>
					<Route path="/app/user/:appId/details" exact component={Settings}/>
					<Route path="/app/user/:appId/credentials" exact component={Credentials}/>
					<Route path="/app/user/:appId/roles" exact component={Roles}/>
				</Switch>
			</div>
        </div>
    </>);
}

export default Index;
