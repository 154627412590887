import React from 'react';
import Moment from 'moment';
import _ from 'lodash';
import {pluginsGetApi} from "../../../../../Model/Network/Config/Axios";
import {Title as Header, TitleTopHeader} from "../../../../Header/TopHeader";
import {PageLoader} from '../../../../Common/Components/PageLoader';
import {NormalTable} from '../../../../Tables/NormalTable/NormalTable';
import {Filters} from '../../../../Common/fFilters';
import Expand from './Expand';
import {DownloadCsv} from '../../../../../Utils/Downloader';
import {Item} from '../../../fCommon/Item';
import Sync from './Sync';


class index extends React.Component {

	state = {
		loading: true,
		failedRequest: [],
		type: {
			label: "By Id",
			value: "INVOICE",

		},
		filters: [],
		id: undefined,
		sync: false,
		status: [
			{
				name: "Voucher",
				active: false,
				logic: (txn) => {
					return txn.eventType.includes("voucher");
				},
				color: "#5365EF"
			},
			{
				name: "Invoice",
				active: false,
				logic: (txn) => {
					return txn.eventType.includes("invoice");
				},
				color: "#15ADCA"
			},
			{
				name: "Travel",
				active: false,
				logic: (txn) => {
					return txn.eventType.includes("travel");
				},
				color: "#2CCA72"
			},

		],
		typeFilter: [
			{
				name: "Approved",
				active: false,
				logic: (txn) => {
					return txn.eventType.includes("approved");
				},
				color: "#5365EF"
			},
			{
				name: "Settled",
				active: false,
				logic: (txn) => {
					return txn.eventType.includes("settled");
				},
				color: "#15ADCA"
			},
		]

	}


	componentDidMount() {
		this.init();
	}

	init = () => {
		pluginsGetApi(`plugins/custom/cjdarcl/sapclone/sync/allFailed`, (e, r) => {
			if (r) {
				this.suggestions = {
					"INVOICE": r.darclSapItemSyncs.map(item => ({
						firstCriteria: this.status(item),
						value: this.status(item)
					}))
				};
				this.setState({
					loading: false,
					failedRequest: r.darclSapItemSyncs
				})
			}
		})
	}

	sync = (item) => {
		this.setState({
			sync: item
		})

	}


	status = (item) => {
		let eventRequest = item.eventRequest;
		if (typeof eventRequest === 'string') {
			eventRequest = JSON.parse(eventRequest)
		}

		if (item.eventType.includes('voucher')) {
			return eventRequest.voucherId ? eventRequest.voucherId : "-"
		} else if (item.eventType.includes('travel')) {
			return eventRequest.transId ? eventRequest.transId : "-"
		} else {
			return eventRequest.ekaInvoiceId ? eventRequest.ekaInvoiceId : "-"
		}
	}

	mapItem = (item, index) => {
		let active = this.state.id === item.id;



		let center = (
			<div key={index} className="border-bottom fintrip-department-item table-item">
				<div className="linear-layout horizontal" style={{
					flex: 1, cursor: 'pointer'
				}}>
					<div style={{
						flex: 2
					}}>
						<p className="top">{this.status(item)}</p>
						<p className="bottom">Event Type - {item.eventType}</p>
						<p className="bottom">Updated At - {Moment(item.updatedAt).format('DD-MM-YYYY')}</p>
					</div>

					<div style={{
						flex: 1,
						fontSize: 16
					}}>
						<p>{Moment(item.createdAt).format('DD-MM-YYYY')}</p>
					</div>
				</div>
				<div className="right-actions" style={{ width: 150 }}>
					<p style={{
						color: 'var(--red-color)',
						marginRight: 30,
						fontSize: 14,
						fontWeight: 600,
						cursor: 'pointer'
					}} onClick={() => {
						this.sync(item)
					}}>Sync</p>
					<p onClick={() => {
						if (this.state.id === item.id) {
							this.setState({
								...this.state,
								id: undefined
							})
						} else {
							this.setState({
								...this.state,
								id: item.id
							})
						}
					}} style={{
						color: 'var(--primary-color)',
						marginRight: 20,
						fontSize: 14,
						fontWeight: 600,
						cursor: 'pointer'
					}}>{active ? "Collapse" : "View"}</p>

				</div>
			</div>
		)

		if (item.id === this.state.id) {
			return (
				<Expand data={item}>
					{center}
				</Expand>
			)
		}
		return center;
	}


	addFilter = (value) => {
		if (value) {
			let type = this.state.type.value;
			let filters = this.state.filters;
			filters = filters.filter(item => {
				if (item.type === type && item.value === value)
					return false;
				else return true;
			})
			filters.push({
				type, value
			})
			this.setState({
				...this.state,
				filters
			})
		}
	}

	deleteSelectValue = item => {
		let filters = this.state.filters;
		filters = filters.filter(mItem => {
			if (item.type === mItem.type && item.value === mItem.value)
				return false;
			else return true;
		})
		this.setState({
			...this.state,
			filters
		})
	}

	filter = emps => {
		let filters = this.state.filters;
		let filterData = {}
		filters.forEach(f => {
			let items = filterData[f.type];
			items = items ? items : [];
			items.push(f.value)
			filterData[f.type] = items;
		})
		emps = emps.filter(item => {
			let invoiceFilter = filterData["INVOICE"];
			if (invoiceFilter) {

				if (!_.includes(invoiceFilter, this.status(item)))
					return false;
			}

			return true;
		})
		return emps;

	}


	filterByName = () => {
		let status = this.state.status;
		return (
			<div style={{
				marginTop: 4, marginBottom: 6
			}}>
				<p style={{
					fontSize: 11,
					fontWeight: 600,
					marginBottom: 12,
					color: "var(--text-color)"
				}}>
					By  Type:
				</p>
				<div style={{ paddingBottom: 20, display: 'flex' }}>
					{
						status.map((value, index) => {
							return (
								<Item color={value.color} key={index} onClick={
									() => {
										let status = this.state.status.map(mValue => {
											if (mValue.name === value.name) {
												mValue.active = !mValue.active;
											}
											return mValue;
										});
										this.setState({
											...this.state,
											status
										})
									}
								} title={value.name} selected={value.active} />
							)
						})
					}
				</div>
			</div>
		)
	}

	filterByType = () => {
		let status = this.state.typeFilter;
		return (
			<div style={{
				marginTop: 4, marginBottom: 6
			}}>
				<p style={{
					fontSize: 11,
					fontWeight: 600,
					marginBottom: 12,
					color: "var(--text-color)"
				}}>
					By Settled Type:
				</p>
				<div style={{ paddingBottom: 20, display: 'flex' }}>
					{
						status.map((value, index) => {
							return (
								<Item color={value.color} key={index} onClick={
									() => {
										let typeFilter = this.state.typeFilter.map(mValue => {
											if (mValue.name === value.name) {
												mValue.active = !mValue.active;
											}
											return mValue;
										});
										this.setState({
											...this.state,
											typeFilter
										})
									}
								} title={value.name} selected={value.active} />
							)
						})
					}
				</div>
			</div>
		)
	}



	handleStatusLogic = (txns, status) => {
		let anyActive = false;
		status.forEach(item => {
			if (!anyActive)
				anyActive = item.active;
		})
		if (anyActive) {
			txns = txns.filter(txn => {
				let active = false;
				status.forEach(value => {
					if (value.active) {
						if (!active) {
							active = value.logic(txn);
						}
					}
				})
				return active;
			})
		}
		return txns;
	}



	DownloadCsv = () => {
		let baseData = [
			[
				"Eka Invoice Id",
				"Event Failed Type",
				"Created At ",
				"Vendor Invoice Date",
				"Vendor Gstin",
				"Vendor Code",
				"Invoice Submission Date",
				"Total Amount",
				"Total Service Amount"
			]
		]


		let data = this.filter(this.state.failedRequest);
		data = this.handleStatusLogic(data, this.state.status);
		data = this.handleStatusLogic(data, this.state.typeFilter)

		data.forEach(item => {
			let eventRequest = item.eventRequest;

			if (typeof eventRequest === 'string') {
				eventRequest = JSON.parse(eventRequest);
			}


			baseData.push(
				[
					this.status(item),
					item.eventType,
					Moment(item.createdAt).format('DD-MM-YYYY'),
					eventRequest.vendorInvoiceDate,
					eventRequest.vendorGSTIN,
					eventRequest.vendorCode,
					eventRequest.invoiceSubmissionDate,
					eventRequest.totalAmt,
					eventRequest.totalServiceAmt
				]
			)
		})

		DownloadCsv("Sap_failed.csv", baseData);
	}




	render() {

		if (this.state.loading) {
			return <PageLoader />
		}

		let data = this.filter(this.state.failedRequest);
		data = this.handleStatusLogic(data, this.state.status);
		data = this.handleStatusLogic(data, this.state.typeFilter)

		return (
			<div>
				<TitleTopHeader items={[]} title="Sap Failed Request" des="List of all sap failed request" />
				<Header title="Sap Failed Request" des="List of all sap failed request" >
					<div style={{
						position: 'absolute',
						right: 10,
						top: 20,

					}} onClick={this.DownloadCsv}>
						<div style ={{
							display:'flex',
							alignItems:'center'
						}}>
							<div className="btn btn-portlet">Download Csv</div>
						</div>

					</div>
				</Header>
				<div className="title-container" style={{
					padding: 18
				}}>

					<Filters
						suggestion={this.suggestions} removeFilter={this.deleteSelectValue}
						addFilter={this.addFilter} filters={this.state.filters} onTypeChange={type => {
							this.setState({
								...this.state,
								type
							})
						}} type={this.state.type}
						types={[
							{
								label: "By Id",
								value: "INVOICE",

							}
						]}
					/>

					{this.filterByName()}
					{this.filterByType()}
					<NormalTable
						pagination={true}
						headers={
							[
								{
									name: 'Details',
									key: 'details',
									weight: 2
								},
								{
									name: 'Created At',
									key: 'event',
									weigth: 1
								},
								{
									name: '',
									key: 'width',
									width: 150
								}
							]}
						data={data}
						mapItem={this.mapItem}
					/>
				</div>
				{
					this.state.sync ?

						<Sync
							sync={this.state.sync}
							id={this.state.sync.id}
							onClose={() => {
								this.setState({
									...this.state,
									sync: false
								})
							}}
							onUpdate={() => {
								this.init();
								this.setState({
									...this.state,
									sync: false
								})
							}}
						/>
						: null

				}
			</div>
		)
	}

}

export default index
