import React from 'react';
import Pdf from './pdf.png'


export default function  Thummail(props) {


	let dim = '40px';
	let font = '1.2rem';

	switch (props.size) {
		case 'S':
			dim = '25px';
			font = '0.8rem';
			break;
		case 'XM':
			dim = '30px';
			font = '0.9rem';
			break;
		case 'L':
			dim = '50px';
			font = '1.4rem';
			break;
		default:
			dim = '40px';
			break;
	}

	const pallete = {
		a: "#C62828",
		b: "#AD1457",
		c: "#6A1B9A",
		d: "#4527A0",
		e: "#283593",
		f: "#1565C0",
		g: "#0277BD",
		h: "#00838F",
		i: "#00695C",
		j: "#2E7D32",
		k: "#558B2F",
		l: "#9E9D24",
		m: "#F57F17",
		n: "#E65100",
		o: "#4E342E",
		p: "#424242",
		q: "#37474F",
		r: "#884EA0",
		s: "#239B56",
		t: "#626567",
		u: "#C62828",
		v: "#880E4F",
		w: "#4A148C",
		x: "#4527A0",
		y: "#5D4037",
		z: "#212121",
		0: "#C62828",
		1: "#AD1457",
		2: "#6A1B9A",
		3: "#4527A0",
		4: "#283593",
		5: "#1565C0",
		6: "#0277BD",
		7: "#00838F",
		8: "#00695C",
		9: "#2E7D32",
	};
	if(props.thumb){
		console.log("****")
		return (
			<>
				{
					props.thumb.includes('pdf') ? (
						<div style={{
							width: dim,
							height: dim,
							borderRadius: "50px",
							objectFit:'contain',
							marginTop: -2,
							marginRight: "13px",
							overflow: "hidden"
						}} onClick={(e)=>{
							e.stopPropagation();
								props.thumbClick()
							}
						}>
							<img height={50} src={Pdf} alt="pdf icon" />
							{/* <PdfViewer file={props.thumb} onlyImage={true} /> */}
						</div>
						) : (
						<img onClick={(e)=>{
							if((props.thumb.includes('jpeg')) || (props.thumb.includes('jpg') || (props.thumb.includes('png')))) {
								e.stopPropagation();
								props.thumbClick()
							}
						}} alt="thumb" src={props.thumb} style={{
							width: dim,
							height: dim,
							borderRadius: "50px",
							objectFit:'contain',
							marginTop: -2,
							marginRight: "13px",
						}}/>	
					)
				}
		</>
		)
	}
	return (
		<div style={{display: "inline-block"}}>
			<div style={{
				backgroundColor: props.letter? pallete[props.letter.toLowerCase()]  : "#1490D4",
				width: dim,
				height: dim,
				borderRadius: "50px",
				color: 'var(--force-white)',
				fontSize: font,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				marginTop: -2,
				marginRight: "13px",
				...props.style
			}}>
				<span>{props.letter?props.letter.toUpperCase():'A'}</span>
			</div>
		</div>
	);
}
