import React from "react";
import {connect} from "react-redux";
import {attemptlogin} from "../../Redux/Actions/LoginController";
import Login from './assets/login_1.jpg'
import {showToast} from "../../Components/Common/fToast/ToastCallback";
import Query from "querystring";
import {FloatInput} from "../../Components/Common/Class/FloatLabelInput";

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
	}

	state = {
		on: false,
		name: undefined,
	}


	handleEnterLogin = (e) => {
		if (e.key === "Enter") {
			this.attemptLogin();
		}
	}

	resetLink = () => {
		let query = Query.parse(this.props.location.search.replace("?", ""));
		let pass = this.pass.value();
		let cpass = this.cpass.value();


		if (pass.length < 8) {
			showToast({
				type: 'error',
				message: 'Please enter mimimum 8 character password'
			})
			return;
		}
		if (pass !== cpass) {
			showToast({
				type: 'error',
				message: "Please enter a valid password"
			})
			return
		}
		this.presentor.changePassword({
			password: pass, token: query.token
		})
	}


	render() {
		return (
			<div onKeyPress={this.handleEnterLogin} style={{
				position: 'relative',
				background: 'var(--off-white)',
				width: '100%',
				height: '100vh',
			}}>
				<div style={{
					background: 'var(--white-color)',
					display: 'flex',
					flexDirection: 'row-reverse',
					justifyContent: 'center',
					height: '100vh'
				}}>
					<img src={Login} style={{ height: '100vh', display: 'block', margin: "0 auto" }} alt="Login" />
					<div style={{
						display: 'flex',
						flex: 1,
						alignItems: 'center',
						flexDirection: 'column',
						height: '100vh',
						justifyContent: 'center'
					}}>
						<div style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column'
						}}>
							<img alt="Dice Logo" src={require('./assets/eka_logo.png')} style={{ width: 175 }} />
							<h3 style={{ fontWeight: 700, marginTop: 20, fontSize: 51, color: "var(--light-color)" }}>
								Reset Password
							</h3>
							<div style={{
								position: 'relative',
								marginTop: 35,
								width: 475,
								marginBottom: 55
							}}>
								<div style={{
									height: 1,
									background: "var(--off-white)",
									position: 'absolute',
									left: 0,
									top: 10,
									right: 0,
									zIndex: 1
								}} />
								<h4 style={{
									fontWeight: 400,
									textAlign: 'center',
									background: 'var(--white-color)',
									display: 'inline',
									letterSpacing: 1,
									zIndex: 2,
									position: 'absolute',
									left: '30%',
									paddingLeft: 20,
									paddingRight: 20,
									fontSize: 14,
									color: "var(--light-color)"
								}}>
									Reset Password
								</h4>
							</div>
							<div style={{ width: 475 }}>
								<FloatInput handleRef={(ref) => {
									this.pass = ref
								}} type="password" title="New Password" />
								<FloatInput handleRef={(ref) => {
									this.cpass = ref
								}} type="password" title="Confirm Password" />
								<button style={{ width: "100%", marginTop: 35 }} onClick={this.resetLink}
									className='btn btn-portlet'>
									RESET PASSWORD
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
		login: (email, password) => {
			dispatch(attemptlogin(email, password))
		}
	}
})(View);
