import React, { useEffect, useState } from 'react';
import { get } from '../../../Model/Network/Config/Axios';
import { Modal } from '../fModal/Modal';


const VendorAddressSearch = (props) => {


    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});
    const [filtered,setFiltered] = useState({});
    const [select,setSelect] = useState(undefined);

    useEffect(()=>{
        let data  = {}
        if(props.office) {
            data.officeId = props.office
        }
        get(`vendor/contacts/${props.id}/addresses`,(e,r)=>{
            if(r) {
                setData(r.addresses);
                setFiltered(r.addresses);
                setLoading(false);
            }
        },{
            ...data
        })
    },[props.id])


	const onChangeValue = (val) => {
	let filteredData = data.filter(i => {
			if (i.title.toLowerCase().includes(val.toLowerCase())) {
				return true
			} else if (i.address && i.address.toLowerCase().includes(val.toLowerCase())) {
				return true
			} else {
				return false;
			}
		})
		setFiltered(filteredData)
	}

    return(
        <Modal
            show={true}
            title="Select Vendor Address"
            des="vendor address"
            close={props.close}
            onSave={()=>{
                props.onSelect(select)
            }}
            buttonName={'Select'}
        >
            {
                loading ? 
                    <div />:
                    <>
                        {
						data.length>0?
							<div>
									<div style={{ display: 'flex', position: 'relative', marginTop: '20px', marginBottom: '20px',width:'100%'}}>
										<div style={{ height: '40px', borderRadius: '1px', width: '100%',  }}>
											<input style={{ height: '100%', width: '100%',  border: '1px solid #ddd', backgroundColor: 'rgb(255,255,255)', fontSize: '16px', borderRadius: '8px', padding: '16px' }}
												type='text'
												placeholder={"Search vendor address"}
												onChange={(e) => { onChangeValue(e.target.value) }}
											/>
										</div>
									</div>
								<p style={{fontSize:11,color:'var(--light-color)',marginTop:8}}>
									All Addresses
								</p>
                                <div style={{marginBottom:40}}>
								{
									filtered.map(item=>{
                                        let active = item.id === select;
										return(
											<div key={item.title} style={{position:'relative',marginTop:8}}>
												<p style={{fontSize:15, fontWeight:600,color:"var(--text-color)"}}>
													{item.title}
												</p>
												<p style={{fontSize:11,color:"var(--light-color)"}}>
													{item.address}
												</p>
												<p style={{fontSize:11,color:"var(--light-color)"}}>
													{item.city}, {item.state}
												</p>
												<p style={{fontSize:11,color:"var(--light-color)"}}>
													{item.gstin}
												</p>
												<p onClick={()=>{
													setSelect(item.id)
												}} style={{
													position:'absolute',
													right:0,
													top:12,
													width:80,
													height:24,
													color:active?"var(--white-color)":"var(--text-color)",
													background:active?"var(--primary-color)":"var(--border-color)",
													cursor:'pointer',
													borderRadius:2,
													padding:5,
													fontWeight:600,
													fontSize:11,
													textAlign:'center'
												}}>
													{active?`Selected`:`Select`}
												</p>
											</div>
										)
									})
								}
                                </div>
							</div>:undefined
					}
                  </>
            
            }

        </Modal>
    )
}

export {VendorAddressSearch}