import React from 'react';
import PropTypes from 'prop-types'
import { useReadAccessConfig } from '../../../Hooks/ReadAccessConfig';
import { useWriteAccessConfig } from '../../../Hooks/useWriteAccessConfig';
import {useSelector} from 'react-redux';

/*
PROPS:

onClick = ()
title = string
icon = JSX, SVG
*/

const ReadOnly = ({type=undefined,...props}) => {

    const readAccess = useReadAccessConfig();
    const writeAccess = useWriteAccessConfig();
    const {isSuper} = useSelector(state=>state.auth.config);    
    


    if(isSuper) {
        return (<>{props.children}</>);

    }
    if(type && writeAccess && writeAccess[type]) {
        return (<>{props.children}</>);  
    }

    if(readAccess) {
        return <></>
    }

    if(type && writeAccess && !writeAccess[type]) {
        return (<div/>);  
    }

    return (<>{props.children}</>);
}



ReadOnly.propTypes = {
    title:PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
    icon:PropTypes.object
}

export {ReadOnly}

