import React from "react";
import Moment from 'moment';

export default function Index(props) {
	const { data } = props;
	let { message } = data;
	let time = Moment(data.createdAt).format('hh:mm A');

	if (props.hidden && !props.date) {
		return (
			<div className="luna-chat" style={{ padding: "4px 2px", paddingLeft: 56, display: 'flex', alignItems: 'center' }}>
				<p style={{
					fontSize: 15
				}}>{message.title}</p>
				<p className="hidden" style={{
					marginLeft: 8, fontSize: 10, color: 'var(--light-color)', cursor: 'pointer', paddingTop: 3
				}}>{time}</p>
			</div>
		)
	}
	return (
		<div>
			{props.date ?
				<div style={{ display: 'flex', flex: 1,marginTop:5,marginBottom:18}}>
					<div style={{ borderBottom: '1px solid var(--border-color)', flex: 1,display:'flex',justifyContent:'center' }}>
						<p style = {{
							padding:'5px 10px',
							background:'var(--white-color)',
							color:"var(--light-color)",
							position:'relative',
							top:15,
							fontWeight:600,
							fontSize:11
						}}>{Moment(data.createdAt).format('dddd')},{Moment(data.createdAt).format('MMMM')},{Moment(data.createdAt).format('DD')}</p>
					</div>
				</div>
				: <div></div>
			}
			<div className="luna-chat" style={{ flexDirection: 'row',marginTop:12, display: 'flex', cursor: 'pointer', padding: '5px 0px' }}>
				<img style={{
					width: 36,
					height: 36,
					marginRight: 5,
					objectFit: 'cover',
					marginTop: 5,
					marginLeft: 8,
					borderRadius: 4
				}} src={data.sendor ? data.sendor.image : ""} className="chat-icon" alt="s" />
				<div>
					<div style={{
						display: 'flex'
					}}>
						<span style={{ color: "var(--text-color)", fontSize: 17, marginLeft: 6, fontWeight: 700 }}>{data.sendor ? data.sendor.name : ""}</span>
						<div style={{ marginLeft: 8, fontSize: 11, color: 'var(--light-color)', cursor: 'pointer', paddingTop: 5 }}>{time}</div>
					</div>
					<div style={{
						padding: "2px 6px"
					}} className="notifications">
						<p className="t" style={{
							fontSize: 15
						}}>{message.title}</p>
					</div>
				</div>
			</div>
		</div>
	)
}
