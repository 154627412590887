import React, { useEffect, useState } from 'react';
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { Modal } from '../../../../Common/fModal/Modal';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';



const BankAdd = (props) => {


    const [banks, setBanks] = useState([]);
    const [changeTds, setChangeTds] = useState(false);
    const [selectedBank, setSelected] = useState(false);

    let id = +props.id;
    let { vendorId, bank } = props

    useEffect(() => {
        const getBanks = () => {
            get(`/vendor/contacts/${vendorId}/banks`, (e, r) => {
                if (r) {
                    setBanks(r.allBanks.map((val) => ({
                        id: val.accountId,
                        name: val.name,
                        ifsc: val.ifsc,
                        account: val.account,
                        label: val.bankName
                    })))
                }
            })
        }
        getBanks();
    }, [])

    const mapBank = (item) => {
        return (
            <div style={{
                border: selectedBank === item.id ? '2px solid var(--primary-color)' : '2px solid var(--border-color)',
                borderRadius: 10,
                padding: 12,
                margin:4

            }} onClick={() => setSelected(item.id)}>
                <p style={{fontSize:12}}> Bank Name: {item.label}</p>
                <p style={{fontSize:12}}> Account No.: {item.account}</p>
                <p style={{fontSize:12}}> IFSC: {item.ifsc}</p>
            </div>
        )
    }

    return (
        <>
            <div style={{ marginTop: 0 }}>
                <p style={{ marginBottom: 10 }}>Vendor Bank Account (Optional)</p>
                <div style={{
                    height: 40,
                    background: 'var(--off-white)',
                    border: '1px solid var(--border-color)',
                    width: '100%',
                    padding: 10,
                    fontSize: 12,
                    color: 'var(--text-color)'
                }} onClick={() => {
                    if(props.disabled) return;
                    setChangeTds(true);
                }}>
                    {bank && bank.bankName ? bank.bankName : ''}
                </div>
            </div>
            {
                changeTds ?
                    <Modal
                        onSave={() => {
                            console.log("selectedBank", selectedBank);
                            post(`/vendor/invoicing/${id}/change`, {
                                key: 'bank',
                                value: selectedBank,
                            }, (e, r) => {
                                if (r) {
                                    props.init();

                                } else {
                                    handleError(e)
                                }
                            })
                            setChangeTds(false);
                            showToast({
                                type: 'success',
                                message: "Field update successfully"
                            })
                        }}
                        style={{
                            save: {
                                padding: '12px 15px',
                                width: '100%',
                                textAlign: 'center',
                            }
                        }}
                        show={true}
                        title="Add Bank"
                        des="Add Bank"
                        close={() => {
                            setChangeTds(false);
                        }}
                        buttonName="Add Bank"
                    >

                        <div style={{
                            marginTop: 20
                        }} />
                        {banks.map(mapBank)}
                    </Modal>
                    : null
            }

        </>
    )
}

export default BankAdd;