import React from 'react'

function Portlet(props){

		return (
			<div id={props.id} className="fintrip-portlet" style={props.style}>
				{
					props.children
				}
			</div>
		)

}


Portlet.defaultProps = {
	style: {}
}

export {
	Portlet
}
