import React, { useState,useRef,useEffect } from 'react';
import { HalfModal } from '../../../../../Common/fModal/HalfModal';
import { FloatSelect } from '../../../../../Common/Class/FloatLabelInput';
import { FunctionalInput } from '../../../../../Common/fInputs';
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import { PieGraph } from '../../../../../Common/fAnalytics';
import Container from '../../../../../Common/fAnalytics/Container';
import SearchEntity from './SearchEntity';
import SearchOffice from './SearchOffice';
import { CostAllocation } from './index';
import { useSelector } from 'react-redux';
import SearchFilterLayer from './SearchFilterLayer';


const LineItemCostAllocation = ({lineItems,updateLineItems,index,close,previousType,view,edit,invoiceTotalAmount,init,gstin=undefined,disabled}) =>  {


    const item = lineItems[index-1];
    const {costAllocationType} = useSelector(state=>state.auth.config);
    const [items,setItems] = useState(item.costAllocations?[...item.costAllocations]:[]);
    const [toggleData] = useState('ratio');
    const pieItems = items.map(e => e.location);
    const pieData = items.map(e => e[toggleData]);
    const [add,setAdd] = useState(false);
    const [type,setType] = useState(previousType ? {...previousType}:{key:costAllocationType,value:costAllocationType});
    const [tempLineItem,setTempLineItem] = useState([...lineItems])
    const [filterLayer, setFilterLayer] = useState(undefined);

    let AMOUNT = useRef(),PERCENTAGE=useRef(),LOCATION=useRef(),COSTALLOCATION=useRef(),FILTER_LAYER= useRef();


   


    let totalAmount = item.total? Number(item.total.toFixed(2)):item.total;



    let addAllocation = () => {
        if(disabled) return;
        let totalPercentage = items.reduce((acc, item) => +parseFloat(acc + item.ratio).toFixed(2), 0);

        let tAmount = items.reduce((acc, item) => acc + item.amount, 0);


        tAmount = Number(tAmount.toFixed(3));
        totalAmount  = Number(totalAmount.toFixed(3));


        console.log("--line item cost allocation",totalPercentage,tAmount,totalAmount,items)

        
        if(items.length===0) {
            showToast({
                type:'error',
                message:'Atleast select 1 line item'
            })
            return;
        } 

        if(totalPercentage!==100.00) {
            showToast({
                type:'error',
                message:'Combine cost allocation must be 100%'
            })
            return false;
        }


        if(tAmount!==totalAmount) {
            showToast({
                type:'error',
                message:'Combine amount value must be equal to totalAmount of invoice'
            })
            return false;
        }
        const adjustedItems = items.map(item => ({
            ...item,
            ratio:+parseFloat(item.ratio).toFixed(2)
          }));
          item.costAllocations = [...adjustedItems];
          if(edit) {
             let data = {}
             let initData = COSTALLOCATION.current.getValue()?COSTALLOCATION.current.getValue():{costAllocations:[]};
             const reduceCostAllocations = initData.costAllocations.map(item => ({
                ...item,
                ratio: +parseFloat(item.ratio).toFixed(2)
             }));
            const updatedInitData = {
                ...initData,
                costAllocations: reduceCostAllocations
            };
             data = {
               costAllocation:{
                ...updatedInitData,
                    costAllocationAtItems:{
                       [item.id]:[...adjustedItems]
                    }
               }
             }
             init(data);

        } else {
            lineItems[index-1] = item;
            updateLineItems(lineItems,type);
            close();
        }

    }

    let addNewItem = () =>{
        return(
            <div style={{
                padding:10,
                marginTop:20,
                borderRadius:4
            }}>
                <p style={{fontSize:14,fontWeight:'bold',marginBottom:10}}>Add Line Item</p>
                <SearchFilterLayer
                    ref={FILTER_LAYER}
                    onSelect={setFilterLayer}
                    filterLayer={filterLayer}
                />
                <FloatSelect
                        title="Select Type"
                        blue="** All cost allocation define on the type you select on the first time."
                        items={[
                            {key:'entity',value:'Entity'},
                            {key:'office',value:'Office'},
                            {key:'dept',value:'Department'},
                            {key:'team',value:'Team'},
                        ]}
                       
                        onChange={(value)=>{
                            setType({
                                key:value,
                                value
                            })
                        }}
                        value={type}
                        disabled={items.length || previousType}
                    />

                    {
                        type.key==='office' ?
                        <SearchOffice ref={LOCATION} /> :
                        type.key === costAllocationType ?
                        <SearchEntity gstin={gstin} ref={LOCATION}  extra={filterLayer ? {data:filterLayer}:{}}/>
                        :null
                    }

                    <div style={{display:'flex',flex:1}}>
                         <FunctionalInput
                            type="number" 
                            title="Percentage" 
                            placeholder="Enter the percentage" 
                            ref={PERCENTAGE} 
                            style={{marginRight:20}}
                            onChange={(e)=>{
                                let value = +e.target.value;
                                 if(value>100) {
                                     PERCENTAGE.current.value = 0;
                                     showToast({
                                            type:'error',
                                             message:'Percentage value not more than 100%'
                                    })
                                    return;
                                 }

                                 let currentAmount = (value*totalAmount)/100
                                 AMOUNT.current.value = currentAmount;

                             }}
                         />
                          <FunctionalInput 
                                type="number" 
                                title="Amount"
                                placeholder="Enter the amount" 
                                ref={AMOUNT} 
                                onChange={(e)=>{
                                        let value = +e.target.value;
                                        let currentPercentage = ((parseInt(value)*100)/(totalAmount));
                                        PERCENTAGE.current.value = currentPercentage;
                                 }}
                        
                        />
                    </div>

                    <div style={{display:'flex',flex:1,alignItems:'center'}}>
                        <div className='btn btn-portlet' style={{marginTop:20,flex:1,marginRight:20}} onClick={()=>{
                            let percentage = PERCENTAGE.current.value;
                            let amount = AMOUNT.current.value;
                             let location = LOCATION.current.getValue();
 
                             if(!location) {
                                showToast({
                                  type:'error',
                                     message:"Please select the location"
                              })
                                 return;
                             }
 
                            if(!percentage && !amount) {
                                showToast({
                                    type:'error',
                                     message:"Please enter the percentage or amount"
                                 })
                                 return;
                            }
             
                             let data = {
                                 ratio:+parseFloat(percentage || amount / totalAmount * 100),
                                 amount: +parseFloat(amount || totalAmount * percentage / 100),
                                 location:`${location.name}`
                             }
 
                            let sItems = [...items];
                             sItems = sItems.filter(item=>item.location!==data.location);
                             sItems.push(data);
                             setItems(sItems);
 
                            let totalPercentage = sItems.reduce((acc, item) => acc + item.ratio, 0);
                            let tAmount = sItems.reduce((acc, item) => acc + item.amount, 0);


                            tAmount=+parseFloat(tAmount).toFixed(3);
                            totalAmount = +parseFloat(totalAmount).toFixed(3);
 
                         // Check if the total percentage exceeds 100
                            if (totalPercentage > 100) {
                              showToast({
                                     type: 'error',
                                     message: 'Total percentage cannot exceed 100%'
                                 })
                                 sItems.pop(); // Remove the last item from the list
                                 setItems(sItems);
                                 return;
                            }
                     
                         // Check if the total amount exceeds the props totalAmount
                            if (tAmount > totalAmount) {
                                 showToast({
                                    type: 'error',
                                     message: 'Total amount cannot exceed the total amount'
                                 })
                                 sItems.pop(); // Remove the last item from the list
                                setItems(sItems);
                                return;
                             }
                             
                             if(edit) {
                                item.costAllocations = [...sItems];
                                let s1Items = [...tempLineItem]                                      // setting temporary changes so that cost allocation value re-calculate
                                s1Items[index-1]= item;
                                setTempLineItem(s1Items)
                             }
 
                             setAdd(false);
                             
                        }}>
                             Add
                        </div>

                        <div onClick={()=>{
                            setAdd(false);
                        }} className='btn btn-portlet' style={{backgroundColor:'var(--red-color)',flex:1,marginTop:20}}>
                            Cancel
                        </div>


                    </div>

                    
            </div>

        )
    }

    useEffect(() => {
        const handleResize = () => {
         // window.dispatchEvent(new Event('resize'));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return(
        <HalfModal
            title={`Cost Allocation of ${item.name?item.name:item.desc}`}
            container={{
                left:0,
                right:'60%'
            }}
            outerContainer={{
                zIndex:1111
            }}
            close={close}
        >
            {
                items.length>0 || true ?
                <>
                    <div style={{height:36,background:'#dedede',border:'1px solid var(--border-color)',display:'flex',borderTopLeftRadius:4,borderTopRightRadius:4}}>
                    {
                     [
                        {name:'Type',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Percentage',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Amount',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        !view ?
                        {name:'Action',flex:1,style:{fontWeight:'bold'}}:{}
                     ]
                     .map((item,index)=>{
                        return(
                            <div key={index} style={{flex:item.flex,...item.style,height:40,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                {item.name}
                            </div>
                        )
                     })
                     }
                   </div>
                   {
                    items.map((item,index)=>{
                        return(
                            <div key={index} style={{minHeight:40,border:'1px solid var(--border-color)',display:'flex',flex:1}}>
                                <div style={{minHeight:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.location}</div>
                                <div style={{minHeight:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.ratio?item.ratio.toFixed(2):item.ratio}</div>
                                <div style={{minHeight:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.amount?item.amount.toFixed(3):item.amount}</div>
                               { 
                                    !view ?
                                    <div onClick={()=>{
                                        if(disabled) return;
                                        if(!view) {
                                            let temp = [...items];
                                            temp = temp.filter(i=>i.location!==item.location);
                                            setItems(temp);
                                        }
                                        
                                    }} style={{minHeight:40,display:'flex',flex:1,borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',color:'var(--red-color)',cursor:'pointer'}}>{
                                        !view? "Delete":null
                                    }</div>
                                    :null
                               }

                            </div>  
                        )
                    })
                   }
                
               
                </>
                :null
            }
            {
            add ?
            addNewItem():
            <>
            {
                view ?
                <Container width="40%" style={{
                    container:{
                        margin:0,
                        marginTop:40
                    }
                }}>
                    <PieGraph
                        textColor="#fff"
                        colors={["#0d6efd","#fd7e14","#20c997","#d63384"]}
                        title={`Cost Allocation of ${item.name?item.name:item.desc}`}
                        height={350}
                        data={pieData}
                        items={pieItems}
                    />
                </Container>
                :null
            }
            {
                !view ?
                <>
                     <div style={{display:'flex',justifyContent:'flex-end'}}>
                            <p style={{backgroundColor:'var(--green-color)',color:'#fff',height:25,width:25,borderRadius:'50%',fontWeight:'bold',cursor:'pointer',marginTop:10,fontSize:16,display:'flex',alignItems:'center',justifyContent:'center'}} 
                            onClick={()=>{
                                if(disabled) return;
                                setAdd(true)}
                                }>+ </p>
                    </div>
                     <div style={{
                            background:'var(--primary-color)',
                            width:70,
                            marginTop:20,
                            padding:'5px 12px',
                            color:'#fff',
                            fontSize:14,
                            borderRadius:4,
                            cursor:'pointer'
                            }} onClick={addAllocation}>Submit</div>
                </>
                :null
            }
           

           {
            edit ?

            <CostAllocation
                totalAmount={invoiceTotalAmount}
                prevItems={tempLineItem}
                costAllocationType={previousType}
                ref={ref=>COSTALLOCATION.current=ref}
                hide={true}
                gstin={gstin}
              

            />
            :null
           }

            </>
            
            }

           


        </HalfModal>
    )
}

LineItemCostAllocation.defaultProps = {
    previousType:undefined,
    edit:false,
    systemInvoiceId:undefined,
    type:undefined
}

export  {LineItemCostAllocation}