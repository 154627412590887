import React, { useState } from "react";
import {
  Account,
  Home,
  Main,
  Others,
  Payments,
  SETTLEMENTS,
  Vendor,
  Workspace,
} from "./data";
import Csidebar from "./Csidebar";
import _ from "lodash";

const SidebarMini = (props)=>{

  const getState = useState({
    trips: 0,
    policies: 0,
    requests: 0,
    payouts: 0,
    support: 0,
    settlements: 0,
  })


  let LOGO = require("./logo.png")


  const map = (item) => {
    let route = props.history.location.pathname;
    item.active = route.includes(item.path);
    item.current = route;
    return item;
  };

    let config = props.config;
    let modules = config.modules;
    let allviews = [];
    let data = {
      main: true,
    };
    allviews.push(
      <Csidebar
        state={getState[0]}
        text="Dashboard"
        key="Home"
        route={props.history}
        common={Home.filter((i) => i.filter(data)).map(map)}
      />
    );
    let MAIN = [...Main];
    if (
      _.includes(modules, "SELF BOOKINGS") ||
      _.includes(modules, "TRAVEL DESK")
    ) {
      MAIN.push({
        cname: "travel",
        value: "Travel",
        ukey: "18",
        path: "/app/travel",
        link: "/app/travel",
        info: "All Expense history of your company",
        role: ["finance.read"],
        filter: (data) => {
          return data.main;
        },
      });
    }
    allviews.push(
      <Csidebar
        state={getState[0]}
        text="Travel & Expenses"
        key="Main"
        route={props.history}
        common={MAIN.filter((i) => i.filter(data)).map(map)}
      />
    );
    if (
      _.includes(modules, "VENDOR") ||
      _.includes(modules, "COLLECTIONS") ||
      _.includes(modules, "CUSTOMERS")
    ) {
      allviews.push(
        <Csidebar
          state={getState[0]}
          key="banking"
          text="Invoicing"
          route={props.history}
          common={Vendor.filter((i) => i.filter(data)).map(map)}
        />
      );
    }
    if (_.includes(modules, "SETTLEMENTS")) {
      allviews.push(
        <Csidebar
          state={getState[0]}
          key="settlements"
          text="Settlements"
          route={props.history}
          common={SETTLEMENTS.filter((i) => i.filter(data, config)).map(
            map
          )}
        />
      );
    }
    allviews.push(
      <Csidebar
        state={getState[0]}
        key="payments"
        text="Payment & Collections"
        route={props.history}
        common={Payments.filter((i) => i.filter(data,config)).map(map)}
      />
    );
    if (
      _.includes(modules, "VENDOR") ||
      _.includes(modules, "CUSTOMWORKFLOW") ||
      _.includes(modules, "CUSTOMSUPPORT")
    ) {
      allviews.push(
        <Csidebar
          state={getState[0]}
          key="workspace"
          text="Apps & Automations"
          route={props.history}
          common={Workspace.filter((i) => i.filter(data)).map(map)}
        />
      );
    }
    allviews.push(
      <Csidebar
        state={getState[0]}
        key="others"
        text="Others"
        route={props.history}
        common={Others.filter((i) => i.filter(data)).map(map)}
      />
    );
    allviews.push(
      <Csidebar
        state={getState[0]}
        key="accounts"
        text="Account"
        route={props.history}
        common={Account.filter((i) => i.filter(data)).map(map)}
      />
    );
    return (
        <div
          id="sidebar"
          style={{
            ...props.style,
            borderRight: "1px solid var(--border-color)",
            maxWidth: '90px',
            width: '90px',
            transform: 'translateX(-100%)',
            opacity: 0,
            overflowX: 'hidden'
          }}
          className={`icons expanded ${props.className}`}
        >
          <div
            style={{
              marginTop: '30px',
              display: "flex",
              flexDirection: "column",
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              className="Sidebar_Logo_Mini"
              alt="logo"
              src={LOGO}
              style={{
                width: 50,
                marginBottom: 18,
                ...(localStorage.getItem('DARK_THEME')==='true'?{filter: 'grayscale(100%) invert(100%)'}:{})
              }}
            />
          </div>
          {allviews}
        </div>
    );
  }

export default SidebarMini;
