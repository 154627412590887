import ReactDOM from 'react-dom';
import App from './Controller'
import 'leaflet/dist/leaflet.css';
import * as ServiceWorker from './serviceWorker';
import Moment from 'moment';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';

let proto = Moment.prototype;
proto.getTime = proto.valueOf;
Moment.prototype = proto;

ReactDOM.render(App, document.getElementById('root'));
ServiceWorker.register({
	onUpdate: reg => {
		const waitingServiceWorker = reg.waiting
		waitingServiceWorker.addEventListener("statechange", event => {
			if (event.target.state === "activated") {
				console.log("Updated")
			}
		});
		waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
	}
})

