import React, {useEffect, useRef, useState} from 'react';
import {get, post} from '../../../../Model/Network/Config/Axios';
import {Title} from "../../../Common/Title";
import {Modal} from "../../../Common/fModal/Modal";
import {FunctionalInput} from "../../../Common/fInputs";
import {showToast} from "../../../Common/fToast/ToastCallback";
import {useHistory} from 'react-router-dom'
import Toggle from './Toggle'
import {useScriptConfig} from "../../../Hooks/ScriptConfig";
import AppCard from '../../../../Components/Common/fCard/AppCard'
import { Spinner } from '../../../Common/fLoader';
import Empty from '../../../Common/fCard/Empty';
import { Switch, Route} from 'react-router-dom';
import {AllRouters} from '../Helpers';

const AllPlugins = (props) => {

	const Config = useScriptConfig();
	const [Apps, setApps] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState(false)
	const History = useHistory()


	let NAME = useRef(), DES = useRef(), TYPE = useRef();

	let INIT = () => {
		setModal(false)
		get(`/market/installed`, (e, r) => {
			if (r) {
				setApps(r.apps);
				setLoading(false);
			}
		})
	}

	useEffect(() => {
		INIT()
	}, [])

	let plugin = (item) => {

		return (
			<AppCard
			onClick={()=>{
				if(item.type === 'USERAPP'){
					History.push(`/app/user/${item.id}/details`)
				}else if(item.type === "DASHBOARDINJECT"){
					History.push(`/app/dashboard/${item.id}/details`)

				}
			}}
			icon={item.icon}
			name={item.title}
			description={item.description}
			live={item.live}
			category={item.category}
			type={item.type}
			/>
		)

	}

	if (loading) {
		return <Spinner style={{marginTop: '100px'}}/>
	}

	return (
		<div style={{padding: '0px'}}>
			<Toggle reload={Config.reload}/>
			<div style={{padding: '0px'}}>
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '10px', marginTop: '10px'}}>
					<Title title={`${Apps.length} Apps`}/>
					<div onClick={() => {
						setModal(true)
					}} style={{
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 12,
						color: 'var(--force-white)',
						background: 'var(--primary-color)',
						padding: '10px 20px',
						borderRadius: '10px'
					}}>
						<div>
							+ Create App
						</div>
					</div>
				</div>
			</div>
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				margin: '0px -10px',
				paddingTop: 0
			}}>
				{	Apps.length?
					Apps.map(plugin)
					:<Empty message='No Apps installed'/>
				}
			</div>

			<div style={{marginTop: '30px'}}>
				<div style={{fontSize: '12px', fontWeight: 'bold'}}>PLUGINS</div>
				<Switch>
				{
					AllRouters.map(value => {
						let Component = value.component;
						return (
							<Route {...value.extra} key={value.path} path={value.path} component={Component}/>
						)
					})
				}
				</Switch>
			</div>


			{
				modal ?
					<Modal des={`Please enter the following details to create app.`}
						   buttonName="Create" style={{
						save: {
							width: '100%',
							textAlign: 'center'
						},
						close: {
							display: 'none'
						}
					}} show={modal} onSave={() => {
						let name = NAME.current.value;
						let des = DES.current.value;
						let type = TYPE.current.value;
						if (!name) {
							showToast({
								type: 'error',
								message: "Please enter a valid name"
							})
							return
						}
						if (!des) {
							showToast({
								type: 'error',
								message: "Please enter a valid description"
							})
							return
						}
						post(`/market`, {name, des, type: type.toUpperCase()}, (e, r) => {
							if (r) {
								INIT()
							}else{
								showToast({
									type:'error',
									message: e.data.message
								})
							}
						})

					}} title="Create App"
						   close={() => {
							   setModal(false)
						   }}>
						<FunctionalInput type="text" ref={NAME} title="Name"/>
						<FunctionalInput type="text" ref={DES} title="Description"/>
						<div style={{fontSize: '10px', marginTop: '20px', marginBottom: '10px'}}>APP TYPE</div>
						<select ref={TYPE} style={{color: 'var(--text-color)', background: 'var(--tile-color)', width: '100%', padding: '10px', border: '1px solid var(--border-color)', borderRadius: '6px'}}>
							<option value="userApp">User App</option>
							<option value="travelApp">Travel App</option>
							<option value="procurementApp">Procurement App</option>
							<option value="personalApp">Personal App</option>
							<option value="dashboardInject">Dashboard App</option>
						</select>
					</Modal> : undefined
			}
		</div>
	)
}

export default AllPlugins;
