import React, {  useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {useParams} from  'react-router-dom';
import {post,axios} from '../../../../../Model/Network/Config/Axios';
import { PoCopyDropdown as MultiselectDropDown } from './PoCopyDropdown';
import { Warning } from '../../../../Common/fCard/Warning';
import { FunctionalInput } from '../../../../Common/fInputs';
import { FileUpload } from '../../../../Common/fInputs/FunctionalFile';
import { Modal } from '../../../../Common/fModal/Modal';
import PhotoTile from '../../../../Common/fPhoto';
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {RightSidebar} from "../fCommon/RightSidebar";
import Details from './Details';
import Status from "./Status";


export default ({details,invoices,conditions,init,defaultBank,...props}) =>{
    let {renewalTracking,poPdfDownloadAfterApproval} = useSelector(state=>state.auth.config.plugins);
    let {tenant} = useSelector(state=>state.auth.config);
    let TYPE = useRef(),TITLE=useRef();
    const [poCopy,setPoCopy] = useState(false);
	const [acknowledge,setAcknowledge] = useState(false);
    const [Files,setFiles] = useState([]);
    const {id} = useParams();
    const withDraw = (data) =>{
		post(`vendor/po/requests/${id}/withdrawPo`,data,(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:'PO Withdrawn successfully'
				})
				init();
			} else {
				handleError(e)
			}
		})
        
	}

	const deletePo = (data) => {
		post(`vendor/po/requests/${id}/cancel`,data,(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:'PO deleted successfully'
				})
				init();
				
			}
		})
	}

    const reset = (data) =>{
		post(`vendor/po/requests/${id}/moveFailedToDraft`,data,(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:'PO Reset successfully'
				})
				init();
				
			}
		})
	}
	
    let editable = () => {
		return details.status !== "COMPLETED" &&
			details.status !== "FAILED" &&
			details.status !== "DECLINED" &&
			details.status !== "CANCELLED"
	}


    return(
        <div style={{
            flex: 1,
            position: 'relative',
        }}>
            
            <div style={{
                display:'flex',
                flexDirection:'row'
            }} >
                <div style={{
                    flex:5,
                    height:'calc(100vh - 200px)',
                    overflowY:'auto'
                }}>
                    <Warning warning={details.warnings} />
                    <Status
                       defaultBank={defaultBank}
                       data={details} 
                       delete={deletePo}
                       withDraw={withDraw}
                       reset={reset}
                       conditions={conditions}
                       poPdfDownloadAfterApproval={poPdfDownloadAfterApproval}
                    />
                    <Details invoices={invoices} init={init} id={id} editable={editable}  data={details} {...props}/>
                </div>
            <RightSidebar
                multiple={true}
                fileName={details.fileNames}
                id={id} 
                ticket={true} 
                status={details.status}
                renewalTracking={renewalTracking}
                init={init}  
                type="purchase-order"
                contract={true}
                acknowledge={details.status==='VENDOR_ACK'}
                publicComments={details.publicComments} 
                publicComment={true} 
                multipleEmail={true}
                multipleEmailValue={details.additionalEmail}
                data={details}
                privateAttachments={details.privateAttachments?details.privateAttachments:[]} 
                timeline={details.timelines} 
                publicAttachments={details.publicAttachments?details.publicAttachments:[]} 
                comments={details.comments} attachments={details.attachments} 
                poCopy={details.poDocument}
                onAcknowledge={()=>{
                    setAcknowledge(true);
                }}
                openPoCopy={()=>{
                    setPoCopy(true);
                }}
                removeAttachment={attachment=>{
                    post(`vendor/po/requests/${id}/attachment.remove`, {attachment}, (e,r)=>{
                        if(r) {
                            init();
                        } else {
                            handleError(e);
                        }
                    })

                }}  
                postAsyncPublicAttachment={  async (items,cb)=>{
                    let count = 0;
                    for(let item in items) {
                        let result = await axios({
                            method: 'post',
                            url: `https://heimdall.eka.io/admin/vendor/po/requests/${id}/attachment`,
                            data: {
                                attachment:items[item].url,
                                name:items[item].name
                            },
                           
                        }).then(()=>{
                            return 1;
                        })

                        count = count+result;
                        if(count===items.length) {
                            cb(count);
                            init();
                        }

                    }
                }}
    
    
                postAsyncPrivateAttachment={async (items,cb)=>{
                    let count = 0;
                    for(let item in items) {
                        let result = await axios({
                            method: 'post',
                            url: `https://heimdall.eka.io/admin/vendor/po/requests/${id}/attachment.private`,
                            data: {
                                attachment:items[item].url,
                                name:items[item].name
                            },
                           
                        }).then(()=>{
                            return 1;
                        })
                        count = count+result;
                        if(count===items.length) {
                            cb(count);
                            init();
                        }
                    }
                    
                }}
                postAttachment={data=>{
                    post(`vendor/po/requests/${id}/attachment`, {attachment:data}, (e,r)=>{
                        if(r) {
                            init();
                        } else {
                            handleError(e);
                        }
                    })

                }}  
                postPrivateAttachment={attachment=>{
                    post(`vendor/po/requests/${id}/attachment.private`, {
                        attachment
                    }, (e,r)=>{
                        if(r){
                            init()
                        }else{
                            handleError(e)
                        }
                    })
                }}
    
                removePrivateAttachment={attachment=>{
                    post(`vendor/po/requests/${id}/attachment.private.remove`, {attachment}, (e,r)=>{
                        if(r){
                            init()
                        }else{
                            handleError(e)
                        }
                    })
                }}
                addComment={(comment,type)=>{
                    let data ={}
                    if(type==='public') {
                        data.public=comment;
                    } else {
                        data.comment=comment;
                    }
                    post(`vendor/po/requests/${id}/comment`, data, (e,r)=>{
                        if(r) {
                            init();
                        } else {
                            handleError(e);
                        }
                    })
                }} editable={editable} />
            </div>

            {
                poCopy ?
                <Modal
                    title="Send PO copy"
                    des="Select the type"
                    show={true}
                    close={()=>{
                        setPoCopy(false)
                    }}
                    buttonName="Send"
                    onSave={()=>{
                        let type = TYPE.current.getValue();
                        let items = type.split(';');
                        let count =0;
                        items.forEach(i=>{
                            post(`vendor/po/requests/${id}/notifyTrigger`,{key:i},(e,r)=>{
                                if(r) {
                                    count++;
                                    if(count===items.length) {
                                        showToast({
                                            type:'success',
                                            message:"PO copy sent successfully"
                                        })
                                        setPoCopy(false);
                                    }
                                    
                                }
                            })
                        })
                        
                    }}
                >
                <MultiselectDropDown
                    title={"Select the Type"}
                    options={[
                        {value:'vendor',label:"Vendor"},
                        {value:'admin',label:"Buyer"},
                        {value:'employee',label:"User"}, 
                        {value: "custom", label: "Custom"}
                    ]}
                    handleRef={ref => TYPE.current = ref}
                />
                 
                </Modal>
                :null
            }

            {
                acknowledge ?
                <Modal
                    title="Acknowledge PO"
                    des="Acknowledge"
                    close={()=>{
                        setAcknowledge(false);
                    }}
                    show={true}
                    buttonName="Acknowledge"
                    onSave={()=>{
                        let title = TITLE.current.value;
                        if(!title) {
                            showToast({
                                type:'error',
                                message:"Please enter the comment"
                            })
                            return;
                        }
                        if((tenant==='TGRANL'||tenant==='TGRANAL') && Files.length===0) {
                            showToast({
                                type:'error',
                                message:"Please upload at least one file"
                            })
                            return;
                        }
                        post(`vendor/po/requests/${id}/vendorAck`,{comment:title,attachments:[...Files]},(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"PO Acknowledge Success"
                                })
                                init();
                                setAcknowledge(false);
                            }
                        })
                    }}
                >
                    <FunctionalInput title="Enter Comment" ref={TITLE} />
                    <div style={{
                        display: 'flex',
                        marginLeft: '-10px',
                        flexWrap: 'wrap',
                        maxHeight: '250px',
                        overflowY: 'scroll'
                    }}>
                        <FileUpload onUpload={({file}) => {
                            setFiles(prev => [...prev, file]);
                        }}/>
                        {
                            Files.length > 0 ?
                                Files.map((item, index) => {
                                return (
                                    <PhotoTile onDelete={()=>{
                                        let all = [...Files];
                                        all = all.filter(i=>i!==item);
                                        setFiles(all)
                                    }} key={`Photo-${index}`} link={item}/>
                                )
                            }) : <></>
                        }
                    </div>
                </Modal>
                :null
            }
        </div>
    )
}