import {showToast} from "../../../../../Common/fToast/ToastCallback";

class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }


    init = () => {
        this.Model.init((e, r) => {
            if (r) {
                this.View.load(r);
            }
        })
    }

    triggerMail = () => {
        this.Model.triggerMail((e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Mail Triggered successfully'
                })
            }
        })
    }

}

export default Presentor;
