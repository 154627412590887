import React from 'react';
import Moment from 'moment';

const Items = (props) => {

    let header = (value,style) => {
        return(
            <div style={{
                borderRight:'1px solid var(--border-color)',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                padding:'0px 5px',
                minHeight:80,
                fontSize:13,
                ...style
            }}>
                {value}
            </div>
        )
    }
    let {procured,billingTo} = props.data;
    return(
        <>
            <p style={{
                fontWeight:600,
                height:50,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}>Annexure 1</p>
            <div style={{
                display:'flex',
                flex:1,
                border:'1px solid var(--border-color)'
            }}>
               {header('S.No',{width:50})}
               {header('Location',{flex:1})}
               {header('Delivery Address',{flex:1})}
               {header('Delivery Date',{flex:0.5})}

               {header('Billing Address',{flex:1})}
               {header('Gstin',{flex:1})}
            </div>
            {procured.map((val,index)=>{
                return(
                    <div key={index} style={{
                        display:'flex',
                        flex:1,
                        border:'1px solid var(--border-color)'
                    }}>
                        {header(index+1,{width:50})}
                        {header(val.officeName,{flex:1})}
                        {header(`${val.officeAddress}`,{flex:1})}
                        {header(`${Moment(val.deliveryDate).format('DD-MM-YYYY')}`,{flex:0.5})}
                        {header(`${billingTo.address}`,{flex:1})}
                        {header(`${billingTo.gstin}`,{flex:1})}

                    </div>
                )
            })}

        </>
    )
}

export default Items;