// eslint-disable-next-line
import React, {useCallback, useEffect} from 'react'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

function RecaptchaV3({onToken}) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    
    const handleReCaptchaVerify = useCallback(async () => {
      if (executeRecaptcha) {
        const token = await executeRecaptcha('yourAction');
        onToken(token)
      }
    }, [executeRecaptcha, onToken]);
  
    useEffect(() => {
      handleReCaptchaVerify();
    }, []);

    return <></>
}

export default RecaptchaV3;