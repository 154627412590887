import React from 'react';
import {connect} from 'react-redux';

class Index extends React.Component {

    render() {
        return (
            <span style={{
                paddingRight: 5
            }} >{this.props.curr + "  "}</span>
        )
    }

}

const Currency = connect((state) => {
    return {
        curr: state.auth.config.currency
    }
}, null)(Index);




export { Currency }






