import React from 'react';
import Moment from 'moment';
import Track from '../../../../Common/fTrack';
import { timelineLogStatus } from '../../fProcurement/fPoAmend/GRN/GrnView/Timeline';



const Index = ({timelines}) => {

    return(
        <div id="Invoice_Timeline" className='FadeRight-Effect'>
			<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline</div>
			<Track stages={
				[...timelines.map((log,index) => {

                    let DESC = <div><b>{log.name}</b><br/>
                        <div style={{marginTop: '5px'}}>Type: {log.type}</div>
                        <div style={{marginTop: '5px'}}>Status: {log.status}</div>

                        <div style={{marginTop: '5px'}}>Sent
                            on: {Moment(log.time).format("hh:mm a DD MMM YY")}
                            <br/>{log.actionTime && log.status !== 'PENDING' ? `Approved on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
                        </div>
                    </div>

                    return (
                        {
                            title: log.email,
                            desc: DESC,
                            status: log.type === 'start' || log.type === 'scanner-end' || (log.type === 'scanner-start' && timelines.length>(index+1))? true : log.status ? timelineLogStatus.includes(log.stages) : log.name === 'Finance Action' || log.type === 'Completed' || log.email === 'Action completed by Admin' ?  true : false
                        })
                })]
            }/>									
		</div>
    )
}

Index.defaultProps = {
    timelines:[]
}

export default Index;