import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import { post } from '../../../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../../../Common/fToast/ToastCallback';
import {FloatInputButton} from "../../../../../../Common/fInputs";

function Index(props) {

    const [About, setAbout] = useState('');
    const {appId} = useParams()
    
    useEffect(() => {
        if(props.about){
            setAbout(props.about)
        }
    }, [props]);

    const addAbout = (file)=>{
        post(`market/${appId}/about`, {about: file}, (e,r)=>{
            if(r){
                props.reload();
                showToast({
                    type:'success',
                    message: 'About App Updated'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in updating'
                })
            }
        })
    }

    return (
        <>
        <span id="Login_Heading"style={{fontSize: '12px', fontWeight: 'bold'}}>App Description</span><br/>
        <div style={{marginBottom: '30px', display: 'flex', alignItems: 'center'}}>
            <FloatInputButton style={{width: '100%', maxWidth: '500px'}} onSubmit={(val) => {addAbout(val)}} placeholder={About}/>
        </div>
        </>
    );
}

export default Index;