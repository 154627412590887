import React from 'react';
import {Title as Header, TitleTopHeader} from '../../../../Header/TopHeader';
import {DateRangePicker} from "react-date-range";
import Moment from 'moment';
import {showToast} from '../../../../Common/fToast/ToastCallback';
import {pluginsGetApi, pluginsPostApis} from '../../../../../Model/Network/Config/Axios';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            range: {
                startDate: Moment().add(-60, 'd').toDate(),
                endDate: Moment().add(1, 'd').toDate(),
                key: 'selection',
            },
            data: []
        }
    }

    componentDidMount() {
        pluginsGetApi(`plugins/advanceLedgerReport/info`, (e, r) => {
            if (r) {

            }
        })
    }


    handleSelect = (ranges) => {

        let startDate = Moment(ranges.selection.startDate).hours(0).minutes(0).seconds(0);
        let endDate = Moment(ranges.selection.endDate).hours(23).minutes(59).seconds(59);
        this.setState({
            ...this.state,
            range: {
                startDate: Moment(startDate).toDate(),
                endDate: Moment(endDate).toDate(),
                key: 'selection'
            },
        })
    }


    send = () => {



        pluginsPostApis(`plugins/custom/chaitanya/reports/download`, {
            startDate: Moment(this.state.range.startDate).valueOf(),
            endDate: Moment(this.state.range.endDate).valueOf()
        }, (e, r) => {
            if (r) {
                let data = [];
                data.push(r.AP_Invoice_Lines_Interface_Url);
                data.push(r.AP_Invoices_Interface_Url)

                this.setState({
                    ...this.state,
                    data
                })

                showToast({
                    type: 'success',
                    message: 'Report download successfully'
                })
            }
        })
    }

    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <TitleTopHeader items={[]} title=" Report" des="Report for company " />
                <Header title="Report" des="Report for  company" />
                <div className="title-container" style={{
                    padding: 18
                }}>
                    <label className="floating-top-label">Select Time Range</label>
                    <div style={{ position: 'relative' }} id="no-shadow">
                        <DateRangePicker
                            color="#0062C5"
                            ranges={[this.state.range]}
                            onChange={this.handleSelect}
                        />
                    </div>

                    <div className="btn btn-portlet" style={{
                        marginTop: 30
                    }} onClick={() => {
                        this.send()
                    }}>
                        Download Report
                    </div>


                    {
                        this.state.data.length > 0 ?
                            <div style={{
                                marginTop: 20
                            }}>
                                <p>Click on file to download it</p>

                                {
                                    this.state.data.map((val, index) => {
                                        return (
                                            <a href={val} style={{
                                                marginTop: 10,
                                                textDecoration: 'none',
                                                display: 'block'
                                            }} rel="noopener noreferrer" key={index} target="_blank">{val}</a>
                                        )
                                    })
                                }
                            </div> :
                            null
                    }
                </div>
            </div>
        )
    }
}

export default Index;
