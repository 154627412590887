import React, {useEffect, useState} from 'react';
import ICON from './icon_dice.png'
import {useParams} from 'react-router-dom'
import {get, post} from '../../../../../Model/Network/Config/Axios'
import { showToast } from '../../../../Common/fToast/ToastCallback';
import {Spinner} from '../../../../../Components/Common/fLoader';

function Index(props) {

    let {appId} = useParams();
    const [Details, setDetails] = useState(undefined);

    useEffect(() => {
		fetchDetails(appId)
	}, [appId]);

	const fetchDetails = (ID) => {
		get(`market/${ID}/details`, (e,r)=>{
            if(r){
                setDetails(r)
                
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading details'
                })
            }
        },{})
	}

    const renderPreviews = (item, index)=>{
        return (
            <div key={index} onClick={()=>window.open(item,'_blank')} className='FadeRight-Effect hoverShrink' style={{background: `url("${item}") center center / cover no-repeat`, border: '1px solid var(--border-color)', height: '180px', minWidth: '300px', marginRight: '20px', borderRadius: '20px', cursor:'pointer'}}></div>
        )
    }

    const installApp = ()=>{
        post(`market/install`, {id: parseInt(appId), name: Details.name, des: Details.about}, (e,r)=>{
            if(r){
                fetchDetails(appId)
                showToast({
                    type:'success',
                    message: 'App Installed'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Unable to install this App'
                })
            }
        })
    }

    const uninstallApp = ()=>{
        post(`market/uninstall`, {id: parseInt(appId), name: Details.name, des: Details.about, type: Details.type}, (e,r)=>{
            if(r){
                fetchDetails(appId)
                showToast({
                    type:'success',
                    message: 'App Uninstalled'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Unable to install this App'
                })
            }
        })
    }
    

    if(!Details){
        return <Spinner style={{marginTop: '100px'}}/>
    }

    return (
        <>
        <div className="children-container" style={{background: 'var(--white-color)', marginTop: '20px', marginBottom: '40px', padding: '40px', borderRadius: '20px'}}>
            <div style={{ flex: 1, overflowY: 'auto', marginBottom: '20px'}}>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <div className='FadeUp-Effect' style={{width:'120px', height: '120px', flexShrink:0,borderRadius: '10px', marginRight: '20px', overflow: 'hidden'}}>
                        <img src={Details.logo?Details.logo:ICON} alt='ICON' style={{width: '100%'}}/>
                    </div>
                    <div className='FadeRight-Effect' style={{overflow: 'hidden'}}>
                        <div style={{fontSize: '22px', fontWeight: 'bold', overflow:'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis'}}>{Details.name}</div>
                    {
                        Details.installed?
                        <div className='button-outline-dark' onClick={()=>{uninstallApp()}} style={{marginTop: '10px', padding: '6px 12px', borderRadius:'50px'}}>
                            Uninstall
                            <svg style={{fontSize: "16px", verticalAlign: "middle", marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"></path>
                            </svg>
                        </div>
                        :<div className='button-outline-dark' onClick={()=>{installApp()}} style={{marginTop: '10px', padding: '6px 12px', borderRadius:'50px'}}>
                            Install
                            <svg style={{fontSize: "16px", verticalAlign: "middle", marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"></path>
                            </svg>
                        </div>
                    }
                    </div>
                </div>
                
                <div className='FadeUp-Effect' style={{marginTop: '30px', maxWidth: '800px', display:'flex', padding: '20px 0px',borderTop: '1px solid var(--border-color)'}}>
                    <div style={{padding: '10px 30px 10px 0px',fontSize: '14px', borderRight: '1px solid var(--border-color)'}}>
                        <span style={{fontSize: '11px'}}>Created By</span>
                        <br/>
                        Aakash Bhadana
                    </div>
                    <div style={{padding: '10px 30px',fontSize: '14px', borderRight: '1px solid var(--border-color)'}}>
                    <span style={{fontSize: '11px'}}>Published on</span>
                        <br/>
                        24 May 2022
                    </div>
                    <div style={{padding: '10px 30px',fontSize: '14px', borderRight: '1px solid var(--border-color)'}}>
                    <span style={{fontSize: '11px'}}>Category</span>
                        <br/>
                        {Details.category}
                    </div>
                </div>

                <div style={{fontSize: '14px', fontWeight: 'bold', marginTop: '20px'}}>Previews</div>
                <div style={{marginTop: '20px', width: '100%', display: 'flex', alignItems: 'center', flexWrap:'nowrap', overflowX:'scroll'}}>
                    {
                        Details.screenshots.map(renderPreviews)
                    }
                </div>

                <div className='FadeUp-Effect' style={{marginTop: '30px', maxWidth: '800px'}}>
                    <div style={{fontSize: '14px', fontWeight: 'bold'}}>Description</div>
                    <div className='FadeUp-Effect' style={{marginTop: '20px', display: 'flex', alignItems: 'center',fontSize:'14px'}}>
                            {Details.about}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Index;