import React from 'react';
import Info from './info.png';
import cross from './cross.png';
import Props from 'prop-types';


class InfoNotification extends React.Component {


    render() {
        return (
            <div style={{
                height: 60,
                border: '1px solid #5A62CE',
                borderRadius: 4,
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#ebecf9',
                marginTop: 30,
                ...this.props.style
            }}>
                <div style={{
                    marginRight: 10
                }}>
                    <img src={Info} alt="" style={{
                        height: 32,
                        width: 32
                    }} />
                </div>
                <div style={{ color: '#5A62CE', fontSize: 15 }}>
                    {this.props.message}
                </div>

                <div style={{
                    marginLeft: 'auto'
                }}>
                    {
                        this.props.buttonName ?
                            <div style={{
                                backgroundColor: 'var(--red-color)',
                                height: 40,
                                width: 90,
                                color: 'var(--white-color)',
                                borderRadius: 4,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 600,
                                cursor: 'pointer'
                            }} onClick={this.props.onClick}>
                                {this.props.buttonName}
                            </div> :
                            <div onClick={this.props.onClick}>
                                <img src={cross} alt="" style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} />
                            </div>

                    }
                </div>
            </div>
        )
    }
}


InfoNotification.propsTypes = {
    message: Props.string.isRequired,
    buttonName: Props.string,
    onClick: Props.func
}

export { InfoNotification }
