import React from 'react';
import './index.css'
import PropTypes from 'prop-types'
import Loader from '../../../../Components/Common/fButtonLoader'

/*
PROPS:

onClick = ()
title = string
icon = JSX, SVG
*/

function ButtonOutline(props) {
    if(props.readOnly) {
        return <></>
    }

    return (<>
            <div style={{marginRight:18,...props.style}} className={!props.disabled ? `button-outline-dark ${props.active?'active':""}` : 'button-outline-dark-disabled'} onClick={props.loading||props.disabled?null:props.onClick}>
                {props.loading?<Loader color={props.loaderColor ? props.loaderColor : '#fff'} />:<>{props.icon?props.icon:''} {props.title}</>}
                {props.backIcon ? <img src={props.backIcon} style={{height:15, width:15, marginLeft:5}} alt=""/> : null}
            </div>
    </>);
}



ButtonOutline.propTypes = {
    title:PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
    icon:PropTypes.object
}

export default ButtonOutline;

