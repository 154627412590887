import React from 'react';
import ICON from './icon_dice.png'

function Index(props) {

	let type = ''
	switch(props.type){
		case 'DASHBOARDINJECT': type = 'DASHBOARD'; break;
		case 'USERAPP': type='USER'; break;
		case 'TRAVELAPP': type='TRAVEL'; break;
		case 'PERSONALAPP': type='PERSONAL'; break;
		case 'PROCUREMENTAPP': type='PROCURE'; break;
		case 'VENDORAPP': type='VENDOR'; break;
		case 'MIDDLEWARE': type='MIDDLEWARE'; break;
		default: type=''
	}

    return (
        <>
            <div onClick={()=>{props.onClick()}} className='tile' style={{padding: '15px', display: 'flex', alignItems:'center',maxWidth: '260px', minWidth: '260px', maxHeight: '100px', height: '100px'}}>
				
				<div style={{width:'70px', height: '70px', flexShrink:0,borderRadius: '10px', marginRight: '20px', overflow: 'hidden'}}>
					<img src={props.icon?props.icon:ICON} alt='ICON' style={{width: '100%'}}/>
				</div>
				<div style={{overflow: 'hidden'}}>
					<div style={{fontSize: '12px', fontWeight: 'bold', overflow:'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis'}}>{props.name}</div>
					<div style={{fontSize: '10px', color: 'var(--muted-text-color)', overflow:'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis'}}>
						{props.description}
					</div>
					{
					<div style={{fontSize: '10px', marginTop:4, color: 'var(--muted-text-color)', overflow:'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis'}}>
						{props.category==='CUSTOM'?'CUSTOM -':''} {type}
					</div>
					}
					{props.live?
					<div style={{padding: '3px 6px', color: '#198754', display: 'inline-flex', marginTop: '5px', marginRight: '6px', fontSize: '9px', border:'1px solid #198754', borderRadius: '5px'}}>LIVE</div>
					:<></>}
				</div>
			</div>
        </>
    );
}

export default Index;