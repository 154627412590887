import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {Spinner} from '../../../../../../../Components/Common/fLoader/';
import {get} from '../../../../../../../Model/Network/Config/Axios';
import { SidePane } from '../../../../../../Common/fSidePane/RightPane';
import {handleError} from "../../../../../../Common/fToast/ToastCallback";

function Index(props) {

    const [Loading, setLoading] = useState(true);
    const [Details, setDetails] = useState(undefined);

    useEffect(() => {
        fetchDetails();
    }, []);

    const fetchDetails = () => {
        get(`settlements/settlements/${props.id}/details`,(e,r)=>{
            if(r) {
                setDetails(r)
                setLoading(false)
            }else if(e){
                if(e.data.message==='Settlement not found'){
                    setLoading(false)
				}else{
					handleError(e)
				}
            }
        })
    }
    return (
        <SidePane button={false} onClose={()=>props.onClose(false)}>
            <div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
            <div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}} >Ledger Details</div>
            <div style={{color: 'var(--text-color)',marginBottom: '35px', fontWeight: 600, fontSize: '24px', lineHeight: '1.3'}}>#{props.id}</div>
            {
            Loading?
            <Spinner/>
            :
            Details?
            <div className='FadeRight-Effect'>
                <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px'}}>Details</div>
                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Ledger ID: </span>{Details.ledgerId}</div>
                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Amount: </span>{Details.currency} {Details.amount}</div>
                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Created On: </span>{moment(Details.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
                {
                    Object.keys(Details.attrs).map(val=>{
                        return(
                            <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>{val}: </span>{Details.attrs[val]?Details.attrs[val]:'-'}</div>
                        )
                    })
                }
                {
                    Details.tdsAmount ?
                    <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Tds Amount: </span>INR {Details.tdsAmount?Details.tdsAmount:'-'}</div>
                    :null
                }
               
                <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px', marginBottom: '20px'}}>Settlements</div>
                {
                    Details.settlements.length?
                    Details.settlements.map((item, index)=>{
                        return (
                            <div key={`info-${index}`} style={{padding: '10px 20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '15px'}}>
                                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Created On: </span>{moment(item.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
                                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Amount: </span>{item.currency} {item.amount}</div>
                                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Ledger ID: </span>{item.ledgerId}</div>
                                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Type: </span>{item.type}</div>
                                {
                                    item.type==='INVOICES' || item.type==='POADVANCE'?
                                        <>
                                        <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>TDS Amount: </span>{item.tdsAmount}</div>
                                        <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Paid Amount: </span>{item.paidAmount}</div>
                                        </>
                                        :<></>
                                }
                                <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Transferred: </span>{item.transferred?'Yes':'No'}</div>
                            </div>
                        )
                    })
                    :<div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
                }
            </div>
            :<div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
            }
            </div>
        </SidePane>
    );
}

export {
    Index as ViewLedger
}

export default Index;