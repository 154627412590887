import React, { useState} from 'react'
import TabSwtich from "../../../../Common/fTabSwitch";
import  { post} from "../../../../../Model/Network/Config/Axios";
import {FloatInputButton} from "../../../../Common/fInputs";
import PhotoTile from "../../../../Common/fPhoto";
import Track from "../../../../Common/fTrack";
import Moment from "moment";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import Invoice from "./fInvoice";
import Tds from './Tds';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import { PageLoader } from '../../../../Common/Components/PageLoader';
import { ViewAttachment } from '../../../../Common/ViewAttachments';
import { DropdownModal } from '../../../../Common/fModal/DropdownModal';
import { timelineLogStatus } from '../../../fVendor/fProcurement/fPoAmend/GRN/GrnView/Timeline';
import CustomTags from '../../../fCommon/CustomTags/CustomTags'


export  default function India(props) {
	let {details,onReload,id,debitNote,debitNoteDetails} = props;

	const [getTab, setTab] = useState(1);
	const [Settlements] = useState(undefined);
	const [loading]  = useState(false);
	const [attachmentMode,setAttachmentMode] = useState(false);
	const [decline,setDecline] = useState(undefined);

	const mapLineItemTag = (item,index) =>{
		return(
			<div key={index} style={{border:'1px solid var(--border-color)',padding:12,borderRadius:12,marginTop:20,paddingTop:0}}>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<FloatInputButton disabled={true} style={{width: '100%'}}  title={item.key} placeholder={item.value}/>
				</div>
			</div>
		)
	}


	
	let tabs = [
		{
			name: 'Format', active: getTab === 1 , onClick: () => {
				setTab(1)
			}
		},
		{
			name: 'Insights', active: getTab === 4 , onClick: () => {
				setTab(4)
			}
		},
		{
			name: 'Timeline', active: getTab === 3 , onClick: () => {
				setTab(3)
			}
		},
		
		
	]
console.log(".details.timelines",details.timelines);

	if(loading) {
		return <PageLoader />
	}

	return (
		<SidePane button={false} onClose={() => props.onClose(false)}
				  column={
					  <Invoice data={{
						  vendor: details.vendor.name,
						  tradeName:details.vendor.tradeName,
						  vendor_code:details.vendor.registeredId,
						  email: details.vendor.email,
						  rcVendor:details.vendor.rcVendor,
						  vendor_id: details.vendor.gstin,
						  date: details.date,
						  due: details.dueOn?details.dueOn:undefined,
						  Credit_note_id: details.creditNoteId,
						  bill_add: details.officeData.address,
						  ship_add: details.officeData.address,
						  bill_to:details.officeData,
						  ship_to:details.officeData,
						  invoice_id:(details && details.invoice && details.invoice.invoiceId)? details.invoice.invoiceId:undefined,
						  bill_number:details.billNo,
						  debitNote,
						  debitNoteDetails,
						  table: [
							  ...details.items.map((item => {
								  return ({
									  referenceId:item.referenceId,
									  desc: item.name?item.name:item.service,
									  service:item.service,
									  price: item.price,
									  original_qtty:item.qtty,
									  credit_note_qtty: item.qtty,
									  base_amt: item.base,
									  gross_amt: item.base - (item.base * (details.taxInfo.discount/100)),
									  tax_amt: item.tax,
									  amount: item.amount,
									  hsn: item.hsn,
									  cessAmount:item.cessAmount?item.cessAmount:0,
									  debitQty:item.debitQty?item.debitQty:0,
									  forms:item.data
								  })
							  }))
						  ],
						  cgst:details.taxInfo.cgst,
						  gst: details.taxInfo.gst,
						  sgst: details.taxInfo.sgst,
						  igst: details.taxInfo.igst,
						  tds: details.taxInfo.tds,
						  total_tax: details.taxInfo.gst,
						  total_base: details.taxInfo.baseAmount,
						  roundOff:details.taxInfo.roundOff,
						  total_amt: details.taxInfo.total,
						  payable_amt: details.taxInfo.paidAmount,
						  discount:details.taxInfo.discount || 0,
						  discountAmount:details.taxInfo.discountAmount ||0,
						  cessAmount:details.taxInfo.cessAmount,
						  status:details.status,
						  settlement:Settlements?Settlements.settled:true,
						  allSettlement:Settlements?Settlements.settlements:[],
						  mSettlement:Settlements?Settlements:false,
						  invoice_amount:details.invoice ? details.invoice.amount :undefined,
						  penalty: details.config && details.config.penalty ?  details.config.penalty : 0
					  }} 
					 
					  
					  />
				  }>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%', minWidth: '400px'}}>
				<div style={{display:'flex',alignItems:'center',marginBottom:35}}>
					<div style={{flex:2}}>
						<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}}>Credit Note</div>
						<div style={{
							color: 'var(--text-color)',
							fontWeight: 600,
							fontSize: '24px',
							lineHeight: '1.3'
						}}>{details.creditNoteId}</div>
					</div>
					{details.config.files.length?
					<div style={{flex:1,display:'flex',justifyContent:'flex-end'}}>		
						 <div onClick={()=>setAttachmentMode(!attachmentMode)} style={{background:'var(--primary-color)',cursor:'pointer',color:'#fff',padding:'5px 10px',borderRadius:4,...attachmentMode?{background:'#000'}:{}}}>{attachmentMode?'Disable View': 'Image View'} </div>			
					</div>
					:null}
				</div>
				{
					
				attachmentMode ?
				<ViewAttachment items={details.config.files}  />
				:
				<>
				<TabSwtich tabs={tabs}/>

				{getTab === 1 ?
					<div id="Invoice_Format" className='FadeRight-Effect' style={{position:'relative',paddingBottom:100}}>
						{
							details.status==='POHOLD'?
							<div style={{fontSize: '12px',background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center'}}>
								<svg style={{marginRight:10, fontSize: '16px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
									<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
								</svg>
								<span>This Invoice is on hold because <b>PO is active or GRN is pending</b>.</span>
							</div>
							:<></>
						}
				
					{
                        details.duplicates && details.duplicates.length  ?
                        <>
                            <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 15px 0px'}}>Duplicate Voucher Id</div>
                            {details.duplicates.map((item)=>{
                                    return (
                                        <div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>
                                <svg style={{fontSize: '14px', marginRight: '10px', verticalAlign: 'middle', color: '#fd7e14', marginBottom: '2px'}} className="bi bi-exclamation-triangle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                </svg>
                                {item}</span>
                                        </div>
                                    )
                                })}
                        </>
                        : null
                    }

						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Details
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: 'var(--text-color)', marginRight: '3px'}}>Credit Note ID:</span>{details.creditNoteId}
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: 'var(--text-color)', marginRight: '3px'}}>Category:</span> {details.category}</div>
						<div style={{marginTop: '5px'}}><span style={{
							color: 'var(--text-color)',
							marginRight: '3px'
						}}>Amount:</span> {`INR ${numberWithCommas(details.amount)}`}</div>

					
						
						{
							props.status === 'COMPLETED' || props.status === 'CANCELLED' ?
								<>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Information
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Vendor Name:</span> {details.vendor.name}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Invoice Date:</span> {Moment(details.date).format('DD-MM-YYYY')}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Invoice ID:</span> {details.creditNoteId}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>GSTIN:</span> {details.vendor.gstin}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Email:</span> {details.vendor.email}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Billing Address:</span> {details.officeData.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Shipping Address:</span> {details.officeData.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>GST:</span> {details.taxInfo.gst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>SGST/UTGST:</span> {details.taxInfo.sgst + details.taxInfo.utgst}
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>CGST:</span> {details.taxInfo.cgst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>IGST:</span> {details.taxInfo.igst}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>TDS:</span> {details.taxInfo.tds}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Base Amount:</span> {details.taxInfo.baseAmount}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Payable Amount:</span> {details.taxInfo.paidAmount}</div>
								</>
								: <>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Original Invoice Details
									</div>
										


									<FloatInputButton  title='Credit Note ID' disabled={details.status !== 'APPROVAL'} placeholder={details.creditNoteId}/>

									<div style={{display: 'flex'}}>
										<div style={{width: '50%'}}><FloatInputButton  title='Base Amount ' placeholder={details.taxInfo.baseAmount} disabled={details.status !== 'APPROVAL'}/>
										</div>
										<div style={{width: '50%', paddingLeft: '10px',marginTop:20}}>
											<Tds id={id} value={details.taxInfo.tds} init={()=>{
												onReload()
											}} 			
											disabled={true}								
											/>
										</div>
									</div>
									<div style={{display: 'flex'}}>
										<div style={{width: '33%'}}><FloatInputButton  title='SGST / UTGST '
											placeholder={details.taxInfo.sgst?details.taxInfo.sgst.toFixed(2):details.taxInfo.sgst}
											disabled={true}
											/>
										</div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											 title='IGST ' placeholder={details.taxInfo.igst?details.taxInfo.igst.toFixed(2):details.taxInfo.igst} disabled={true}/></div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											title='CGST' placeholder={details.taxInfo.cgst} disabled={true}/></div>
									</div>

								</>
						}
						<div
							style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment
						</div>
						<div style={{
							display: 'flex',
							marginLeft: '-15px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>

							{
								details.config.files.length > 0 ?
									details.config.files.map((item, index) => {
										return (
											<PhotoTile  key={`Photo-${index}`} link={item}/>
										)
									}) : <></>
							}
						</div>
						
						{
							details.status === 'APPROVAL' ?
							<div style={{left:'calc(100% - 650px)',right:0,bottom:0,background:'#fff',borderTop:'1px solid var(--border-color)',position:'fixed',height:55,width:'100%',display:'flex',alignItems:'center',paddingLeft:10,paddingRight:10}}>
								<div onClick={()=>{
										post(`vendor/vendor/creditNote/approve/${details.id}`, {}, (e,r)=>{
											if(r){
												props.onReload();
												props.init && props.init()
												showToast({
													type: 'success',
													message: 'Approved'
												})
											}else{
												handleError(e)
											}
										})
								}}  className='btn btn-portlet-success' style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'315px'}}>
								   Approve
								</div>
								<div onClick={()=>{
									setDecline(details.id)
								}} className='btn btn-portlet-success' style={{background:"#ffdad4",color:'#ba1b1b',width:'100%',maxWidth:'315px',marginLeft:10}}>
								   Decline
								</div>
					   		</div>
							:null
						}
                      
                       
						
					</div> : <></>
				}
                       
						
				

				{getTab === 3 ?
					<div id="Invoice_Timeline" className='FadeRight-Effect'>
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline
						</div>
						<Track stages={
							[...details.timelines.map((log,index) => {

								let DESC = <div><b>{log.name}</b><br/>
									<div style={{marginTop: '5px'}}>Type: {log.type}</div>
									<div style={{marginTop: '5px'}}>Status: {log.status}</div>

									<div style={{marginTop: '5px'}}>Sent
										on: {Moment(log.time).format("hh:mm a DD MMM YY")}
										<br/>{log.actionTime && log.status !== 'PENDING' ? `Approved on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
									</div>
								</div>

								return (
									{
										title: log.email,
										desc: DESC,
										status: log.type === 'start' || log.type === 'scanner-end' || (log.type === 'scanner-start' && details.timelines.length>(index+1))? true : log.status ? timelineLogStatus.includes(log.status) : log.name === 'Finance Action' || log.type === 'Completed'|| log.name === 'COMPLETED' ? true : false
									})
							})]
						}/>
					</div> : <></>
				}

				{getTab === 4 ?
					<div id="Invoice_Insight" className='FadeRight-Effect'>
							<div
								style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Insights
							</div>
							{
								details.data ? details.data.map(mapLineItemTag) : <></>
							}
							<div>
								<CustomTags title={"Vendor Custom Tags"} data={(details && details.vendor && details.vendor.customTags)?details.vendor.customTags:{}}/>
								<CustomTags title={"Office Data Custom Tags"} data={(details && details.officeData && details.officeData.customTags)?details.officeData.customTags:{}}/>
								<CustomTags title={"Ship To Data Custom Tags"} data={(details && details.shipToData && details.shipToData.customTags)?details.shipToData.customTags:{}}/>
							</div>
						</div> : <></>
				}
				
				
				<div style={{height: '50px'}}/>
				</>}
			</div>
			

			{
				(decline && decline!== 0) ? <DropdownModal
					title="Decline Credit Note"
					button="Decline"
					close={() => {
						setDecline(undefined)
					}}
					dropdown="vendor-decline"
					onNext={val => {
						post(`vendor/vendor/creditNote/decline/${decline}`,{remarks:val},(e,r)=>{
							if(r) {
								props.onReload();
								setDecline(undefined);
								props.init && props.init();
								showToast({
									type:'success',
									message:"Credit Note Decline Successfully"
								})
							}
						})
					}}
				/> : null
			}


		</SidePane>
	)

}
