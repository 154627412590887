import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'

/*
	<TimeRange height={400} title="Graph" data={[{name:"Hello",data:[Timestamp-121221321312,222]]}/>
*/

function TimeRange(props) {
	return (
		<div>
			<ReactApexChart options={{
				chart: {
					type: 'area',
					stacked: false,
					height: props.height,
					zoom: {
						type: 'x',
						enabled: true,
						autoScaleYaxis: true
					},
					toolbar: {
						tools: {
							download: false,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom'
					}
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 6,
					strokeWidth: 3
				},
				title: {
					text: props.title,
					align: 'left'
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						inverseColors: false,
						opacityFrom: 0.5,
						opacityTo: 0,
						stops: [0, 90, 100]
					},
				},
				yaxis: {
					title: {
						text: props.y
					},
				},
				xaxis: {
					type: 'datetime',
				},
				tooltip: {
					shared: false,
				}
			}} series={props.data} type="area" height={props.height} />
		</div>
	)
}

TimeRange.propTypes = {
	data : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

TimeRange.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350
}

export {
	TimeRange
}

export default TimeRange
