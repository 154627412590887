import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from '../../../../../Model/Network/Config/Axios';
import Title from '../../../../Common/Title';



const Index = ({data}) => {

    let allModules = useSelector(state=>state.auth.config.modules);
    let isContract = _.includes(allModules,"CONTRACTS");
    let [list,setList] = useState([]);
    useEffect(()=>{
        let url = data.contractId  ? 
        `vendor/contracts/fetchLiveContracts?filter=${data.contractId}@id` 
        : 
        `vendor/contracts/fetchLiveContracts?filter=${data.vendor.id}@vendor_id&filter=${data.category.id}@category_id&filter=${data.details.officeId}@office_id`
        get(url,(e,r)=>{
            if(r) {
                setList(r.contracts)
            }
        },{offset:0})
    },[])

    if(!isContract) {
        return <></>
    }

    let mapItem = (item,index) => {
        return(
            <div key={index} style={{borderBottom:'1px solid var(--border-color)',marginTop:10,display:'flex',justifyContent:'space-between'}}>
                <p style={{fontSize:11,paddingBottom:8}}>{item.contractId}</p>
                <p onClick={()=>{
                    window.open(`/app/vendor/contracts/details/${item.id}`,"_blank")
                }} style={{color:'var(--primary-color)',fontSize:12,cursor:'pointer'}}>View</p>
            </div>
        )
    }

    return(
        <div style={{margin:12}}>
            <Title id="Contract" marginLeft={0} title="Contract List"/>
            <div className="input-container" style={{background: "var(--white-color)",margin: 0,padding: 20}}>
                {
                    list.map(mapItem)
                }
            </div>
        </div>
    )
}

export default Index;