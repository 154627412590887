import React from 'react';
import inrInWords from '../../../../../../../Utils/INRToWords';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';

const Data = (props) => {

    let header = (value,style) => {
        return(
            <div style={{
                borderRight:'1px solid var(--border-color)',
                padding:'10px 5px',
                textAlign:'center',
                fontSize:13,
                ...style
            }}>
                {value}
            </div>
        )
    }

    let { procured } = props.data;
    let base = 0,total = 0,tax = 0;

    let totalQuantity = 0;
    let totalBaseAmount = 0;
    procured.forEach(val => {
        base = val.base + base;
        total = total + val.amount;
        tax = tax + val.tax;
        totalQuantity += Number(val.qtty);
        totalBaseAmount += Number(val.base);
    })
    
    return(
        <>
            <div style={{
                display:'flex',
                flex:1,
                border:'1px solid var(--border-color)'
            }}>
               {header('Sr. No.',{width:50})}
               {header('HSN Code',{width:80})}
               {header('Material Description',{flex:1})}
               {header('Quantity',{width:80})}
               {header('U.O.M',{width:80})}
               {header('Rate per Unit',{width:80})}
               {header('Amount',{width:90})}
            </div>
            {procured.map((val,index)=>{
                return(
                    <div key={index} style={{
                        display:'flex',
                        flex:1,
                        border:'1px solid var(--border-color)'
                    }}>
                        {header(index+1,{width:50})}
                        {header(val.hsn,{width:80})}
                        <div style={{
                             borderRight:'1px solid var(--border-color)',
                             padding:'10px 5px',
                             textAlign:'center',
                             fontSize:13,
                             flex:1
                        }}>
                            <p>{val.name}</p>
                            <p>{val.description}</p>
                        </div>
                        {header(val.qtty,{width:80})}
                        {header(val.unit,{width:80})}
                        {header(numberWithCommas(val.price.toFixed(2)),{width:80})}
                        {header(numberWithCommas(val.base.toFixed(2)),{width:90})}        
                    </div>
                )
            })}

            <div style={{ display: "flex" }}>
                {header("Sub - Total (A)", { flex: 1, textAlign: "left" })}
                {header(`${totalQuantity}`, { width: 80 })}
                {header(`${procured[0].unit}`, { width: 80 })}
                {header("", { width: 80 })}
                {header(`${numberWithCommas(totalBaseAmount.toFixed(2))}`, { width: 90 })}
            </div>

            <div style={{ display: "flex", border:'1px solid var(--border-color)' }}>
                {header("Applicable GST (B)", { flex: 1, textAlign: "left" })}
                {header(`${procured[0].taxSlab}%`, { width: 160, textAlign: "left" })}
                {header(`${numberWithCommas(tax.toFixed(2))}`, { textAlign: "right", width: 90 })}
            </div>

            <div style={{ display: "flex", border:'1px solid var(--border-color)' }}>
                {header("Grand Total (A) + (B)", { flex: 1, textAlign: "left" })}
                {header(`${numberWithCommas(total.toFixed(2))}`, { textAlign: "right", width: 90 })}
            </div>

            <div style={{ display: "flex", border:'1px solid var(--border-color)' }}>
                {header("Amount in words: " , { width: 130 })}
                {header(`${inrInWords(total)} Only`, { flex: 1, textAlign: "left" })}
            </div>

            <div style={{flex:2}}>
                <div style={{
                    display:'flex',
                    borderBottom:'1px solid var(--border-color)',
                    padding:"0px 10px",
                    minHeight:45,
                }}>
                    <div style={{
                        width:200,
                        fontWeight:'bold',
                        display:'flex',
                        alignItems:'center'
                    }}>
                        Terms and Condition :
                    </div>
                    <div style={{
                        flex:1,
                        borderLeft:'1px solid var(--border-color)',
                        fontSize:13,
                        paddingLeft:10,
                        minHeight:45,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                        <div>
                            {
                                props.data.conditions.map(val=>{
                                    return(
                                        <div dangerouslySetInnerHTML={{__html:val.content}} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div style={{
                    display:'flex',
                    borderBottom:'1px solid var(--border-color)',
                    padding:"0px 10px",
                    minHeight:45,
                }}>
                    <div style={{
                        width:200,
                        fontWeight:'bold',
                        display:'flex',
                        alignItems:'center'
                    }}>
                        Payment Terms:
                    </div>
                    <div style={{
                        flex:1,
                        borderLeft:'1px solid var(--border-color)',
                        fontSize:13,
                        paddingLeft:10,
                        minHeight:45,
                        display:'flex',
                        alignItems:'center'
                    }}>
                        <div>
                            {props.data.payments.map(val=>{
                                return(
                                    <div dangerouslySetInnerHTML={{__html:val.content}} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Data;
