import {get} from "../../Utils/Crypto";

const Actions = {
	THEME: "THEME"
}

let STATE = {
	color: '#767DFF',
	logo: require('./logo.png'),
	login: {header: 'Dice for Enterprise',
	message: 'Manage all your corporate spendings with dice'},
	domain: 'www.dice.tech',
	sidebar: {
		logo: require('./logo.png'),
	},
	default : true
}

const initialState = () => {
	const THEME = get("THEME");
	if (THEME) {
		return {
			...STATE, ...THEME
		}
	} else
		return STATE;
}


export const theme = (state = initialState(), action) => {
	let data = action.data;
	switch (action.type) {
		case Actions.THEME:
			return {
				...state,
				...data
			};
		default:
			return state;
	}
}
