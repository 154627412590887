import React from 'react';
import GoogleLogin from 'react-google-login';
import Google_Ico from './assets/google.png';
import { showToast } from '../../Components/Common/fToast/ToastCallback';


const Index = ({email}) => {



    const responseGoogle = (response) => {
        console.log(response);
        if(response.code){
            const form = document.createElement('form');
            form.method = "post";
            form.action = "https://provider-qa.dice.tech/auth/admin/sso";

            let params = {
                type: 'google',
                token: response.code,
                referrer: window.location.origin,
                redirect: 'http://localhost:2000',
                client_id: "2;3iu4h2p3948p782o3ux8927grp981237r6fo128e973862te7y7286t4xb2ct3b278643230987rgf"
            }

            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = params[key];
                    form.appendChild(hiddenField);
                }
            }
            document.body.appendChild(form);
            try {
                // setLoader(true)
                form.submit();
            } catch (err) {
                // setLoader(undefined)
                showToast({
                    type: 'error',
                    message: err.response ? err.response.data.message : "Something went Wrong !"
                })
            }
        }else{
            // setErrorPage(true)
        }
    }

    return(
        <div>
            <img className='mb-4' src={Google_Ico} style={{maxWidth: '80px'}} alt=""/>
            <h1 className='display-6 fw-bold'>Continue</h1>
            <p style={{marginBottom: '40px'}}>You have SSO enabled for your account</p>
            <GoogleLogin
                clientId="577109105568-e65ha2mpib84tua3o06b2qrjnldosutk.apps.googleusercontent.com"
                onSuccess={responseGoogle}
                accessType="offline"
                uxMode="redirect"
                approvalPrompt="force"
                prompt='consent'
                loginHint={email}
                responseType="code"
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}/>
        </div>
    )
}


export default Index;