import React, { useState } from 'react';
import PhotoTile from '../fPhoto';
import { PdfViewer } from './PdfViewer';


const ViewAttachment = ({items}) => {

    let [item,setItem] = useState(items[0]?items[0]:'')
    
    return(
        <div>
            <div style={{height:'80vh',overflowY:'auto'}}>
            {
                item.toLowerCase().includes('pdf') ?
                    <PdfViewer file={item} /> :item.toLowerCase().includes('jpeg') || item.toLowerCase().includes('png') || item.toLowerCase().includes('jpg')?
                     <img alt="" src={item} style={{height:'100%',width:'100%',objectFit:'contain'}} />:null
            }
            </div>
            {
                items.length>0 ?
                <div style={{display:'flex',flexWrap:'wrap'}}>
                {
                    items.map((photo,index)=>{
                    return(
                        <PhotoTile onClick={()=>{
                            setItem(items[index])
                        }} viewOnly={true} link={photo} key={index} />
                    )
                     })
                }
                </div>
                :null
            }

            
        </div>
    )
}

export {ViewAttachment}