import React,{useEffect, useRef, useState} from "react";
import Moment from 'moment';
import { get, post } from "../../../../../Model/Network/Config/Axios";
import { KeyValue } from "../../../../Common/Components/KeyValue";
import { PageLoader } from "../../../../Common/Components/PageLoader";
import {  FunctionalInput } from "../../../../Common/fInputs";
import { showToast } from "../../../../Common/fToast/ToastCallback";
import { InfoNotification } from "../../../../Common/Components/Workflow/CommonNotification/Info";


const Index = ({id,details,gst2ASyncingForIrn}) => { 



    const [loading,setLoading] = useState(true);
    const [syncLoading,setSyncLoading] = useState(false);
    const [syncLoading2A,setSyncLoading2A] = useState(false);
    const [gstin] = useState(details.address.gstin)
    const [usernameRequired,setUserNameRequired] = useState(false);
    const [data,setData] = useState(undefined);
    const [otpRequired,setOtpRequired] = useState(false);
    const [otpSent,setOtpSent] = useState(false);
    const [sync,setSync] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [open,setOpen] = useState(true);
    let  USERNAME = useRef(),OTP=useRef();

    useEffect(()=>{
        INIT(id);
    },[id])


    let INIT = () => {
        get(`settings/gstin/gst2b/getGstr2BInvoiceDetails/${id}`,(e,r)=>{
            if(r) {
                setData(r);
                setLoading(false);
                setSync(true);
            } else {
                setErrorMessage(e.data.message);
                get(`settings/gstin/gst2b/getGstr2BAuthRequest?gstin=${gstin}`,(e1,r1)=>{
                    if(r1) {
                        setUserNameRequired(r1.usernameRequired);
                        setOtpRequired(r1.otpVerificationRequired)
                        setLoading(false);
                        if(!r1.usernameRequired && r1.otpVerificationRequired) {
                            post(`settings/gstin/gst2b/triggerGstr2BOtp`,{gstin},(e2,r2)=>{
                                if(r2) {
                                    showToast({
                                        type:'success',
                                        message:"OTP sent successfully"
                                    })
                                    setOtpSent(true);
                                    setLoading(false);
                                } else {
                                    showToast({
                                        type:'error',
                                        message:e2.data.message
                                    })
                                }
                            })
                        }
                        if(!r1.usernameRequired && !r1.otpVerificationRequired) {
                            setSync(true);
                        }
                        
                    }
                })
               
            }
        })
    }
    
    let sync2b = () => {
        showToast({
            type:'success',
            message:'Sync running in the background'
        })
        setSyncLoading(true);
        if(!details.address.gstin){
            showToast({
                type:'error',
                message:"Buyer's GST not available"
            })
            return;
        }
        post(`settings/gstin/gst2b/syncGstr2BReconInvoices`,{
            gstin:details.address.gstin,
            date:Moment(details.invoice.date,"DD-MM-YYYY").valueOf(),
            force: true
        },(e,r)=>{
            if(r) {
                INIT();
                showToast({
                    type:'success',
                    message:"Sync Succesfully"
                })
                INIT();
                setSyncLoading(false);
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
                setSyncLoading(false);

            }
        })
    }

    const  sync2a = () => {
        setSyncLoading2A(true);
        post(`settings/gstin/gst2b/syncGstr2AInvoices/${id}`,{ },(e,r)=>{
            if(r) {
                INIT();
                showToast({
                    type:'success',
                    message:"Sync successful"
                })
                INIT();
                setSyncLoading2A(false);
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
                setSyncLoading2A(false);
            }
        })
    }



    if(loading) {
        return <PageLoader/>
    }

    if(otpSent && otpRequired) {
        return(
            <div style={{marginTop:30}}>
                <FunctionalInput ref={OTP} title="Enter the OTP" />
                <div onClick={()=>{
                    let otp = OTP.current.value;;
                    if(!otp) {
                        showToast({
                            type:'error',
                            message:"Please enter the otp"
                        })
                        return;
                    }
                    post(`settings/gstin/gst2b/verifyGstr2BOtp`,{otp,gstin},(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:"OTP verified successfully"
                            })
                        }
                    })
                }} className="btn btn-portlet">Verify OTP</div>
            </div>
        )
    }


    let item = (key,value1,value2)=>{
        return(
            <div style={{display:'flex',alignItems:'center',flex:1,border:'1px solid var(--border-color)',minHeight:35,height:35}}>
                   <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{key}</div>
                   <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{value1}</div>
                   <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{value2}</div>
            </div>
        )
    }




    
    return(
        <div style={{marginTop:30}}>
            <div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>2B Recon</div>
             {
                       data&& data.mismatch && open ?
                        <InfoNotification onClick={()=>{
                            setOpen(false)
                        }} message="There is mismatch in 2B data and current Invoice" style={{marginBottom:20,marginTop:20,height:50}} />
                        :null
                }
            {
                usernameRequired ?
                <>
                    <FunctionalInput ref={USERNAME} title="Enter the Gstin Portal Username" />
                    <div className="btn btn-portlet" onClick={()=>{
                        let username = USERNAME.current.value;
                        if(!username) {
                            showToast({
                                type:'error',
                                message:"Please enter the username"
                            })
                            return;
                        }
                        post(`settings/gstin/gst2b/addGstr2BUsername`,{username,gstin},(e,r)=>{
                            if(r) {
                                post(`settings/gstin/gst2b/triggerGstr2BOtp`,{gstin},(e2,r2)=>{
                                    if(r2) {
                                        showToast({
                                            type:'success',
                                            message:"OTP sent successfully"
                                        })
                                        setOtpSent(true);
                                    } else {
                                        showToast({
                                            type:'error',
                                            message:e2.data.message
                                        })
                                    }
                                })
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }} style={{marginTop:15}}>Continue</div>
                </>
                :null
            }
            {
                sync && !data  ?
                <>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                      <p>{errorMessage?errorMessage:'Sync It'}</p>
                      <p onClick={sync2b} style={{fontSize:14,background:'var(--red-color)',padding:'3px 15px',cursor:"pointer",borderRadius:4,color:"#fff"}}>{syncLoading?"Syncing...":'Sync'}</p>
                  </div>
                </>
                :null
            }

            {
                data ?
                <div style={{marginTop:10}}>

                    <div style={{fontSize: '14px', fontWeight: 'bold', margin: '5px 0px 15px 0px'}}>2B Details</div>

                     <div style={{
                        display:'flex',
                        alignItems:'center',
                        height:35,
                        border:'1px solid var(--border-color)',
                        fontWeight:'500'
                     }}>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>Key</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>2B Value</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>Invoice Value</div>
                     </div>
                     {item("Buyer Gstin",data.buyerGstin,details.deliverTo.gstin||'NA')}
                     {item("GST 2b Captured on",Moment(data.gst2bCapturedOn).format('DD-MM-YYYY'),"-")}
                     {item("Invoice Number",data.gst2bInv.inum,details.invoice.invoiceId||'NA')}
                     {item("Invoice Date",data.gst2bInv.dt,details.invoice.date)}
                     {item("Total Amount",data.gst2bInv.val,details.taxInformation.total)}


                     <div style={{marginTop:20}} />

                      <div style={{
                        display:'flex',
                        alignItems:'center',
                        height:35,
                        border:'1px solid var(--border-color)',
                        fontWeight:'500'
                     }}>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>Item Number</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>Base</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>Tax %</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>IGST</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>SGST</div>
                          <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>CGST</div>
                     </div>

                     {
                        data.gst2bInv.items.map(({num,rt,cgst,txval,igst,sgst},index)=>(
                            <div style={{
                                display:'flex',
                                alignItems:'center',
                                height:35,
                                border:'1px solid var(--border-color)',
                                fontWeight:'500'
                            }} key={index}>
                                <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{num}</div>
                                <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{txval}</div>
                                <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{rt}</div>
                                <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{igst}</div>
                                <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{sgst}</div>
                                <div style={{flex:1,height:'inherit',paddingLeft:5,display:'flex',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>{cgst}</div>
            
                            </div>
                        ))
                     }


                     <div style={{marginTop:20}} />

                     
                   

                    {
                        data.suggestedChange.length>0 ?
                        <div>
                            <p style={{marginBottom:20,fontWeight:'bold'}}>Suggestive Changes</p>
                            <div style={{display:'flex',flexWrap:'wrap'}}>
                                {data.suggestedChange.map((val,index)=>{
                                    return(
                                        <KeyValue style={{width:'23.5%'}} key={index} title={`Change ${index+1}`} value={val} />
                                    )
                                })}
                            </div>
                        </div>
                        :null
                    }


                </div>
                :null
            }
            {/* 2A Recon */}
            {details && details.vendor && details.vendor.eInvoiceApplicable ?
                <>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>2A Recon</div>
                    {
                        gst2ASyncingForIrn ?
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <p onClick={sync2a} style={{ fontSize: 14, background: 'var(--red-color)', padding: '3px 15px', cursor: "pointer", borderRadius: 4, color: "#fff" }}>{syncLoading2A ? "Syncing..." : 'Sync'}</p>
                                </div>
                            </>
                            : null
                    }
                </>
                : null}
        </div>
    )
}

export default Index;