import React from 'react';
import inrInWords from '../../../../../../../Utils/INRToWords';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';

const Data = (props) => {

    let header = (value,style) => {
        return(
            <div style={{
                padding:'10px 5px',
                ...style
            }}>
                {value}
            </div>
        )
    }

    let { procured } = props.data;

    let totalTax = 0;
    let totalBaseAmount = 0;
    procured.forEach(val => {
        totalBaseAmount += val.base;
        totalTax += val.tax;
    })
    
    return(
        <>
            <div style={{
                display:'flex',
                flex:1,
                border:'1px solid var(--border-color)',
                background: "#eeeeee",
                marginTop: 20
            }}>
               {header('#',{width:50})}
               {header('Name/Description',{width: 150})}
               {header('Comment',{width: 160})}
               {header('Qty',{width:60})}
               {header('Unit of Measure',{width:80})}
               {header('Rate',{width:100})}
               {header('Tax (SGST + CGST)',{width:90})}
               {header('Tax Percentage',{width:90})}
               {header('Total',{width:100, textAlign: "right"})}
            </div>
            {procured.map((val,index)=>{
                return(
                    <div key={index} style={{
                        display:'flex',
                        flex:1
                    }}>
                        {header(index+1, {width:50})}
                        {header(val.name, {width:150})}
                        {header(val.description, {width: 160})}
                        {header(val.qtty, {width:60})}
                        {header(val.unit, {width:80})}
                        {header(`${props.data.currency} ${numberWithCommas(val.price.toFixed(1))}`, {width:100})}
                        {header(`${props.data.currency} ${numberWithCommas(val.tax.toFixed(1))}`, {width:90})}
                        {header(`${numberWithCommas(val.taxSlab.toFixed(1))} %`, {width: 90})}
                        {header(`${props.data.currency} ${numberWithCommas(val.amount.toFixed(1))}`, {width: 100, textAlign: "right", paddingRight: 0})}
                    </div>
                )
            })}

            <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ display: "flex", flex: 1.75 }}>
                    <span style={{ fontWeight: 700, padding: 5 }}>Net Total Amount in words: </span>
                    {header(`${inrInWords(totalBaseAmount + totalTax)} Only`, { flex: 1, textAlign: "left", padding: 5 })}
                </div>
                <div style={{flex: 1}}>
                    <div style={{display: "flex", justifyContent: "space-between", padding: 5,
                textAlign:'center',
                fontSize:13}}>
                        <div>Total</div> <div style={{ fontWeight: 700 }}>{`${props.data.currency} ${totalBaseAmount.toFixed(1)}`}</div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", padding: 5,
                textAlign:'center',
                fontSize:13}}>
                        <div>Tax (SGST + CGST)</div> <div style={{ fontWeight: 700 }}>{`${props.data.currency} ${totalTax.toFixed(1)}`}</div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", padding: 5,
                textAlign:'center',
                fontSize:13}}>
                        <div>Net Total</div> <div style={{ fontWeight: 700 }}>{`${props.data.currency} ${(totalBaseAmount + totalTax).toFixed(1)}` }</div>
                    </div>
                </div>
            </div>

            
            <p style={{fontSize: 15, color: "#87adf1", marginBottom: 10}}>Note to Vendor</p>
            <div style={{flex:2}}>
                <div style={{
                    height:45,
                    display:'flex',
                    alignItems:'center',
                    fontSize:13
                }}>Terms and Condition :</div>
                <div style={{
                    display:'flex',
                    padding:"0px 10px",
                    minHeight:45,
                }}>
                    <div style={{
                        flex:1,
                        fontSize:13,
                        paddingLeft:10,
                        minHeight:45,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                        {props.data.vendorConditions}
                        <div>
                            {
                                props.data.conditions.map(val=>{
                                    return(
                                        <div dangerouslySetInnerHTML={{__html:val.content}} />
                                    )
                                })
                            }
                        </div>
                        <div>
                            {props.data.payments.map(val=>{
                                return(
                                    <div dangerouslySetInnerHTML={{__html:val.content}} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Data;
