import React from 'react';
import {Title as Header, TitleTopHeader} from '../../../../Header/TopHeader';
import {pluginsPostApis} from '../../../../../Model/Network/Config/Axios';
import {showToast} from '../../../../Common/fToast/ToastCallback';


class Index extends React.Component {


    componentDidMount() {
        pluginsPostApis(`plugins/readInvoiceQr/config`, {}, (e, r) => {
            if (r) {

            }
        })
    }

    activate = () => {
        pluginsPostApis(`plugins/readInvoiceQr/activate`, {}, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: "Plugin activate successfully"
                })
            }
        })
    }

    render() {
        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }}>
                <TitleTopHeader
                    title="IRN Invoice Reader"
                    des="Activate this plugin and start attaching your IRN invoice details"
                    items={[]}
                />

                <Header
                    title="IRN Invoice Reader"
                    des="Activate this plugin and start attaching your IRN invoice details"

                />

                <div className="title-container" style={{
                    padding: 20
                }}>

                    <div className="btn btn-portlet" onClick={this.activate}>Activate</div>
                </div>

            </div>
        )
    }
}


export default Index;
