import _ from 'lodash'

const trackData = (data) => {
	let path = window.location.href;
	data.path = path;
	if(data.data.password){
		return;
	}
	if(_.includes(["https://heimdall.eka.io/admin/auth/token","https://heimdall.eka.io/admin/auth/username"],data.data.apiUrl)){
		return
	}
}

export {
	trackData
}
