import React from "react";
import Props from "prop-types";

class ShownSelect2 extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			shown: false,
			items: props.items
		}
		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	showMenu(event) {
		event.preventDefault();
		this.setState({shown: true, items: []}, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}


	closeMenu(event) {
		if (!event) {
			this.setState({shown: false}, () => {
				document.removeEventListener('click', this.closeMenu);
			});
			return
		}
		if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
			this.setState({shown: false}, () => {
				document.removeEventListener('click', this.closeMenu);
			});

		}
	}

	onClick = (click) => {
		this.setState({shown: false}, () => {
			document.removeEventListener('click', this.closeMenu);
		});
		click()
	}

	onChange = () => {
		let value = this.input.value;
		if (this.props.onChange) {
			this.props.onChange(value, items => {
				this.setState({items})
			})
		}
	}

	select = item => {
		this.closeMenu()
		this.props.select(item)
	}

	render() {
		return (
			<div ref={ref => this.dropdownMenu = ref} className="selectContainer2">
				{this.props.lebel ? <label className="floating-top-label">{this.props.lebel}</label> : null}
				<div onClick={this.showMenu} className={`floating-label`}>
					<p style={this.props.inputStyle}>{this.props.value ? this.props.value.label : this.props.placeholder}</p>
				</div>
				{
					this.state.shown ?
						<div style={{top: 45, width: '100%'}} className="menu">
							<div className="item-container">
								{
									this.props.items.map(item => <div key={item.value}
																	  onClick={this.select.bind(this, item)}
																	  className="item">
										<p>{item.label}</p>
									</div>)
								}
							</div>
						</div> :
						null


				}
			</div>
		)
	}

}

ShownSelect2.propTypes = {
	value: Props.object,
	placeholder: Props.string.isRequired,
	items: Props.array,
	select: Props.func.isRequired,
}

ShownSelect2.defaultProps = {
	items: []
}

export {
	ShownSelect2
}
