import React from 'react';
import './fInvoice.css'

const Invoice = (props) => {
    let data = props.data;
    let {billingTo,shippingTo} = data;
    let {vendor,discount} = props;


    let base = 0,cgst = 0,sgst = 0,igst= 0,total = 0,tax = 0;

    let discountAmount = 0;
    let cess = 0;

    data.lineItems.forEach(item=>{
        base = base + parseFloat(item.base);
        discountAmount = discountAmount + (item.base * (discount/100));
        total = total + parseFloat(item.total);
        tax = tax + parseFloat(item.tax);
        cess = cess+ item.cessAmount ? parseFloat(item.cessAmount):0;
    })

    if(vendor&& vendor.gstin && vendor.gstin.length>2 && data.billingTo&& data.billingTo.gstin && data.billingTo.gstin.length>2){
        if(vendor.gstin.slice(0,2)===data.billingTo.gstin.slice(0,2)){
            cgst = tax/2;
            sgst = tax/2;
        }else{
            igst = tax;
        }
    }else{
        cgst = tax/2;
        sgst = tax/2;
    }
    const renderTable = () => {
        return (
           <div>
               {
                   data.lineItems.map((item,index)=>{
                        console.log(item);
                       return (
                           <div key={index} style={{display: 'flex', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                               <div style={{width: '160px'}}>
                                    {item.name}<br></br>
                                    <span style ={{fontSize:12}}>{item.service.label}<br/>
                                    Code: {item.hsn}</span> <br/>
                                    <span style={{
                                        fontSize:12,
                                    }}>
                                        {item.itemId ? `Item Ref No : ${item.itemId}`:null}
                                    </span>
                                    {item.hasOwnProperty("autoBudgeting") && <p className='text-small'>Auto Budgeting: {item.autoBudgeting?"yes":"no"}</p>}
                                    {item.budgetId?<div>Budget ID: {item.budgetId}</div>:null}
                                    {item.budgetStatus?<div>Budget Status: {item.budgetStatus}</div>:null}
                                    {item.budgetAmount?<div>Budget Amount: {item.budgetAmount}</div>:null}
                                </div>
                               <div style={{width: '100px',}}> {item.price?item.price.toFixed(3):item.price}</div>
                               <div style={{width: '100px',}}> {item.qtty}</div>
                               <div style={{width: '100px',}}> {item.base?item.base.toFixed(3):item.base}</div>
                               <div style={{width: '100px',}}> {item.grossBase?item.grossBase.toFixed(2):item.grossBase}</div>
                               {/* <div style={{width: '100px',}}> {item.cessAmount?item.cessAmount:0}</div> */}
                               <div style={{width: '100px'}}> {item.tax?item.tax.toFixed(2):item.tax}</div>
                               {props.budgetingV2 && <div style={{ width: '100px', textAlign: 'right', display: 'flex', justifyContent: 'center' }}>
                                   <div style={{
                                       width: 45
                                   }}>
                                       <div style={{ width: 44 }}>
                                           <div onClick={() => props.updateLineItem(index)} className="portlet-switch">
                                               <div className={`check ${(item.autoBudgeting) ? 'on' : 'off'}`} />
                                           </div>
                                       </div>
                                   </div>
                               </div>}
                               <div style={{width: '100px', textAlign: 'right'}}> {item.total?item.total.toFixed(2):item.total}</div>
                           </div>
                       )
                   })
               }
           </div>
        )
    }

    return (
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
            <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>INVOICE</div>

            <div style={{border: '1px solid var(--border-color)', padding: '20px', borderRadius: '15px', marginTop: '30px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <b>Vendor</b><br/>
                        {
                            vendor?
                                <div>
                                    {vendor.name}<br/>
                                   {vendor.address ?
                                   <>
                                    <p>
                                    Address:{ typeof vendor.address === 'string' ? vendor.address:vendor.address.address}
                                    </p><br/>
                                    </>
                                    
                                    :null
                                }
                                    Email: {vendor.email}<br/>
                                    GSTIN: {vendor.gstin}
                                </div>:<div>
                                    Select vendor to continue
                                </div>
                        }
                    </div>
                    <div style={{textAlign: 'right'}}>
                        Date:{data.billDate ? data.billDate : ''}<br/>
                        Due Date:{data.due ? data.due : ''}<br/>
                        Order ID: {data.billNumber}<br/>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <div style={{paddingRight: '20px'}}>
                        <b>Billing Address</b><br/>
                        {
                            billingTo?
                                <div>
                                    {billingTo.name}<br/>
                                    {billingTo.registeredName?<span>RegisteredName: {billingTo.registeredName}<br></br></span>:null}
                                    {billingTo.address?<span>Address: {billingTo.address}<br></br></span>:null}
                                    {billingTo.gstin?<span>Gstin: {billingTo.gstin}<br></br></span>:null}
                                    {billingTo.city?<span>City: {billingTo.city}<br></br></span>:null}

                                </div>:<div>
                                    Select billing to
                                </div>
                        }
                    </div>
                    <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                        <b>Shipping Address</b><br/>
                        {
                            shippingTo?
                                <div>
                                    {shippingTo.name}<br/>
                                    {shippingTo.registeredName?<span>RegisteredName: {shippingTo.registeredName}<br></br></span>:null}
                                    {shippingTo.address?<span>Address: {shippingTo.address}<br></br></span>:null}
                                    {shippingTo.gstin?<span>Gstin: {shippingTo.gstin}<br></br></span>:null}
                                    {shippingTo.city?<span>City: {shippingTo.city}<br></br></span>:null}
                                </div>:<div>
                                    Select ship to
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '30px',
                fontWeight: 'bold',
                paddingBottom: '10px',
                borderBottom: '1px solid var(--border-color)'
            }}>
                <div style={{width: '160px'}}>Description</div>
                <div style={{width: '100px',}}>Price</div>
                <div style={{width: '100px',}}>Qty</div>
                <div style={{width: '100px',}}>Base Amt</div>
                <div style={{width: '100px',}}>Gross Amt</div>
                {/* <div style={{width: '100px',}}>Cess Amt</div> */}
                <div style={{width: '100px'}}>Tax Amt</div>
                {props.budgetingV2 && <div style={{width: '100px', textAlign: 'right'}}>Auto Budgeting</div>}
                <div style={{width: '100px', textAlign: 'right'}}>Amount (INR)</div>
            </div>

            {renderTable()}


            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Base Amt:</div>
                    <div> {base?base.toFixed(2):base}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Discount Amt:</div>
                    <div> {discountAmount}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>SGST / UTGST:</div>
                    <div> {sgst.toFixed(3)}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>CGST:</div>
                    <div> {cgst.toFixed(3)}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>IGST:</div>
                    <div> {igst.toFixed(2)}</div>
                </div>
            </div>

            <div className='invoice-taxes' >
                <div className='wrap'>
                    <div>Tax Amt:</div>
                    <div> {tax.toFixed(2)}</div>
                </div>
            </div>

            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Cess Amt:</div>
                    <div> {cess.toFixed(2)}</div>
                </div>
            </div>


            <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
                <div className='wrap'>
                    <div>Total Amt:</div>
                    <div>{total ? total.toFixed(2): parseInt(total)}</div>
                </div>
            </div>
           

           


            <div style={{height: '40px'}}/>

            
        </div>
    )
}

export default Invoice;
