import React from "react";
import Props from 'prop-types'

function Status(props) {

	return (
		<div style={{
			...props.container
		}}>
			<div style={{display:'flex', marginBottom:20,alignItems:'center'}}>
				<div style={{
					width:10,
					height:10,
					marginRight:5,
					border:'3px solid #ffc107',
					borderRadius:5,
					marginTop:1
				}}/>
				<p style={{
					fontWeight:600,
					fontSize:12,
					textTransform:'uppercase',
					letterSpacing:.5
				}}>
					Insights
				</p>
			</div>
			<div style={{display:'flex',}}>
				{
					props.badges.map(item=>{
						return (
							<div key={item.title} id={item.title} style={{
								background:"var(--border-color)",
								padding:'6px 12px',
								borderRadius:8,
								marginRight:8,
								color:'var(--text-color)',
								fontSize:9,
							}}>
								{item.title}
							</div>
						)
					})
				}
			</div>
		</div>
	)

}


Status.propTypes = {
	container : Props.object,
	badges : Props.array.isRequired
}

Status.defaultProps = {
	container : {
		marginTop:25,
		marginBottom:30
	}
}


export {
	Status
}
