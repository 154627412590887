import { useState } from "react"
import { get } from "../../../Model/Network/Config/Axios";



const useVendorPaymentTerm = () => {
    
    const [paymentTerm,setPaymentTerm] = useState({});

    let fetchVendorTerms = (id) => {
        get(`vendor/contacts/${id}/category`,(e,r)=>{
            if(r) {
                setPaymentTerm(r.paymentTerms)
            }
        })
    }

    let getTerm=  (id) => {
        return paymentTerm[id]?paymentTerm[id]:undefined
    }

    return {fetchVendorTerms,getTerm}
   
}

export {useVendorPaymentTerm}