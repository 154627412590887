import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'
/*
	<AreaGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/
function AreaGraph(props) {
	return (
		<div>
			<ReactApexChart options={{
				xaxis: {
					categories: props.items,
				},
				title: {
					text: props.title,
					align: 'left'
				},
				dataLabels: {
					enabled: false
				},
				chart: {
					type: 'area',
					height: props.height,
					toolbar: {
						tools: {
							download: false,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom'
					}
				},
				stroke: {
					curve: 'straight'
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'],
						opacity: 0.5
					},
				},
				fill: {
					opacity: 1
				},
			}} series={props.data} type="area" height={props.height} />
		</div>
	)
}


AreaGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

AreaGraph.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350
}

export {
	AreaGraph
}

export default AreaGraph
