import React from 'react'

function Container(props) {
	return (
		<div style={{
			flexBasis: `calc(${props.width?props.width:"30%"} - 30px)`,
			margin:12,
			borderRadius:10,
			padding:10,
			border:'1px solid var(--border-color)',
			boxShadow:'2px 1px 1px var(--border-color)',
			background:"var(--white-color)",
			...props.style.container
		}} onClick={()=>{
			if(props.onClick) {
				props.onClick();
			}
		}}>
			{props.children}
		</div>
	)
}

Container.propTypes = {

}

Container.defaultProps={
	style : {
		container : {

		}
	}
}

export default Container;
