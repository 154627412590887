import React from 'react';
import {KeyValue} from '../../../../../../Common/Components/KeyValue';


class OpenItem extends React.Component {



    render() {
        let { approvers } = this.props;
        return (
            <div>
                {this.props.children}
                <div className="expanded-item" style={this.props.style ? this.props.style : { padding: '20px 25px' }}>
                    <div style={{
                        borderBottom: '2px solid var(--border-color)',
                        marginBottom: 20
                    }}>
                        <KeyValue
                            title="Vendor Name"
                            value={this.props.vendorName}
                        />
                        <KeyValue
                            title="Description"
                            value={this.props.description}
                        />

                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 2,
                        flexWrap: 'wrap'
                    }}>
                        {Object.keys(approvers).map((val, index) => {
                            let i = approvers[val];
                            let curr = null;

                            Object.keys(approvers).forEach((j, k) => {
                                let check = approvers[j];
                                if (check.declined) {
                                    curr = k
                                }
                            })

                            let status = "PENDING";
                            if (curr !== null && index >= curr) {
                                status = "DECLINED"
                            } else if (i.approved) {
                                status = "APPROVED"
                            }
                            return (
                                <div key={index} style={{
                                    width: '33%'
                                }}>
                                    <KeyValue
                                        title={i.email + ` ( ${val} ) `}
                                        value={status}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default OpenItem;
