import React from 'react';
import Props from "prop-types";
import './index.css'

/*
PROPS:
title = string (Card Heading),
type = string ('solid' or 'outline')
*/

const Index = (props) => {

    return (
        <>
            <div onClick={props.onClick} className={`FadeUp-Effect card-${props.type?props.type:'solid'}`} style={{...props.style}}>
                {
                    props.title?
                    <div className='title'>
                        {props.title}
                    </div>
                    :<></>
                }
                {
                    props.children
                }
            </div>
        </>
    );
}

Index.propTypes = {
	title: Props.string,
	type: Props.string,
}

export default Index;