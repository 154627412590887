import React, { useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { showToast } from "../../../../../Common/fToast/ToastCallback";
import { Modal } from "../../../../../Common/fModal/Modal";
import { Select } from "../../../../../Common/Class/Select";
import { FunctionalInput } from "../../../../../Common/fInputs";
import AutocompleteSearch from "../../../../fControl/Config/Common/autocomplete";
import { GSTS } from "./GSTS";
import { get } from "../../../../../../Model/Network/Config/Axios";
import { SearchableSelect } from "../../../../../Common/Class/Select/SearchableSelec2";

const AddConditions = ({
  onClose,
  onSave,
  show,
  loading,
  label,
  des,
  update,
  id,
  editable
}) => {
  const [gst, setGst] = useState({
    value: 0,
    label: 0,
  });
  const [updated, setUpdated] = useState({
    content: {},
  });
  const [state,setState]  = useState({
     vendors:[],
     selectedVendor:undefined,
     po:undefined,
     condition:undefined
  });

  const {vendors,selectedVendor,po,condition} = state;

  const Price = useRef(),
    Notes = useRef(),
    Qty = useRef(),
    Hsn = useRef();

  useEffect(() =>{
    if(show.qtty) {
        Qty.current.value =  show.qtty ? show.qtty : '';
    }
  },[show.qtty]);

    useEffect(() => {
      if (editable && show) {
        Price.current.value = editable.price;
        Notes.current.value = editable.notes;
        Hsn.current.value = editable.hsn;
        Qty.current.value = editable.qty ? editable.qty : '';
        setState(prev  => ({...prev,
           condition:{
            label:editable.title,value:editable.conditionId
            },
            po:{
              label:editable.conditionalPoNo,value:editable.conditionalPoId
            },
            selectedVendor:{
              label:editable.vendorName,value:editable.vendorId
            },
          }))
          setGst({
            value: editable.taxSlab,
            label: editable.taxSlab,
          })
      }
    }, [editable]);

  const updateFunction = (key, value) => {
    let updateObject = { ...updated };
    updateObject["content"][key] = value;
    setUpdated(updateObject);
  };

  const selectLineItems = () => {
    let notes = Notes.current.value;
    let qty =  Qty.current && +Qty.current.value;
    let hsn = Hsn.current.value;
    let price = +Price.current.value;
    

    if (!condition) {
      showToast({
        type: "error",
        message: "Please select the condition",
      });
      return;
    }

    if (!qty && condition  && !condition.variable) {
      showToast({
        type: "error",
        message: "Please enter the qty",
      });
      return;
    }
    if (!notes) {
      showToast({
        type: "error",
        message: "Please add a  note ",
      });
      return;
    }

    if(!selectedVendor) {
      showToast({
        type:'error',
        message:"Please select the vendor"
      })
      return;
    }

    let data = {
      po_condition_id: condition.value,
      po_item_id:id,
      notes,
      hsn,
      price,
      taxSlab: gst.value,
    };
    if(qty) data.qty = qty
    if(po) data.conditional_po_id =  po.value 
    if(selectedVendor) data.vendor_id =  selectedVendor.value 
    onSave(data);
  };

  const onEdit = () => {
    let obj = {
      ...updated["content"],
    };
    if (!Object.keys(obj).length > 0) {
      showToast({
        type: "error",
        message: "Please edit any field",
      });
      return;
    }
    let data = {
      ...obj,
    };
    update(data, editable.poConditionItemId,);
  };


  const searchVendor = (val) => {
    if (val.length > 2) {
      get(
        `/vendor/contacts/search`,
        (e, r) => {
            setState({...state,vendors:r.contacts.map((val) => ({
                label: val.title,
                value: val.id,
              }))
            })
          },
        {
          q: val,
        }
      );
    }
  }

  const setValue = (key,val) =>{
    if(key === 'po') {
        setState({...state,[key]: val, selectedVendor:undefined})
        updateFunction('conditional_po_id', val.value);
    } else {
      setState({...state,[key]: val})
      updateFunction('po_condition_id', val.value);
    }
  }

const ShowConditions = useCallback(() =>{
  return(
    <AutocompleteSearch
    title={"Select Condition"}
    disabled={editable}
    mtype={"poCondition"}
    defaultValue={condition}
    url={`vendor/procurement/poCondition/getPoConditions`}
    modal={false}
    onSelect={(val) => setValue('condition',val)}
  />
  )
},[condition]);

const ShowConditionPO = useCallback(() =>{
  return(
    <AutocompleteSearch
    title={"PO for Condition (optional)"}
    mtype={"po"}
    defaultValue={po}
    url={`vendor/po/requests/searchPo`}
    modal={false}
    onSelect={(val) => setValue('po',val)}
  />
  )
},[po]);



return (
    <Modal
      show={show}
      title={label}
      des={des}
      close={onClose}
      buttonName={editable ? "Update changes" : "Save Changes"}
      stateLoading={loading}
      onSave={editable ? onEdit : selectLineItems}
    >
      <>
      <ShowConditions />
      <ShowConditionPO />
        {!po && 
         <SearchableSelect
            label="Vendor for Condition"
            placeholder="Select for Condition"
            value={selectedVendor}
            select={(item) => {
                  setState({
                     ...state,
                    selectedVendor: item,
                    vendors: [],
              });
            updateFunction('vendor_id',item.value)
            }}
            onChange={searchVendor}
            items={vendors}
             style={{
               color: "#e6ebf3",
               height: 46,
              marginBottom: 12,
              }}
            />
         }
        <FunctionalInput
          type="text"
          title="HSN"
          ref={Hsn}
          onChange={(e) => {
            updateFunction("hsn", e.target.value);
          }}
        />
        {!condition || (condition  && !condition.variable)  ? 
        <FunctionalInput
          type="text"
          title="qty"
          ref={Qty}
          onChange={(e) => {
            updateFunction("qty", +e.target.value);
          }}
        />
        : null }
        <FunctionalInput
          type="number"
          title="Price"
          ref={Price}
          onChange={(e) => {
            updateFunction("price", parseFloat(e.target.value));
          }}
        />
        <FunctionalInput
          type="text"
          title="notes"
          ref={Notes}
          onChange={(e) => {
            updateFunction("notes", e.target.value);
          }}
        />
        <Select
          value={gst}
          items={GSTS}
          placeholder="Select Gst"
          select={(data) => {
            setGst(data);
            updateFunction("taxSlab", parseFloat(data.value));
          }}
        />
      </>
    </Modal>
  );
};

export default AddConditions;
