import React from 'react'
import Pager from "../Components/Pages";
import {connect} from "react-redux";
import {AuthActions} from "../Redux/Reducers/Auth";
import {setRefrehCallback} from "./ConfigRefresher";
import {fetchConfig} from "../Utils/ConfigController";
import {FirebaseUtils} from "../Utils/Firebase";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IdleTimer from 'react-idle-timer'
import {initPositions} from "./DynamicPositions";
import {get, set} from "../Utils/Crypto";
import Moment from 'moment';
import {FloatInput} from "../Components/Common/Class/FloatLabelInput";
import {post} from "../Model/Network/Config/Axios";
import {handleError} from "../Components/Common/fToast/ToastCallback";

let KEY = "last_active_at";


class AuthenticatedController extends React.Component {

	constructor(p) {
		super(p)
		this.handleOnAction = this.handleOnAction.bind(this)
		this.handleOnIdle = this.handleOnIdle.bind(this)
		let time = get(KEY)
		if (!time) {
			this.state = {
				idle: false
			}
		} else {
			time = Moment().valueOf() - time;
			if (time > (365*24*60* 60*1000)) {
				this.state = {
					idle: true
				}
			} else {
				this.state = {
					idle: false
				}
			}
		}
		try {
			FirebaseUtils.init();
		} catch (e) {

		}
	}

	componentDidMount() {
		this.props.init()
		initPositions()
		setRefrehCallback(() => {
			this.props.refresh()
		})

	}
	state = {
		idle: false
	}
	handleOnAction() {
		if (!this.state.idle) {
			set(KEY, Moment().valueOf())
		}
	}

	handleOnIdle() {
		this.setState({
			...this.state,
			idle: true
		})
	}

	render() {
		if (!this.props.auth) {
			this.props.history.push("/")
		}
		if (this.state.idle) {
			return (
				<div style={{
					position: 'relative',
					width: '100vw',
					height: '100vh',
				}} onKeyPress={(e) => {
					if (e.key === "Enter") {
						if (this.mPass) {
							let pass = this.mPass.value;
							if (pass) {
								post(`/auth/validate`, { password: pass }, (e, r) => {
									if (r) {
										this.setState({
											...this.state,
											idle: false
										})
									}
								})
							}
						}
					}
				}}>
					<div style={{
						position: 'fixed',
						zIndex: 11,
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						padding: 100,
						overflowY: 'auto',
						maxHeight: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						background: 'var(--white-color)'
					}}>
						<p style={{ fontWeight: 500, color: "var(--light-color)", fontSize: 37, textTransform: 'uppercase' }}>
							Session <span style={{ fontWeight: 800, color: "var(--text-color)" }}>Timeout</span>
						</p>
						<p style={{ fontSize: 13, color: "var(--light-color)" }}>
							Due to browser inactivity for the past <b>30</b> minutes your session has timed out and you
							must login again to access your data. <br />
							We appologize for he inconvinence, but session timeouts were added for security reasons to
							ensure your data is protected.
						</p>
						<p style={{ fontSize: 13, color: "var(--light-color)", marginTop: 20, marginBottom: 20 }}>
							Please fill the details below to return using <b>Dice DASHBOARD</b>.
						</p>
						<div style={{ width: 450 }}>
							<FloatInput title="Password" type="password"
								handleRef={({ ref }) => this.mPass = ref} />
							<button style={{ maxWidth: "100%", marginTop: 12 }} onClick={() => {
								if (this.mPass) {
									let pass = this.mPass.value;
									if (pass) {
										post(`/auth/validate`, { password: pass }, (e, r) => {
											if (r) {
												this.setState({
													...this.state,
													idle: false
												})
											}else {
												handleError(e)
											}
										})
									}
								}
							}}
								className={`btn btn-portlet`}>
								Unlock Session
							</button>
							<p style={{
								color: 'red',
								cursor: 'pointer',
								textAlign: 'center',
								margin: 20
							}} onClick={() => {
								window.localStorage.clear()
								FirebaseUtils.signout()
								window.location.replace('https://enterprise.dice.tech')
							}}>
								Log Out
							</p>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div>
				<IdleTimer
					timeout={1000 * 60 * 30}
					onIdle={this.handleOnIdle}
					onAction={this.handleOnAction}
					debounce={250}
				/>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Pager {...this.props} />
				</MuiPickersUtilsProvider>
			</div>
		)
	}

}

export default connect(state => {
	return {
		auth: state.auth.authenticated
	}
}, dispatch => {
	return {
		init: () => {
			fetchConfig.fetchConfig(r => {
				dispatch(
					{
						type: AuthActions.Config,
						data: r
					}
				)
			})
		},
		refresh: () => {
			fetchConfig.refreshConfig(r => {
				dispatch(
					{
						type: AuthActions.Config,
						data: r
					}
				)
			})
		}
	}
})(AuthenticatedController);
