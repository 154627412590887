import React from 'react'
import Props from 'prop-types'

function Header(props){
		return (
			<div style={{position: 'relative', marginBottom: 25,...props.style}}>
				<p className="fintrip-portlet-title" style={props.titleStyle}>
					{props.title}
				</p>
				<span className="fintrip-portlet-des" style={{color: "var(--light-color)"}}>
					{props.des}
				</span>
				{props.children}
			</div>
		)
}

Header.propTypes = {
	title: Props.string.isRequired,
	des: Props.string
}

function Header2(props){

		return (
			<div style={{position: 'relative', marginBottom: 18,...props.style}}>
				<p className="fintrip-portlet-title" style={{fontSize: 19, fontWeight: 600, lineHeight: "23px"}}>
					{props.title}
				</p>
				<span className="fintrip-portlet-des"
					  style={{color: "var(--light-color)", fontSize: 13, lineHeight: "15px"}}>
					{props.des}
				</span>
				{props.children}
			</div>
		)

}

Header2.propTypes = {
	title: Props.string.isRequired,
	des: Props.string
}

export {
	Header, Header2
}
