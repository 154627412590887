import React from 'react';
import {DownloadCsv} from '../../../../../../../Utils/Downloader';
import {NormalTable} from '../../../../../../Tables/NormalTable/NormalTable';
import OpenItem from './openItem.js';


class VoucherTable extends React.Component {


    state = {
        vouchers: [],
        voucherChange: false,
        isopen: undefined
    }






    downloadCsv = () => {


        let fileName = "Invoice.csv";

        let baseData = [
            [
                "Sno",
                "InvoiceNo",
                "Vendor Name",
                "Description",
                "Invoice Date",
                "Base Amount",
                "Gst",
                "Cgst",
                "Sgst",
                "Total",
                "Status",
                "Link",
            ]
        ]


        Object.keys(this.props.approvers).forEach((val) => {
            baseData[0].push(val)
        })


        this.props.lineItems.forEach(item => {
            let value = [
                item.sNo,
                item.invoiceNo,
                item.vendorName,
                item.description,
                item.invoiceDate,
                item.baseAmount,
                item.gst,
                item.cgst,
                item.sgst,
                item.total,
                item.declined ? 'DECLINED' : "PENDING",
                item.invoice,
            ]

            Object.keys(item.approvers).forEach((val, index) => {
                let i = item.approvers[val];
                let curr = null;

                Object.keys(item.approvers).forEach((j, k) => {
                    let check = item.approvers[j];
                    if (check.declined) {
                        curr = k
                    }
                })

                let status = "PENDING";
                if (curr !== null && index >= curr) {
                    status = "DECLINED"
                } else if (i.approved) {
                    status = "APPROVED"
                }
                value.push(status);
            })



            baseData.push(
                value
            )
        })

        for (let j = 0; j < 4; j++) {
            baseData.push("");

        }

        let mailInitialize = ["", "", "", "Initiator Name", this.props.initiator, "", "", "", "", "", "", ""];
        let mailTiming = ["", "", "", "Mail Initialize At", this.props.mailInitialized, "", "", "", "", "", "", ""];


        Object.keys(this.props.approvers).forEach((val) => {
            mailInitialize.push("");
            mailTiming.push("");
        })

        baseData.push(mailInitialize);
        baseData.push(mailTiming);



        DownloadCsv(fileName, baseData);

    }


    type = (item) => {
        if (this.props.getType === 'pending') {
            return (
                <div>{item.declined ? "DECLINED" : "PENDING"}</div>
            )

        } else if (this.props.getType === 'declined') {
            return (
                <div>DECLINED</div>
            )

        } else if (this.props.getType === 'completed') {
            return (
                <div>{item.declined ? "DECLINED" : "APPROVED"}</div>
            )

        }
    }


    mapItem = (item, index) => {

        let viewItem = () => {
            if (this.state.isopen === item.id) {
                this.setState({
                    isopen: undefined
                })
            } else {
                this.setState({
                    isopen: item.id
                })
            }
        }

        let center = (
            <div key={index} style={{ paddingLeft: 0, paddingRight: 0 }}
                className="border-bottom fintrip-department-item table-item" onClick={viewItem} >
                <div className="linear-layout horizontal"
                    style={{ flex: 1, cursor: 'pointer', alignItems: 'center', fontSize: 14 }}>
                    <div style={{
                        flex: 1.5
                    }}>
                        <div className="top">
                            <p style={{
                                fontSize: 13,
                                fontWeight: 600
                            }}>SNo : {item.sNo}</p>
                            <p style={{
                                fontSize: 13,
                                fontWeight: 600
                            }}>Invoice No : {item.invoiceNo}</p>


                        </div>
                    </div>
                    <div style={{ flex: 1, fontSize: 14 }}>
                        {item.invoiceDate}
                    </div>
                    <div style={{ flex: 1 }}>
                        {item.baseAmount}
                    </div>

                    <div style={{ flex: 1 }}>
                        {item.gst}
                    </div>

                    <div style={{ flex: 1 }}>
                        {item.cgst}
                    </div>
                    <div style={{ flex: 1 }}>
                        {item.sgst}
                    </div>
                    <div style={{ flex: 1 }}>
                        {item.total}
                    </div>
                    <div style={{ flex: 1 }}>
                        {this.type(item)}
                    </div>
                    <div style={{ flex: 1 }}>
                        <a target='_blank' rel="noopener noreferrer" href={item.invoice}>Link</a>

                    </div>




                </div>
                <div style={{
                    width: 80
                }} className="right-actions">
                    <div className="not-hoverable">{this.state.isopen === item.id ? "Collapse" : "View"}</div>
                </div>
            </div>
        )

        if (this.state.isopen === item.id) {
            return (
                <OpenItem approvers={item.approvers} vendorName={item.vendorName} description={item.description}>
                    {center}
                </OpenItem>
            )
        }
        return center;
    }

    render() {
        let { lineItems } = this.props;
        return (
            <div style={{
                marginBottom: 20,
                overflowX: 'scroll',

            }}>
                <div style={{
                    display: 'flex',
                    position: 'relative',
                    flex: 1,
                    marginBottom: 20,
                    justifyContent: 'space-between'
                }}>
                    <div>
                        <p style={{
                            fontSize: 20,
                            fontWeight: 600
                        }}>Line Items</p>
                        <p style={{
                            fontSize: 14
                        }}>List of all line items will be visible here</p>
                    </div>
                    <div className="btn btn-portlet-invert" style={{
                        width: 150,
                        height: 45,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} onClick={() => this.downloadCsv()}>
                        Download Csv
                    </div>
                </div>

                <NormalTable headers={
                    [
                        {
                            key: 'details',
                            name: 'Details',
                            weight: 1.5
                        },
                        {
                            key: 'invoice',
                            name: 'Invoice Date',
                            weight: 1
                        },
                        {
                            key: 'base',
                            name: 'Base Amount',
                            weight: 1,

                        },
                        {
                            key: 'gst',
                            name: 'Gst',
                            weight: 1,

                        },
                        {
                            key: 'cgst',
                            name: 'Cgst',
                            weight: 1,

                        },
                        {
                            key: 'sgst',
                            name: 'Sgst',
                            weight: 1
                        },
                        {
                            key: 'total',
                            name: 'Total',
                            weight: 1
                        },
                        {
                            key: 'Status',
                            name: 'Status',
                            weight: 1
                        },
                        {
                            key: 'Link',
                            name: 'Link',
                            weight: 1
                        },
                        {
                            key: 'nothin',
                            name: '',
                            width: 80
                        }
                    ]
                } data={lineItems} pagination={false} mapItem={this.mapItem} />
            </div>
        )
    }
}


export default VoucherTable;
