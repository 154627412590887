import React from 'react'
import moment from "moment"
import { useHistory } from "react-router-dom"
import Card  from '../../../Common/Card'

const PoItem = ({ item }) => {
    const HISTORY = useHistory()
    return (
        <div style={{ border: '1px solid var(--border-color)',marginTop:'10px',backgroundColor:'#f6f6f6',padding:10,gap:12,borderRadius:10}}>
            <div style={{ padding: '8px', borderRadius: '6px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '15px', fontWeight: 'bold' }}>PO Item</div>
                    <div style={{ padding: '4px 8px', borderRadius: '5px', fontSize: '11px', color: '#fff', display: 'inline-flex', marginTop: '5px', background: item.status === 'COMPLETED' ? '#198754' : '#0d6efd' }}>Status: {item.status}</div>
                </div>
                <div style={{ }}>
                    <span onClick={() => HISTORY.push(`/app/vendor/procurement/order/${item.id}/details`)} style={{ marginRight: '3px', cursor: 'pointer', fontWeight: '600', textDecoration: 'underline', lineHeight: 2 }}><span style={{ color: 'var(--light-color)' }}>ID:</span> {item.orderId}</span><br />
                    <span style={{ marginRight: '3px' }}><span style={{ color: 'var(--light-color)' }}>Created on:</span> {moment(item.details.createdAt).format('HH:MM a DD--MM-YYYY')}</span><br />
                    <span style={{ marginRight: '3px' }}><span style={{ color: 'var(--light-color)' }}>Gross Amount:</span> {item.grossAmount}</span><br />
                    <span style={{ marginRight: '3px' }}><span style={{ color: 'var(--light-color)' }}>Category:</span> {item.category.name}</span><br />
                </div>
            </div>
            <div style={{ display: 'flex',}}>
                <div style={{ padding: '8px', borderRadius: '6px',marginRight: '20px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Supplier</div>
                    <div style={{  }}>
                        <span style={{ marginRight: '3px' }}>{item.vendor.name}</span><br />
                        <span style={{ marginRight: '3px' }}>{item.vendor.email}</span><br />
                        <span style={{ marginRight: '3px' }}>{item.vendor.mobile}</span><br />
                        <span style={{ marginRight: '3px' }}>Gstin: {item.vendor.gstin ? item.vendor.gstin : '-'}</span><br />
                    </div>
                </div>
                <div style={{ padding: '8px', borderRadius: '6px',}}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Billing</div>
                    <div style={{  }}>
                        <span style={{ marginRight: '3px' }}>{item.billingTo.name}</span><br />
                        <span style={{ marginRight: '3px' }}>{item.billingTo.city}, {item.billingTo.country}</span><br />
                        <span style={{ marginRight: '3px' }}>{item.billingTo.gstin ? item.billingTo.gstin : "-"}</span><br />
                    </div>
                </div>
            </div>

            <div style={{ padding: '8px', borderRadius: '6px',}}>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>PO Items</div>
                <table style={{ borderCollapse: 'collapse', marginTop: '20px',border: '1px solid var(--border-color)' ,backgroundColor:"#fff"}}>
                    <tr style={{ background: '#fff', textAlign: 'left', }}>
                        <th colSpan={2} style={{ padding: '10px' }}>item</th>
                        <th style={{ padding: '10px' }}>Delivery Address</th>
                        <th style={{ padding: '10px' }}>Qty</th>
                        <th style={{ padding: '10px' }}>Price/Unit</th>
                        <th style={{ padding: '10px' }}>Amount</th>
                    </tr>
                    {
                        item.procured.map(row => {
                            return (
                                <tr>
                                    <td  colSpan={2} style={{ padding: '10px' }}>
                                        <b>{row.name}</b><br />
                                        HSN: {row.hsn}<br />
                                    </td>
                                    <td style={{ padding: '10px' }}>{row.officeAddress}</td>
                                    <td style={{ padding: '10px' }}>{row.qtty} {row.unit}</td>
                                    <td style={{ padding: '10px' }}>{row.price} /{row.unit}</td>
                                    <td style={{ padding: '10px' }}>{row.amount}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
            <div style={{ padding: '8px',borderRadius: '6px', display: 'flex', flexWrap: 'wrap', maxHeight: 500, overflow: 'scroll' }}>
                <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px' }}>Vendor History</div>
                {
                    item.invoices.map((item, index) => {
                        return (
                            <Card key={index} style={{ marginTop: '5px', cursor: 'pointer' ,background:'#fff'}}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <span style={{ fontWeight: 'bold' }}>{item.invoiceId.toUpperCase()}</span>
                                    <div style={{ padding: '3px 7px', borderRadius: '5px', fontSize: '10px', color: '#fff', display: 'inline-flex', marginTop: '5px', background: item.status === 'COMPLETED' ? '#198754' : '#0d6efd' }}>{item.status}</div>
                                </div>
                                <div style={{ lineHeight: '16px', fontSize: '11px' }}>
                                    Amount: {item.currency}&nbsp;{item.currencyAmount}
                                    <br />
                                    Date: {moment(item.createdAt).format('HH:MM a DD-MM-YYYY')}
                                    <br />
                                    Due Date: {moment(item.dueDate).format('HH:MM a DD-MM-YYYY')}
                                    <br />
                                    Category: {item.category}<br />
                                </div>
                            </Card>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default PoItem