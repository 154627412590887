import {useSelector,useDispatch} from "react-redux";
import {get} from "../../Model/Network/Config/Axios";
import {useState,useEffect} from 'react'

let IS_THEME_LOADING = false;

function useThemeConfig() {
	let {mTheme} = useSelector(state => {
		return {
			mTheme:state.theme
		}
	})
	let dispatch = useDispatch()
	let LOAD  = () => {
		if(!IS_THEME_LOADING){
			IS_THEME_LOADING = true;
			get(`/auth/customize/settings`,(e,r)=>{
				if(r){
					IS_THEME_LOADING = false;
					dispatch({
						type:"THEME",
						data : {
							...r.whiteLabel,
							default:false
						}
					})
				}
			})
		}
	}
	let [theme,setTheme] = useState(mTheme);

	useEffect(()=>{
		if(mTheme.default){
			LOAD()
		}else{
			setTheme(mTheme)
		}
		let r = document.getElementById("theme");
		if(r && mTheme){
			if(mTheme.theme){
				if(mTheme.theme==="light"){
					if(localStorage.getItem('DARK_THEME')==='true'){
						r.className = "dark"
					}else{
						r.className = "light"
					}
				}else{
					r.className = "dark"
				}
			}
			window.document.title = mTheme.login.header || "Corporate Spending PLatform";
			r.style.setProperty('--primary-color', mTheme.color)
		}

	},[mTheme])
	theme.reload = LOAD;
	return theme;
}


export {useThemeConfig}
