import React from 'react';
import { InputContainer } from '../../../../Common/Title';


const VendorTerms = (props) => {

    if(!props.vendorConditions) {
        return <div />
    }
    return(
        <InputContainer title="Vendor Terms" style={{minHeight:170}}>
            <div dangerouslySetInnerHTML={{ __html: `${props.vendorConditions}` }} style={{ fontSize: 14 }} />
        </InputContainer>
    )
}
export default VendorTerms