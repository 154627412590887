import React from 'react'
import AddCondition from './AddCondition'

const Terms = (props) => {

	return (<AddCondition {...props} conditions={props.conditions}/>)

}

export default Terms
