import React, { forwardRef, useImperativeHandle, useState } from "react";
import Add from "./Add";

const AdditionalCharges =  forwardRef((props,ref) => {
    const [state, setState] = useState({
        edit: false,
        add: false
    })
    const [Charges,setCharges] = useState([])
    
    useImperativeHandle(ref, () => ({
        getValue: () => Charges.map(item => ({
            ...item,
            taxAmount: item.tax
        })),
        setValue: (charges) => {
            setCharges(charges)
        }
    }))

    return (
        <>
            <p style={{
                color: "var(--text-color)",
                fontSize: 13,
                fontWeight: 400,
                marginTop: 12
            }}>
                Additional Charges
            </p>
            <div style={{
                border: '1px solid #ddd',
                display: 'flex',
                marginTop: 15
            }}>
                <div style={{ flex: 2, padding: 12, borderRight: '1px solid #ddd' }}>
                    Details
                </div>
                <div style={{ flex: 1, padding: 12, borderRight: '1px solid #ddd' }}>
                    Type
                </div>
                <div style={{ flex: 1, padding: 12, borderRight: '1px solid #ddd' }}>
                    Base
                </div>
                <div style={{ flex: 1, padding: 12, borderRight: '1px solid #ddd' }}>
                    Amount
                </div>
                <div style={{ padding: 12, width: 100 }}>
                    Action
                </div>
            </div>
            {
                Charges.map((item, index) => {
                    return (
                        <td key={index} style={{
                            border: '1px solid #ddd',
                            display: 'flex'
                        }}>
                            <tr style={{
                                flex: 2,
                                padding: 12,
                                borderRight: '1px solid #ddd'
                            }}>
                                {item.title} <br />
                                Description: {item.description} <br />
                                Applicable on : {item.applicableOn||'-'}<br />
                                TaxSlab : {(item.taxSlab && item.taxSlab.toFixed(2))||'-'}<br />
                                Tax : {(item.tax && item.tax.toFixed(2))||'-'}<br />
                            </tr>
                            <tr style={{
                                flex: 1,
                                padding: 12,
                                borderRight: '1px solid #ddd'
                            }}>
                                {item.type}
                            </tr>
                            <tr style={{
                                flex: 1,
                                padding: 12,
                                borderRight: '1px solid #ddd'
                            }}>
                                {item.base? item.base.toFixed(2): 0}
                            </tr>
                            <tr style={{
                                flex: 1,
                                padding: 12,
                                borderRight: '1px solid #ddd'
                            }}>
                                {item.amount? item.amount.toFixed(2): 0}
                            </tr>
                            <tr style={{ display: 'flex', alignItems: 'center', padding: 12, width: 100 }}>
                                <>
                                    <div style={{ marginRight: 5, fontSize: 10, color: '#6C5DD3', fontWeight: '600', cursor: 'pointer' }}
                                        onClick={() => {
                                           setState({ ...state, edit: item, add: true })
                                        }}
                                    >
                                        Edit
                                    </div><br /></>
                                <div style={{ flex: 1, color: 'var(--red-color)', cursor: 'pointer' }}
                                    onClick={() => {
                                        let items = [...Charges];
                                        items = items.filter(val => val.id !== item.id);
                                        setCharges(items)
                                    }}>
                                    Delete
                                </div>
                            </tr>
                        </td>
                    )
                })
            }
            <p style={{
                marginTop: 12,
                color: "var(--button-color)",
                fontSize: 13,
                cursor: 'pointer',
                fontWeight: 600
            }} onClick={() => {
                setState({ ...state, add: true,edit:false })
            }}>
                + Add Additional Charges
            </p>
            {
                state.add ?
                    <Add
                        prefill={state.edit}
                        onClose={() => setState({ ...state, add: false })}
                        onSubmit={(item) => {
                            let items = [...Charges]
                            if (items.find((e) => e.id === item.id)) {
                                items = items.map((lineItem) => lineItem.id === item.id ? item : lineItem)
                            } else {
                                items.push(item)
                            }
                            setState({ ...state, add: false })
                            setCharges(items)
                        }}
                        onEdit={(item) => {
                            const updateItems = Charges.map((lineItem) => lineItem.id === item.id ? item : lineItem);
                            setState({ ...state, add: false, edit: false })
                            setCharges(updateItems)
                        }}
                    />
                    : null
            }
        </>
    )
})

export default AdditionalCharges