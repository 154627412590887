import React from "react";
import Props from 'prop-types'
import Thumb from "../fCard/Thumb";

function Owner(props) {

	return (
		<div style={{...props.container}}>
			<div style={{display:'flex', marginBottom:20,alignItems:'center'}}>
				<div style={{
					width:10,
					height:10,
					marginRight:5,
					border:'3px solid var(--primary-color)',
					borderRadius:5,
					marginTop:1
				}}/>
				<p style={{
					fontWeight:600,
					fontSize:12,
					textTransform:'uppercase',
					letterSpacing:.5
				}}>
					Created By
				</p>
			</div>
			<div style={{display:'flex',alignItems:'center'}}>
				<div style={{marginTop:5}}>
					<Thumb thumb={props.thumb} size="M" letter={props.owner.ownerName?props.owner.ownerName[0]:props.owner.name[0]}/>
				</div>
				<div>
					<p style={{fontSize:12,}}>
						<span style={{
							color:"var(--light-color)",
							width:60
						}}>Name:</span> &nbsp;{props.owner.ownerName?props.owner.ownerName:props.owner.name}
					</p>
					<p style={{fontSize:12}}>
						<span style={{
							color:"var(--light-color)",
							width:60
						}}>Type:</span> &nbsp;{
							props.owner.ownerType?props.owner.ownerType:"EMPLOYEE"
						}
					</p>
					<p style={{fontSize:12}}>
						<span style={{
							color:"var(--light-color)",
							width:60
						}}>ID:</span> &nbsp;{
							props.owner.ownerId?props.owner.ownerId:props.owner.code
						}
					</p>
					{
						(props&&props.activeAdvance&&Object.keys(props.activeAdvance).length)?<p style={{fontSize:12}}>
							<span style={{
								color:"var(--light-color)",
								width:60
							}}>Current Active Advance:</span> &nbsp;{
								Object.keys(props.activeAdvance).map((item)=>{return <>{`${item} ${props.activeAdvance[item]} `}</>})
							}
						</p>:null
					}
				</div>
			</div>
		</div>
	)

}


function Vendor(props) {

	return (
		<div style={{...props.container}}>
			<div style={{display:'flex', marginBottom:20,alignItems:'center'}}>
				<div style={{
					width:10,
					height:10,
					marginRight:5,
					border:'3px solid var(--primary-color)',
					borderRadius:5,
					marginTop:1
				}}/>
				<p style={{
					fontWeight:600,
					fontSize:12,
					textTransform:'uppercase',
					letterSpacing:.5
				}}>
					Vendor Details
				</p>
			</div>
			<div style={{display:'flex',alignItems:'center'}}>
				<div style={{marginTop:5}}>
					<Thumb size="M" letter={props.vendor.name[0]}/>
				</div>
				<div>
					<p style={{fontSize:12,}}>
						<span style={{
							color:"var(--light-color)",
							width:60
						}}>Name:</span> &nbsp;{props.vendor.name}
					</p>
					<p style={{fontSize:12}}>
						<span style={{
							color:"var(--light-color)",
							width:60
						}}>Address:</span> &nbsp;{
						props.vendor.address
					}
					</p>
					<p style={{fontSize:12}}>
						<span style={{
							color:"var(--light-color)",
							width:60
						}}>Gstin:</span> &nbsp;{
						props.vendor.gstin
					}
					</p>
				</div>
			</div>
		</div>
	)

}

Owner.propTypes = {
	owner:Props.object.isRequired
}

Vendor.propTypes = {
	vendor:Props.object.isRequired
}

Owner.defaultProps = {
	container : {
		marginTop:25,
		marginBottom:50
	},
	owner:{
		ownerName:"Loading...",
	}
}


export {
	Owner,Vendor
}
