import React from 'react'
import {pluginsGetApi, pluginsPostApis} from '../../../../../Model/Network/Config/Axios';
import {handleError, showToast} from '../../../../Common/fToast/ToastCallback';
import Moment from 'moment';
import {FloatInput} from '../../../../Common/Class/FloatLabelInput';
import {Title as Header, TitleTopHeader} from "../../../../Header/TopHeader";

class index extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			syncedWithWorkLine: 0,
			workLineError: {
				remarks: "No error while syncing"
			}
		}

		pluginsGetApi(`plugins/custom/fino/hrms/info`, (e, r) => {
			if (r) {
				this.setState({
					syncedWithWorkLine: r.syncedWithWorkLine
				})
			}
		})
	}




	_syncWithWorkLine = () => {
		pluginsPostApis(`plugins/custom/fino/hrms/sync`, {}, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: "Event triggered successfully!"
				})
			} else {
				handleError(e)
			}
		})
	}

	render() {
		return (
			<div >
				<TitleTopHeader items={[]} title="Fino Sync" des="Syncing of data with Fino hrms." />
				<Header title="Fino Sync" des="Syncing of data with fino hrm." />
				<div className="title-container" style={{
					padding: 18
				}}>
					<FloatInput handleRef={(ref) => {

					}} type="text" title="Last Synced On" inputProps={{
						disabled: true,
						value: this.state.syncedWithWorkLine > 0 ? Moment(this.state.syncedWithWorkLine).format("DD-MM-YYYY HH:MM A") : "Not synced yet"
					}} />
					<FloatInput handleRef={(ref) => {
					}} type="text" title="Last Error" inputProps={{
						disabled: true,
						value: this.state.workLineError.remarks
					}} />
					<div>
						<button onClick={this._syncWithWorkLine} className="btn btn-success" style={{ marginTop: 12 }}>
							Sync With Fino
						</button>
					</div>
				</div>
			</div>
		)
	}

}

export default index
