import React from 'react'
import {Link} from 'react-router-dom'

function NotFound() {
	return (
		<>
		<div style={{padding: 25, textAlign: 'center', position:'absolute', top:'50%', left: '55%', transform: 'translate(-50%, -50%)'}}>
			<p style={{color: "var(--text-color)", fontWeight: 600, marginBottom: 8, fontSize: 42}}>Page not found</p>
			<p style={{color: "#536d90", fontWeight: 400, marginBottom: 18, fontSize: 17, width: 800}}>
				The page you're looking for can't be found. Double-check the URL and try again.<br/>
				You might be able to find what you're looking for from our <Link style={{color: "#7298ff"}}
																				 to="/app/home"> home</Link> page.
			</p>
		</div>
		</>
	)

}

export default NotFound
