let refreshCallback = undefined;

const setRefrehCallback = callback => {
	refreshCallback = callback;
}

const refreshConfig = () => {
	if (refreshCallback)
		refreshCallback()
}


export {
	refreshConfig, setRefrehCallback
}
