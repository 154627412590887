import React from 'react';


const Index = (props) => {

        let details = (key,value) => {
            return(
                <div style={{
                    display:'flex',
                    flex:1,
                    borderBottom:'1px solid var(--border-color)',
                    fontSize:13,
                    borderLeft:'1px solid var(--border-color)'
                }}>
                    <div style={{
                        width:200,
                        padding:'10px 20px',
                    }}>
                        {key}
                    </div>
                    <div style={{flex:1,padding:'10px 20px',borderLeft:'1px solid var(--border-color)'}}>
                        {value}
                    </div>
                </div>
            )
        }
        return (
            <div>
                <p style={{
                    fontWeight: 600,
                    borderBottom:'1px solid var(--border-color)',
                    padding:'10px 10px'
                }}>Buyer Details</p>
                <div style={{display:'flex',flex:1}}>
                    {details('Company Name','IIFL Securities Limited')}
                    {details('PHONE','-')}
                </div>
                {details('Address','IIFL House,Sun Info Tech Park, Road No 16V,Plot No b-23,MIDC,400604,IN')}
                {details('Email','-')}
                {details('Gstin','27AAACI7397D2ZD')}
            </div>
        )
}

export default Index;