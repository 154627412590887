import React from 'react'
import {pluginsGetApi, pluginsPostApis} from '../../../../../../Model/Network/Config/Axios';
import {handleError, showToast} from '../../../../../Common/fToast/ToastCallback';
import Moment from 'moment';
import {FloatInput} from '../../../../../Common/Class/FloatLabelInput';
import {Title as Header, TitleTopHeader} from "../../../../../Header/TopHeader";

class index extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			syncedWithRamcoFtps: 0,
			workLineError: {
				remarks: "No error while syncing"
			}
		}

		pluginsGetApi(`plugins/custom/dtdc/ramco/info`, (e, r) => {
			if (r) {
				this.setState({
					syncedWithRamcoFtps: r.syncedWithRamcoFtps
				})
			}
		})
	}




	_syncWithRamco = () => {
		pluginsPostApis(`plugins/custom/dtdc/ramco/syncWithRamco`, {}, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: "Event triggered successfully!"
				})
			} else {
				handleError(e)
			}
		})
	}

	render() {
		return (
			<div >
				<TitleTopHeader items={[]} title="Ramco Sync" des="Syncing of data with Ramco." />
				<Header title="Ramco Sync" des="Syncing of data with ramco." />
				<div className="title-container" style={{
					padding: 18
				}}>
					<FloatInput handleRef={(ref) => {

					}} type="text" title="Last Synced On" inputProps={{
						disabled: true,
						value: this.state.syncedWithRamcoFtps > 0 ? Moment(this.state.syncedWithRamcoFtps).format("DD-MM-YYYY HH:MM A") : "Not synced yet"
					}} />
					<FloatInput handleRef={(ref) => {
					}} type="text" title="Last Error" inputProps={{
						disabled: true,
						value: this.state.workLineError.remarks
					}} />
					<div>
						<button onClick={this._syncWithRamco} className="btn btn-success" style={{ marginTop: 12 }}>
							Sync With Ramco
						</button>
					</div>
				</div>
			</div>
		)
	}

}

export default index
