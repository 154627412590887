let toastCallback = undefined;

const addToastCallback = callback => {
	toastCallback = callback
}

const showToast = item => {
	if (toastCallback)
		toastCallback(item)
	else {
		alert(item.message)
	}
}

const handleError = item => {
	if (toastCallback)
		toastCallback({
			type: "error",
			message: item.data.error
		})
	}

const removeCallback = () => {
	toastCallback = undefined;
}

export {
	addToastCallback, showToast, removeCallback, handleError
}
