import React, { useRef } from "react"
import PhotoTile from "../../../../../../Common/fPhoto"
import Empty from "../../../../../../Common/fCard/Empty"
import moment from "moment"
import { PDFExport } from '@progress/kendo-react-pdf';

export default ({ Details = {}, viewGrn = {}, Details2 = {} }) => {
    console.log("Details2", Details2);
    const PDF = useRef()



    return (
        <>
          <div style={{display:'flex',justifyContent:'flex-end',marginRight:20,marginTop:10}}>
          <div onClick={() => { PDF.current.save() }} style={{ width: 130, textAlign: 'center' }} className='btn btn-portlet'>
                Download Pdf
            </div>
          </div>
            <PDFExport margin={20} fileName={Details.attrs.reference} ref={PDF}>
                <div>
                    <div style={{ fontSize: '14px', marginTop: '40px', fontWeight: 'bold' }}>GRN Items</div>
                    <table style={{ border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px', width: '100%' }}>
                        <tr style={{ background: 'var(--lite-color)', textAlign: 'left' }}>
                            <th style={{ padding: '10px' }}>Name</th>
                            <th style={{ padding: '10px' }}>Delivery Address</th>
                            <th style={{ padding: '10px' }}>Order quantity</th>
                            <th style={{ padding: '10px' }}>Invoice quantity</th>
                            <th style={{ padding: '10px' }}>Qty</th>
                        </tr>
                        {Details && Details.items.length ?
                            Details.items.map(row => {
                                return (
                                    <tr>
                                        <td style={{ padding: '10px' }}>
                                            {row.name} <br />
                                            Purchase Order Id : {row.orderId}
                                        </td>
                                        <td style={{ padding: '10px' }}>{row.address}</td>
                                        <td style={{ padding: '10px' }}>{row.poQty}</td>
                                        <td style={{ padding: '10px' }}>{row.invoiceQty}</td>
                                        <td style={{ padding: '10px' }}>{row.qtty}</td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ padding: '10px', textAlign: 'center' }} colSpan={3}>No Items</td>
                            </tr>
                        }
                    </table>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        height: 45,

                    }} className='flex flex-v-center'>
                        <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13 }}>
                            Delivery Date:  <span>{moment(viewGrn.date).format("DD-MM-YYYY")}</span>
                        </p>
                    </div>
                    <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13 }}>
                        GRN Owner: {Details.owner ? Details.owner.ownerName : "-"}
                    </p>
                    <p style={{ paddingLeft: 8, fontWeight: '500', fontSize: 13, marginTop: 10 }}>
                        Remarks: {Details2.remarks}
                    </p>
                    <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 13 }}>All Attachment</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                        {
                            Details.documents && Details.documents.map((item, index) => {
                                return (
                                    <PhotoTile key={index} link={item} />
                                )
                            })
                        }
                        {
                            Details.documents && Details.documents.length === 0 ?
                                <Empty />
                                : null
                        }
                    </div>
                </div>
            </PDFExport>
        </>
    )
}