import React from "react";

export default function Buyer(props) {
    const { data } = props;

    return (
        <div style={{display: "flex", flex: 1}}>
            <div style={{flex: 1}}>
                <div style={{fontSize: 15, color: "#87adf1", marginBottom: 10}}>Buyer Billing Address</div>
                <div style={{marginBottom: 10, fontWeight: 700}}>{data.billingTo.name}</div>
                <div style={{marginBottom: 10}}>{data.billingTo.address}</div>
                <div style={{marginBottom: 10}}>
                    GST Number
                    <div style={{fontWeight: 700}}>{data.billingTo.gstin || "-"}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    PAN Number
                    <div style={{fontWeight: 700}}>{data.billingTo.pan || "-"}</div>
                </div>
            </div>
            <div style={{flex: 1}}>
                <p style={{fontSize: 15, color: "#87adf1", marginBottom: 10}}>Buyer Shipping Address</p>
                <div style={{marginBottom: 10, fontWeight: 700}}>{data.procured[0].officeName}</div>
                <div style={{marginBottom: 10}}>{data.procured[0].officeAddress}</div>
                <div style={{marginBottom: 10}}>
                    GST Number
                    <div style={{fontWeight: 700}}>{data.billingTo.gstin || "-"}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    PAN Number
                    <div style={{fontWeight: 700}}>{data.billingTo.pan || "-"}</div>
                </div>
            </div>
        </div>
    )
}
