import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { get } from '../../../../../Model/Network/Config/Axios';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec2';
import { Modal } from '../../../../Common/fModal/Modal';
import {  showToast } from '../../../../Common/fToast/ToastCallback';
import { LineHeader } from '../../../../Header/LineHeader';

const TdsLineItem = (props) => {

    const [tds,setTds] = useState([]);
    const [tdsMode,setTdsMode] = useState('percentage');
    const [changeTds,setChangeTds] = useState(false);
    const [allTds,setAllTds] = useState([]);
    const [selectedTds,setSelectedTds] = useState(undefined);
    const Amount = useRef(),CODE=useRef();
	let {vendorId} =props
    useEffect(()=>{
		get(`vendor/contacts/${vendorId}/details`,(e1,r1)=>{
			if(r1) {
				get(`/vendor/config/tds/all`,(e,r)=>{
					if (r) {
						if(r1.config.tdsIds.length) {
							setTds(r.tds.filter(item=>_.includes(r1.config.tdsIds,item.id)).map(val=>({
								id: val.id,
								label: val.code,
								des: val.description,
								tax: val.tax
							})))
						} else {
							setTds(r.tds.map(val=>({
								id: val.id,
								label: val.code,
								des: val.description,
								tax: val.tax
							})))
						}
						
					}
				})
			}
		})
       
    },[])
    return(
        <>      
                <div style={{}}>
                    <p style={{marginBottom:10}} className='floating-top-label' >Tds</p>
                    <div style ={{
                        height:40,
                        background:'var(--off-white)',
                        border:'1px solid var(--border-color)',
                        width:'100%',
                        padding:5,
                        fontSize:12,
                        color:'var(--text-color)'
                    }} onClick={()=>{
						if(props.disabled) return;
                        setChangeTds(true);
                    }}>
                        {(!selectedTds && props.value) ? 
                         `${props.value.tdsCode} (${props.value.tdsAmount})` : selectedTds  ?
                         `${selectedTds.label} (${selectedTds.id})`
                         :
                          null }
                    </div>
                </div>
                {
					changeTds ?
						<Modal
							onSave={() => {
								if (tdsMode === 'percentage') {
									if (!selectedTds) {
										showToast({
											type: 'error',
											value: 'Please enter some value'
										})
										return;
									}
                                    props.onSelect({
                                        "tdsSlab": selectedTds.tax,
                                        "tdsCode": selectedTds.label,
                                        "tdsDesc": selectedTds.des
                                    })
                                    setChangeTds(false);
								} else {
									let amount = Amount.current.value;
									let code = CODE.current.value;
									if (!amount) {
										showToast({
											type: 'error',
											message: "Please enter the amount"
										})
										return;
									}
									if(!code) {
										showToast({
											type: 'error',
											message: "Please enter the remarks"
										})
										return;
									}
                                    setChangeTds(false);
									showToast({
										type: 'success',
										message: "Field update successfully"
									})
								}
							}}
							style={{
								save: {
									padding: '12px 15px',
									width: '100%',
									textAlign: 'center',

								}
							}}
							show={true}
							title="Select Tds"
							des="Select Tds"
							close={() => {
                                setChangeTds(false);
							}}
							buttonName="Select Tds "
						>
							<LineHeader
								items={
									[
										{
											name: 'Select Percentage',
											active: tdsMode === 'percentage',
											click: () => {
                                                setTdsMode('percentage');
											}
										},

									]
								}
							/>
							<div style={{
								marginTop: 20
							}}/>
							{
								tdsMode === 'percentage' ?
									<SearchableSelect
										disabled={props.disabled}
										label="Select Tds"
										placeholder="Select Tds"
										onChange={val => {
											if (val.length > 0) {
												let allItems = tds.filter(item => {
													if (val.toLowerCase().includes(item.label)) {
														return true
													}
													if (val.toLowerCase().includes(item.desc)) {
														return true;
													}
													if (val.toLowerCase().includes(item.tax)) {
														return true
													}
													return false;
												})

                                                setAllTds(allItems);
											} else {
                                                setTds(tds);
											}
										}}
										select={selectedTds => {
                                            setSelectedTds(selectedTds);
										}}
										value={selectedTds}
										items={allTds.length > 0 ? allTds : tds}
									/>
									: 
									null

							}
						</Modal>
						: null
				}

        </>
    )
}

export default TdsLineItem;