import React from 'react'

const Index = (props) => {

    return(
        <div style={{padding:20,borderTop:'1px solid var(--border-color)'}}>
             {props.data.vendorConditions}
                            <div>
                                {
                                    props.data.conditions.map(val=>{
                                        return(
                                            <div dangerouslySetInnerHTML={{__html:val.content}} />
                                        )
                                    })
                                }
                            </div>
                            <div>
                            {props.data.payments.map(val=>{
                                return(
                                    <div dangerouslySetInnerHTML={{__html:val.content}} />
                                )
                            })}
                </div>
        </div>
    )
}

export default Index;