import React from "react"
import moment from "moment"
import Track from "../../../../../../Common/fTrack"
import { timelineLogStatus } from "../../../fPoAmend/GRN/GrnView/Timeline"

export default ({ timelines={} }) => {

    return (
        <>
            <div style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '40px' }}>Timeline</div>
            <Track stages={
                [...timelines.map((log) => {

                    let DESC = <div><b>{log.name}</b><br />
                        <div style={{ marginTop: '5px' }}>Type: {log.type}</div>
                        <div style={{ marginTop: '5px' }}>Status: {log.status}</div>

                        <div style={{ marginTop: '5px' }}>Sent
                            on: {moment(log.time).format("hh:mm a DD MMM YY")}
                            <br />{log.actionTime ? `Approved on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
                        </div>
                    </div>

                    return (
                        {
                            title: log.email,
                            desc: DESC,
                            status: log.type === 'start' ? true : log.status ? timelineLogStatus.includes(log.status) : log.name === 'Finance Action'|| log.type === 'Completed' ? true : false
                        })
                })]
            } />
            <div style={{ height: '50px' }}></div>
        </>
    )
}