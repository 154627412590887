import React, {useEffect} from "react";
import Props from "prop-types";
import {Link, useHistory, useLocation} from "react-router-dom";
import {connect} from 'react-redux'
import {useState} from 'react'
import {withAnyRoles} from "../../Utils/Utils";
import _ from 'lodash'
import Fade from 'react-reveal/Reveal';

let DOMAINS = [
	"/app/organisation/offices",
	"/app/tower",
	"/app/approvals",
	"/app/expenses",
	"/app/advances/petty",
	"/app/travel",
	"/app/travel",
	"/app/vendor/contacts",
	"/app/vendor/invoices",
	"/app/vendor/procurement/item",
	"/app/vendor/procurement/item/past",
	"/app/vendor/procurement/item/items",
	"/app/vendor/products",
	"/app/others/settlements/offline",
	"/app/others/settlements/invoicing",
	"/app/others/settlements/bookings",
	"/app/others/settlements/employees",
	"/app/others/settlements/vendor",
	"/app/others/payouts/active",
	"/app/others/qr",
	"/app/collections/proforma",
	"/app/workspace/support",
	"/app/workspace/app",
	"/app/workspace/table",
	"/app/vendor/scanners",
	"/app/vendor/apps",
	"/app/others/export/expense/export",
	"/app/others/reports",
	"/app/settings"
]

function TopHeader2(props){

	let item = item => {
		let config = props.config;
		if (withAnyRoles(config, item.module, item.superAccess)) {
			if (item.id) {
				return <Link id={item.id} to={item.link} className={item.active ? "active" : ""} key={item.name}>
					{item.name}
				</Link>
			} else {
				return <Link to={item.link} className={item.active ? "active" : ""} key={item.name}>
					{item.name}
				</Link>
			}
		}
	}

	return (
		<div className="padding55">
			<div style={props.style} id="top-header">
				{
					props.items.filter(i => i !== null).map(item)
				}
				{
					props.children
				}
			</div>
		</div>
	)

}

TopHeader2.propTypes = {
	items: Props.array.isRequired
}

const TopHeader = connect(state => ({
	config: state.auth.config
}))(TopHeader2)


function TitleTopHeader2(props) {
	let HISTORY = useHistory()
	let [shown,setShown] = useState(false)
	let LOCATION = useLocation().pathname;
	useEffect(() => {
		if(!_.includes(DOMAINS,LOCATION)){
			setShown(true)
		}else{
			setShown(false)
		}
	}, [LOCATION])
	let item = item => {
		let config = props.config;
		if(item.readOnly) {
			return <div />
		}
		if (item.onClick) {
			if (withAnyRoles(config, item.module, item.superAccess)) {
				if (item.id) {
					return <p id={item.id} onClick={item.onClick} className={item.active ? "active link" : "link"}
							  key={item.name}>
						{item.name}
					</p>
				} else {
					return <p onClick={item.onClick} className={item.active ? "active link" : "link"}
							  key={item.name}>
						{item.name}
					</p>
				}
			}
		} else {
			if (withAnyRoles(config, item.module, item.superAccess)) {
				if (item.id) {
					return <Link id={item.id} to={item.link} className={item.active ? "active" : ""}
								 key={item.name}>
						{item.name}
					</Link>
				} else {
					return <Link to={item.link} className={item.active ? "active" : ""} key={item.name}>
						{item.name}
					</Link>
				}
			}
		}
	}
	return (
		<div style={{position: 'relative'}}>
			<div style={{position: 'relative', padding: 45, paddingBottom: 0, paddingTop: 20}}
				 className={"title-top-header"}>
				<div>
					<div style={{
						display: props.hideTitle ? 'none' : 'flex',
						alignItems:'center'
					}}>

						<div style={{
							flex:1
						}}>
							<Fade effect="fadeInLeft">
								<p style={{
									fontSize: 24,
									color: "var(--text-color)",
									lineHeight: 1.33,
									marginBottom:0,
									marginTop: 27
								}}>
									{props.isCustomTitle ? "": 'Manage'} {props.title}
								</p>
							</Fade>
							<Fade effect="fadeInUp">
								<div style={{
									display:'flex',
									marginTop: -5,
									alignItems:'center'
								}}>
									{
										shown?
											<div onClick={()=>{
												if(props.url){
													HISTORY.push(props.url)
												}else{
													HISTORY.goBack()
												}
											}} style={{
												background:"var(--primary-color)",
												padding:"5px",
												width:30,
												height:30,
												borderRadius:15,
												cursor:'pointer',
												marginRight:20
											}}>
												<img alt="back" src={require('./left-arrow (2).png')} style={{
													width:20,
													height:20
												}}/>
											</div>:undefined
									}
									<p className={props.animate?"FadeRight-Effect":""} style={{
										fontSize: 37,
										fontWeight: 700,
										color: "var(--text-color)",
										maxWidth:"85%",
										marginTop:4,
										lineHeight: 1.75,
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										letterSpacing: .5
									}}>{props.des?props.des:"Loading..."}</p>
								</div>
							</Fade>
						</div>
					</div>
				</div>
				{
					props.items.length > 0 ?
						<div className={`noScrollBar ${props.className}`} style={{
							...props.titlebarStyle,
							display: 'flex',
							width:'100%',
							overflowX:'scroll',
							marginLeft: -6,
							marginRight: -6,
							marginBottom:25,
						}}>
							{
								props.items.filter(i => i !== null).map(item)
							}
						</div> : <div style={{}}/>
				}
				{
					props.children
				}
			</div>
		</div>
	)
}

function Title(props) {
	let style = {
		padding: "6px 4px",
		color: "var(--light-color)",
		fontWeight: 500,
		fontSize: 11,
		textTransform: 'uppercase'
	}
	if (props.container) {
		style = {
			padding: "6px 4px",
			color: "var(--text-color)",
			fontWeight: 700,
			fontSize: 13,
			margin: '0px -25px',
			textTransform: 'uppercase'
		}
	}
	return (
		<div style={{position: 'relative', zIndex: 11}} className="breadcrumb">
			{
				props.hide ? <></> : <>
					{
						props.breadcrumb ? <>{props.breadcrumb()}</> : <>
							<p id={props.id} style={{
								...style,
							}}>
								{props.title}
							</p>
						</>
					}
				</>
			}
			{
				props.children
			}
		</div>
	)
}


Title.propTypes = {
	title: Props.string,
	des: Props.string,
	breadcrumb: Props.func
}

const TitleTopHeader = connect(state => ({
	config: state.auth.config
}))(TitleTopHeader2)


TitleTopHeader.propTypes = {
	items: Props.array,
	animate:Props.bool,
	title: Props.string,
	des: Props.string
}

TitleTopHeader.defaultProps = {
	items: [],
	animate:true
}

export {
	TopHeader, TitleTopHeader, Title
};
