import React from 'react'
import India from './India'
import Other from './Other'

export default function Index(props) {
	if(props.currency==="INR"){
		return (<India {...props}/>)
	}else{
		return (<Other {...props}/>)
	}
}
