import React from 'react';

export default function Index(props) {
    let data = props.data;
    let  content = data.message;
    return (
        <div>
            <div style = {{flex:1,paddingLeft:12,paddingRight:12,marginTop:15,marginBottom:8,display:'flex',maxWidth:600}}>
                <div>
                    <img src = {data.sendor.image} alt = "" style = {{width:40,height:40,objectFit:'contain',marginRight:10}} />
                </div>
                <div style = {{width:'70%'}}>
                    <p style = {{fontSize:13,fontWeight:600,color:'var(--light-color)'}}>{data.sendor.name}</p>
                    <div style = {{padding:12,paddingLeft:8,paddingBottom:6,paddingRight:8,marginTop:6,border:'1px solid var(--border-color)',maxWidth:600}}>
                        <p style = {{fontSize:17,fontWeight:700,color:content.color}}>{content.title}</p>
                        <p style = {{fontSize:15,marginTop:3,}}>{content.description}</p>
                        <img src = {content.image ? content.image : ""} alt = "" style = {{
                            objectFit:'initial',
                            marginTop:8,
                            width:'100%',
                            height:150}} />
                        {
                            content.forms ?
                                content.forms.map((item,index)=>{
                                    return(
                                        <div style = {{display:'flex'}} key = {index}>
                                            <p style = {{fontSize:14,color:'var(--light-color)'}}>{item.key}</p>

                                            <p style = {{fontSize:14,marginLeft:12,fontWeight:600}}>{item.value}</p>
                                        </div>
                                    )
                                }) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
