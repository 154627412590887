import React, {useEffect, useState} from 'react';
import {SearchableSelect} from "../../../../Common/Class/Select/SearchableSelec2";
import {get} from '../../../../../Model/Network/Config/Axios';
import { useSelector } from 'react-redux';

function ProductSearch(props) {
    let [product, setProducts] = useState([])
    let [selected, setSelected] = useState(undefined)
    let { productCategoryFilter, poTypeProductFilter } = useSelector(state => ({ ...state.auth.config.plugins }))


    useEffect(() => {
        if (props.getValue) {
            props.getValue(() => {
                return selected;
            })
        }
        if(props.product) {
            setSelected(props.product)
        }
    }, [selected,props]);
    
    const getUrl = (q) => {
        const categoryQueryParam = props.selectCategory ? props.selectCategory.id ? `&category=${props.selectCategory.id}` : '': '';
        if(productCategoryFilter && poTypeProductFilter) {
            return `vendor/product/search.buy?q=${q}${categoryQueryParam}&poTypeId=${props.selectedPType ? Number(props.selectedPType): ''}`;
        }else if(productCategoryFilter){
            return `vendor/product/search.buy?q=${q}${categoryQueryParam}`;
        }else if(poTypeProductFilter){
            return `vendor/product/search.buy?q=${q}&poTypeId=${props.selectedPType ? Number(props.selectedPType): ''}`;
        } else return `vendor/product/search.buy?q=${q}`
    }

    return (
        <SearchableSelect
            items={product}
            disabled={props.disabled}
            onChange={q => {
                get(getUrl(q), (e, r) => {
                    if (r) {
                        setProducts(r.products.map(item => ({
                            label: item.title,
                            value: item.id,
                            des: `${item.description}/gst:${item.gst} %`,
                            gst:item.gst,
                            des1:item.productId,
                            unit:item.unit,
                            hsn:item.hsn,
                            amount:item.amount,
                        })))
                    }
                })
            }}
            label={props.label}
            placeholder={props.label ? props.label  : "Select Product"}
            select={selectProduct => {
                setSelected(selectProduct)
                if (props.onSelect) {
                    props.onSelect(selectProduct)
                }
            }} value={selected}/>
    )
}


ProductSearch.defaultProps = {
    label: 'Product'
}
export {ProductSearch};
