import React, { useEffect, useState } from 'react';
import  {  reportGetApis } from '../../../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../../../Common/Components/PageLoader';
import ApprovalMemo from '../ApprovalMemo/ApprovalMemo';
import Template1 from '../Template1';


const Index = (props) => {

    const [details,setDetails] = useState({});
    const [loading,setLoading] = useState(true);
    useEffect(()=>{
        reportGetApis(`vendor/po/requests/poApprovals/${props.data.orderId}`,(e,r)=>{
            if(r) {
                setDetails(r);
                setLoading(false);
            }
        })
    },[])


    if(loading) {
        return <PageLoader />
    }
    return(
        <div>
            <ApprovalMemo details={details} />
            <Template1 {...props} />
        </div>
    )
}

export default Index;