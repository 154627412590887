import React from 'react';



const Index = (props) =>{


    return(
        <>
             <p style={{
                    fontWeight: 600,
                    borderBottom:'1px solid var(--border-color)',
                    padding:'10px 10px'
                }}>Annexure Attached</p>
              <div style={{ width:200,fontWeight:'bold',padding:'10px 20px', display:'flex',alignItems:'center'}}>
                    Payment Terms
             </div>
            <div style={{ flex:1,borderLeft:'1px solid var(--border-color)',fontSize:13, paddingLeft:10, minHeight:45,display:'flex', alignItems:'center', flexDirection:'column'}}>
                    {props.data.vendorConditions}
                    <div>
                        {
                           props.data.conditions.map(val=>{
                            return(
                                <div dangerouslySetInnerHTML={{__html:val.content}} />
                            )
                        })         
                        }        
                    </div>
                    <div>
                        {props.data.payments.map(val=>{
                                return(
                                    <div dangerouslySetInnerHTML={{__html:val.content}} />
                                )
                            })}    
                    </div>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                <div style={{fontWeight:600}}>
                    Supplier Signatory
                </div>
                <div style={{fontWeight:600}}>
                    Company Signatory
                </div>
            </div>
        </>
    )
}

export default Index;