import React, { useRef } from 'react';
import Moment from 'moment';
import Items from './Items';
import Terms from '../Common/Terms';
import {PDFExport} from "@progress/kendo-react-pdf";
import Address from './Address';


const Index = ({data,...props}) => {

    let PDF =  useRef();

    let {billingTo,vendor,timelines} = data;


    let details = (key,value) => {
        return(
            <div style={{
                display:'flex',
                flex:1,
                borderBottom:'1px solid var(--border-color)',
                fontSize:13,
            }}>
                <div style={{
                    padding:'10px',
                    flex:1,
                    borderRight:'1px solid var(--border-color)'
                }}>
                    {key}
                </div>
                <div style={{flex:1,padding:'10px',borderRight:'1px solid var(--border-color)'}}>
                    {value}
                </div>
            </div>
        )
    }

    let single = (key,value,style={}) => {
       return(
           <div style={{
               display:'flex',
               flex:1,
               fontSize:14,
               borderBottom:'1px solid var(--border-color)'
           }}>
               <div style={{flex:1,borderRight:'1px solid var(--border-color)',...style}} className="flex flex-v-center">
                   {key}
               </div>
               <div style={{flex:2,...style}} className="flex flex-v-center">
                   {value}
               </div>
           </div>
       )
    }

    let supplier = () => {
        return(
            <div style={{
                flex:1,
                borderRight:'1px solid var(--border-color)'
            }}>
                {single('Name',vendor.name,{padding:10,textAlign:'left'})}
                {single('Address',`${vendor.address.address},${vendor.address.city}, ${vendor.address.pincode}`,{padding:10,textAlign:'left'})}
                {single('GSTIN',vendor.address.gstin?vendor.address.gstin:'-',{padding:10,textAlign:'left'})}
                {single('Vendor Email',vendor.email?vendor.email:'-',{padding:10,textAlign:'left'})}
                {single('Vendor Number',vendor.mobile?vendor.mobile:'-',{padding:10,textAlign:'left'})}
             </div>
        )
    }

    let billing = () => {
        return(
            <div style={{
                flex:1
            }}>
                {single('Name',billingTo.name,{padding:10,textAlign:'left'})}
                {single('Address',`${billingTo.address}`,{padding:10,textAlign:'left'})}
                {single('GSTIN',billingTo.gstin?billingTo.gstin:'-',{padding:10,textAlign:'left'})}
                {single('Employee Code',data.details.raisedBy?data.details.raisedBy.ownerId:'-',{padding:10,textAlign:'left'})}
                {single('Employee Name',data.details.raisedBy?data.details.raisedBy.ownerName:'-',{padding:10,textAlign:'left'})}
            
            </div>
        )
    }

    return(
        <div>
            <div style={{
                display:'flex',
                justifyContent:'flex-end'
                }}>
                    <div style={{
                        cursor:'pointer',
                        color:'var(--primary-color)',
                        fontSize:16
                        }} 
                        onClick={()=>{PDF.current.save()}}>
                            Download
                    </div>
            </div>

            <PDFExport title="Order Document" padding="40" fileName={`Order-${data.orderId}.pdf`} ref={r => PDF.current= r}>
            <div style={{
                border:'1px solid var(--border-color)'
            }}>
                 
                <div style={{
                    padding:20,
                    borderBottom:'1px solid var(--border-color)',
                    textAlign:'center',
                    position:'relative'
                }}>

                    <h2>Cmunity Innovations Pvt. Ltd</h2>
                    <p style={{fontWeight:600}}>{billingTo.name}</p>
                    <p style={{fontSize:14,}}>{billingTo.address}</p>
                </div>
                <div style={{textAlign:'center',padding:'10px',fontWeight:'600',borderBottom:'1px solid var(--border-color)'}}>
                    Purchase Order
                </div>
                <div style={{
                    display:'flex',
                    flexWrap:'wrap'
                }}>
                        {details('Po Number',data.orderId)}
                        {details('Created At',Moment(data.details.createdAt).format('DD-MM-YYYY'))}
                        {details('Delivery Addresss',`As Per Annexure`)}

                </div>
                <div style={{
                    display:'flex',
                    flex:1,
                    borderBottom:'1px solid var(--border-color)'
                }}>
                    <div style={{flex:1,fontSize:14,fontWeight:600,padding:10,borderRight:'1px solid var(--border-color)'}}>
                        Supplier Details
                    </div>
                    <div style={{flex:1,fontWeight:600,fontSize:14,padding:10}}>
                        Billing Details
                    </div>
                </div>
                <div 
                    style={{
                    display:'flex',
                    flex:1,
                    borderBottom:'1px solid var(--border-color)'
                }}>
                    <div style={{flex:1}}>
                        {supplier()}
                    </div>
                    <div style={{flex:1}}>
                        {billing()}
                    </div>
                </div>
                <Items {...props}  {...data} />
                <Address data={data} />
                <Terms conditions={[...data.conditions]} />
                <Terms title = "Payment Terms" conditions={[...data.payments]} />
                <div style={{height:50,borderBottom:'1px solid var(--border-color)',paddingLeft:10,fontSize:14,color:'#000',display:'flex',alignItems:'center'}}>
                    <span>PO Raised / PR Raised Details : </span> {timelines[0].name}
                </div>
                <div style={{borderTop:'1px solid var(--border-color)',padding:10,fontSize:13}}>
                    This is an electronically generated PO, hence does not require signature
                </div>
            </div>
            </PDFExport>
            
        </div>
    )
}

export default Index;