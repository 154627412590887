import React from 'react';
import Moment from 'moment';

const Header = (props) => {


    let details = (key1,value1,key2,value2) => {
        return(
            <div style={{display:'flex',border:'1px solid var(--border-color)'}}>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {key1}
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {value1}
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {key2}
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {value2}
                </div>
            </div>
        )
    }
    let {data} = props;
    return(
        <>
           
            <div style={{
                minHeight:50,
                padding:10,
                borderBottom:'1px solid var(--border-color)',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
            }}>
                <p>PURCHASE ORDER</p>
            </div>
            <div>
                {details('Po Number',data.orderId,"Issue Date",Moment(data.details.createdAt).format('DD-MM-YYYY'))}
            </div>
        </>
    )
}

export default Header;