import React from 'react';
import Title from '../../../../Common/Title';
import Moment from 'moment';


const View  = ({data}) => {

    let {views} = data;
    if(!views.length) {
        return <div />
    }

    let mapItem = (item,index) => {
        return(
            <div key={index} style={{
				marginTop: 15,
				width:'33%'
			}}>
				<p style={{
					fontSize: 11,
					color: 'var(--light-color)',

				}}>
					{item.key}
				</p>
				<p style={{
					fontSize: 15,
					color: "var(--text-color)",
					marginTop: 4,
					fontWeight: 600
				}}>
					{item.value ? item.value : "-"}
				</p>
			</div>
        )
    }

    return(
        <div>
            <div style={{padding:'0px 40px'}}>
                <Title title="Approver Input" />
            </div>
            <div className='input-container'>
               {views.map((val,index)=>{
                   return(
                       <div key={index} style={{marginBottom:20}}>
                           <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                               <div>
                                   <h3 style={{fontSize:14}}>{val.title}</h3>
                                   <p style={{
                                       	fontSize: 11,
                                        color: 'var(--light-color)',
                                   }}>{val.description}</p>
                                </div>

                                <div style={{
                                    	fontSize: 12,
                                        color: 'var(--light-color)',
                                }}>
                                    {Moment(val.date).format('DD-MM-YYYY hh:mm:ss')}
                                </div>
                           </div>
                           <div style={{display:'flex',flexWrap:'wrap',flex:1}}>
                              {val.values.map(mapItem)}
                          </div>
                       </div>
                   )
               })}
            </div>
        </div>
    )
}

export default View;