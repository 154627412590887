import React from 'react'
import { FloatInputButton } from '../../../Common/fInputs'
import { FunctionalKeyValue } from '../../../Common/fInputs/details'

function CustomTags({title,data}) {
    const mapKeyValue = (item,index) =>{
		return(
			<div key={index}>
				<FloatInputButton disabled={true} style={{width: '100%'}}  title={item} placeholder={data[item]}/>
			</div>
		)
	}
    return (
        <div>
            {
                (data && Object.keys(data).length)?<div>
                <div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>{title}</div>
                    {
                        Object.keys(data).map(mapKeyValue)
                    }
                </div>:null
            }
        </div>
    )
}

export default CustomTags

export function CustomTagsUtility({title,data}){
    function isHttpLink(value) {
        const pattern = /^(https?:\/\/)[\w.-]+(\.[a-zA-Z]{2,})(\/\S*)?$/;
        return pattern.test(value);
    }

    return (
        <div>
            {
                (data && Object.keys(data).length)?<div>
                    <div style={{
                        height: 50,
                        backgroundColor: 'var(--off-white)',
                        marginTop: 30,
                        marginBottom: 20,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 20,
                        fontWeight: 600,
                        color: 'var(--light-color)'
                    }}>
                    {title}
                    </div>
                    {
                        Object.keys(data).map((item, index) => (
                            <div key={index} style={{ marginBottom: 15, display: 'flex' }}>
                                <p style={{
                                    fontWeight: 600,
                                    width: 170
                                }}>{item}</p>
                                <p style={{
                                    marginLeft: 10
                                }}> {isHttpLink(data[item]) ? <a href={data[item]} target="_blank" rel="noopener noreferrer">{data[item]}</a> : data[item]}</p>
                            </div>
                        ))
                    }
                </div>:null
            }
        </div>
    )

}

export function CustomTagsContract({title,data}){
    const mapKeyValue = (item,index) =>{
		return(
			<div key={index}>
                <FunctionalKeyValue style={{width: '100%',wordBreak:'break-all'}} title={item} value={data[item]}/>
			</div>
		)
	}
    return (
        <div>
            {
                (data && Object.keys(data).length)?<div>
                <div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>{title}</div>
                    {
                        Object.keys(data).map(mapKeyValue)
                    }
                </div>:null
            }
        </div>
    )

}