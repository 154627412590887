import React from "react";
import Props from 'prop-types'
import PhotoTile from "../fPhoto";

function KeyValueDetails(props) {

	return (
		<div style={{...props.container,paddingBottom:0, borderTop: '1px solid var(--border-color)'}}>
			<div style={{display:'flex', marginBottom:20,alignItems:'center'}}>
				<div style={{
					width:10,
					height:10,
					marginRight:5,
					border:'3px solid #20c997',
					borderRadius:5,
					marginTop:1
				}}/>
				<p style={{
					fontWeight:600,
					fontSize:12,
					textTransform:'uppercase',
					letterSpacing:.5
				}}>
					{props.header}
				</p>
			</div>
			<div>
				{props.data && props.data.tripId  && (
					<p  style={{fontSize: 12,marginBottom:6}}>
					<span style={{color: "var(--light-color)",width: 60}}>{"Trip Id"}:</span> 
					 {props.data.tripId ? props.data.tripId  : "-"}
			       </p>
				)}
				{
					props.values.map(item => {
						return (
							<p key={item.key} style={{fontSize: 12,marginBottom:6}}>
									<span style={{
										color: "var(--light-color)",
										width: 60
									}}>{item.key}:</span> 
									{item.value ? (
									typeof item.value !== 'object' && (
										typeof item.value === 'string' && (item.value.includes('jpeg') || item.value.includes('png') || item.value.includes('pdf')) ?
										<PhotoTile link={item.value} />
										: item.value
									)
									) : "-"}
							</p>
						)
					})
				}
			</div>
		</div>
	)

}


KeyValueDetails.propTypes = {
	header: Props.string.isRequired,
	values: Props.array.isRequired
}

KeyValueDetails.defaultProps = {
	container: {
		marginTop:25,
		marginBottom:50,
		paddingTop: 25
	},
	header: "Loading",
	values: []
}


export {KeyValueDetails};
