import React from 'react'
import Props from 'prop-types'
import Arrow from './arrow.png';
import Empty from "../../Common/fCard/Empty";

const PAGECOUNT = 10;

class NormalTable extends React.Component {

	state = {
		page: 0
	}

	_headerItem = (item) => {

		let active = this.props.sort ? this.props.sort.type === item.key : false;

		if (item.type === "check") {
			return (
				<div key={item.key} style={{ width: 40, alignItems: 'center', display: 'flex', ...item.checkStyle }}>
					<div onClick={item.click}
						style={{ width: 16, height: 16, border: '1px solid var(--border-color)', cursor: 'pointer' }}>
						<div style={item.checked ? {
							background: "var(--success-color)",
							width: 12,
							height: 12,
							margin: 1
						} : {}} />
					</div>
				</div>
			)
		}
		return (
			<div key={item.key}
				style={item.width ? { width: item.width } : item.weight ? { flex: item.weight } : { flex: 1 }}>
				<p onClick={item.sort ? item.sort : null} className="fintrip-table-header-title" style={{
					...item.style, ...item.sort ? {
						cursor: 'pointer'
					} : null
				}}>
					{item.name}
					{active ? (this.props.sort.up ? <img src={Arrow} alt="" style={{ height: 10, width: 10, marginLeft: 5 }} /> :
						<img src={Arrow} alt="" style={{ height: 10, width: 10, transform: 'rotate(180deg)', marginLeft: 5 }} />) : null}
				</p>
			</div>
		)
	}

	pagination = () => {
		let pagecount = this.props.pagecount ? this.props.pagecount : PAGECOUNT;
		let start = this.state.page * pagecount + 1;
		let end = this.state.page * pagecount + pagecount;
		let length = this.props.data.length
		if (end > length)
			end = length;
		if (start > length)
			start = length;
		return (
			<div className="fintrip-table-footer">
				<img style={{ cursor: 'pointer' }} onClick={() => {
					let page = this.state.page;
					if ((start + pagecount) <= (length)) {
						this.setState({
							page: page + 1
						})
					}
				}} alt="left arrow" className="right-arrow" src={require('./right-arrow (2).png')} />
				<img style={{ cursor: 'pointer' }} onClick={() => {
					let page = this.state.page;
					if (page > 0) {
						this.setState({
							page: page - 1
						})
					}
				}} alt="right arrow" className="left-arrow" src={require('./left-arrow (1).png')} />
				<p>
					{start} - {end} of {length} results
				</p>
			</div>
		)
	}


	render() {
		let data = this.props.data ? this.props.data : [];
		let pagecount = this.props.pagecount ? this.props.pagecount : PAGECOUNT;
		if (this.props.pagination) {
			let start = this.state.page * pagecount;
			let end = this.state.page * pagecount + pagecount;
			data = data.slice(start, end)
		}
		return (
			<div className={`fintrip-table ${this.props.portlet ? 'portlet-table' : ""}`}>
				{this.props.search &&(
					<>
						<div style={{display:'flex', position:'relative', marginBottom:'20px'}}>
							<div style={{flex:'1 1 0%', height:'40px', borderRadius:'1px'}}>
								<input style={{height:'100%', width:this.props.width, flex:'1 1 0%', border:'1px solid #ddd', backgroundColor:'rgb(255,255,255)', fontSize:'16px', borderRadius:'8px', padding:'12px'}} 
									type='text'
									placeholder={this.props.placeHolder}
									onChange={(e)=>{this.props.onChangeValue(e.target.value)}}
									/>
							</div>
						</div>
					</>
					)
				}
				{this.props.headers.length>0?<div className="fintrip-table-header" style={this.props.headerStyle}>
					{this.props.headers.map(this._headerItem)}
				</div>:undefined}

				<div className="fintrip-table-body" style={this.props.bodyStyle}>
					{data.map((item, i) => {
						return (
							<div key={i}>{this.props.mapItem(item, i)}</div>
						)
					})}
					{data.length === 0 ?
						<p style={{ padding: 18, textAlign: 'center' }}>No data found to show.</p> : null}
				</div>
				{
					this.props.pagination ? this.pagination() : null
				}
			</div>
		)
	}

}


class NormalTable2 extends React.Component {

	state = {
		page: 0
	}

	pagination = () => {
		let pagecount = this.props.pagecount ? this.props.pagecount : PAGECOUNT;
		let start = this.state.page * pagecount + 1;
		let end = this.state.page * pagecount + pagecount;
		let length = this.props.data.length
		if (end > length)
			end = length;
		if (start > length)
			start = length;
		return (
			<div className="fintrip-table-footer">
				<img style={{ cursor: 'pointer' }} onClick={() => {
					let page = this.state.page;
					if ((start + pagecount) <= (length)) {
						this.setState({
							page: page + 1
						})
					}
				}} alt="left arrow" className="right-arrow" src={require('./right-arrow (2).png')} />
				<img style={{ cursor: 'pointer' }} onClick={() => {
					let page = this.state.page;
					if (page > 0) {
						this.setState({
							page: page - 1
						})
					}
				}} alt="right arrow" className="left-arrow" src={require('./left-arrow (1).png')} />
				<p>
					{start} - {end} of {length} results
				</p>
			</div>
		)
	}


	render() {
		let data = this.props.data ? this.props.data : [];
		let pagecount = this.props.pagecount ? this.props.pagecount : PAGECOUNT;
		if (this.props.pagination) {
			let start = this.state.page * pagecount;
			let end = this.state.page * pagecount + pagecount;
			data = data.slice(start, end)
		}
		return (
			<div style={{position:'relative',marginTop:18,marginLeft:-10,marginRight:-10,...this.props.bodyContainer}} className="">
				<div className="fintrip-office-container" style={this.props.bodyStyle}>
					{data.map((item, i) => {
						return (
							<div key={i}>{this.props.mapItem(item, i)}</div>
						)
					})}
					{data.length === 0 ? <Empty/>: null}
				</div>
				{
					this.props.pagination ? this.pagination() : null
				}
			</div>
		)
	}

}

NormalTable.propTypes = {
	headers: Props.array.isRequired,
	mapItem: Props.func,
	data: Props.array.isRequired,
	portlet: Props.bool
}


NormalTable2.propTypes = {
	mapItem: Props.func,
	data: Props.array.isRequired,
}

NormalTable.defaultProps = {
	portlet: false,
	bodyContainer:{}
}

export {
	NormalTable,NormalTable2
}
