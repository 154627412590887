import React from 'react';
import Moment from 'moment';

const Header = (props) => {


    let details = (rows) => {
        return (
            Object.keys(rows).map(row => {
                return (
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
                        <div style={{flex: 1}}>{row}:</div> <div style={{fontWeight: "bold", flex: 1, textAlign: "right"}}>{rows[row]}</div>
                    </div>
                )
            })
        )
    }
    let { data } = props;
    return(
        <>
            
            <div style={{
                display: "flex",
                border:'1px solid var(--border-color)',
                borderRadius: 5,
                padding: 10,
                background: "#fafafa"
            }}>
                <div style={{
                    flex: 1.5
                }}>
                    <img src="https://static.eka.io/v2/receipts/2023/04/d06f3f7f-7a6d-47de-a2bd-30f26bbb9f5b.png" alt="" style={{width:180}} />
                    <p style={{fontWeight: 700}}>Roppen Transportation Services Pvt Ltd (Rapido)</p>
                </div>
                
                <div style={
                    {
                        display:'flex',
                        flex: 1,
                        flexDirection:'column',
                        padding:'10px 5px'
                    }
                }>
                    <p
                        style={{
                            border:'1px solid var(--border-color)',
                            borderRadius: 5,
                            padding: 8,
                            textAlign: "center",
                            background: "white",
                            fontWeight: 700,
                            fontSize: 17
                        }}
                    >PURCHASE ORDER</p>
                    <div style={{
                        padding: "20px 10px"
                    }}>
                        {details({ 
                            "PO Number": data.orderId,
                            "PO Date": Moment(data.details.createdAt).format('MMM-DD-YYYY'),
                            "Expected Delivery Date": Moment(data.details.createdAt).format('MMM-DD-YYYY'),
                            "Payment Term": "Payment 30 days after invoice date"
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;
