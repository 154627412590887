import {post} from "../../Model/Network/Config/Axios";

class Model {

	sendLink = (data, cb) => {
		post(`/auth/reset`, {
			username: data.email,
			...data
		}, cb)
	}

}

export default Model;
