import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import Terms from './Common/Terms';
import Items from './Common/Items';
import { get } from '../../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../../Common/Components/PageLoader';


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            employeeDetails:{},
            loading:true
        }
    }

    componentDidMount() {
        let employeeId = this.props.data.details.raisedBy.ownerId
        get(`/heirarchy/employees/${employeeId}`,(e,r)=>{
            if(r) {
                this.setState({
                    ...this.state,
                    employeeDetails:r.details,
                    loading:false
                })
            }
        })
    }


    address = (title,address)=> {
        return(
            <>
                 <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                    fontWeight:600
                }}>
                   {title} :
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   {address.name}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   {address.address}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   {address.city}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   Gst : {address.gstin ? address.gstin:'-'}
                </p>
            </>
        )
    }


    items = (procured) => {

        return (
            <div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p style={{
                        paddingLeft: 10,
                        fontWeight: 600
                    }}>Please provide the following materials</p>

                </div>

                <div>
                    <Items items={procured} />
                </div>
            </div>

        )
    }



    approve = () => {
        let {vendor,billingTo} = this.props.data
        return (
            <>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    borderBottom: '1px solid var(--border-color)'
                }}>

                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column'
                    }}>
                        <p></p>
                        <p style={{
                            marginTop: 30,
                            fontWeight: 500,
                            borderTop:'1px solid var(--border-color)',
                            borderBottom:'1px solid var(--border-color)',
                            width:'100%',
                            padding:'5px 0px',
                            textAlign:'center'
                        }}>Authorized Signatory</p>
                        <p style ={{
                            padding:'5px 0px',
                            fontWeight:600,
                            textAlign:'center',
                            fontSize:20
                        }}>{billingTo.name}</p>
                    </div>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column'
                    }}>
                      <p></p>
                        <p style={{
                            marginTop: 30,
                            fontWeight: 500,
                            borderTop:'1px solid var(--border-color)',
                            borderBottom:'1px solid var(--border-color)',
                            width:'100%',
                            padding:'5px 0px',
                            textAlign:'center'
                        }}>Authorized Signatory</p>
                        <p style ={{
                            padding:'5px 0px',
                            fontWeight:600,
                            textAlign:'center',
                            fontSize:20
                        }}>{vendor.name}</p>
                    </div>
                </div>


            </>
        )
    }


    left = () => {
        let {vendor,billingTo,shipTo} = this.props.data;
        return(
            <div style ={{
                borderRight:'1px solid var(--border-color)',
                flex:1
            }}>
                <p style ={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontWeight:700,
                    textTransform:'uppercase',
                    fontSize:12
                }}> Vendor Name and Address :</p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14
                }}>
                    {vendor.name}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14
                }}>
                    {vendor.address.address}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14
                }}>
                    {vendor.address.city}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                    fontWeight:600
                }}>
                   Gstin : {vendor.gstin}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                    fontWeight:600
                }}>
                   Pan : {vendor.pan?vendor.pan:'-'}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   Email : {vendor.email}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   Mobile : {vendor.mobile}
                </p>
                {
                    this.address("BILL TO ADDRESS",billingTo ? billingTo : shipTo)
                }
            </div>
        )
    }

    right = () => {
        let list = this.props.data;

        let {  procured} = this.props.data;
        return(
            <div style ={{
                borderRight:'1px solid var(--border-color)',
                flex:1
            }}>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:13,
                    fontWeight:600
                }}>
                    Po Number: {list.orderId}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   Date of Issue : {Moment(list.details.createdAt).format('DD-MM-YYYY')}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   VALID FROM : {Moment(list.details.createdAt).format('DD-MM-YYYY')}
                </p>

                <p style ={{
                    marginTop:66,

                }} />

                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                    borderTop:'1px solid var(--border-color)',

                }}>
                   Contact Person : {list.details.raisedBy.ownerName}
                </p>
                <p style={{
                    borderBottom:'1px solid var(--border-color)',
                    padding:'7px 5px',
                    fontSize:14,
                }}>
                   Mobile : - {this.state.employeeDetails.mobile}
                </p>
                {
                    this.address("SHIP TO ADDRESS",{
                        name:procured[0].officeName,
                        address:procured[0].officeAddress,
                        city:procured[0].officeCity,
                    })
                }
            </div>
        )
    }

    render() {

        let { conditions,orderId ,procured } = this.props.data;
        if(this.state.loading) {
            return <PageLoader />
        }
        return(
            <div>
                  <div style={{
                    textAlign: 'right'
                }}>
                    <p style={{
                        fontWeight: 600,
                        color: 'var(--primary-color)',
                        cursor: 'pointer'
                    }} onClick={() => {
                        this.pdf.save();
                    }}>Download Now</p>
                </div>
                <PDFExport paperSize="A4" title="Order Document" padding="40" fileName={`Order-${orderId}.pdf`} ref={r => this.pdf = r}>
                     <div style={{
                        border: '1px solid var(--border-color)',
                        marginTop:20
                    }}>
                         <div style={{
                            height: 50,
                            borderBottom: '1px solid var(--border-color)',
                            backgroundColor: 'rgb(244,244,244)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 600
                        }}>
                            PURCHASE ORDER
                         </div>
                         <div style ={{
                             display:'flex',
                             minHeight:400,
                            borderBottom:'1px solid var(--border-color)'
                         }}>
                             {this.left()}
                             {this.right()}
                         </div>
                        {this.items(procured)}
                        <Terms conditions={conditions}/>
                        {this.approve()}
                    </div>
                </PDFExport>
            </div>
        )
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Index);
