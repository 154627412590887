import { useSelector } from "react-redux";




const useReadAccessConfig = () => {
    let readOnly = useSelector(val=>(val.auth.config.readOnly))
    return readOnly;
}

export {useReadAccessConfig}