import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { showToast ,handleError} from '../../../../Common/fToast/ToastCallback';
import { TitleTopHeader,Title as Header } from '../../../../Header/TopHeader';
import { pluginsGetApi,pluginsPostApis } from '../../../../../Model/Network/Config/Axios';
import { StateButton } from '../../../../Common/fStateButton';
import {DateRangePicker} from "react-date-range";



const Index = () => {

  const [loading,setLoading] = useState(false);
  const [range,setRange] = useState({
    startDate: Moment().add(-60, 'd').toDate(),
    endDate: Moment().add(1, 'd').toDate(),
    key: 'selection',
})

  useEffect(() => {
    pluginsGetApi('plugins/custom/chaitanya/lms/info', (e, r) => {
      if (r) {
       
      }
    });
  }, []);

 

  const syncWithDarwin = () => {

    let data = {
        start: Moment(range.startDate).valueOf(),
        end: Moment(range.endDate).valueOf(),
   }
	  setLoading(true);
    pluginsPostApis('plugins/custom/chaitanya/lms/syncLms', data, (e, r) => {
      if (r) {
		    setLoading(false);
        showToast({
          type: 'success',
          message: 'Event triggered successfully!',
        });
      } else {

	    	setLoading(false);
        handleError(e);
      }
    });
  };

  let handleSelect = (ranges) => {
    let startDate = Moment(ranges.selection.startDate).hours(0).minutes(0).seconds(0);
    let endDate = Moment(ranges.selection.endDate).hours(23).minutes(59).seconds(59);

    setRange( {
         startDate: Moment(startDate).toDate(),
         endDate: Moment(endDate).toDate(),
         key: 'selection'
      })

  }

  return (
    <div>
      <TitleTopHeader items={[]} title="LMS  Sync" des="Syncing of data with LMS box." />
      <Header title="LMS Sync" des="Syncing of data with LMS." />
      <div
        className="title-container"
        style={{
          padding: 18,
        }}
      >

          <label className="floating-top-label">Select Time Range</label>
          <div style={{ position: 'relative' }} id="no-shadow">
              <DateRangePicker
                            color="#0062C5"
                            ranges={[range]}
                            onChange={handleSelect}
                        />         
          </div>         

        
        <div>
		  {
			    loading ?
			    <StateButton />
		      	:
          <button onClick={syncWithDarwin} className="btn btn-success" style={{ marginTop: 12 }}>
            Sync
          </button>

		  }
        </div>
      </div>
    </div>
  );
};

export default Index;
