import React from 'react';


class Index extends React.Component {


    render() {
        let { conditions,title } = this.props;
        return (
            <div style={{
                minHeight: 100,
                borderBottom: '1px solid var(--border-color)',
                padding:10
            }}>
                <p style={{
                    fontWeight: 800,
                    marginBottom: 10,
                    borderBottom:'1px solid var(--border-color)',
                    padding:'8px 10px'
                }}>{title}</p>

                {
                    conditions.length > 0 ?
                        conditions.map((item, index) => {
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'var(--text-color)',
                                    borderBottom:'1px solid var(--border-color)',
                                    padding:'8px 10px'
                                }}>
                                    <div style={{
                                        marginLeft: 10,
                                        fontSize:13,
                                    }} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                                </div>
                            )
                        })
                        :null
                               }

            </div>
        )
    }
}

Index.defaultProps = {
    title:`Terms and Conditions:`
}

export default Index;