import {get} from "../../../Utils/Crypto";

let  plugins, luna, token, guest, report;

plugins = "https://plugins.eka.io/";
guest = "https://inventories.eka.io/";
report = "https://reports.eka.io/"
luna = "support";
token = () => get("refresh_token");

let DEV_ENVIRONMENT = false ;
let DEV_MODE = false;
let AUTHURL=  `https://auth.dice.tech/auth/admin`;
let HARDCODEURL = `https://heimdall.eka.io/admin/`;
let TABLEULR = `.tables.eka.io`


if(window.location.href.includes('dev-enterprise-dice-tech') || window.location.href.includes('enterprise.dev.eka.io') || window.location.href.includes('admin-dev.eka.io') || DEV_ENVIRONMENT || window.location.href.includes('sandbox-dice')) {
	DEV_MODE = true;
	HARDCODEURL = `https://api.dev.eka.io/admin/`;
	plugins=`https://integration.dev.eka.io/`;
	TABLEULR=`.tables.dev.eka.io`
}


export {
	  plugins, luna, token, guest,  report,HARDCODEURL,AUTHURL,DEV_ENVIRONMENT,TABLEULR,DEV_MODE
}
