import React from 'react'
import {StateButton} from "../../../../Common/fStateButton";
import {pluginsPostApis} from "../../../../../Model/Network/Config/Axios";
import {showToast} from "../../../../Common/fToast/ToastCallback";
import {Title as Header, TitleTopHeader} from "../../../../Header/TopHeader";
import {FloatFile} from "../../../../Common/Class/FloatLabelInput/FloatFile";


class index extends React.Component {

	state = {
		loading: false
	}


	_syncWithEka = () => {
		let file = this._file.value();
		if (!file) {
			showToast({
				type: 'error',
				message: "Please select valid file"
			})
			return;
		}

		pluginsPostApis(`plugins/csv/transactions/upload`, { fileUrl: file }, (e, r) => {
			if (e) {
				showToast({
					type: 'error',
					message: e.data
				})
			} else {
				showToast({
					type: 'success',
					message: "File uploaded successfully!"
				})
			}
		})

	}

	render() {
		return (
			<div>
				<TitleTopHeader items={[]} title="Transaction Data Sync" des="Syncing your Transaction  data with Dice." />
				<Header title="Transaction Data Sync" des="Syncing your Transaction data with Dice." />
				<div className="title-container" style={{
					padding: 18
				}}>

					<FloatFile title="Csv File" ref={ref => { this._file = ref }} />
					<p style={{
						marginTop: 18,
						fontSize: 13
					}}>
						Please download the sample csv file for upload. <a style={{
							cursor: 'pointer'
						}} rel="noopener noreferrer" target='_blank' href={require('./downloads.csv')}>Download Csv</a>
					</p>
					<div style={{ marginTop: 12 }}>
						{
							this.state.loading ?
								<StateButton /> :
								<button onClick={this._syncWithEka} className="btn btn-portlet" style={{ marginTop: 25 }}>
									Sync With Dice
								</button>
						}
					</div>
				</div>
			</div>
		)
	}

}

export default index
