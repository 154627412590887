import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import {useParams} from  'react-router-dom';
import {get} from '../../../../../Model/Network/Config/Axios';
import {PageLoader} from '../../../../Common/Components/PageLoader';
import {handleError} from "../../../../Common/fToast/ToastCallback";
import RenderPoDetails from './RenderPoDetails';
import ToggleSwitch from '../../../../Common/fSwitch';
import PoAmend from '../fPoAmend';
import { Modal } from '../../../../Common/fModal/Modal';


const PoDetails = (props) => {

	const [loading,setLoading] = useState(true);
	const [details,setDetails] = useState({});
	const [invoices,setInvoices] = useState([]);
	const [conditions, setConditions] = useState([]);
	const [toggle, setToggle] = useState(false);
	const [modal, setModal] = useState(false);
	const [defaultBank, setDefaultBank] = useState(undefined);
	const [vendorStatus, setVendorStatus] = useState(undefined);
	const {id} = useParams();
	let {poAmendmentRequest} = useSelector(state=>state.auth.config.plugins);

	useEffect(()=>{
		init();
	},[])

	let getVendorStatus=(id)=>{
		get(`/vendor/contacts/${id}/details`, (e, r) => {
			if(r) {
				setVendorStatus(r.vendorStatus);
			}else if(e){ 
				handleError(e);
			}
		})
	}

	let init = () => {
		get(`vendor/po/requests/${id}/details`, (e, r) => {
			if (r) {
				setDetails(r);
				setInvoices(r.invoices)
				fetchDefaultBankDetails(r.vendor.id)
				fetchConditions();
				if(r.vendor)	getVendorStatus(r.vendor.id);
			}
			else if(e){
				handleError(e);
			}
		})
	}

	let fetchDefaultBankDetails=(id)=>{
		get(`/vendor/contacts/${id}/banks`, (e, r) => {
            if (r) {
                setDefaultBank(r.defaultBank)
            }
        })
	}

	let fetchConditions=()=>{
		get(`vendor/po/requests/${id}/conditions`, (e, r) => {
            if (r) {
                setConditions(r.conditions);
				setLoading(false);
            }
        })
	}



	if(loading) {
		return(<PageLoader />)
	}
	
	return (
		<>
			{vendorStatus==='BLOCKED' && <div style={{ fontSize: '12px', background: '#FDF0DD', borderRadius: '4px',margin:'20px 35px', padding: '20px', display: 'flex', alignItems: 'center', border:'1px solid rgb(144 150 38 / 57%)' }}>
				<svg style={{ marginRight: 12 }} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" viewBox="0 0 16 16">
					<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
					<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
				</svg>
				<span style={{fontSize:'medium', display:'flex'}} >
					This Vendor is Blocked. 
				</span> 
			</div>}
			{poAmendmentRequest ?
				<div style={{display:'flex',width:'66%',marginLeft:28,paddingBottom:8}}>
				<ToggleSwitch
					style={{background:'#fff',padding:10,borderRadius:10,marginLeft:5 }}
					label="PO Amendment Request"
					status={toggle}
					callback={() => { 
						toggle ? setModal(true) : setToggle(!toggle) 
					}}
				/>
			    </div>
				: null}
			{toggle ? 
			   <PoAmend
				details={details}
				invoices={invoices}
				conditions={conditions}
				init={() => init()}
				{...props}
			/> : 
			   <RenderPoDetails
				defaultBank={defaultBank}
				details={details}
				invoices={invoices}
				conditions={conditions}
				init={() => init()}
				{...props}
			/>}
			{modal &&
				<Modal
					title={'Confirmation'}
					buttonName={'Yes'}
					des="Please confirm if you want to revert the changes, as doing so will clear all modifications made."
					close={() => setModal(false)}
					onSave={() => {
						setToggle(false)
						setModal(false)
					}}
					show={true}
				>
				</Modal>
			}
		</>
		)

}



export default PoDetails
