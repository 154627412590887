// code directly copied from npm package repo - https://www.npmjs.com/package/inr-words
const TEN = 10;
const ONE_HUNDRED = 100;
const ONE_THOUSAND = 1000;
const ONE_LAKH = 100000;
const ONE_CRORE = 10000000;

const LESS_THAN_TWENTY = [
    'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
    'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
];

const TENTHS_LESS_THAN_HUNDRED = [
    'Zero', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
];

let firstPart = 0;
let secondPart = 0;
let firstPartString = "";
let secondPartString = "";
let fullString = "";

const inrInWords = function (inr) {
    
    try {
        if (!isFinite(inr)) {
            throw new TypeError(
                `Invalid number format: ${inr} (${typeof inr})`
            );
        }

        // number = removeLeadingZeros(inr); // Convert from Octal to decimal
        let stringInr = inr.toString();
        let parts = stringInr.split('.');
        firstPart = +parts[0];
        firstPartString = `${getWords(firstPart)} rupee(s)`;
        if(parts.length > 1) {
            // Set decimal part
            secondPart = +parts[1];
            secondPartString = `and ${getWords(secondPart)} paise`;
        }
        fullString = `${firstPartString} ${secondPartString}`;
        // console.log(`${inr} ${fullString}`);
        return fullString.trim();
    }
    catch (error) {
        console.log(error.message);
        return error.message;
    }

}


// Testing
// inrInWords(process.argv[2]);


function getWords(num) {
    num = +num;
    // If number is zero, just  return "zero"
    if(!num || num === 0) return "zero";

    let finalString = "";
    let crores = Math.floor(num / ONE_CRORE);
    let croresRemainder = num % ONE_CRORE;
    let croresInWords = "";

    let tenLakhs = Math.floor(croresRemainder / ONE_LAKH);
    let tenLakhsRemainder = croresRemainder % ONE_LAKH;
    let tenLakhsInWords = "";

    let tenThousands = Math.floor(tenLakhsRemainder / ONE_THOUSAND);
    let tenThousandsRemainder = tenLakhsRemainder % ONE_THOUSAND;
    let tenThousandsInWords = "";

    let hundreds = Math.floor(tenThousandsRemainder / ONE_HUNDRED);
    let hundredsRemainder = tenThousandsRemainder % ONE_HUNDRED;
    let hundersInWords = "";

    let tens = Math.floor(hundredsRemainder / TEN);
    let tensRemainder = hundredsRemainder % TEN;
    let tensInWords = "";
    let onesInWords = "";

    if (crores > 0) {
        croresInWords = `${getWords(crores)} crore${crores > 1 ? 's' : ''}`;
        finalString = `${croresInWords} `;
    }

    if (tenLakhs > 0) {
        // tenLakhsInWords = (tenLakhs < 20) ? LESS_THAN_TWENTY[tenLakhs] : TENTHS_LESS_THAN_HUNDRED[tenLakhs];
        tenLakhsInWords = `${getWords(tenLakhs)} lakhs`;
        finalString = `${finalString}${tenLakhsInWords} `;
    }

    if (tenThousands > 0) {
        // tenThousandsInWords = (tenThousands < 20) ? LESS_THAN_TWENTY[tenThousands] : TENTHS_LESS_THAN_HUNDRED[tenThousands];
        tenThousandsInWords = `${getWords(tenThousands)} thousand`;
        finalString = `${finalString}${tenThousandsInWords} `;
    }
    if (hundreds > 0) {
        hundersInWords = LESS_THAN_TWENTY[hundreds];
        hundersInWords = `${hundersInWords} hundred`;
        finalString = `${finalString}${hundersInWords} `;
    }

    if (hundredsRemainder > 0 && hundredsRemainder < 20) {
        // constuct string for less than 20 ( like thirteen, fifteen)
        tensInWords = LESS_THAN_TWENTY[hundredsRemainder];
        finalString = `${finalString}${tensInWords}`;
    }

    else {
        // If hundreds Remainder is 0, like 300.
        if(hundredsRemainder === 0) {
            finalString = finalString.trimEnd();
        }
        if (tens > 0) {
            tensInWords = TENTHS_LESS_THAN_HUNDRED[tens];
            finalString = `${finalString}${tensInWords} `;
        }

        if (tensRemainder > 0) {
            onesInWords = LESS_THAN_TWENTY[tensRemainder];
            finalString = `${finalString}${onesInWords}`;
        }
    }


    return finalString;
}



export default inrInWords;
