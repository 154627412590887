import React from 'react';
import {Title as Header, TitleTopHeader} from '../../../../Header/TopHeader';
import {FloatInput} from '../../../../Common/Class/FloatLabelInput';
import {DateRangePicker} from "react-date-range";
import Moment from 'moment';
import {showToast} from '../../../../Common/fToast/ToastCallback';
import {pluginsGetApi, pluginsPostApis} from '../../../../../Model/Network/Config/Axios';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            range: {
                startDate: Moment().add(-60, 'd').toDate(),
                endDate: Moment().add(1, 'd').toDate(),
                key: 'selection',
            },
        }
    }

    componentDidMount() {
        pluginsGetApi(`plugins/gomech/info`, (e, r) => {
            if (r) {

            }
        })
    }


    handleSelect = (ranges) => {

        let startDate = Moment(ranges.selection.startDate).hours(0).minutes(0).seconds(0);
        let endDate = Moment(ranges.selection.endDate).hours(23).minutes(59).seconds(59);
        this.setState({
            ...this.state,
            range: {
                startDate: Moment(startDate).toDate(),
                endDate: Moment(endDate).toDate(),
                key: 'selection'
            },
        })
    }


    send = () => {
        let email = this.email.value();
        if (!email) {
            showToast({
                type: 'error',
                message: 'Please enter a email id'
            })
            return;
        }

        pluginsPostApis(`plugins/custom/gomech/reports/export`, {
            email,
            start: Moment(this.state.range.startDate).valueOf(),
            end: Moment(this.state.range.endDate).valueOf()
        }, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Report sent successfully'
                })
            }
        })
    }

    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <TitleTopHeader items={[]} title="Reimbursement Report" des="Ledger Report for company " />
                <Header title="Ledger report" des="Ledger report for  company" />
                <div className="title-container" style={{
                    padding: 18
                }}>
                    <FloatInput type="text" handleRef={ref => this.email = ref} title="Email" />
                    <label className="floating-top-label">Select Time Range</label>
                    <div style={{ position: 'relative' }} id="no-shadow">
                        <DateRangePicker
                            color="#0062C5"
                            ranges={[this.state.range]}
                            onChange={this.handleSelect}
                        />
                    </div>

                    <div className="btn btn-portlet" style={{
                        marginTop: 30
                    }} onClick={() => {
                        this.send()
                    }}>
                        Send Report
                    </div>

                </div>
            </div>
        )
    }
}

export default Index;
