import { PDFExport } from "@progress/kendo-react-pdf";
import React from "react";
import Data from "./Data";
import Footer from "./Footer";
import Moment from 'moment';

export default function Zippmat(props) {

    let { data } = props;
    let pdfRef;

    let getAddress = (fullAddress) => {
        const { address, state, country, pincode } = fullAddress;
        let addressString = "";
        if(address) addressString += address;
        if(state) addressString += `, ${state}`;
        if(country) addressString += `, ${country}`;
        if(pincode) addressString += ` - ${pincode}`;
        return addressString;
    }


    console.log(data);
    return(
        <>
        <div style={{
            display:'flex',
            justifyContent:'space-between',
            marginBottom:15,
            flexDirection:'column',
            alignItems:'center'
        }}>
          
            <div style={{
                cursor:'pointer',
                color:'var(--primary-color)',
                fontSize:16,
                alignSelf:'flex-end'
            }} onClick={()=>{
                pdfRef.save()
            }}>Download</div>
           <img src="https://static.eka.io/v2/receipts/2023/08/3c61afff-15ae-450e-a36e-2f7eb1cba70a.png" alt="" style={{width:'100%',height:70}} />
        </div>
        <PDFExport title="Order Document" padding="40" fileName={`Order-${data.orderId}.pdf`} ref={r => pdfRef = r}>
            <div style={{ margin: 20 }}>
                <div style={{border:'1px solid var(--border-color)'}}>
                    <div style={{display:'flex',border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                        <div style={{flex:1,display:'flex',justifyContent:'left',alignContent:'center',borderRight:'1px solid var(--border-color)'}}>
                            Date: {Moment(data.details.createdAt).format('DD-MM-YYYY')}
                        </div>
                        <div style={{flex:1,display:'flex',justifyContent:'left',alignContent:'center',borderRight:'1px solid var(--border-color)'}}>
                            Supplier Address:
                        </div>
                    </div>
                    <div style={{display:'flex',border:'1px solid var(--border-color)'}}>
                        <div style={{flex:1,display:'flex',justifyContent:'left',alignContent:'center',borderRight:'1px solid var(--border-color)', padding: "10px 5px"}}>
                            Order No: {data.orderId}
                        </div>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                                <div>{data.vendor.name}</div>
                                <div>{getAddress(data.vendor.address)}</div>
                            </div>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                                <div>Mob: {data.vendor.mobile}</div>
                                <div>GST No.: {data.vendor.gstin || " "}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>Billing Address:</div>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                                <div>{data.billingTo.name}</div>
                                <div>{getAddress(data.billingTo)}</div>
                            </div>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                                <div>GST No.: {data.billingTo.gstin}</div>
                                <div>Contact Person: Mr. Anmol</div>
                                <div>Mob: 9364007929</div>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>Ship To Address:</div>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                                <div>{data.procured[0].officeName}</div>
                                <div>{data.procured[0].officeAddress }</div>
                            </div>
                            <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                                <div>GST No.: {data.procured[0].officeGstin}</div>
                                <div>Contact Person: Mr. Roshan</div>
                                <div>Mob: 9364007913/9364007928</div>
                            </div>
                        </div>
                    </div>
                    <div style={{border:'1px solid var(--border-color)', padding:'10px 5px'}}>
                        <p>Dear Sir,</p>
                        <p>We are pleased to to place the order for the below mentioned goods subject to the instructions herein below. Kindly acknowledge the receipt and confirm acceptance of the purchase order.</p>            
                    </div>
                    <Data data={data} />
                </div>
                <Footer />
            </div>
        </PDFExport>
        </>
    )
}