import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.css';

function Tabs(props) {

    let HISTORY = useHistory();

    const renderTabs = (item, index)=>{
        let cls = '';

        if(item.active){
            cls = 'active'
        }else{
            cls = 'inactive'
        }
        return(
            <div key={index} onClick={()=>HISTORY.push(item.link)} className={`Tabs-${cls}`}>{item.name}</div>
        )
    }

    return (<>
        <div className='Tabs'>
            {props.tabs.map(renderTabs)}
        </div>
    </>);
}

export default Tabs;