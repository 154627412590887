import React from 'react';
import {useHistory} from 'react-router-dom';

/*
PROPS:

title = string
suffix = string
*/

function Navigator(props) {

    const History = useHistory();

    return (<>
             <div style={{display: 'flex', fontSize: '32px', fontWeight: 'bold', cursor: 'pointer', alignItems: 'center', ...props.style}}>
                <svg onClick={()=>props.url?History.push(props.url):History.goBack()} style={{marginRight: '20px', fontSize: props.size==='S'?'24px':'28px'}} className="link-item bi bi-arrow-left-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                </svg>
                {props.title?
                <div className='FadeRight-Effect' style={{display: 'flex'}}>
                    <div style={{maxWidth: 500, textOverflow: 'ellipsis', overflow:'hidden', whiteSpace: 'nowrap', display: 'inline-block', verticalAlign: 'middle'}}>
                        {props.title}
                    </div>&nbsp;
                    {props.suffix?<div>{props.suffix}</div>:''}
                </div>:
                <span style={{color: '#f9fafa'}}>...</span>}
            </div>
    </>);
}

export default Navigator;
