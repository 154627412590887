import {pluginsGetApi} from "../../../../../../Model/Network/Config/Axios";


class Model {

    init = (cb) => {
        pluginsGetApi(`plugins/mailApproval/approvals`, cb)
    }

    triggerMail = (cb) => {
        pluginsGetApi(`plugins/mailApproval/trigger`, cb)

    }
}

export default Model;
