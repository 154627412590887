import React from 'react'
import {Modal} from "./Modal";
import { FloatSelect} from "../Class/FloatLabelInput";
import {get} from "../../../Model/Network/Config/Axios";
import {useState,useEffect} from 'react'
import { FunctionalInput } from '../fInputs';

function DropdownModal(props) {
	let [list,setList] = useState([])
	let [selected,setSelected] = useState(undefined)
	useEffect(()=>{
		get(`/settings/dropdowns/${props.dropdown}`, (e, r) => {
			setList(r.list)
			setSelected(r.list[0])
		})
	},[props.dropdown])
	let REMARKS = React.createRef()
	return (
		<Modal show={true}
			   des="Please select value from drop down"
			   title={props.title}
			   style={{
				   save: {
					   background: "var(--primary-color)",
					   border: "2px solid var(--primary-color)",
					   padding: '8px 15px',
					   width: '100%',
					   textAlign: 'center'
				   }
			   }}
			   close={() => {
				   props.close()
			   }}
			   onSave={() => {
				   if (selected) {
					   let value = selected;
					   if (selected === "Reason not listed") {
						   value = REMARKS.current.value;
					   }
					   if (value) {
						   props.onNext(value)
					   }
				   }
			   }}
			   buttonName={props.button}
		>
			<div className="light-group" style={{ marginBottom: 20 }}>
				<FloatSelect onChange={item => {
					setSelected(item)
				}} items={list.map(item => ({
					key: item,
					value: item
				}))} />
				{
					selected && selected === "Reason not listed" ?
						<FunctionalInput type="text" title="Enter Remarks" ref={REMARKS} /> : null
				}
			</div>
		</Modal>
	)
}


export {
	DropdownModal
}
