import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {TitleTopHeader} from '../../../../Header/TopHeader';
import Pending from './Pending'
import Completed from './Completed';
import Declined from './Declined';
import All from './All';
import Common from './Common';

class Index extends React.Component {


    render() {
        let route = this.props.location.pathname;
        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }}>
                <TitleTopHeader
                    title="All mail"
                    des="All your mail approval shown below"
                    items={[
                        {
                            name: 'All Invoice',
                            link: '/app/settings/plugins/mail/all',
                            active: route === '/app/settings/plugins/mail/all'
                        },
                        {
                            name: "Pending Invoice",
                            link: "/app/settings/plugins/mail",
                            active: route === "/app/settings/plugins/mail" || route.includes('/app/settings/plugins/mail/pending'),

                        },
                        {
                            name: "Completed Invoice",
                            link: "/app/settings/plugins/mail/completed",
                            active: route === "/app/settings/plugins/mail/completed" || route.includes('/app/settings/plugins/mail/completed'),
                        },
                        {
                            name: "Declined Invoice",
                            link: "/app/settings/plugins/mail/declined",
                            active: route === "/app/settings/plugins/mail/declined" || route.includes('/app/settings/plugins/mail/declined'),
                        },

                    ]}
                />
                <Switch>
                    <Route path="/app/apps/mail/all" component={All} exact />
                    <Route path="/app/apps/mail" component={Pending} exact />
                    <Route path="/app/apps/mail/completed" component={Completed} exact />
                    <Route path="/app/apps/mail/declined" component={Declined} exact />
                    <Route path="/app/apps/mail/:type/:id" component={Common} />

                </Switch>

            </div>
        )
    }
}


export default Index;
