import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import Terms from './Common/1mgCondigtions';
import Items from './Common/Item2';

class Index extends React.Component {






    confidentails = () => {
        return (
            <div style={{
                borderBottom: '1px solid var(--border-color)',
                display: 'flex',
            }}>
                <div style={{
                    flex: 2,
                    borderRight: '1px solid var(--border-color)',
                    padding: '10px 20px',

                }}>
                    <p style={{
                        fontSize: 16,
                        textTransform: 'uppercase',
                        fontWeight: 600
                    }}>
                        Confidentiality
                    </p>
                    <p style={{
                        textAlign: 'justify',
                        paddingTop: 10
                    }}>
                        All information that is revived or derived or accessed by the vendor and /or its skilled manpower shall remain
                        confidential and shall not be disclosed to any third party whatsover provided further that the vendor may disclose
                        such information in compliance of statutory and regulatory requirements o rin compliance with a judicial or regulatory directive
                        with the prior approval of official to the extent possible and allowed by law. The Vendor shall undertake to ensure that the data collected as part of the
                        service delivery shall be treated in confidentiality and the Vendor shall also undertak that the data will not be shared with any
                        third party and that the data will not be misappropriated, retained beyond the period that is defined for the delivery of
                        services
                    </p>
                </div>
                <div style={{
                    flex: 1
                }}>

                </div>
            </div>
        )
    }

    approve = () => {
        return (
            <>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    height: 80,
                    borderBottom: '1px solid var(--border-color)'
                }}>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <p></p>
                        <p style={{
                            marginTop: 30,
                            fontWeight: 600
                        }}>Prepared By</p>
                    </div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <p></p>
                        <p style={{
                            marginTop: 30,
                            fontWeight: 600
                        }}>Verified By</p>
                    </div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <p></p>
                        <p style={{
                            marginTop: 30,
                            fontWeight: 600
                        }}>Authorized By</p>
                    </div>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <p></p>
                        <p style={{
                            marginTop: 30,
                            fontWeight: 600
                        }}>Authorized By</p>
                    </div>
                </div>
                <div style={{
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 10,
                    fontSize: 16
                }}>
                    Please note that in case any  representative asks you for any kind of favour at the time of issuing the
                    purchase order or releasing the payment please contact Authorized official.
                </div>

            </>
        )
    }

    render() {
        let list = this.props.data;
        let { conditions, vendor, procured,billingTo } = this.props.data;
        return (
            <div>
                <div style={{
                    textAlign: 'right'
                }}>
                    <p style={{
                        fontWeight: 600,
                        color: 'var(--primary-color)',
                        cursor: 'pointer'
                    }} onClick={() => {
                        this.pdf.save();
                    }}>Download Now</p>
                </div>
                <PDFExport title="Order Document" padding="40" fileName={`Order-${list.orderId}.pdf`} ref={r => this.pdf = r}>
                    <div style={{
                        width: 400,
                        margin: '10px auto',
                        marginTop: 10,
                        marginBottom: 20,
                        textAlign: 'center'
                    }}>
                        <p style={{
                            fontSize: 26,
                            fontWeight: 800
                        }}>Purchase Order</p>
                        <p style={{
                            fontSize: 15,
                            fontWeight: 600
                        }}>PURPL</p>
                    </div>
                    <div style={{
                        border: '1px solid var(--border-color)'
                    }}>
                       

                        <div style={{
                            borderBottom: '1px solid var(--border-color)',
                            height: 100,
                            display: "flex",
                        }}>

                            <div style={{
                                borderRight: '1px solid var(--border-color)',
                                height: 100,
                                flex: 1,
                                padding: 10
                            }}>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>GSTIN</p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5

                                    }}>{vendor.gstin}</p>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>PO NO. </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}> {list.orderId}</p>

                                </div>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>Location</p>
                                    <p style ={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}> {billingTo.city}</p>
                                </div>



                            </div>
                            <div style={{
                                flex: 1,
                                padding: 10
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{

                                        width: 140,
                                        fontWeight: 600
                                    }}>Date of issue </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>{Moment(list.deliveryDate).format('DD-MM-YY')}</p>

                                </div>

                                <div style = {{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style = {{
                                        width : 140,
                                        fontWeight : 600
                                    }}>Ship To</p>
                                    <p style = {{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{procured[0].officeAddress}</p>
                                </div>
                            </div>

                        </div>
                        <div style={{
                            height: 200,
                            borderBottom: '1px solid var(--border-color)',
                            display: 'flex'
                        }}>
                            <div style={{
                                borderRight: '1px solid var(--border-color)',
                                flex: 1,
                                padding:10
                            }}>
                                {/* <Vendor vendor={vendor} /> */}
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Supplier Name</p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5

                                    }}>{vendor.name}</p>

                                </div>
                                <div style ={{
                                    display : 'flex',
                                    alignitems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>Address</p>
                                    <p style ={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>{vendor.address.address}</p>
                                </div>
                                <div style ={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>State</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft: 5
                                    }}>{vendor.address.city}</p>
                                </div>
                                <div style={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>State Code</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{vendor.address.pincode}</p>
                                </div>
                                <div style={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight: 600
                                    }}>GSTIN</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{vendor.address.gstin}</p>
                                </div>
                            </div>
                            <div style={{
                                flex: 1,
                                padding:10
                            }}>
                                
                                <div style={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight: 600
                                    }}>Name</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>TATA 1MG HEALTHCARE SOLUTIONS PRIVATE LIMITED</p>
                                </div>
                                <div style ={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style ={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>Address</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{billingTo.address}</p>
                                </div>
                                <div style={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>City</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{billingTo.city}</p>
                                </div>

                                <div style={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>State Code</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{billingTo.city}</p>
                                </div>
                                <div style={{
                                    display : 'flex',
                                    alignItems : 'center'
                                }}>
                                    <p style={{
                                        width : 140,
                                        fontWeight : 600
                                    }}>GSTIN</p>
                                    <p style={{
                                        flex : 1,
                                        marginLeft : 5
                                    }}>{billingTo.gstin}</p>
                                </div>
                            </div>
                        </div>
                      

                        <Items data={this.props.data} />
                        <Terms conditions={conditions} />
                        <p style={{
                            fontSize:14,
                            borderTop:'1px solid var(--border-color)',
                            padding:5
                        }}>This is a system generated Purchase Order and hence no Stamp & Signature is required.</p>
                    </div>
                </PDFExport>
            </div>
        )
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Index);
