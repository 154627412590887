import React from 'react';
import {useParams} from 'react-router-dom'
import { post } from '../../../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../../../Common/fToast/ToastCallback';

function Index(props) {

    let {appId} = useParams();

    const renderVersions = (item, index) => {

        return (
            <div key={index} className='CustomApp-card'>
                <div className='muted-text' style={{fontSize: '11px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span style={{fontSize: '28px', fontWeight: 'bold'}}>v{item.version.lastIndexOf('--')>-1?item.version.substring(item.version.lastIndexOf('--')+2):'0'}</span> <br/>
                        <div onClick={()=>publish(item.version)} className='deploy'>
                            Deploy
                        </div>
                    </div>
                    <div style={{marginTop: '20px', textTransform: 'uppercase', opacity: '0.5'}}>
                    UUID: {item.version}
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                    <div>
                        {
                        item.live?
                        <div style={{background: 'var(--green-color)', color: '#fff', display: 'inline-flex', marginTop: '15px', padding: '5px 10px', borderRadius: '10px'}}>
                            Active
                        </div>
                        :<></>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const publish = ( ver) => {
        post(`market/dashboard/${appId}/publish.version`, { version: ver}, (e,r)=>{
            if(r){
                props.reload();
                showToast({
                    type:'success',
                    message: 'Published'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem occured'
                })
            }
        })
    }

    return (
        <>
         <div style={{marginTop: '30px', maxHeight: '350px', overflow: 'scroll'}}>
            <span id="Login_Heading" style={{fontSize: '12px', fontWeight: 'bold'}}>Versions</span><br/>
                <div style={{marginTop: '20px', display: 'flex', flexWrap: 'wrap', marginLeft: '-8px'}}>
                    {
                        props.versions.map(renderVersions)
                    }
                </div>
        </div>
        </>
    )
}

export default Index;