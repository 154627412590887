import React from 'react';
import Props from 'prop-types';
import Portal from '../Portal';

function HalfModal(props) {
	return (
		<Portal>
			<div style={{
				position: 'fixed',
				zIndex: 110,
				right: 0,
				left: 0,
				top: 0,
				bottom: 0,
				backgroundColor: 'rgba(1,1,1,0.21)',
				...props.outerContainer
			}}>
				<div style={{
					zIndex: 111,
					boxShadow: "-10px 0 20px 0 rgba(123,147,180,.05)",
					right: 0,
					position: 'fixed',
					overflowY: 'auto',
					height: '100vh',
					top: 0,
					bottom: 0,
					left: 350,
					backgroundColor: 'var(--white-color)',
					...props.container
				}}>
					<div style={{
						position: 'relative'
					}}>

						<div style={{
							height: 75,
							background: 'var(--white-color)',
							display: 'flex',
							paddingTop: 25,
							marginBottom:25,
							...props.container
						}}>
							<div style={{
								padding: '10px 30px',
								flex: 1
							}}>
								<p style={{fontSize: 13, color: 'var(--light-color)'}}>{props.desc}</p>
								<p style={{fontWeight: 600, fontSize: 21, marginTop: 4}}>{props.title}</p>
							</div>

							<img alt="Close Button" onClick={() => {
								props.close()
							}} src={require('./close.png')} style={{
								margin: 22,
								cursor: 'pointer',
								width: 16,
								height: 16,
								marginRight: 30
							}}/>

						</div>
						<div style={{padding: '30px',borderTop:'1px solid var(--border-color)'}}>
							{props.children}
						</div>
					</div>
				</div>
				<div onClick={() => {
					props.close()
				}} style={{
					position: 'fixed',
					width: 60,
					left: 350,
					zIndex: 112,
					height: 60,
					cursor: 'pointer',
					background: "var(--white-color)",
					borderRadius: 30,
					marginLeft: -75,
					top: 'calc(50vh - 25px)',
					border: '1px solid var(--border-color)',
					boxShadow: '3px 2px 3px rgba(1,1,1,.015)',
					...props.container
				}}>
					<img alt="Close Button" src={require('./close.png')} style={{
						margin: 21,
						width: 18,
						height: 18,
					}}/>
				</div>
			</div>
		</Portal>
	)
}


HalfModal.propTypes = {
	close: Props.func
}


export {HalfModal}
