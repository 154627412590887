import React from 'react';

/*
PROPS:

crumbs = [string, .... n]
*/

function Breadcrumb(props) {
    const LEVELS = props.crumbs.length;
    const renderCrums = (item, index)=>{
        if(index<LEVELS-1){
            return (
                <>
                {item} 
                <svg style={{fontSize: '8px', margin: '0 7px 0 7px', transform: 'rotate(90deg)'}} className="bi bi-triangle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"/>
                </svg>
                </>
            )
        }else{
            return item
        }
    }

    return (<>
             <div className="page__hello h6" style={{display: 'flex', alignItems: 'center'}}>
                {props.crumbs.map(renderCrums)}
            </div>
    </>);
}

export default Breadcrumb;