import React from 'react';
import Moment from 'moment';
import { Status } from '../../../../../Common/fCore/fStatus';
import { Owner } from '../../../../../Common/fCore/fOwner';
import { KeyValueDetails } from '../../../../../Common/fCore/fKeyValue';


const Index = ({data}) => {



    let {po,grn,stock}=data;

    return(
        <div>
             <Status badges={[
				{
					title:`Item Name : ${data.name}`,
					background:'var(--primary-color)'
				},
				{
					title:`Quantity :  ${data.qty} ${data.stock.product.units}`,
					background:"var(--green-color)"
				},
               
                {
					title:`Tracking Type :  ${data.trackingType}`,
					background:"var(--green-color)"
				},

			]}/>
            <Owner owner={data.owner} />
            {
            po?
            <>
            <KeyValueDetails header="Purchase Order Details" values={[
				{
					key:'PO Number',
					value:po.poId
				},{
					key:'Created At',
					value:Moment(po.createdAt).format('DD-MM-YYYY')
				},{
					key:'Vendor Name',
					value:po.vendor.name
				},
				{
					key:'Vendor Gstin',
					value:po.vendor.registeredId
				},
				{
                    key:'Vendor Registered Id',
                    value:po.vendor.registeredId
                }
			]}/>
			
            </>
            :<div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
            }


             <KeyValueDetails header="Grn Details" values={[
				{
					key:'GRN Number',
					value:grn.reference
				},
                {
					key:'Created At',
					value:Moment(grn.createdAt).format('DD-MM-YYYY')
				},
                {
					key:'Delivery At',
					value:Moment(grn.deliverAt).format('DD-MM-YYYY')
				},
				
			]}/>    

            <KeyValueDetails header="Office Details" values={[
				{
					key:'Name',
					value:stock.office.name
				},
                {
					key:'Address',
					value:stock.office.address
				},
			]}/>           
           
         </div>
    )
}

export default Index;