import {addToastCallback, removeCallback} from "./ToastCallback";
import {useAlert} from "react-alert";
import React, {useEffect} from "react";


const ToastContainer = () => {
	const Alert = useAlert();
	useEffect(()=>{
		addToastCallback((data)=>{
			let type = data.type;
			let message = data.message;
			if(type && type==="success"){
				Alert.success(message)
			}else{
				Alert.error(message)
			}
		})
		return () => {
			removeCallback(undefined)
		}
	})
	return(
		<div/>
	)

}


export {
	ToastContainer
}
