import React from 'react'
import ToggleSwitch from "../../../Common/fSwitch";
import {post} from "../../../../Model/Network/Config/Axios";
import {useSelector} from "react-redux";
import {refreshConfig} from "../../../../Controller/ConfigRefresher";

export default function Render(props) {
	let {config} = useSelector((state => ({config: state.auth.config})));
	let toggle = () => {
		post('/auth/config', {
			plugin: 'inject'
		}, (e, r) => {
			if (r) {
				refreshConfig()
				props.reload()
			}
		})
	}

	return (
		<div style={{
			position:'absolute',
			top:120,
			right:40,
			zIndex:31
		}}>
			<ToggleSwitch callback={toggle} status={config.plugins.developmentInject}/>
		</div>
	)
}
