import React from 'react';
import Header from './Header';
import Vendor from './Vendor';
import Buyer from './Buyer';
import Items from './Items'
import Address from './Address';
import {PDFExport} from "@progress/kendo-react-pdf";
import OtherTerm from './OtherTerms';

class IIFL extends React.Component {

    render() {
        let {data} = this.props;
        return(
            <>
            <div style={{
                display:'flex',
                justifyContent:'space-between',
                marginBottom:15
            }}>
                <img src="https://static.eka.io/v2/receipts/2023/02/bb39079e-8e34-4afb-89b2-1d8133b0a5e7.png" alt="" style={{width:120,height:50}} />
                <div style={{
                    cursor:'pointer',
                    color:'var(--primary-color)',
                    fontSize:16
                }} onClick={()=>{
                    this.pdf.save()
                }}>Download</div>
            </div>
           <PDFExport title="Order Document" padding="40" fileName={`Order-${data.orderId}.pdf`} ref={r => this.pdf = r}>
            <div style={{border:'1px solid var(--border-color)'}}>
                <Header data={data} />
                <div style={{flex:1}}>
                    <Buyer data={data} />
                </div>    
                <div style={{minHeight:200,borderRight:'1px solid var(--border-color)',flex:1}} >
                    <Vendor vendor={data.vendor} />
                </div>
                <Items data={data} />
                <Address data={data} />
                <OtherTerm data={data} />
            </div>
          </PDFExport>
            </>
        )
    }
}

export default IIFL;