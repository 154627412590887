import React, {useEffect} from 'react'
import Back from "../Login/back.jpg";
import Spinner from "react-spinner-material";
import {useLocation,Redirect} from 'react-router-dom'
import {post} from "../../Model/Network/Config/Axios";
import * as Crypto from "../../Utils/Crypto";
import Moment from "moment";
import {AuthActions} from "../../Redux/Reducers/Auth";
import {useDispatch, useSelector} from "react-redux";

export default function Index() {
	const {search} = useLocation()
	const dispatch = useDispatch()
	let {config} = useSelector((state => ({config: state.auth})));
	useEffect(()=>{
		const params = new URLSearchParams(search);
		let data = {
			code : params.get("code"),
			type : params.get("grant_type"),
			baseUrl : params.get("apiOrigin")
		}
		post(`/auth/authorize`,data,(e,r)=>{
			if(r){
				Crypto.set('token', r.access_token);
				Crypto.set('refresh_token', r.refresh_token);
				Crypto.set("lastlogin", Moment().valueOf())
				Crypto.set("loggedin", "yes");
				dispatch({
					type: AuthActions.Login,
					data: {
						name: data.name,
						login: Moment()
					}
				})
			}
		})
	},[])
	if (config.authenticated) {
		return  (
			<Redirect to="/app/organisation/offices"/>
		)
	}
	return (
		<div style={{
			position: 'relative',
			background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)) center / cover no-repeat, url('${Back}') center / cover no-repeat`,
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<div className="FadeRight-Effect" style={{
				boxShadow:'rgba(0, 0, 0, 0.25) 0px 0.25rem 0.25rem',
				background:'rgba(235,235,235,0.9',
				backdropFilter: 'blur(10px)',
				zIndex:2,
				borderRadius:12,
				padding:'55px 45px',
			}}>
				<Spinner radius={36} visible={true} color="var(--primary-color)"/>
			</div>
		</div>
	)
}
