import React, { useRef, useState } from 'react';
import { useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { post } from '../../../../../../Model/Network/Config/Axios';
import { EmployeeSearchable } from '../../../../../Common/Class/Select/EmployeeSearchable';
import { SearchableSelect } from '../../../../../Common/Class/Select/SearchableSelec';
import { FunctionalInput } from '../../../../../Common/fInputs';
import { Modal } from '../../../../../Common/fModal/Modal';
import {Title as Header} from "../../../../../Header/TopHeader";
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import { KeyValue } from '../../../../../Common/Components/KeyValue';
import { ReadOnly } from '../../../../../Common/fCard/ButtonOutline/ReadOnly';



const Index = (props) => {

    let {paymentData} = props.data;

    const {budgets} = useSelector(state=>state.auth.config.plugins)
    const [add,setAdd] = useState(false);
    const [type,setType] = useState({value:'ANNUAL',label:'ANNUAL'});
    const [employee,setEmployee] = useState(undefined);
    let {id} = useParams();

    let  THERSOLD = useRef(),PODAYS=useRef(),POPERCENTAGE=useRef(),POADVANCEDAYS=useRef(),POADVANCEPERCENTAGE=useRef();


    let getStatus = () => {
        return props.data.status;
    }

    if(!budgets) {
        return <></>
    }

    return(
        <div>
           <div style={{padding:'0px 20px'}}>
                <Header container={true} id="terms" title="Payment Terms"
                       des="All Payment Conditions are visible below.">
                        {
                       getStatus() === 'DRAFT' ?
                           <div style={{
                               position: 'absolute',
                               right: 12,
                               top: 20
                           }}>
                           <ReadOnly>
                            <p onClick={() => {
                                   setAdd(true);
                               }} style={{
                                   color: "var(--primary-color)",
                                   cursor: 'pointer',
                                   fontWeight: 600,
                                   fontSize: 14
                               }}>
                                    + Add Term
                               </p>
                           </ReadOnly>
                           </div> : null
                   }
                </Header>
            </div>
            <div>

            </div>
            <div className="input-container">
                <KeyValue title='Payment Frequency' value={paymentData.paymentFrequency} />
                <KeyValue title='Payment Thresold' value={paymentData.paymentThreshold} />

            </div>
            <p></p>
            <div style={{display:'flex',flexWrap:'wrap'}}>
                {
                    paymentData.paymentTerms.map((val,index)=>{
                        return(
                           <div title={`Terms ${index+1}`} style={{width:'30%',border:'1px solid var(--border-color)',borderRadius:12,padding:15,margin:10,background:"#fff",display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <KeyValue title='Payment Terms Days' value={val.days} style={{marginTop:10}} />
                                <KeyValue title='Payment Terms Percentage' value={val.percentage} />
                                <KeyValue title='Advance Payment Terms Days' value={paymentData.advancePaymentTerms[index].days} />
                                <KeyValue title='Payment Frequency' value={paymentData.advancePaymentTerms[index].percentage} />
                           </div>

                        )
                    })
                }
            </div>

            {
                add ?
                <Modal
                    title="Add Terms"
                    des="Add Payment Terms in PO"
                    show={true}
                    close={()=>{
                        setAdd(false);
                    }}
                    buttonName="Add"
                    onSave={()=>{

                   
                      let threshold = THERSOLD.current.value;
                      let poDays = PODAYS.current.value;
                      let poPercentage = POPERCENTAGE.current.value;
                      let poAdvanceDays = POADVANCEDAYS.current.value;
                      let poAdvancePercentage = POADVANCEPERCENTAGE.current.value;




                      let data = {
                        threshold:+threshold,
                        frequency:type.value,
                        poPaymentTerm:{
                            days:+poDays,
                            percentage:+poPercentage
                        },
                        advancePoPaymentTerm:{
                            days:+poAdvanceDays,
                            percentage:+poAdvancePercentage
                        },
                        alertUsers:[]
                      }

                      if(poDays || poPercentage) {
                        if(poDays) {
                            data.poPaymentTerm={
                                ...data.poPaymentTerm,
                                days:+poDays
                            }
                        }

                        if(poPercentage) {
                            data.poPaymentTerm={
                                ...data.poPaymentTerm,
                                percentage:+poPercentage
                            }
                          }
                      
                      }

                      if(poAdvanceDays || poAdvancePercentage) {
                        if(poAdvanceDays) {
                            data.advancePoPaymentTerm={
                                ...data.advancePoPaymentTerm,
                                days:+poAdvanceDays
                            }
                        }

                        if(poAdvancePercentage) {
                            data.advancePoPaymentTerm={
                                ...data.advancePoPaymentTerm,
                                percentage:+poAdvancePercentage
                            }
                          }
                      
                      }

                     



                      if(employee) {
                        data.alertUsers = [employee.id]
                      }

                      post(`vendor/po/requests/${id}/postPaymentData`,data,(e,r)=>{
                        if(r) {
                            props.init();
                            showToast({
                                type:'success',
                                message:"Payment Term posted successfully"
                            })
                            setAdd(false);
                        }
                      })
                  
                    }}
                >
                    <p style={{fontSize:10,color:'var(--light-color)',fontWeight:400,marginTop:20,marginBottom:10,textTransform:'uppercase'}}>Select Period Type</p>
                    <SearchableSelect
                        value={type}
                        title="Select Type"
                        label="Period"
                        select={setType}
                        items={
                            [
                                {
                                    label:'Annual',
                                    value:'ANNUAL'
                                },
                                {
                                    label:'oneTime',
                                    value:'ONETIME'
                                },
                                {
                                    label:'Half Yearly',
                                    value:'SEMIANNUAL'
                                },
                                {
                                    label:'Quaterly',
                                    value:'QUARTERLY'
                                },
                                {   
                                    label:'Monthly',
                                    value:'MONTHLY'
                                },
                                {   
                                    label:'Weekly',
                                    value:'WEEKLY'
                                }
                            ]
                        }
                    />
                    <FunctionalInput ref={THERSOLD} type="number" title="Thershold" />
                    <EmployeeSearchable employee={employee} onSelect={setEmployee} />
                    <FunctionalInput ref={PODAYS} title="Enter Number of Days(PO payment term)" />
                    <FunctionalInput ref={POPERCENTAGE} title="Enter Percentage (PO payment percentage)" />
                    <FunctionalInput ref={POADVANCEDAYS} title="Enter Number of Days (Advance PO payment term)" />
                    <FunctionalInput ref={POADVANCEPERCENTAGE} title="Enter Percentage (Advance PO payment term)" />


                </Modal>
                :null
            }
        </div>
    )
}

export default Index;