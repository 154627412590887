import {get} from "../Model/Network/Config/Axios";

let AllPositions = undefined;

const initPositions = () => {
	get(`/settings/config/positions/all`, (e, r) => {
		if (r) {
			if (r) {
				AllPositions = r;
			}
		}
	})
}

const fetchAmounts = () => [
	0, 1, 2, 3, 4, 5, 6
]

const fetchPositions = cb => {
	if (AllPositions) {
		cb(AllPositions)
	} else {
		get(`/settings/config/positions/all`, (e, r) => {
			if (r) {
				if (r) {
					AllPositions = r;
					cb(AllPositions)
				}
			}
		})
	}
}

const fetchSequenceTypes = () => [
	"SEQUENCE", "PARALLEL"
]

const fetchSequenceAnswer = () => (
	{

		SEQUENCE: "Sequence Permissions",
		PARALLEL: "Parallel Permissions"

	}
)

const fetchAnswers = () => ({
	"0": "All approvals required",
	"1": "One approval required",
	"2": "Two approvals required",
	"3": "Three approvals Required",
	"4": "Four approvals required",
	"5": "Five approvals required",
	"6": "Six approvals required",
}
)

export {
	fetchPositions, initPositions, fetchAmounts, fetchAnswers, fetchSequenceTypes, fetchSequenceAnswer
}
