import React from 'react';


const Index = (props) => {

        let details = (key,value) => {
            return(
                <div style={{
                    display:'flex',
                    flex:1,
                    borderBottom:'1px solid var(--border-color)',
                    fontSize:13
                }}>
                    <div style={{
                        width:100,
                        textAlign:'center',
                        padding:'10px 0px',

                    }}>
                        {key}
                    </div>
                    <div style={{flex:1,textAlign:'center',padding:'10px 0px',borderLeft:'1px solid var(--border-color)'}}>
                        {value}
                    </div>
                </div>
            )
        }
        let { data } = props;
        return (
            <div>
                <p style={{
                    fontWeight: 600,
                    textAlign:'center',
                    borderBottom:'1px solid var(--border-color)',
                    padding:'10px 0px'
                }}>Buyer Details</p>
                {details('Delivery Address','As per Annexure')}
                {details('Billing Address','As per Annexure')}
                {details('Send Paper Invoice to','As per Annexure')}
                {details('GSTIN',data.details.officeGstin)}
                {details('Mobile','-')}
                {details('Email','-')}
            </div>
        )
}

export default Index;