import React from 'react';
import Moment from 'moment';
import {KeyValue} from '../../../../Common/Components/KeyValue';
import {Currency} from '../../../../../Utils/Currency';


class Expand extends React.Component {


    render() {
        let { eventRequest } = this.props.data;
        if (typeof eventRequest === 'string') {
            eventRequest = JSON.parse(eventRequest);
        }
        return (
            <div>
                {this.props.children}
                <div className="expanded-item" style={{
                    padding: '20px 25px'
                }}>

                    <div style={{
                        flex: 4,
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Branch Gstin" value={`${eventRequest.branchGSTIN}`} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Submission Date" value={`${Moment(eventRequest.submissionDate).format('DD-MM-YYYY')}`} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Invoice Submission Date" value={`${eventRequest.invoiceSubmissionDate}`} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Vendor Invoice Date" value={`${eventRequest.vendorInvoiceDate}`} />
                        </div>
                    </div>

                    <div style={{
                        flex: 4,
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: 10
                    }}>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Total Amount" value={<p><Currency /> {eventRequest.totalAmt}</p>} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Total Service Amount" value={<p><Currency /> {eventRequest.totalServiceAmt}</p>} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Vendor Code" value={`${eventRequest.vendorCode}`} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <KeyValue title="Vendor Gstin" value={`${eventRequest.vendorGSTIN}`} />
                        </div>
                    </div>

                    <div style={{
                        flex: 1,
                        marginTop: 10
                    }}>
                        <KeyValue title="Error Response" value={this.props.data.response} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Expand;
