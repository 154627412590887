import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useDateFormat = (defaultFormate) => {
  const [formattedDate, setFormattedDate] = useState('');
  const config  = useSelector(state => state.auth.config);

  useEffect(() => {
    let configFormat = config.customDateFormat
    if (configFormat) {
      setFormattedDate(config.customDateFormat);
    } else {
        setFormattedDate(defaultFormate ? defaultFormate : 'MM-DD-YYYY')
    }
  }, [config]);

  return formattedDate;
};

export default useDateFormat;
