import React, {useEffect, useRef, useState} from 'react';
import { SidePane } from '../../../../../Common/fSidePane/RightPane';
import {get} from '../../../../../../Model/Network/Config/Axios'
import moment from 'moment';
import Track from "../../../../../Common/fTrack";
import { Spinner } from '../../../../../Common/fLoader';
import {PDFExport} from '@progress/kendo-react-pdf';
import PhotoTile from '../../../../../Common/fPhoto';
import Empty from '../../../../../Common/fCard/Empty';
// import { showToast } from '../../../../../Common/fToast/ToastCallback';

const Index = (props) => {

    const [Loading, setLoading] = useState(true);
    const [Details, setDetails] = useState(undefined);
    let PDF=useRef();

    useEffect(() => {
        get(`vendor/procurement/grn/${props.id}/details `, (e, r) => {
			if (r) {
				setDetails(r);
				setLoading(false);
			}
		})
    }, [props.id]);
    
if(Loading) return <Spinner/>
    return(
            <SidePane button={false} onClose={()=>props.onClose()}>
                <div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
                    <div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}} >GRN Details</div>
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <div onClick={()=>{PDF.current.save()}} style={{width:130,textAlign:'center'}} className='btn btn-portlet'>
                             Download Pdf
                         </div>
                         {/* <div className='btn btn-danger' style={{width:130,textAlign:'center',marginLeft:15}} onClick={()=>{
                            post(`vendor/procurement/grn/${props.id}/withdraw`,{force:true,remarks:'wrong qtty'},(e,r)=>{
                                if(r) {
                                    showToast({
                                        type:'success',
                                        message:"GRN withdraw successfully"
                                    })
                                } else {
                                    showToast({
                                        type:'error',
                                        message:e.data.message
                                    })
                                }
                            })
                         }}>
                            Withdraw
                        </div> */}
                    </div>
                    <PDFExport margin={20} fileName={Details.attrs.reference}  ref={PDF}>
                      <div style={{color: 'var(--text-color)',marginBottom: '35px', fontWeight: 600, fontSize: '24px', lineHeight: '1.3'}}>{Details.attrs.reference}</div>
                        <div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '20px'}}>
                            <div style={{display: 'flex',justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{fontSize: '14px', fontWeight: 'bold'}}>Details</div>
                                <div style={{ padding: '4px 8px',borderRadius: '5px',fontSize: '11px',color: '#fff',display: 'inline-flex',marginTop: '5px', background: Details.status==='COMPLETED'?'#198754':'#0d6efd'}}>STAGE: {Details.attrs.stage}</div>
                            </div>
                            <div style={{display: 'flex',justifyContent: 'flex-end', alignItems: 'center'}}>
                            <div style={{ padding: '4px 8px',borderRadius: '5px',fontSize: '11px',color: '#fff',display: 'inline-flex',marginTop: '5px',justifyContent:'flex-end',  background: Details.status==='COMPLETED'?'#198754':'#0d6efd'}}>Status: {Details.status}</div>
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>ID:</span> {Details.attrs.reference}</span><br/>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>Partial: </span> {Details.attrs.partial}</span><br/>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>Amends:</span> {Details.attrs.amend}</span><br/>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>Owner:</span> {Details.owner.ownerName} ({Details.owner.ownerType})</span><br/>
                                {Object.keys(Details.attrs).length && Object.keys(Details.attrs).map((key) =>(
                                        <>
                                        <span style={{marginRight: '3px'}}>
                                        <span style={{color: 'var(--light-color)'}}>{key}:</span>
                                       {Details.attrs[key]}
                                      </span><br/>
                                      </>
                                ))}
                            </div>
                        </div>

                        <div style={{fontSize: '14px', marginTop: '40px', fontWeight: 'bold'}}>GRN Items</div>
                        <table style={{border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px',width: '100%'}}>
                            <tr style={{background: 'var(--lite-color)', textAlign: 'left'}}>
                                <th style={{padding: '10px'}}>Name</th>
                                <th style={{padding: '10px'}}>Delivery Address</th>
                                <th style={{padding: '10px'}}>Qty</th>
                            </tr>
                            {   Details.items && Details.items.length?
                                Details.items.map(row=>{
                                    return (
                                        <tr>
                                            <td style={{padding: '10px'}}>
                                                {row.name} <br/>
                                               Purchase Order Id : {row.orderId}
                                                </td>
                                            <td style={{padding: '10px'}}>{row.address}</td>
                                            <td style={{padding: '10px'}}>{row.qtty}</td>
                                        </tr>
                                    )
                                })
                                :<tr>
                                    <td style={{padding: '10px', textAlign: 'center'}} colSpan={3}>No Items</td>
                                </tr>
                            }
                        </table>
                        <p style={{ marginTop: 20, fontWeight: 'bold', fontSize: 13 }}>All Attachment</p>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                            {
                                Details.documents && Details.documents.map((item, index) => {
                                    return (
                                        <PhotoTile key={index} link={item} />
                                    )
                                })
                            }
                            {
                                Details.documents && Details.documents.length === 0 ?
                                    <Empty />
                                    : null
                            }
                        </div>
                        <div style={{fontSize: '14px', fontWeight: 'bold', marginTop: '40px'}}>Timeline</div>
                        <Track stages={
                                [...Details.timelines.map((log) => {

                                    let DESC = <div><b>{log.name}</b><br/>
                                        <div style={{marginTop: '5px'}}>Type: {log.type}</div>
                                        <div style={{marginTop: '5px'}}>Status: {log.status}</div>

                                        <div style={{marginTop: '5px'}}>Sent
                                            on: {moment(log.time).format("hh:mm a DD MMM YY")}
                                            <br/>{log.actionTime ? `Approved on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
                                        </div>
                                    </div>

                                    return (
                                        {
                                            title: log.email,
                                            desc: DESC,
                                            status: log.type === 'start' ? true : log.status ? log.status === 'COMPLETED' || log.status === 'CANCELLED' : log.name === 'Finance Action'? true : false
                                        })
                                })]
                            }/>
                        <div style={{ height: '50px' }}></div>
                    </PDFExport>
                </div>
            </SidePane>
    )
}

export default Index;