import React from 'react';
import { connect } from 'react-redux';
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from 'moment';
import Items from '../Common/Items';

class Dtdc extends React.Component {

    items = (procured) => {
        return (
            <div>
                <div style={{
                    height: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <p style={{
                        paddingLeft: 10,
                        fontWeight: 600
                    }}>Please provide the following materials</p>

                </div>

                <div>
                    <Items items={procured} currency={this.props.data.currency} />
                </div>
            </div>
        )
    }

    render() {
        let list = this.props.data;
        let { vendor, billingTo, procured, } = this.props.data;
        let totalAmt = 0
        let totalBase = 0
        let cgst=0,sgst=0,igst=0,tax=0;
        procured && procured.length && procured.forEach(element => {
            totalBase += element.base
            totalAmt += element.amount
            tax += element.tax
        });
	if (vendor && vendor.gstin && vendor.gstin.length > 2 && billingTo && billingTo.gstin && billingTo.gstin.length > 2) {
        if (vendor.gstin.slice(0, 2) === billingTo.gstin.slice(0, 2)) {
            cgst = tax / 2;
            sgst = tax / 2;
        } else {
            igst = tax;
        }
    } else {
        cgst = tax / 2;
        sgst = tax / 2;
    }

    let approvers = '';
    if (list && list.timelines && list.timelines.length > 0) {
        approvers = [...new Set(list.timelines.filter(item => item.email === "Approver").map(item => item.name))].toString();
    }

        return (
            <div style={{ maxWidth: '800px', margin: 'auto' }}>
                <div>
                    <div style={{ textAlign: "right" }} >
                        <p
                            style={{
                                fontWeight: 600,
                                color: "var(--primary-color)",
                                cursor: "pointer",
                            }}
                            onClick={() => { this.pdf.save() }}
                        >
                            Download Now
                        </p>
                    </div>
                    <PDFExport
                        title="Order Document"
                        padding="50"
                        fileName={`Order-${list.orderId}.pdf`}
                        ref={(r) => (this.pdf = r)}
                    >
                        <div style={{ padding: '20px' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src="https://static.eka.io/v2/receipts/2023/01/27307c20-b2d6-422b-9922-6ddc5565cc8a.png" alt="" style={{ height: '120px', width: '250px', }} />
                                    </div>
                                    <p style={{ textAlign: 'center' }}>PURCHASE ORDER</p>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <h3>DTDC Express Limited</h3>
                                    <p>{billingTo && billingTo.address}</p>
                                    <strong>GSTIN: {(billingTo && billingTo.gstin)||''}</strong>
                                </div>
                            </div>

                            {/* Body */}
                            <div style={{ maxWidth: '900px', margin: 'auto', marginBottom: '30px', marginTop: '20px', fontFamily: 'Open Sans, Helvetica Neue, source-sans-3, source-sans-pro, sans-serif' }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" style={{ border: '1px solid #000', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'start' }}>
                                                <h4 style={{ margin: '5px 0px' }}>TO,</h4>
                                                <p style={{ margin: '5px 0px' }}>Vendor Code {vendor.vendorId}</p>
                                                <p style={{ margin: '5px 0px' }}>GST Number {vendor.gstin}</p>
                                                <p style={{ margin: '5px 0px' }}>Name {vendor.name}</p>
                                                <p style={{ margin: '5px 0px' }}>{vendor.address ? vendor.address.address : '-'}</p>
                                                <p style={{ margin: '5px 0px' }}>{vendor.address ? vendor.address.city : "-"} {vendor.address && vendor.address.pincode}</p>
                                            </td>
                                            <td colspan="6" style={{ border: 'none',}}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ border: 'solid 1px #000', flex: 1, paddingLeft: '5px', color: '#303c42', fontSize: '12px', padding: '10px', textAlign: 'left' }}>
                                                        Order No: {list.orderId}
                                                    </div>
                                                </div>
                                                <div style={{ border: 'solid 1px #000', flex: 1, paddingLeft: '5px', color: '#303c42', fontSize: '12px', padding: '10px', textAlign: 'left' }}>
                                                    Created Date:  {moment(procured[0].createdAt).format('DD-MM-YYYY')}
                                              </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ border: 'solid 1px #000', flex: 1, paddingLeft: '5px', fontSize: '12px', padding: '10px', textAlign: 'left' }}>
                                                        Version No: {vendor.version}
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ border: 'solid 1px #000', flex: 1, paddingLeft: '5px', fontSize: '12px', padding: '10px', textAlign: 'left' }}>
                                                        Our Enquiry Ref:
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ border: 'solid 1px #000', flex: 1, paddingLeft: '5px', fontSize: '12px', padding: '10px', textAlign: 'left' }}>
                                                        <div>Requisitioner </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ border: 'solid 1px #000', flex: 1, paddingLeft: '5px', fontSize: '12px', padding: '10px', textAlign: 'left' }}>
                                                        <div>Your Quotation Ref: </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={12} style={{ border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'start', justifyContent: 'space-between' }}>
                                                <p>Please supply the below mentioned materials as per the instructions. </p>
                                                <p>Currency: INR </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 700,width:40, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}>Sl no</p>
                                            </td>
                                            <td colSpan={5} style={{ fontWeight: 700, width:100,border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}> Material Code</p>
                                            </td>
                                            <td style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}>Material/Service Description</p>
                                            </td>
                                            <td style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}>UOM</p>
                                            </td>
                                            <td style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}> Quantity</p>
                                            </td>
                                            <td style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}>GST</p>
                                            </td>
                                            <td style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}> Unit Price/Qty</p>
                                            </td>
                                            <td style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{ margin: '5px 0px' }}> Value</p>
                                            </td>
                                        </tr>
                                        {procured.map((item, index) => (
                                            <tr key={index}>
                                                <td colSpan={1} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{index + 1}</td>
                                                <td colSpan={5} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', fontSize: '10px' }}>{item.productCode}</td>
                                                <td style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>
                                                    {item.description} <br/>
                                                    HSN Code {item.hsn}
                                                    </td>
                                                <td style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.unit}</td>
                                                <td style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.qtty}</td>
                                                <td style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.taxSlab}</td>
                                                <td style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.price} / 1 </td>
                                                <td style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.amount}</td>
                                            </tr>
                                        ))}
                                        <tr style={{ height: '10px' }}></tr>
                                        <tr>
                                            <td colSpan={2} style={{ fontWeight: 700, border: '0.5px solid #fff', color: '#303c42', fontSize: '12px', paddingLeft: '4px', paddingRight: '5px', textAlign: 'left' }}>
                                                <p>Terms & Conditions.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8} style={{ textAlign: 'left', fontSize: '12px' }}>
                                                <ol>
                                                    <li>Order acknowledgement shall be sent by you within two days, else it will be deemed as acceptance of this purchase order.</li>
                                                    <li>Your bills should comply with GST rules and should be complete in all respects.</li>
                                                    <li>Materials/Services are liable to be rejected if do not comply with approved/agreed specifications and quantity.</li>
                                                    <li>While acceptance of materials/services we follow standard sample inspection
                                                        method. In case of supplied materials/services are found to be defective at the end user
                                                        level, created adverse effects, the cost of materials/services and cascading losses as per
                                                        discretion of the management will be debited to you.</li>
                                                    <li>Company is not liable to return the rejected materials like bar-coded cnote / stickers,
                                                        exclusively designed items for our company use as those would get mis-used.</li>
                                                    <li>You shall upload your invoices in GSTN portal within stipulated time to enable us to
                                                        avail input credit failing which you will be paid only the basic value. You are obligated
                                                        to issue a credit note for the GST amount and input credit loss of DTDC charged by you
                                                        or penalty imposed on DTDC due to claiming of input credit due to either -
                                                        non-payment of GST by you or errors in uploading information to GSTN by you. You
                                                        also agree to honour and pay penalty amount imposed by DTDC via a taxable invoice
                                                        relating to the aforementioned acts committed by you.</li>
                                                    <li>In the event of abnormal delays in delivery of materials/services, DTDC reserves the
                                                        right of imposing penalty as per company discretion.</li>
                                                </ol>
                                            </td>
                                            <td colSpan={4} style={{ verticalAlign: 'top' }}>
                                                <p style={{ fontSize: '12px', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>BASIC:</span>
                                                    <span style={{ textAlign: 'right' }}>{totalBase}</span>
                                                </p>  
                                                <p style={{ fontSize: '12px', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>CGST:</span>
                                                    <span style={{ textAlign: 'right' }}>{cgst.toFixed(2)}</span>
                                                </p> 
                                                <p style={{ fontSize: '12px', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>SGST:</span>
                                                    <span style={{ textAlign: 'right' }}>{sgst.toFixed(2)}</span>
                                                </p> 
                                                <p style={{ fontSize: '12px', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>IGST:</span>
                                                    <span style={{ textAlign: 'right' }}>{igst.toFixed(2)}</span>
                                                </p>                            
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={12} style={{ textAlign: 'end' }}>
                                                <p style={{ fontSize: '12px' }}>TOTAL: {totalAmt}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} style={{ height: '100px', verticalAlign: 'top', textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', fontWeight: 800, paddingLeft: '5px' }}>Packing Instruction: </p>
                                            </td>
                                            <td colSpan={4} style={{ verticalAlign: 'top', textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', fontWeight: 800, paddingLeft: '5px' }}>Special Instructions if any:</p>
                                            </td>
                                            <td colSpan={4} style={{ verticalAlign: 'top', textAlign: 'left' }}>
                                                <p style={{ fontSize: '12px', fontWeight: 800, paddingLeft: '5px' }}>Amount in words:</p>
                                                <p style={{ fontSize: '12px', paddingLeft: '5px' }}>Two thousand rupees Only</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8} style={{ verticalAlign: 'top' }}>
                                                <p style={{ fontSize: '12px', fontWeight: 800, paddingLeft: '5px', textAlign: 'left' }}>Payment terms:
                                                 
                                                </p><br />
                                                {list && list.payments.length ? list.payments.map((item) =>(
                                                    <div>   
                                                        {/* {item.content &&
                                                            <div dangerouslySetInnerHTML={{ __html: `${item.content}` }} style={{
                                                                textAlign: 'left', fontSize: '11px',
                                                                fontWeight:500,
                                                                color: 'var(--light-color)'
                                                            }}>
                                                        </div>} */}
                                                     <p className="small-text" style={{ textAlign: 'left', fontSize: '11px', }}>{item.title}</p>
                                                    </div>
                                                ))
                                                : null }
                                            </td>
                                            <td colSpan={4} style={{ height: '100px', verticalAlign: 'top' }}>
                                                <p style={{ fontSize: '12px', fontWeight: 800, textAlign: 'left' }}>Approvers: </p>
                                                <p style={{ textAlign: 'left', marginTop: '50px', paddingRight: '10px' }}>
                                                    {approvers}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr style={{ height: '10px' }}></tr>
                                        <tr>
                                            <td colSpan={12} style={{ border: 'none', textAlign: 'left' }}>
                                                Purchase order Number: {this.props.data && this.props.data.orderId}
                                            </td>
                                        </tr>
                                        <tr style={{ height: '10px' }}></tr>
                                        <tr>
                                            <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                                                <p style={{ fontWeight: 800, fontSize: '12px' }}>Billing Address & GSTIN </p>
                                                <p></p>
                                            </td>
                                            <td colSpan={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
                                                <p style={{ fontWeight: 800, fontSize: '12px' }}>Delivery/Shipping Address</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={6}>
                                                <p style={{ fontWeight: 800, fontSize: '12px' }}>{"DTDC Express Limited"}</p>
                                                <p style={{ margin: '5px 0px', fontSize: '12px', textAlign: 'center' }}>{billingTo && billingTo.address}</p>
                                                <p style={{ margin: '5px 0px', fontSize: '12px' }}>City:{billingTo && billingTo.city}</p><br />
                                                {/* <p  style={{ margin: '5px 0px' }}>Tel No : 91-80-2536503291-80 </p><br /> */}
                                                <p style={{ margin: '5px 0px', fontSize: '12px' }}>GST No: {billingTo && billingTo.gstin}</p><br />
                                            </td>
                                            <td colSpan={6}>
                                                <p style={{ fontSize: '12px', fontWeight: 800 }}>{"DTDC Express Limited"}</p>
                                                <p style={{ fontSize: '12px', textAlign: 'center' }}>{procured.length ? procured[0].officeAddress : '-'}</p>
                                                <p style={{ margin: '5px 0px', fontSize: '12px' }}>City: {procured.length ? procured[0].officeCity : '-'}</p><br />
                                                <p style={{ margin: '5px 0px', fontSize: '12px' }}>GSTIN: {procured.length ? procured[0].officeGstin : '-'}</p><br />
                                                {/* <p  style={{ margin: '5px 0px',fontSize:'12px'  }}>Tel No : 91-80-2536503291-80 </p><br /> */}
                                            </td>
                                        </tr>
                                        <tr style={{ height: '10px' }}></tr>
                                        <tr>
                                            <td colSpan={12} style={{ textAlign: 'center', border: 'none' }}>DELIVERY SCHEDULE</td>
                                        </tr>
                                        <tr style={{ height: '10px' }}></tr>
                                        <tr>
                                            <td  colSpan={1} style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{}}>Sno</p>
                                            </td>
                                            <td colSpan={4} style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{}}> Material Code</p>
                                            </td>
                                            <td colSpan={3}  style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{}}>Material Description</p>
                                            </td>
                                            <td  colSpan={2}  style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{}}>Delivery / Start Date</p>
                                            </td>
                                            <td  colSpan={2}  style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{}}>End Date</p>
                                            </td>
                                            <td colSpan={2}  style={{ fontWeight: 700, border: '0.5px solid #e7e7e7', color: '#303c42', fontSize: '12px', paddingLeft: '4px', textAlign: 'center' }}>
                                                <p style={{}}> Quantity</p>
                                            </td>
                                        </tr>
                                        {procured.map((item, index) => (
                                            <tr key={index}>
                                                <td colSpan={1} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{index + 1}</td>
                                                <td colSpan={4} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', fontSize: '10px' }}>{item.productCode}</td>
                                                <td colSpan={3} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>
                                                    {item.description} <br/>
                                                    HSN Code {item.hsn}
                                                </td>
                                                <td colSpan={2} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.deliveryDate ? moment(item.deliveryDate).format("DD-MM-YYYY") : 'Na'}</td>
                                                <td colSpan={2} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{list.endDate ? moment(list.endDate).format('DD-MM-YYYY') : 'Na'}</td>
                                                <td colSpan={2} style={{ border: 'solid 0.5px #e7e7e7', padding: '5px', textAlign: 'center', fontSize: '10px' }}>{item.qtty} / 1 </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>

                            {/* Footer */}
                            <footer>
                                <div style={{ fontSize: '12px', textAlign: 'center', color: '#000', marginTop: '100px' }}>
                                    <p>This is a system-generated purchase order and does not require any physical signature</p>
                                    <p>------------------------------------------------------------------------ ------------------------------------------------------------------------</p>
                                    <p> Regd.Office: "DTDC HOUSE",No.3, Victoria Road, Bangalore - 560 047,Karnataka,India Tel: 91-80-25302333. CIN No: U85110KA1990PLC011089.</p>
                                </div>
                            </footer>
                        </div>
                    </PDFExport>
                </div>
            </div>
        );
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Dtdc);
