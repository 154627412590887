import React from 'react';
import {Title as Header} from '../../../../../Header/TopHeader';
import {Filters} from '../../../../../Common/fFilters';
import {NormalTable} from '../../../../../Tables/NormalTable/NormalTable';
import {PageLoader} from '../../../../../Common/Components/PageLoader';
import Moment from 'moment';
import {get, set} from '../../../../../../Utils/Crypto';
import {Item} from '../../../../fCommon/Item';
import _ from 'lodash';
import {DownloadCsv} from '../../../../../../Utils/Downloader';


class View extends React.Component {

    constructor(p) {
        super(p);
        this.presentor = p.presentor;
        this.presentor.setView(this);
        let filters = get(this.getScreenKey())
        if (!filters) {
            filters = [];
        }
        this.state = {
            pending: [],
            loading: true,
            filters,
            type: {
                label: "By Employee",
                value: "NAME",

            },
            flag: [
                {
                    name: "Pending",
                    active: false,
                    color: "#5365EF",
                    logic: (i) => {
                        return !i.completed && !i.declined
                    }
                },
                {
                    name: "Completed",
                    active: false,
                    color: "#15ADCA",
                    logic: (i) => {
                        return i.completed && !i.declined
                    }
                },
                {
                    name: 'Declined',
                    active: false,
                    color: '#7551E9',
                    logic: (txn) => {
                        return txn.declined
                    }
                }
            ]
        }
    }



    componentDidMount() {
        this.presentor.init();
    }

    load = r => {


        let name = [];
        let id = [];

        r.forEach(val => {
            if (!_.includes(name, val.initiator)) {
                name.push(val.initiator)
            }

            if (!_.includes(id, val.id)) {
                id.push(val.id);
            }
        })

        this.suggestions = {
            NAME: name.map(item => ({
                firstCriteria: item,
                value: item
            })),
            ID: id.map(item => ({
                firstCriteria: `${item}`,
                value: `${item}`
            }))
        }

        this.setState({
            ...this.state,
            pending: r,
            loading: false
        })
    }


    getScreenKey = () => {
        let screenName = this.props.location.pathname;
        return `screen::${screenName}`
    }

    addFilter = (value) => {
        if (value) {
            let type = this.state.type.value;
            let filters = this.state.filters;
            filters = filters.filter(item => {
                if (item.type === type && item.value === value)
                    return false;
                else return true;
            })
            filters.push({
                type, value
            })
            this.setState({
                ...this.state,
                filters
            })
            set(this.getScreenKey(), filters);
        }
    }

    deleteSelectValue = item => {
        let filters = this.state.filters;
        filters = filters.filter(mItem => {
            if (item.type === mItem.type && item.value === mItem.value)
                return false;
            else return true;
        })
        this.setState({
            ...this.state,
            filters
        })
        set(this.getScreenKey(), filters)

    }



    item = (i, index) => {

        let viewItem = () => {
            this.props.history.push(`/app/settings/plugins/mail/${this.getStatus(i)}/${i.id}`)
        }
        return (
            <div key={index} style={{ paddingLeft: 0, paddingRight: 0 }}
                className="border-bottom fintrip-department-item table-item">
                <div className="linear-layout horizontal"
                    style={{ flex: 1, cursor: 'pointer', alignItems: 'center', height: 50 }}>


                    <div style={{
                        flex: 3
                    }}>
                        <div style={{
                            display: 'flex',
                            fontWeight: 600
                        }}>
                            <p>#{i.id}</p>
                            <p className="top" style={{
                                marginLeft: 8
                            }}>{i.initiator}</p>
                        </div>
                    </div>

                    <div style={{
                        flex: 2
                    }}>
                        {Moment(i.mailSentAt).format('DD-MM-YYYY HH:MM a')}
                    </div>


                </div>

                <div style={{
                    width: 150
                }} className="right-actions">
                    <p onClick={viewItem} className="not-hoverable">View</p>
                </div>

            </div>
        )
    }

    filter = emps => {
        let filters = this.state.filters;
        let filterData = {}
        filters.forEach(f => {
            let items = filterData[f.type];
            items = items ? items : [];
            items.push(f.value)
            filterData[f.type] = items;
        })
        emps = emps.filter(item => {



            let employeeFilter = filterData["NAME"];
            if (employeeFilter) {
                if (!_.includes(employeeFilter, item.initiator))
                    return false;
            }

            let idFilter = filterData['ID'];
            if (idFilter) {
                if (!_.includes(idFilter, `${item.id}`))
                    return false;
            }
            return true;
        })
        return emps;

    }


    handleFlagLogic = (txns) => {

        let flag = this.state.flag;
        let anyActive = false;
        flag.forEach(item => {
            if (!anyActive)
                anyActive = item.active;
        })
        if (anyActive) {
            txns = txns.filter(txn => {
                let active = false;
                flag.forEach(value => {
                    if (value.active) {
                        if (!active) {
                            active = value.logic(txn);
                        }
                    }
                })
                return active;
            })
        }



        return txns;

    }

    filterByFlag = () => {
        let status = this.state.flag;
        return (
            <div style={{
                marginTop: 4, marginBottom: 6
            }}>
                <p style={{
                    fontSize: 11,
                    fontWeight: 600,
                    marginBottom: 12,
                    color: "var(--text-color)"
                }}>
                    By Flag Status:
				</p>
                <div style={{ paddingBottom: 20, display: 'flex' }}>
                    {
                        status.map((value, index) => {
                            return (
                                <Item color={value.color} key={index} onClick={
                                    () => {
                                        let flag = this.state.flag
                                        flag = flag.map(mValue => {
                                            if (mValue.name === value.name) {
                                                mValue.active = !mValue.active;
                                            }
                                            return mValue;
                                        });
                                        this.setState({
                                            ...this.state,
                                            flag
                                        })
                                    }
                                } title={value.name} selected={value.active} />
                            )
                        })
                    }
                </div>
            </div>
        )
    }


    getStatus = (i) => {
        if (!i.completed && !i.declined) {
            return "pending"
        } else if (i.completed && !i.declined) {
            return "completed"
        } else if (i.declined) {
            return "declined"
        }
    }


    DownloadCsv = () => {
        let fileName = "All";
        let data = [
            [
                "Id",
                "Initiator Name",
                "Mail Sent At",
                "Status"
            ]
        ]
        let pending = this.handleFlagLogic(this.state.pending);
        this.filter(pending).forEach(val => {
            data.push(
                [
                    val.id,
                    val.initiator,
                    Moment(val.mailSentAt).format('DD-MM-YYYY HH:mm a'),
                    this.getStatus(val)
                ]
            )
        })

        DownloadCsv(fileName, data)
    }


    render() {

        if (this.state.loading) {
            return <PageLoader />
        }

        let data = this.filter(this.state.pending);
        data = this.handleFlagLogic(data);
        return (
            <div style={{ flex: 1 }}>
                <Header
                    title="All Invoices"
                    des="List of all  invoices available here"
                >
                    <div style={{
                        position: 'absolute',
                        top: 20,
                        right: 10
                    }}>
                        <div className="btn btn-portlet-invert" onClick={() => this.DownloadCsv()}>
                            Download Csv
                        </div>
                    </div>
                </Header>
                <div style={{
                    overflowY: 'scroll',
                    padding: 20
                }} className="title-container">
                    <Filters suggestion={this.suggestions} removeFilter={this.deleteSelectValue}
                        addFilter={this.addFilter} filters={this.state.filters} onTypeChange={type => {
                            this.setState({
                                ...this.state,
                                type
                            })
                        }} type={this.state.type} types={[
                            {
                                label: "By Employee",
                                value: "NAME",
                                autoPopulate: "EMPS",
                                autoValue: "name"

                            },
                            {
                                label: 'By ID',
                                value: 'ID'
                            }
                        ]} />
                    {this.filterByFlag()}
                    <NormalTable pagination={true} headers={[
                        {
                            key: "details",
                            name: "Details",
                            weight: 3
                        },
                        {
                            key: "employeecode",
                            name: "Mail Sent At",
                            weight: 2
                        },


                        {
                            key: "actions",
                            name: "",
                            width: 150
                        }
                    ]} data={data} mapItem={this.item} />
                </div>
            </div>
        )
    }
}

export default View;
