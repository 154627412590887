

const data = [
    {
        title:'',
        list:[
            {
                text:"1.1 All Goods & Services Purchased Here under Are Subject to Inspection by Buyer Prior To Final Acceptance."
            },
            {
                text:'1.2 Invoices Must Contain the Following Information - Po Number, Part Numbers, Description of Services, Prices, Extended Totals & Attach All Supporting Documentation, If Any.'
            },
            {
                text:"1.3 To The Extent Applicable, All Invoices Must Include Any Tax Amounts, Listed Separately."
            },
            {
                text:"1.4 Invoices Submitted Hereunder Will Be Paid as Per the Agreed Credit Period After Receipt of a Correct Invoice or Acceptance of Goods or Services by Buyer, Whichever Occurs Later."
            }
        ]
    },
]

export default data;