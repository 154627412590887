import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'

/*
	<PolarGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/

function PolarGraph(props) {
	return (
		<div>
			<ReactApexChart options={{
				xaxis: {
					categories: props.items,
				},
				title: {
					text: props.title,
					align: 'left'
				},
				dataLabels: {
					enabled: false
				},
				chart: {
					width: props.height,
					type: 'polarArea',
				},
				stroke: {
					colors: ['var(--white-color)']
				},
				fill: {
					opacity: 0.8
				},
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 200
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			}} series={props.data} type="polarArea" height={props.height} />
		</div>
	)
}

PolarGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

PolarGraph.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350
}

export {
	PolarGraph
}

export default PolarGraph
