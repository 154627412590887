import React, { useEffect, useRef, useState } from 'react';
import { deleteApi, get, post } from '../../../Model/Network/Config/Axios';
import { FunctionalInput } from '../fInputs';
import { Modal } from '../fModal/Modal';
import { SearchableSelect3 } from '../Class/Select/SearchableSelec3';
import { showToast } from '../fToast/ToastCallback';
import Chat from './Chat';
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';


const SupportTicket  = ({id,type}) => {


    const [create,setCreate] = useState(false);
    const [participate,setParticipate] = useState([]);
    const [user,setUser] = useState(undefined);
    const [rooms,setRoom] = useState([]);
    const [openChat,setOpenChat] = useState(false);
    const [employees,setEmployees] = useState([]);
    let MESSAGE = useRef();



    useEffect(()=>{
        INIT(id,type);
    },[id,type])



    useEffect(()=>{
        FETCHROOM(id,type);
    },[id,type])



    let FETCHROOM = (i,j) => {
        get(`workspace/seekClarification/getAllRooms`,(e,r)=>{
            if(r){
                setRoom(r.rooms);
            }
        },{type:j,id:i})
    }



    let mapRoom = (item,index) => {
        return(
            <div onClick={()=>{setOpenChat(item)}} key={index} style={{border:'1px solid var(--border-color)',marginBottom:10,cursor:'pointer',padding:10,borderRadius:4,display:'flex',justifyContent:'space-between'}}>
                <div>
                    <p style={{fontSize:12,fontWeight:'bold'}}>{item.title}</p>
                    <p style={{fontSize:12,fontWeight:'bold',marginBottom:5}}>Description : {item.description}</p>
                    <span style={{background:"rgb(13, 110, 253)",width:100,fontSize:10,marginTop:5,padding:'4px 6px',borderRadius:4,color:"#fff"}}>{item.status}</span>
                </div>
            </div>
        )
    }



    let INIT = (i,j) => {
        get(`workspace/seekClarification/getPossibleParticipants`,(e,r)=>{
            if(r) {
                let user = [];
                let raiser = r.raiser;
                let vendor = r.vendor;
                Object.keys(r.approvers).forEach(val=>{
                    user.push({
                        type:r.approvers[val].ownerType,
                        ownerType:'APPROVER',
                        userId:r.approvers[val].id,
                        value:r.approvers[val].ownerName,
                        approverNumber:val,
                        des:`APPROVER ${val}`,
                        label:r.approvers[val].ownerName,
                    })
                })
                user.push({
                    type:raiser.ownerType,
                    userId:raiser.id,
                    value:raiser.ownerName,
                    approverNumber:'none',
                    ownerType:'RAISER',
                    des:'RAISER',
                    label:raiser.ownerName

                })
                if(vendor) {
                    user.push({
                        type:vendor.ownerType,
                        userId:vendor.id,
                        value:vendor.ownerName,
                        approverNumber:'none',
                        ownerType:'CONTACT',
                        des:'VENDOR',
                        label:vendor.ownerName
                    })
                }
               
                setParticipate(user);
            }
        },{type:j,id:i})
    }

    return(
        <div >
            <div style={{marginTop:20}} />
            {rooms.map(mapRoom)}
            <ReadOnly>
                <div style={{
                    boxShadow:'rgb(1 1 1 / 2%) 4px 5px 3px',
                    background:'rgb(240, 112, 24)',
                    cursor:'pointer',
                    border:'1px solid var(--border-color)',
                    borderRadius:8,
                    width:120,
                    fontSize:12,
                    textAlign:'center',
                    padding:'10px 12px',
                    color:"#fff",
                    marginTop:20
                }} onClick={()=>{setCreate(true)}}>
                    + Create Ticket
                </div>
            </ReadOnly>
            {
                create ?
                <Modal
                    title='Create Ticket'
                    des="Enter the details and create ticket"
                    show={true}
                    close={()=>{
                        setCreate(false);
                    }}
                    buttonName="Create"
                    onSave={()=>{
                        let message = MESSAGE.current.value;
                        if(!user) {
                            showToast({
                                type:'error',
                                message:"Please select the user"
                            })
                        }

                        if(!message) {
                            showToast({
                                type:'error',
                                message:"Please enter the message"
                            })
                            return;
                        }

                        let data = {
                            userType:user.type,
                            id:+id,
                            type,
                            description:message,
                            userRole:'ADMIN',
                            userId:user.userId
                        }

                        post(`workspace/seekClarification/createRoom`,data,(e,r)=>{
                            if(r) {
                                INIT(id,type)
                                setCreate(false);
                                showToast({
                                    type:'success',
                                    message:"Ticket created successfully "
                                })
                                FETCHROOM(id,type);
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }}
                >
                    <p style={{fontSize:12,marginBottom:5}}>Search Person</p>
                    <SearchableSelect3 select={setUser} placeholder="Search Participate from Approval Flow " items={participate} />
                    <p style={{marginTop:3,marginBottom:8,fontSize:14}}>OR</p>
                    <p style={{fontSize:12,marginBottom:5}}>Search User from Organization</p>
                    <SearchableSelect3 
                        select={setUser} 
                        placeholder="Search User from Organization " 
                        items={employees} 
                        onChange={(val)=>{
                            get(`search/v1/employees?q=${val}`,(e,r)=>{
                                if(r) {
                                    setEmployees(r.employees.map(item=>({
                                        type:'EMPLOYEE',
                                        userId:item.id,
                                        value:item.name,
                                        approverNumber:'none',
                                        ownerType:'EMPLOYEE',
                                        des:'EMPLOYEE',
                                        label:item.name
                    
                                    })))
                                }
                            })
                        }}
                        />
                    <FunctionalInput ref={MESSAGE} title="Enter the Reason" />
                </Modal>
                :null
            }
            {
                openChat?
                <Chat 
                     onClose={() => {
                        deleteApi(`workspace/seekClarification/closeRoom/${openChat.id}`,(e,r)=>{
                            showToast({
                                type:'success',
                                message:"Room Closed Successfully"
                            })
                            FETCHROOM(id,type);
                            setOpenChat(false);
                        })
                     }}
                     status={openChat.status}
                     close={()=>setOpenChat(false)}
                     id={openChat.id} 
                     title={openChat.description} 
                    des={openChat.title} 
                 />
                :null
            }
        </div>
    )
}

export {SupportTicket};