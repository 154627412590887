import React from 'react';
import {Portlet} from './fPortlet';
import {BookingDetails} from '../Common/Components/BookingDetails';
import BreadcrumbEnd from './BreadcrumbEnd';
import {Header} from "./fPortlet/Header";
import {useHistory,useParams} from 'react-router-dom'

function Transactiondetails(){
	let {id} = useParams();
	let HISTORY = useHistory();
	return (
		<div style={{padding: '20px'}}>
			<BreadcrumbEnd title="Transaction"/>
			<Portlet style={{marginTop: 20}}>
				<Header title="Transaction Details" des="All Transaction Details are here"/>
				<BookingDetails history={HISTORY} style={{
					padding: 0,
					border: 'none',
					background: 'var(--white-color)',
					marginBottom: 12
				}} item={{
					transId:id
				}}/>
			</Portlet>
		</div>
	)
}

export {Transactiondetails}
