import { PDFExport } from "@progress/kendo-react-pdf";
import React from "react";
import Buyer from "./Buyer";
import Header from "./Header";
import Data from "./Data";
import Vendor from "./Vendor";
import Footer from "./Footer";

export default function Rapido(props) {

    let { data } = props;
    let pdfRef;
    console.log({ data });

    return(
        <>
            <div style={{
                display:'flex',
                justifyContent:'space-between',
                marginBottom:15
            }}>
                <div style={{
                    cursor:'pointer',
                    color:'var(--primary-color)',
                    fontSize:16
                }} onClick={()=>{
                    pdfRef.save()
                }}>Download</div>
            </div>
            <PDFExport title="Order Document" padding="40" fileName={`Order-${data.orderId}.pdf`} ref={r => pdfRef = r}>
                <div style={{ margin: 20, fontSize:13 }}>
                    <div style={{textAlign: "center"}}>
                        {data.orderId}
                    </div>
                    <div>
                        <Header data={data} />
                        <div style={{padding: "20px 10px"}}>
                            <div style={{ display: "flex" }}>
                                <Vendor data={data} />
                                <Buyer data={data} />
                            </div>
                            <Data data={data} />
                        </div>
                    </div>
                    <Footer />
                </div>
            </PDFExport>
        </>
    )
}