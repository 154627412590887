import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios, { get } from '../../../../../Model/Network/Config/Axios';
import Title from '../../../../Common/Title';
import { Modal } from '../../../../Common/fModal/Modal';
import { getBaseUrl } from '../../../../../Model/Network/Config/DecentralizeWrapper';
import { showToast } from '../../../../Common/fToast/ToastCallback';
import RenewalTrackingDetails from '../../fProducts/RenewalTracking/RenewalTrackingDetails';


const RenewalTracker = (props) => {

    let {id} = props;
    let {procured} = props.data;
    const [items,setItems] = useState([]);
    const [loading,setLoading] = useState(true);
    const [selected,setSelected] = useState([]);
    const [map,setMap] = useState([]);
    const [confirm,setConfirm] = useState(false);
    const [open,setOpen] = useState(false);

    useEffect(()=>{
        INIT();
        GETMAPITEM();
    },[])

    let getFilter = () => {
        let filterData = [];
        procured.forEach(item=>{
            filterData.push(`${item.productId}@product_id`);
            filterData.push(`${item.officeId}@office_id`)
        })
        return filterData;
    }

    let GETMAPITEM = () => {
        let data ={};
        data.filter=`${id}@new_po_id`;
        get(`vendor/stock/renewalTracking/getRenewalTrackers`,(e,r)=>{
            if(r) {
                setMap(r.trackers.map(item=>item.id));
                setLoading(false);
            }
        },data)
    }

    let INIT = () => {
        let data = {
            page:0
        }
        data.filter=getFilter();
        get(`vendor/stock/renewalTracking/getRenewalTrackers`,(e,r)=>{
            if(r) {
                setItems(r.trackers);
                setLoading(false);
            }
        },data)
    }

    if(loading) {
        return <></>
    }

    return(
        <div id={`${props.prefix}.renewal`} style={{margin: 12}}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>   
                <Title id="Rewewal Tracker" marginLeft={0} title="Rewewal Tracker"/>
                <div>
                    {
                        selected.length>0?
                        <div onClick={()=>{setConfirm(true)}}  style={{background:'var(--primary-color)',borderRadius:4,color:'#fff',fontSize:13,cursor:'pointer',padding:'5px 18px'}}>
                            Map
                        </div>
                        :null
                    }
                </div>
            </div>
            <div className="input-container" style={{background: "var(--white-color)",margin: 0,padding: 12}}>
                <div style={{display:'flex',alignItems:'center',flex:1,height:25,border:'1px solid var(--border-color)',background:'var(--sidebar-color)',borderRadius:4}}>
                    <div style={{width:30,height:25,display:'flex',justifyContent:'center',alignItems:'center',borderRight:'1px solid var(--border-color)'}}>
                        <div onClick={()=>{
                            let tempArray = [];
                            items.forEach(item=>{
                                if(_.includes(map,item.id)) {

                                } else {
                                    tempArray.push(item.id);
                                }
                            })
                            setSelected(tempArray);
                            
                        }} style={{width:15,height:15,border:'2px solid var(--border-color)',cursor:'pointer',...(selected.length+map.length)===items.length?{background:'var(--primary-color)'}:{}}}>

                        </div>
                    </div>
                    <div style={{flex:2,fontSize:12,height:25,paddingLeft:5,display:'flex',alignItems:'center',fontWeight:'800',borderRight:'1px solid var(--border-color)'}}>Item</div>
                    <div style={{flex:1,fontSize:12,height:25,paddingLeft:5,display:'flex',alignItems:'center',fontWeight:'800',borderRight:'1px solid var(--border-color)'}}>Quantity</div>
                </div>  
                {
                    items.map((item,index)=>{
                        let active = _.includes(selected,item.id);
                        let isMap = _.includes(map,item.id);
                        return(
                            <div key={index} style={{display:'flex',alignItems:'center',flex:1,minHeight:30,border:'1px solid var(--border-color)'}}>
                                <div style={{width:30,minHeight:30,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    {
                                        isMap ?
                                        <div style={{width:15,height:15,border:'2px solid var(--border-color)',...isMap?{background:'var(--success-color)'}:{}}} />
  
                               
                                        :
                                        <div onClick={()=>{
                                            let tempArray = [...selected];
                                            if(_.includes(tempArray,item.id)) {
                                              tempArray = tempArray.filter(i=>i!==item.id);
                                            } else {
                                              tempArray.push(item.id);
                                            }
                                            setSelected(tempArray);
  
                                      }} style={{width:15,height:15,border:'2px solid var(--border-color)',...active?{background:'var(--primary-color)'}:{}}} />
  
                                    }
                                  
                                </div>
                                <div style={{fontWeight:'bold',minHeight:30,fontSize:10,flex:2,paddingLeft:5,paddingTop:5,paddingBottom:5,flexDirection:'column',display:'flex',alignItems:'center',borderLeft:'1px solid var(--border-color)'}}>
                                    {item.name} 
                                    <span onClick={()=>{
                                        setOpen(item);
                                    }} style={{color:'var(--primary-color)',cursor:'pointer'}}>{item.trackingId}</span>
                                </div>
                                <div style={{fontWeight:'bold',minHeight:30,fontSize:12,flex:1,paddingLeft:5,display:'flex',alignItems:'center',borderLeft:'1px solid var(--border-color)'}}>{item.qty} {item.stock.product.units}</div>
                            </div>
                        )
                    })
                }
            </div>
            {
                open ?
                <RenewalTrackingDetails
                     data={open}
                     onClose={()=>{
                        setOpen(false);
                     }}
                 />
                :null
            }
            {
                confirm ?
                <Modal
                    title="Trigger"
                    des="New PO has been created for this tracking"
                    close={()=>{
                        setConfirm(false);
                    }}
                    buttonName="Map"
                    show={true}
                    onSave={ async()=>{
                        let temp = [...selected];
                        let apiCombine = [];
                        temp.forEach(i=>{
                           
                            apiCombine.push(
                                axios({
                                    method:'post',
                                    url:`${getBaseUrl()}/vendor/stock/renewalTracking/disableTrigger/${i}`,
                                    data:{
                                        poId:+id
                                    }
                                })
                            )
                        })
                        setConfirm(false);
                        showToast({
                            type:'success',
                            message:"Map Successfully"
                        })
                        setSelected([]);

                    }}
                >
                    <p style={{
                        fontSize:14,
                        marginTop:15,
                        marginBottom:15
                    }}>Are you sure want to disable {selected.length} product for New PO created ?</p>
                </Modal>
                :null
            }
        </div>
    )
}

export default RenewalTracker;