import {patch} from "../../Model/Network/Config/Axios";

class Model {

	changePassword = (data, cb) => {
		patch(`/auth/reset`, data, cb)
	}

}

export default Model;
