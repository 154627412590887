import React from 'react'
import Props from 'prop-types';
import {Link} from 'react-router-dom'

class LineHeader extends React.Component {

	mapItem = (item, index) => {
		if (item.link)
			return (
				<Link style={item.active ? {
					padding: '6px 20px',
					marginRight: 15,
					background: "var(--primary-color)",
					fontSize: 12,
					color: 'var(--white-color)',
					cursor: 'pointer',
					borderRadius: 6,
					fontWeight: 600,
					position: 'relative',
					top: 3
				} : {
					padding: '6px 20px',
					marginRight: 15,
					fontSize: 12,
					border: '1.5px solid #bbb',
					color: 'var(--text-color)',
					borderRadius: 6,
					cursor: 'pointer',
					fontWeight: 600,
					position: 'relative',
					top: 3
				}} to={item.link} key={index}>
					{item.name}
				</Link>
			)
		else
			return (
				<p style={item.active ? {
					padding: '6px 20px',
					marginRight: 15,
					background: "var(--primary-color)",
					fontSize: 13,
					color: 'var(--white-color)',
					cursor: 'pointer',
					borderRadius: 6,
					fontWeight: 600,
					position: 'relative',
					top: 3
				} : {
					padding: '6px 20px',
					marginRight: 15,
					fontSize: 12,
					border: '1.5px solid #bbb',
					color: 'var(--text-color)',
					borderRadius: 6,
					cursor: 'pointer',
					fontWeight: 600,
					position: 'relative',
					top: 3
				}} onClick={item.click} key={index}>
					{item.name}
				</p>
			)
	}

	render() {
		return (
			<>
				<div style={this.props.style || {display: 'flex',paddingBottom:20,paddingTop:8,borderBottom:'1px solid var(--border-color)'}}>

					{this.props.items.map(this.mapItem)}
				</div>
			</>
		)
	}
}


LineHeader.propsTypes = {
	items: Props.array.isRequired
}

export {LineHeader};
