import React from 'react'
import empty from './empty.png'

function Empty(props){
	return (
		<div style={{width: '100%', display: 'flex', ...props.style}}>
			<div className='div_outline'>
				<img alt="nothing to show" src={empty} style={{marginRight: '20px', width: '22px',height:22}}/>
				{props.message?props.message:'No items to show'}
			</div>
		</div>
	)
}

export {
	Empty
}
export default Empty;
