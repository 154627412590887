import React from 'react';
import { post } from '../../../Model/Network/Config/Axios';
import { showToast } from '../fToast/ToastCallback';
import Track from '../fTrack';


const Integration = ({message,buttonName,type,reference,refresh,...props}) =>{

    let {integrationData} = props;

    console.log(integrationData);

    let onClick = () => {
        post(`workspace/integration/triggerPosting`,{formType:type,formId:+reference},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Posting done successfully"
                })
                refresh();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }


    let timeslines =[
        {
            title:'Posting Triggered',
            desc:
                <div>
                </div>,
            status:integrationData.postingTriggered,   //pending
            pending: !integrationData.postingTriggered
        },
        {
            title:'Posting Sync',
            desc:
                <div>
                    {
                        !integrationData.postingSynced ?
                        <>
                            <div style={{marginTop:5,color:'var(--red-color)',cursor:'pointer',fontWeight:'bold'}} onClick={onClick}>
                            Re-Trigger
                         </div>
                         {
                            !integrationData.postingSynced ?
                            <p>Error : {integrationData.postingErrors||'NA'}</p>
                            :null
                         }
                        </>
                       
                        :null
                    }
                    {
                        integrationData.postingDocNo||''
                    }
                </div>,
            status:integrationData.postingSynced  //fail trigger option
        },
        {
            title:'Payment  Trigger',
            desc:
                <div>
                </div>,
            status:integrationData.paymentTriggered,  //pending,
            pending: !integrationData.paymentTriggered
        },
        {
            title:'Payment Sync',
            desc:
                <div>
                     {
                        !integrationData.paymentSynced ?
                        <div style={{marginTop:5,color:'var(--red-color)',cursor:'pointer',fontWeight:'bold'}} onClick={onClick}>
                            Re-Trigger
                        </div>
                        :null
                    }
                    {
                        integrationData.paymentDocNo||''
                    }
                </div>,
                
            status:integrationData.paymentSynced   //pending
        }
    ]

    return(
        <div style={{marginTop:20}}>
            <div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline</div>
			<Track stages={timeslines}/>						
        </div>
    )
}


Integration.defaultProps = {
    buttonName:"Re-Trigger",
    message:"Posting Failed"
}


export {Integration};