import React from 'react';
import Moment from 'moment';


const ChangeLog = (props) => {

let	status = (date) => {
		let p = null
		if (date === 0) {
			return (
				<p>Pending</p>
			)
		} else if (date === -1) {
			return (
				<p>Permission Not Required</p>
			)
		} else {
			return (
				<p>{Moment(date).format('DD-MM-YYYY hh:mm a')}</p>
			)
		}
	}


	let show = (item, index) => {
		let active = false
		if (index === 0) {
			active = true
		}
		return (
			<div key={index}>
				{active ?
					(<div style={{display: 'flex'}}>
						<p style={{
							height: 14,
							width: 14,
							borderRadius: '50%',
							background: item.color,
							zIndex: 11,
							marginTop: 10
						}}/>
						<div style={{marginLeft: 12}}>
							<p style={{fontSize: 21, position: 'relative'}}>{item.title} <span
								style={{fontSize: 14, color: "var(--border-color)"}}>
								{status(item.date)}
								</span>
							</p>
						</div>
					</div>) :
					(<div style={{position: 'relative'}}>
						<div style={{
							borderLeft: '1px solid var(--border-color)',
							height: 62,
							position: 'absolute',
							top: -52,
							left: 6.5
						}}/>
						<div style={{display: 'flex', marginTop: 30}}>
							<p style={{
								height: 14,
								width: 14,
								marginTop: 10,
								borderRadius: '50%',
								background: item.color,
								zIndex: 11
							}}/>
							<div style={{marginLeft: 12}}>
								<p style={{fontSize: 21, position: 'relative'}}>{item.title}<span
									style={{fontSize: 14, color: "var(--light-color)"}}>
                                  {status(item.date)}
                                </span></p>
							</div>
						</div>
					</div>)
				}
			</div>
		)
	}


		let items = props.changeLogs;

		if (items.length === 0) {
			return (<p>No Logs Found</p>)
		}
		return (
			<div>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
					{items.map(show)}
				</div>
			</div>
		);
	
}

export default ChangeLog
