import React, {useEffect, useRef, useState} from 'react';
import { get, post } from '../../../../../../Model/Network/Config/Axios';
import axios from 'axios'
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import {useParams} from 'react-router-dom'
import './index.css'
import {v4 as uuidv4} from 'uuid';
import { FileUpload } from '../../../../../Common/fInputs/FunctionalFile';
import AppPreviews from './Previews'
import AboutApp from './About'
import AppVersions from './Versions'

function Index(props) {
    const {appId} = useParams();
    const [Owner, setOwner] = useState(false);
    const [Uploading, setUploading] = useState(undefined);
    const [Logo, setLogo] = useState('');
    const [Previews, setPreviews] = useState([]);
    const [About, setAbout] = useState('');
    const [Versions, setVersions] = useState([]);
    const [Live, setLive] = useState(false);
    const [id,setAppId] =  useState("")

    let FILE = useRef();
    let JSFILE = useRef();

    useEffect(() => {
        fetchDetails()
        fetchVersions()
    }, []);

    const fetchDetails = () => {
        get(`market/${appId}/details`, (e,r)=>{
            if(r){
                setAppId(r.appId)
                setOwner(r.self)
                setLogo(r.logo)
                setAbout(r.about)
                setPreviews(r.screenshots)
                setLive(r.live)

            }
        },{})
    }

    const fetchVersions = () => {
        get(`market/dashboard/${appId}/versions`, (e,r)=>{
            if(r){
                setVersions(r.versions)
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading versions'
                })
            }
        },{})
    }

    const uploadApp = () => {

		let file = FILE.current.files[0];
        if(file.name.substring(file.name.length-4)!=='.zip'){
            showToast({
                type: 'error',
                message: 'Only .zip file acceptable'
            })
        }
        const fileSize = FILE.current.files[0].size / 1024 / 1024;
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setUploading(fileSize);
            let UUID = uuidv4();
            axios({
                method: 'post',
                url: `https://file.eka.io/apis/v3/script`,
                data: formData,
                headers: {
                    "X-APP-ID": "a00f991d87f24a08b831f1898a3ad174",
                    "X-APP-SECRET": "53ca0744572b49544eb7ad1f2178480d891cd1575999e58eaf4d0970506267a3"
                }
            }).then(res => {
                push(res.data.scripts, UUID);
                setUploading(undefined);
                showToast({
                    type: 'success',
                    message: 'App Uploaded'
                })
            }).catch(err =>{
                setUploading(undefined);
                showToast({
                    type: 'error',
                    message: err.data?err.data.message:'Error in uploading App'
                })
            })
        }
	}


    const uploadFile = () => {

        let file = JSFILE.current.files[0];
        if(file.name.substring(file.name.length-3)!=='.js'){
            showToast({
                type: 'error',
                message: 'Only .js file acceptable'
            })
        }
        const fileSize = JSFILE.current.files[0].size / 1024 / 1024;
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setUploading(fileSize);
            let UUID = uuidv4();
            axios({
                method: 'post',
                url: 'https://file.eka.io/file',
                data: formData,
                headers: {
                    clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
                    clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
                }
            }).then(res => {
                push([res.data.url],UUID)
                setUploading(undefined);
                showToast({
                    type: 'success',
                    message: 'App Uploaded'
                })
            }).catch(err =>{
                setUploading(undefined);

                showToast({
                    type: 'error',
                    message: err.data.message?err.data.message:'Error in uploading App'
                })
            })
        }
    }

    const updateIcon = (file) => {
        post(`market/${appId}/logo`, {url: file}, (e,r)=>{
            if(r){
                fetchVersions();
                showToast({
                    type:'success',
                    message: 'Icon Updated'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in updating Icon'
                })
            }
        })
    }

    const push = (urls, ver) => {
        let versioning = ver+'--'+(Versions.length+1);
        post(`market/dashboard/${appId}/version`, {urls, version: versioning}, (e,r)=>{
            if(r){
                fetchVersions();
                showToast({
                    type:'success',
                    message: 'Version Deployed'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading versions'
                })
            }
        })
    }

    const publishApp = (ver) => {
        post(`market/dashboard/${appId}/publish`, {}, (e,r)=>{
            if(r){
                fetchDetails();
                fetchVersions();
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in publishing app'
                })
            }
        })
    }

    const downloadScripts = async () => {
        const json = JSON.stringify({"scripts": ["app.js"]});
        const blob = new Blob([json],{type:'application/json'});
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = "Script.json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
}

    return (<>
        <div className='CustomApp' style={{marginTop: 40, display: 'flex', width: '100%', backgroundColor: 'var(--white-color)', boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 0.2rem 1rem', borderRadius: 20, justifyContent: 'space-between'}}>
            <div className="FadeLeft-Effect" style={{display: 'flex', width: '100%',alignItems: 'stretch'}}>
                <div style={{display: 'flex', width: '100%',alignItems: 'stretch'}}>
                    <div style={{width: '100%',padding: 40}}>
                        <div style={{backgroundColor: 'var(--lite-color)', borderRadius: 8, padding: '10px', display: 'inline-flex', fontSize: '12px', color: 'var(--text-color)', wordBreak: 'break-all'}}>
                            App ID :&nbsp;&nbsp;<span style={{color: '#00aff0', fontFamily: 'Courier'}}>{id}</span>
                        </div>
                        <div style={{marginTop: 30}}>
                            <AboutApp about={About} reload={()=>fetchDetails()}/>
                            <span id="Login_Heading"style={{fontSize: '12px', fontWeight: 'bold'}}>App Logo</span><br/>
                            <div style={{marginLeft: '-10px', marginTop: '20px', marginBottom: '30px', display: 'flex', alignItems: 'center'}}>
                                <FileUpload fileurl={Logo} onUpload={(data => {setLogo(data.file); updateIcon(data.file)})} type="Mono"/>
                                <div style={{fontSize: '10px', marginLeft: '10px'}}>
                                    Upload Logo for your app<br/>(512px X 512px)
                                </div>
                            </div>
                            <AppPreviews previews={Previews} reload={()=>fetchDetails()}/>

                            <span id="Login_Heading"style={{fontSize: '12px', fontWeight: 'bold'}}>App Deployment</span><br/>
                            <div style={{marginTop: '20px', display: 'flex', marginLeft: '-8px'}}>

                                {
                                  Versions.length?
                                    Live ?
                                    <div onClick={publishApp} className='CustomApp-tile' style={{background: 'var(--green-color)', width: '30%', color: '#fff'}}>
                                        <svg style={{fontSize: '26px', marginRight: '20px'}} className="bi bi-stop-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                            <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"></path>
                                        </svg>
                                            Stop App
                                    </div>
                                    :
                                    <div onClick={publishApp} className='CustomApp-tile' style={{background: 'var(--primary-color)', width: '30%', color: '#fff'}}>
                                        <svg style={{fontSize: '26px', marginRight: '20px'}} className="bi bi-play-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"></path>
                                        </svg>
                                            Publish
                                    </div>
                                :<></>
                                }
                                {
                                    Owner?
                                        Uploading?
                                        <div className='CustomApp-tile' style={{width: '60%'}}>
                                            <div className='mini-loader' style={{marginRight: '15px'}}></div>
                                            Uploading... ({Uploading.toFixed(3)} MB)
                                        </div>
                                        :
                                            <div style={{
                                                display:'flex'
                                            }}>
                                                <label for='selectApp' className='CustomApp-tile' style={{width: '50%'}}>
                                                    <svg style={{fontSize: '26px', marginRight: '20px'}} className="bi bi-upload" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                                                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"></path>
                                                    </svg>
                                                    Upload App Archive <br/>(.zip)
                                                </label>
                                                <label for='singleJS' className='CustomApp-tile' style={{width: '50%'}}>
                                                    <svg style={{fontSize: '26px', marginRight: '20px'}} className="bi bi-upload" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                                                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"></path>
                                                    </svg>
                                                    Single .js File
                                                </label>
                                            </div>
                                    :<div style={{fontSize: '12px', marginLeft: '10px'}}>Your are not the App owner, app modification is not allowed</div>
                                }
                                <input ref={FILE} type="file" onChange={uploadApp} accept=".zip" id="selectApp" name="selectApp" style={{display: 'none'}}/>
                                <input ref={JSFILE} type="file" onChange={uploadFile} accept=".js" id="singleJS" name="singleJS" style={{display: 'none'}}/>
                            </div>
                        </div>
                        {
                            Versions.length?
                            <AppVersions versions={Versions} reload={()=>fetchVersions()}/>
                            :<></>
                        }
                    </div>
                    <div style={{background: 'var(--primary-color)',borderRadius: '0px 20px 20px 0px',width: '400px', padding: 30}}>
                        <div className='FadeUp-Effect' style={{height: '100%', position: 'relative', top: 0, right: 0}}>
                            <div style={{display: 'flex', alignItems: 'center', color: '#fff'}}>
                                <svg style={{fontSize: '32px',marginRight: '20px'}} className="bi bi-question-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
                                </svg>
                                <span id="Login_Heading"style={{fontSize: '14px'}}>Deploy in 3 Steps</span>
                            </div>
                            <div style={{backgroundColor: 'rgba(0,0,0,0.2', borderRadius: 10, padding: '15px', marginTop: '30px', fontSize: '11px', width: '100%', color: '#fff', display: 'flex'}}>
                                <div style={{background: '#fff', fontWeight: 'bold', fontSize: '11px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: 'var(--primary-color)', width: 20, minWidth: 20, height: 20, borderRadius: '50px'}}>
                                    1
                                </div>
                                <div style={{paddingLeft: '15px'}}>
                                <sapn onClick={()=>{downloadScripts()}} style={{color: '#fff', textDecoration: 'underline',cursor: 'pointer'}}>Click here</sapn> to download Script.json. It includes default entry path to app.js file.
                                </div>
                            </div>
                            <div style={{backgroundColor: 'rgba(0,0,0,0.2', borderRadius: 10, padding: '15px', marginTop: '10px', fontSize: '11px', width: '100%', color: '#fff', display: 'flex'}}>
                                <div style={{background: '#fff', fontWeight: 'bold', fontSize: '11px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: 'var(--primary-color)', width: 20, minWidth: 20, height: 20, borderRadius: '50px'}}>
                                    2
                                </div>
                                <div style={{paddingLeft: '15px'}}>
                                    Rename your app file to app.js and create Zip archive (.zip) of Script.json and app.js.
                                </div>
                            </div>
                            <div style={{backgroundColor: 'rgba(0,0,0,0.2', borderRadius: 10, padding: '15px', marginTop: '10px', fontSize: '11px', width: '100%', color: '#fff', display: 'flex'}}>
                                <div style={{background: '#fff', fontWeight: 'bold', fontSize: '11px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', color: 'var(--primary-color)', width: 20, minWidth: 20, height: 20, borderRadius: '50px'}}>
                                    3
                                </div>
                                <div style={{paddingLeft: '15px'}}>
                                    Upoad your app and deploy the latest version
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Index;
