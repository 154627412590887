import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'
/*
<PieGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/
function PieGraph(props) {
	return (
		<div>
			<ReactApexChart options={{
				labels:props.items,
				yaxis: {
					categories: props.items,
					labels: {
						formatter: function (value) {
						  if (value >= 100000) {
							return (value / 100000).toFixed(1) + 'lakh';
						  } else if (value >= 1000) {
							return (value / 1000).toFixed(1) + 'k';
						  }
						  return value;
						}
					  }
				},
				title: {
					text: props.title,
					align: 'left'
				},
				legend: {
					position: 'bottom',
					markers:{
						fillColors:props.colors
					}
				},
				plotOptions: {
					pie: {
					  donut: {
						labels: {
						  show: true,
						  total: {
							show: true,
							showAlways: true,
							label: 'Total',
							formatter: function (w) {
								var total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
								if (total >= 100000) {
								  return (total / 100000).toFixed(1) + 'lakh';
								} else if (total >= 1000) {
								  return (total / 1000).toFixed(1) + 'k';
								}
								return total;
							  }
							}
						}
					  }
					}},
				dataLabels: {
					enabled: true,
					formatter: function (val, any) {
						var value = any.w.config.series[any.seriesIndex];
						if (value >= 100000) {
						  return props.format + ' ' + (value / 100000).toFixed(1) + 'lakh';
						} else if (value >= 1000) {
						  return props.format + ' ' + (value / 1000).toFixed(1) + 'k';
						}
						return props.format + ' ' + value;
					  },
					style:{
						colors:[props.textColor]
					}
				},
				fill:{
					colors:props.colors
				},
				chart: {
					width: props.height,
					type: props.type?props.type:"donut",
					toolbar: {
						show: true
					  }
				},
				markers:{
					colors:props.colors
				},
				
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 200
						},
						legend: {
							show: true,
							labels:{
								colors:props.colors
							}
						}
					}
				}],
			}}  series={props.data} type={props.type?props.type:"donut"} height={props.height} />
		</div>
	)
}



PieGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

PieGraph.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350,
	format:'',
	textColor:"#000"
}

export {
	PieGraph
}

export default PieGraph
