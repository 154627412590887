import React from 'react';
import Moment from 'moment';

const Header = (props) => {


    let details = (key1,value1,key2,value2) => {
        return(
            <div style={{display:'flex',border:'1px solid var(--border-color)'}}>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {key1}
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {value1}
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {key2}
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignContent:'center',borderRight:'1px solid var(--border-color)',padding:'5px 0px'}}>
                    {value2}
                </div>
            </div>
        )
    }
    let {data} = props;
    return(
        <>
            <div style={{
                textAlign:'center',
                borderBottom:'1px solid var(--border-color)',
                fontWeight:700,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                height:40
            }}>
                PURCHASE ORDER
            </div>
            <div style={{
                minHeight:50,
                padding:10,
                borderBottom:'1px solid var(--border-color)',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
            }}>
                <p>{data.billingTo.name}</p>
                <p style={{fontSize:13,color:'var(--light-color)'}}>{data.billingTo.address}</p>
            </div>
            <div>
                {details('Po Number',data.orderId,"Created At",Moment(data.details.createdAt).format('DD-MM-YYYY'))}
            </div>
        </>
    )
}

export default Header;