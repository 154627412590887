import React from 'react'
import { EmployeeSearchable } from '../../../../../../Common/Class/Select/EmployeeSearchable'
import ButtonOutline from '../../../../../../Common/fCard/ButtonOutline';
import { NormalTable } from '../../../../../../Tables/NormalTable/NormalTable';

function ConditionOwner({Owner,setOwner,onClick,item,deleteElement,type}) {
  return (
    <>
        <div style={{'display':'flex',alignItems:'center'}}>
            <EmployeeSearchable label={`Add ${type==="INVOICE"?"Invoice":"Grn"} Owner`} employee={Owner} onSelect={employee=>{
                setOwner(employee)
            }}/>
            <div>
                <ButtonOutline
                    style={{ marginTop:25, marginLeft: 30 }}
                    title="Add"
                    onClick={onClick}
                />
            </div>
        </div>
        <div style={{'margin-top':'1rem'}}>
					<NormalTable headers={[{
						key: "name",
						name: "Name",
						weight: 3
					},
						{
							key: "actions",
							name: "",
							width: 100
						}
					]} pagination={true} mapItem={(item,index)=>{
						return (
							<div key={index} className="border-bottom fintrip-department-item table-item">
								<div className="linear-layout horizontal"
									 style={{flex: 1, cursor: 'pointer'}}>
									<div className="flex-3 linear-layout horizontal">
										<div className="flex-3">
											<p className="top">
												{item.ownerName}
											</p>
										</div>
									</div>
								</div>
								<div className="right-actions" style={{
									width: 100
								}}>
									<p onClick={()=>{deleteElement(type,item.ownerId)}} className="hoverable">Delete</p>
								</div>
							</div>
						)
					}} data={(item&&item.conditionOwners)?item.conditionOwners.filter((ele)=>{return ele.types===type}):[]}/>
				</div>
    </>
  )
}

export default ConditionOwner