import React from 'react';
import './FileCard.css';
import Docs from './images/docs.svg';
import Sheets from './images/sheets.svg';
import Slides from './images/slides.svg';
import Pdf from './images/pdf.png';
import file from './images/file.png';

/*
Supported file icons: XLS,PDF,DOC,PPX

<PROPS>
name: String (Name of the file)
*/

function FileCard(props){
    let type = props.data.path.substring(props.data.path.length-3)
    let icon;
    switch (type){
        case 'csv': icon = Sheets;break;
        case 'xlsx': icon = Sheets;break;
        case 'pdf': icon = Pdf;break;
        case 'doc': icon = Docs;break;
        case 'ppx': icon = Slides;break;
        default: icon = file;break;
    }
     
    return(
        <div className='tiles' onClick={()=>window.open(props.data.path, '_blank')}>
                <div className='file_icon'>
                    <img src={icon} alt=""/>
                </div>
                <div className='file_name'>
                    {props.data.title}
            </div>
            {
                props.onDelete?
                <div onClick={(e)=>{props.onDelete(props.data.id);e.stopPropagation();}} className='delete_btn'>
                    <svg style={{fontSize: "20px", position: 'absolute', top:"-8px", right: "-7px", color: "#333"}} className="bi bi-x-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                    </svg>
                </div>
                :<></>
            }
        </div>
    )
}

export default FileCard;