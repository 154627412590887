import React from 'react'
import Props from 'prop-types'

function BreadcrumbEnd(props){
	return (
		<p style={{color: "var(--light-color)", fontWeight: 500, fontSize: 11,marginTop:4}}>
			{props.title}
		</p>
	)

}

BreadcrumbEnd.propTypes = {
	title: Props.string.isRequired
}

export default BreadcrumbEnd
