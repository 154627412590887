import React, {useState} from 'react'
import {Modal} from "../../Common/fModal/Modal";
import {SearchableSelect} from "../../Common/Class/Select/SearchableSelec2";
import {get} from "../../../Model/Network/Config/Axios";
import { useSelector } from 'react-redux';

function OfficeModal(props) {
	let [selected, setSelected] = useState(undefined)
	let tenant = useSelector(state=>state.auth.config.tenant);
	let [items, setItems] = useState([])
	let onChange = q => {
		if (q.length > 0) {
			let data = {q};
			if(props.officeId && tenant==='NYKAA') {
				data.officeId = props.officeId
			}
			if(props.p2p){
				data.p2p="yes"
			}
			get(`/search/v1/offices`, (e, r) => {
				if (r) {
					setItems(r.offices.map(item => ({
						label: item.name,
						value: item.id,
						des:item.address,
						gstin:item.gstin
					})))
				}
			}, data)
		} else {
			setItems([])
		}
	}
	return (
		<Modal show={true}
			   des="Please search office and select it"
			   title="Select Office"
			   style={{
				   save: {
					   background: "var(--primary-color)",
					   border: "2px solid var(--primary-color)",
					   padding: '8px 15px',
					   width: '100%',
					   textAlign: 'center'
				   }
			   }}
			   close={() => {
				   props.close()
			   }}
			   onSave={() => {
				   if (selected) {
					   props.onSelect({
						   id: selected.value,
						   name: selected.label
					   })
				   }
			   }}
			   buttonName="Select"
		>
			<div className="light-group" style={{marginBottom: 20}}>
				<SearchableSelect
					items={items}
					onChange={onChange}
					label="Office"
					placeholder={"Select Office"}
					select={setSelected} value={selected}/>
			</div>
		</Modal>
	)

}


export {
	OfficeModal,

}

export default OfficeModal;
