import React from 'react';
import {connect} from 'react-redux';
import {get, post} from "../../../Model/Network/Config/Axios";
import Pdf from './pdf.png'
import Excel from './excel.png';
import ImageZoom from 'react-medium-image-zoom';
import Moment from 'moment'
import {KeyValue} from "./KeyValue";
import {Link} from "react-router-dom";
import {Modal} from '../fModal/Modal';
import ChangeLog from './Versions';
import {FloatInput} from '../Class/FloatLabelInput';
import {showToast} from '../fToast/ToastCallback';
import {Luna} from "../fLuna";
import {FloatFile} from '../Class/FloatLabelInput/FloatFile';
import {AllColors} from "../../Pages/fCommon/Colors";
import {doc} from '../fImages';
import {Currency} from '../../../Utils/Currency';


class BookingDetail extends React.Component {

	constructor(props) {
		super(props);
		let linebar = [
			{
				key: 'details',
				value: 'Details',
			},
			{
				key: 'insights',
				value: 'Insights'
			},
			{
				key: 'policy',
				value: 'Workflow Rules'
			},
			{
				key: 'attach',
				value: 'All Attachments'
			},
			{
				key:'comment & logs',
				value:"Comment & Logs"
			}
		]
		this.state = {
			data: undefined,
			file: undefined,
			pageNumber: 1,
			changelog: false,
			changeLogs: [],
			linebar: linebar,
			active: 'details',
			attachment: [],
			attachModal: false,
			fileName: '',
			seekModal: false,
			uploadfile: false,
			uploadmodal: false,
			stateLoading: false,
			luna: undefined
		}
	}



	componentDidMount() {
		this.loadExpense()
	}


	loadExpense = () => {
		get(`/expense/${this.props.item.transId}`, (e, r) => {
			if (r) {
				let data = {
					data: r,
					file: r.data.file,
					changeLogs: r.changeLogs
				}
				this.setState(data)
			}
		})

	}


	uploadAttachment = (data) => {
		post(`/expense/${this.props.item.transId}/attachment`, data, (e, r) => {
			if (r) {
				this.loadExpense()
			}
		})
	}

	markNonDuplicate = () => {
		post(`/expense/${this.props.item.transId}/original`, {}, (e, r) => {
			if (r) {
				this.loadExpense()
				this.setState({
					...this.state,
					markDuplicate:false
				})
			}
		})
	}


	seek = () => {
		let data = this.state.data ? this.state.data : this.props.item;
		if (data.owner && data.owner.ownerType === "EMPLOYEE" && (data.status === 1 || data.status === 2)) {
			if (data.support && data.support.isAvail)
				return (
					<p className="hyperlinks" style={{
						marginRight: '18px',
						color: "var(--red-color)"
					}} onClick={() => {
						if (data.support.ticketId) {
							this.setState({
								...this.state,
								luna: {
									id: data.support.ticketId,
									title: "Transaction Clarification " + data.transId,
									des: "Ticket created for this txn appears below",
									closed: !data.support.isActive
								}
							})
						} else {
							this.setState({
								...this.state,
								seekModal: true
							})
						}
					}}>
						{data.support.ticketId ? "View Ticket" : "Create Ticket"}
					</p>
				)
		}
	}

	_startClarification = () => {
		let data = this.state.data ? this.state.data : this.props.item;
		let message = this._seekClarification.value();
		if (message.length < 2) {
			showToast({
				type: 'error',
				message: 'Please enter a valid message'
			})
			return;
		}

		get(`workspace/support/${data.transId}/transaction.ticket`, (e, r) => {
			if (r) {
				this.loadExpense()
				this.setState({
					...this.state,
					luna: {
						id: r.roomId,
						title: "Transaction clarification " + data.transId,
						des: message,
						closed: false
					},
					seekModal: false
				})
			}
		}, {
			remarks: message
		})

	}


	mapLinebar = (item, index) => {
		return (
			<div key={index} onClick={() => this.setState({
				...this.state,
				active: item.key
			})}

				style={{
					padding: '0px 0 12px',
					marginRight: 32,
					fontSize: 14,
					color: 'inherit',
					fontWeight: 600,
					textTransform: 'uppercase',
					cursor: 'pointer',
					position: 'relative',
					top: 3
				}}

				className={this.state.active === item.key ? "active-line" : null}>
				{item.value}
			</div>
		)
	}

	attachment = (data) => {
		return (
			<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{
						data.files ? data.files.length > 0 ? (
							data.files.map((item, index) => {
								return (
									<div key={index}>
										{
											item.path ? item.path.toLowerCase().includes("pdf") ?
												<a rel="noopener noreferrer" href={item.path} target="_blank"><img
													src={Pdf}
													alt="pdf icon" style={{
														width: 120,
														height: 120,
														marginRight: 12,
														border: '1px solid var(--border-color)'
													}} /></a>
												: item.path.toLowerCase().includes("csv") || item.path.includes('xlsx') ||  item.path.includes('xls') ?
													<a rel="noopener noreferrer" href={item.path} target="_blank"><img
														src={Excel}
														alt="pdf icon" style={{
															width: 120,
															height: 120,
															marginRight: 12,
															border: '1px solid var(--border-color)'
														}} /></a>
													:
													item.path.toLowerCase().includes("doc") ?
														<a rel="noopener noreferrer" href={item.path} target="_blank"><img
															src={doc}
															alt="pdf icon" style={{
																width: 120,
																height: 120,
																marginRight: 12,
																border: '1px solid var(--border-color)'
															}} /></a>
														:
														<ImageZoom zoomImage={{
															src: `${item.path}`,
															alt: 'object-fit',
															style: {
																objectFit: 'contain'
															}
														}} image={{
															src: `${item.path}`, alt: 'object-fit',
															style: {
																border: '1px solid var(--border-color)',
																objectFit: 'cover',
																width: 120,
																marginRight: 12,
																height: 120
															}
														}} /> : null
										}
									</div>
								)
							})) : <div style={{
								width: 120,
								height: 120,
								borderRadius: 6,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								border: '1px dashed var(--light-color)',
								cursor: 'pointer',
								marginLeft: 4,
							}}>
								<p style={{
									fontSize: 11,
									textAlign: 'center',
									color: "var(--light-color)"
								}}>
									No Attachment Found
							</p>
							</div> : null
					}
				</div>
				<div>
					<div onClick={() => {
						this.setState({
							uploadmodal: true
						})
					}} style={{
						width: 120,
						height: 120,
						borderRadius: 6,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						border: '1px dashed var(--light-color)',
						cursor: 'pointer',
						marginLeft: 4,
					}}>
						<p style={{
							fontSize: 40,
							textAlign: 'center',
							color: 'var(--light-color)'
						}}>+</p>
						<p style={{
							fontSize: 11,
							textAlign: 'center',
							color: "var(--light-color)"
						}}>
							Add Attachment
						</p>
					</div>
				</div>
			</div>
		)
	}

	policyvoilation = (data) => {
		return (
			<div style={{ width: '100%' }}>
				<p style={{
					color: "var(--light-color)",
					fontSize: 13,
					marginBottom: 4,
					fontWeight: 400
				}} className="bottom">
					Policy Violations
				</p>
				{
					!data.data || !data.data.violations || data.data.violations.length === 0
						? <p className="top">
							No Policy Violations
						</p> : <div>
							{
								data.data.violations.map((item, index) => (
									<p key={index} style={{ marginBottom: 10 }} className="top">{item.error}</p>))
							}
						</div>
				}
				<div style={{ marginTop: 15 }}>
					<div style={{ marginBottom: 14, marginRight: 8 }}>
						<p style={{
							color: "var(--light-color)",
							fontSize: 13,
							marginBottom: 4,
							fontWeight: 400
						}} className="bottom">
							Workflow Approval Required
						</p>
						<p className="top">
							{data.permission ? "Yes" : "-"}
						</p>
					</div>
					{
						data.permission ?
							<div className="linear-layout horizontal" style={{
								paddingBottom: 5
							}}>
								<div className="flex-1">
									<KeyValue title="Permission Type" value={`${data.permission.permissionType}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="Asked On"
										value={`${Moment(data.permission.askedOn).format("DD MMM YYYY")}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="Upadted At"
										value={`${Moment(data.permission.grantedOn).format("DD MMM YYYY")}`} />
								</div>
							</div> : null
					}
					{
						data.permission && data.permission.granters.length > 0 ?
							<div>
								<p style={{
									color: "var(--light-color)",
									fontSize: 17,
									marginBottom: 6,
									fontWeight: 600
								}} className="bottom">
									Permission Granters
								</p>
								{
									data.permission.granters.map(item => (
										<div className="linear-layout horizontal" style={{
											paddingBottom: 12,
											width: 500
										}}>
											<div className="flex-1">
												<KeyValue to={`/app/organisation/employee/${item.code}`}
													title="Granted By" value={`${item.name}`} />
											</div>
											<div className="flex-1">
												<KeyValue
													title="Status" value={`${item.status}`} />
											</div>
											<div className="flex-1">
												<KeyValue title="Updated At"
													value={`${Moment(item.grantedOn).format("DD MMM YYYY")}`} />
											</div>
										</div>
									))
								}
							</div> : null
					}
				</div>
			</div>
		)

	}

	insights = (data) => {
		return (
			<div style={{ width: '100%' }}>
				<p style={{
					fontWeight: 600,
					color: "var(--light-color)",
					fontSize: 15,
					marginBottom: 8
				}}>
					Insights
				</p>
				<div style={{
					display: 'flex',
					flexWrap: 'wrap',
					width: '100%'
				}}>
					{
						data.insights && data.insights.length > 0 ?
							data.insights.map((item, index) => (
								<KeyValue key={item.key} title={item.key} value={item.value} style={{
									width: '49%',
									borderLeft: index % 2 === 0 ? 'none' : '1px solid var(--border-color)',
									paddingLeft: index % 2 === 0 ? 'none' : '20px'
								}}
								/>)) :
							<p style={{ marginBottom: 20 }}>-</p>
					}
				</div>
			</div>
		)
	}

	details = (data) => {
		return (
			<div className="linear-layout horizontal">
				<div className="flex-9">
					<div className="linear-layout horizontal" style={{
						paddingBottom: 12,
						minHeight: 250,
					}}>
						<div className="flex-2">
							<p style={{
								fontWeight: 600,
								color: "var(--light-color)",
								fontSize: 15,
								marginBottom: 8
							}}>
								Form
							</p>
							{
								data.form && data.form.length > 0 ?
									data.form.map(item => (
										<KeyValue key={item.key} title={item.key} value={item.value} />)) :
									<p style={{ marginBottom: 20 }}>-</p>
							}

						</div>
						<div style={{ borderLeft: "1px solid var(--border-color)" }} className="flex-3">
							<div style={{ display: 'flex' }}>
								<div style={{
									marginBottom: 12
								}}>
									<p style={{
										color: "var(--light-color)",
										fontSize: 13,
										marginLeft: 20,
										marginBottom: 4,
										fontWeight: 400
									}} className="bottom">
										Done for
									</p>
									<div style={{
										paddingLeft: 20,
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap'
									}}>
										{
											data.owners && data.owners.length > 0 ? data.owners.map((item, index) =>
												<div
													key={index} style={{
														marginRight: 12,
														marginBottom: 12
													}}>
													<Link target="_blank"
														to={`/app/organisation/employee/${item.ownerId}`}
														className="top">
														{item.ownerName}
													</Link>
												</div>) : <p className="top">
													-
											</p>
										}
									</div>
								</div>
								{
									data.voucherId ?
										<div style={{
											marginBottom: 12
										}}>
											<p style={{
												color: "var(--light-color)",
												fontSize: 13,
												marginLeft: 20,
												marginBottom: 4,
												fontWeight: 400
											}} className="bottom">
												VoucherId
											</p>
											<div style={{
												paddingLeft: 20,
												display: 'flex',
												flexDirection: 'row',
												flexWrap: 'wrap'
											}}>
												<Link target="_blank"
													to={`/app/others/settlements/offline/history/${data.voucherId}`}>{data.voucherId}</Link>
											</div>
										</div> : null
								}
							</div>
							<div>
								<div style={{
									height: 1,
									marginTop: 10,
									marginBottom: 10,
									background: "var(--border-color)"
								}} />
								<div style={{ paddingLeft: 20 }}>
									<p style={{
										color: "var(--light-color)",
										fontSize: 13,
										marginBottom: 14,
										fontWeight: 600
									}} className="bottom">
										Details
									</p>
									{data ? data.data ? data.data.details : null : null}
								</div>


								{
									data.graywolf ?
										<div style={{
											height: 1,
											padding: 20
										}}>
											<div >
												<p style={{
													color: "var(--light-color)",
													fontSize: 13,
													marginBottom: 14,
													fontWeight: 600
												}} className="bottom">
													Duplicate To:
												</p>
												<div style={{ display: 'flex' }}>
													{
														data.graywolf.transIds.map(item => {
															return (
																<Link target="_blank" to={`/app/transaction/${item}`} style={{
																	paddingRight: 15,
																	cursor: 'pointer',
																	color: "var(--primary-color)",
																	fontWeight: 600
																}}>
																	{item}
																</Link>
															)
														})
													}
												</div>
												<p style={{
													color: 'var(--light-color)',
													fontSize: 13,
													marginBottom: 8,
													marginTop: 10,
													fontWeight: 600
												}}>
													Reason:
												</p>
												<div style={{
													cursor: 'pointer',
													fontSize: 18
												}}>
													{data.graywolf.reason ? data.graywolf.reason : 'NA'}
												</div>
											</div>
										</div> : undefined
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	status = (item) => {
		var text = "";
		switch (item.status) {
			case 0:
				text = "Not Submitted"
				break
			case 1:
				if (item.transactionType === "SELFPAID" || item.transactionType === "PETTYPAID") {
					if (item.seekClarification) {
						text = "Employee Seek Clarification"
					} else if (item.voucherId) {
						text = "Voucher Approval Pending"
					} else {
						text = "Voucher Submission Pending"
					}
				} else {
					if (item.seekClarification) {
						text = "Employee Seek Clarification"
					} else
						text = "Workflow Approval Pending"
				}
				break
			case 2:
				if (item.seekClarification) {
					text = "Employee Seek Clarification"
				} else {
					text = "Finance Approval Pending"
				}
				break
			case 3:
				if (item.transactionType === "SELFPAID" || item.transactionType === "PETTYPAID") {
					if (item.reimbursed)
						text = "Settled By Finance"
					else
						text = "Finance Settlement Pending"
				}
				else
					text = "Finance Approved"
				break
			case 5:
				text = "Declined"
				break
			case 6:
				text = "Cancelled"
				break
			case 7:
				text = "Failed";
				break
			default:
				text = "Not Defined"
				break
		}
		if (item.refunded) {
			text = `${text} (Refunded)`
		}
		return text;
	}

	 sortOwnCarIntraLocation = (data) => {

		const locations = data.filter(item => item.key.startsWith('LOCATION')).sort((a, b) => a.key.localeCompare(b.key));
		const nonLocations = data.filter(item => !item.key.startsWith('LOCATION'));
	
		const sorted = [
			...nonLocations.filter(item => item.key === 'START'),
			...locations.filter(item => item.key === 'LOCATION 2'),
			...locations.filter(item => item.key === 'LOCATION 3'),
			...locations.filter(item => item.key === 'LOCATION 4'),
			...nonLocations.filter(item => item.key === 'END'),
			...nonLocations.filter(item => !['START', 'END'].includes(item.key)),
		];
	
		return(
			<>
			<div style={{
					display: 'flex',
					flexWrap: 'wrap',
					width: '100%'
				}}>
					{
						sorted && sorted.length > 0 ?
							sorted.map((item, index) => (
								<KeyValue key={item.key} title={item.key} value={item.value} style={{
									width: '49%',
									borderLeft: index % 2 === 0 ? 'none' : '1px solid var(--border-color)',
									paddingLeft: index % 2 === 0 ? 'none' : '20px'
								}}
								/>)) :
							<p style={{ marginBottom: 20 }}>-</p>
					}
				</div>
				
			</>
		)
	}

	comment = (mData) => {
		return(
			<div>
				<div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px'}}>Comment</div>
				 {
                       mData.comments&& mData.comments.length>0?
					   	mData.comments.map((item, index)=>{
                                return(
                                    <div key={`comments-${index}`}><span style={{color: '#666', marginRight: '5px'}}>{Moment(item.time).format(" hh:mm a DD MMM YY")}</span>: {item.message} - <span>by : {item.owner.ownerName}</span></div>
                                )
                            })
                            : 'No Comments'
                    }
                    <div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px'}}>Logs</div>
                    {
                       mData.logs&& mData.logs.length>0?
					   mData.logs.map((item, index)=>{
                                return(
                                    <div key={`comments-${index}`}><span style={{color: '#666', marginRight: '5px'}}>{Moment(item.time).format(" hh:mm a DD MMM YY")}</span>: {item.log} - <span>by : {item.owner}</span></div>
                                )
                            })
                            : 'No Logs'
                    }
			</div>
		)
	}

	render() {
		let data = this.state.data ? this.state.data : this.props.item;
		return (
			<div>
				{this.props.children}
				<div className="expanded-item" style={this.props.style ? this.props.style : { padding: '20px 25px' }}>
					<div className="linear-layout horizontal">
						<div style={{
							width: 140,
							height: 150,
						}}>
							{
								this.state.file ? (this.state.file.toLowerCase().includes('pdf') ?
									<a rel="noopener noreferrer" href={this.state.file} target="_blank"><img
										src={Pdf}
										alt="pdf icon"
										style={{
											objectFit: 'contain',
											width: '110px',
											height: '110px',
											border: '1px solid var(--border-color)'
										}} /></a>
									:
									this.state.file.toLowerCase().includes("csv") || this.state.file.toLowerCase().includes('xlsx') || this.state.file.toLowerCase().includes('xls') ?
										<a rel="noopener noreferrer" href={this.state.file} target="_blank"><img
											src={Excel}
											alt="pdf icon"
											style={{
												objectFit: 'contain',
												width: '110px',
												height: '110px',
												border: '1px solid var(--border-color)'
											}} /></a> :
										this.state.file.includes("doc") ?
											<a rel="noopener noreferrer" href={this.state.file} target="_blank"><img
												src={doc}
												alt="pdf icon"
												style={{
													objectFit: 'contain',
													width: '110px',
													height: '110px',
													border: '1px solid var(--border-color)'
												}} /></a> :

											<ImageZoom zoomImage={{
												src: `${this.state.file}`,
												alt: 'object-fit',
												style: {
													objectFit: 'contain'
												}
											}} image={{
												src: `${this.state.file}`, alt: 'object-fit',
												style: {
													objectFit: 'contain',
													width: '110px',
													height: '110px',
													border: '1px solid var(--border-color)'
												}
											}} />
								)
									: <p style=
										{{
											width: '110px',
											height: '110px',
											display: 'flex',
											textAlign: 'center',
											fontSize: 11,
											alignItems: 'center',
											justifyContent: 'center',
											border: '1px solid var(--border-color)'
										}}>No Attachment Found</p>

							}
						</div>
						<div style={{
							flex: 9
						}}>
							<div className="linear-layout horizontal" style={{}}>
								<div className="flex-1">
									<KeyValue title="TRANS ID" value={`${data.transId}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="SERVICE" value={`${data.service}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="AMOUNT" value={<p> <Currency />{data.amount}</p>} />
								</div>
								<div className="flex-1">
									<KeyValue title="Status" value={this.status(data)} />
								</div>
								<div className="flex-1">
									<KeyValue title={
										data.transType === "WALLETPAID" || data.transType === "COMPANYPAID" || data.transType === "CARDPAID" ? "Penalty Amount" : data.transType === "SELFPAID" ? "Claimed Amount" : "Settled Amount"
									} value={<p> <Currency /> {data.reimbursable}</p>} />
								</div>
							</div>
							<div className="linear-layout horizontal" style={{
								paddingBottom: 12
							}}>
								<div className="flex-1">
									<KeyValue title="BOOKED ON"
										value={`${Moment(data.bookedOn).format("DD MMM YYYY HH:mm a")}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="Transaction Date"
										value={`${Moment(data.date).format("DD MMM YYYY HH:mm a")}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="Done by" value={`${data.owner ? data.owner.ownerName : null}`} />
								</div>
								<div className="flex-1">
									<KeyValue title="Voucher" value={data.voucherId} />
								</div>
								<div className="flex-1">
									<KeyValue title="Refund Amount" value={`${data.refundAmount}`} />
								</div>
							</div>
						</div>
					</div>
					<div className="linear-layout horizontal">
						<div style={{
							borderBottom: '2px solid rgb(222,222,222)',
							flex: 1,
							marginTop: 0,
							marginBottom: 18,
							display: 'flex'
						}}>
							{
								this.state.linebar.map(this.mapLinebar)
							}
						</div>
					</div>
					<div className="linear-layout horizontal">
						{this.state.active === "details" ? this.details(data) : null}
						{this.state.active === "insights" ? data.service.toLowerCase()==='own car intra' ? this.sortOwnCarIntraLocation(data.insights): this.insights(data) : null}
						{this.state.active === "policy" ? this.policyvoilation(data) : null}
						{this.state.active === "attach" ? this.attachment(data) : null}
						{this.state.active === "comment & logs" ? this.comment(data) : null}

					</div>
					<div style={{
						display: 'flex', paddingTop: 18, borderTop: '1px solid var(--border-color)'
						, flexDirection: 'row-reverse', marginTop: 12
					}}>


						{
							this.state.file ?
								<a rel="noopener noreferrer" href={data.data.file} target="_blank">
									View Attachment
								</a> : null
						}
						{
							!this.props.item.color ?
								<p onClick={() => {
									this.setState({
										...this.state,
										colorModal: true
									})
								}} className="hyperlinks" style={{ color: "#616161", marginRight: 12 }}>
									Add Color Tag
								</p> :
								<p onClick={() => {
									this.props.removeTag({
										id: this.props.item.transId
									})
								}} className="hyperlinks" style={{ color: 'red', marginRight: 12 }}>
									Remove Color Tag
								</p>
						}
						{this.seek()}
						<p onClick={() => {
							this.setState({
								...this.state,
								changelog: true
							})
						}} className="hyperlinks" style={{
							marginRight: 18,
							color: '#AB47BC'
						}}>View Logs</p>
						{
						  this.state.data && this.state.data.graywolf && this.state.data.graywolf.fraud ?
						<p onClick={() => {
							this.setState({
								...this.state,
								markDuplicate:true
							})
						}} className="hyperlinks" style={{
							marginRight: 18,
							color: '#AA47BC'
						}}>Mark Not Duplicate</p>
						: null
						}


					</div>
				</div>
				{this.state.changelog ? <Modal show={this.state.changelog} hideButton={true} close={() => {
					this.setState({
						...this.state,
						changelog: false
					})
				}} title="Change Log" des="You can see the status of your expense here" onSave={() => {
				}}>
					<ChangeLog changeLogs={this.state.changeLogs} />
				</Modal> : null}
				{
					this.state.seekModal ? <Modal
						title="Raise Ticket"
						des="Enter the Reason To Raise Ticket"
						onSave={this._startClarification}
						style={{
							save: {
								background: "#3d4cfa",
								border: "2px solid #3d4cfa",
								padding: '12px 15px',
								width: '100%',
								textAlign: 'center'
							}
						}}
						show={this.state.seekModal}
						buttonName="Create Ticket"
						close={() => {
							this.setState({
								...this.state,
								seekModal: false
							})
						}}>
						<FloatInput type="text" title="Enter Remarks" handleRef={ref => this._seekClarification = ref} />
					</Modal> : null
				}
				{
					this.state.uploadmodal ?
						<Modal show={this.state.uploadmodal}
							title="Upload File"
							des="Please attach the file that you want to upload "
							close={() => {
								this.setState({
									uploadmodal: false
								})
							}}
							stateLoading={this.state.stateLoading}
							onSave={() => {
								let url = this._file.value();
								if (!url) {
									showToast({
										type: 'error',
										message: 'Please select valid file'
									})
									return;
								}
								this.uploadAttachment({ file: url })
								this.setState({
									...this.state,
									uploadmodal: false,
									stateLoading: false
								})
							}}
							style={{
								save: {
									width: '100%',
									fontSize: 17,
									height: 44,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									...this.state.stateLoading ? {
										background: 'var(--white-color)'
									} : null
								}
							}}
						>

							<FloatFile title="File" ref={ref => this._file = ref} />

						</Modal> : null
				}
				{this.state.luna ?
					<Luna onClose={id => {
						post(`/workspace/support/${id}/close`, {}, (e, r) => {
							this.setState({
								...this.state,
								luna: undefined
							})
							this.loadExpense()
						})
					}} closed={this.state.luna.closed} id={this.state.luna.id} title={this.state.luna.title}
						des={this.state.luna.des}
						close={() => {
							this.setState({
								...this.state,
								luna: undefined
							})
						}} /> : undefined}
				{
					this.state.colorModal ?
						<Modal buttonName="Add Color Tag" onSave={() => {
							if (this.state.selectedColor) {
								if (this.props.onTagAdd) {
									this.props.onTagAdd({
										color: this.state.selectedColor,
										id: this.props.item.transId
									})
									this.setState({
										colorModal: false
									})
								}
							}
						}}
							title="Color Tag"
							des="Add color tag by selecting below." close={() => {
								this.setState({
									colorModal: false
								})
							}} style={{
								save: {
									width: '100%',
									textAlign: 'center',
									fontSize: 17,
									background: "var(--red-color)",
									border: "2px solid var(--red-color)",
									marginTop: 20,
									padding: "12px 20px"
								},
							}} show={true}>
							<p style={{
								marginBottom: 12,
								fontWeight: 400,
								fontSize: 13
							}}>
								Select Color
							</p>
							<div style={{
								display: 'flex',
								flexWrap: 'wrap'
							}}>
								{
									AllColors.map(item => (
										<div onClick={() => {
											this.setState({
												...this.state,
												selectedColor: item
											})
										}} key={item} style={{
											cursor: 'pointer',
											width: 22,
											marginRight: 5,
											height: 22,
											padding: 3,
											borderRadius: 11,
											border: item === this.state.selectedColor ? `2px solid ${item}` : "2px solid var(--border-color)"
										}}>
											<div style={{
												width: 12,
												height: 12,
												backgroundColor: item,
												borderRadius: 8
											}} />
										</div>
									))
								}
							</div>
						</Modal> : undefined
				}

				{this.state.markDuplicate ? <Modal show={this.state.markDuplicate} hideButton={true} close={() => {
					this.setState({
						...this.state,
						markDuplicate: false
					})
				}} title="Mark Non Duplicate" des="you can mark this expense non-duplicate" onSave={() => {
				}}>
					<p style ={{fontSize:14,color:'var(--text-color)'}}>Are you sure about to mark this expense non-duplicate ?</p>

					<div className="btn btn-portlet" style ={{
						marginTop:'35px',
						maxWidth:'100%'
					}} onClick={()=>{
						this.markNonDuplicate()
					}}>Mark</div>
				</Modal> : null}
			</div>
		)
	}

}

const mapStateToProps = (state) => {
	return {

	}
}

const BookingDetails = connect(mapStateToProps, null)(BookingDetail)

export {
	BookingDetails
}
