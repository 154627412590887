import React from 'react'
import PropTypes from 'prop-types'
import ButtonOutline from "./fCard/ButtonOutline";
import { ReadOnly } from './fCard/ButtonOutline/ReadOnly';

function Title(props) {
	return (
		<div style={{position:'relative'}}>
			<div id={props.id}  style={{
				fontWeight:700,
				fontSize:12,
				marginTop:20,
				letterSpacing:1.5,
				marginLeft:props.marginLeft,
				marginBottom:20
			}}>{props.title.toUpperCase()}</div>
			{props.children}
		</div>
	)
}

Title.propTypes = {
	title:PropTypes.string.isRequired
}

function InputContainer(props) {
	if(props.readOnly) {
		return <></>
	}
	return(
		<div style={{...props.style}}>
			<Title id={props.id} marginLeft={props.marginLeft?props.marginLeft:38} title={props.title}/>
			{props.button?<div style={{
				padding:'20px 40px',
				paddingTop:0
			}}>
				<ReadOnly><ButtonOutline title={props.button.title} onClick={props.button.onClick}/></ReadOnly>
			</div>:undefined}
			<div className="input-container" style={{...props.container}}>
				{props.children}
			</div>
		</div>
	)
}


InputContainer.propTypes = {
	title:PropTypes.string.isRequired
}

export {
	Title,InputContainer
}


export default Title;
