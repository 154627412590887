import React, { useRef, useState } from 'react';
import { useParams} from 'react-router-dom';
import { HalfModal } from '../../../../../Common/fModal/HalfModal';
import Item from './Item';
import { DatePickerCustom, FunctionalInput } from '../../../../../Common/fInputs';
import { FileUpload } from '../../../../../Common/fInputs/FunctionalFile';
import PhotoTile from '../../../../../Common/fPhoto';
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import axios, { post } from '../../../../../../Model/Network/Config/Axios';
import Moment from 'moment';
import { HARDCODEURL } from '../../../../../../Model/Network/Api/config';

const GrnFill = (props) => {

    let {data} =props;
    let {orderId,procured,currency} = data;
    const [grnItem,setGrnItem] = useState({});
    const [conditionItem,setConditionItem] = useState({});
    const [attachment,setAttachment]= useState([]);
    const [loading,setLoading] = useState(false);
    const GRNDATE = useRef(),REMARKS=useRef();
    const {id} = useParams();


    let mapItem = (item,index) =>{
        return(
            <Item 
                onChange={(value)=>{
                    let allObject = {...grnItem};
                    if(!value) {
                         delete allObject[item.id];
                    } else {
                        allObject[item.id] = value
                     }
                    setGrnItem(allObject);
                }} 
                onConditionChange={(value,i)=>{
                    let allObject = {...conditionItem};
                    if(!value) {
                         delete allObject[i.poConditionItemId];
                    } else {
                        allObject[i.poConditionItemId] = {
                            value,
                            item:i
                        }
                     }
                    setConditionItem(allObject);
                }}
                key={index} 
                item={item} 
                index={index} 
                currency={currency} />
        )
    }

    let completeGrn = async () => {
        if(Object.keys(grnItem).length===0) {
            showToast({
                type:'error',
                message:'Please fill atleast 1 item'
            })
            return
        } else {

            let date = GRNDATE.current.value;
            let remarks = REMARKS.current.value;
            if(!date) {
                showToast({
                    type:'error',
                    message:"Please select the grn date"
                })
                return;
            }

            if(!remarks) {
                showToast({
                    type:'error',
                    message:"Please enter the remarks"
                })
                return
            }

            date = Moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
            let data = {
                date:date,
                items:Object.keys(grnItem).map(item=>({
                    qtty:+grnItem[item],
                    id:item
                })),
                files:[...attachment],
                remarks
            }

            setLoading(true);
           

            post(`vendor/po/requests/${id}/grn`,{data}, async (e,r)=>{
                if(r) {

                    let endPoint = [];
                    Object.keys(conditionItem).forEach(i=>{
                        endPoint.push({
                            url:`${HARDCODEURL}vendor/procurement/poCondition/postPoItemConditionGrn/${i}`,
				            method:'POST',
				            data:{
					            "vendorId": conditionItem[i].item.vendorId,
                                "grnId": r.grnId,
                                "qty":+conditionItem[i].value,
                                "price": +conditionItem[i].item.price
				            }
                        })
                    })

                    if(Object.keys(conditionItem).length>0) {
                        const createAxiosRequest = endPoint.map(i=>axios(i));

                        await Promise.all(createAxiosRequest)
			                .then(res=>{
				            return res;
			            })
                        setLoading(false);
                        showToast({
                            type:'success',
                            message:'GRN done successfully'
                        })
                        props.close();

                    } else {
                        setLoading(false);
                        showToast({
                            type:'success',
                            message:'GRN done successfully'
                        })
                        props.close();

                    }

                    
                } else {
                    setLoading(false);
                }
            })
        }
    } 


    return(
        <HalfModal
            title={orderId}
            close={props.close}
        >
            <p style={{fontWeight:'bold',marginBottom:20}}>GRN Items</p>
            <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        border: '1px solid var(--border-color)',
                        background:'#dedede'
                    }}>
                        {
                            [
                                {
                                    name:'Id',
                                    width:50
                                },
                                
                                {
                                    name: "Details",
                                    flex: 2
                                },
                                {
                                    name:'Price',
                                    flex:1
                                },
                                {
                                    name: "Available Quantity/unit",
                                    flex: 1
                                },
                                {
                                    name: "Delivered",
                                    flex: 1
                                }
                                
                            ].map((item, i) => {
                                return (
                                    <div key={i} style={{
                                        borderRight:'1px solid var(--border-color)',
                                        ...item.width ? {
                                            width: item.width
                                        } : {
                                                flex: item.flex
                                            }
                                    }}>
                                        <p style={{
                                            padding: 10,
                                            fontSize: 13,
                                            color: "var(--text-color)"
                                        }}>
                                            {item.name}
                                        </p>
                                    </div>
                                )
                            })
                        }
                </div>
                {
                    procured.filter(val => val.qtty !== val.approved).map(mapItem)
                }

                <DatePickerCustom ref={GRNDATE} label="GRN Date" formate="DD-MM-YYYY" />

                <FunctionalInput title="Remarks" ref={REMARKS} /> 

                <p style={{
                    fontSize:13,
                    fontWeight:'bold',
                    marginBottom:5
                }}>Attachment</p>
                <div style={{display:'flex',alignItems:'center'}}>
                        <FileUpload 
                            onUpload={({file})=>{ 
                                let files = [...attachment]
                                files.push(file);
                                setAttachment(files)
                             }}
                             
                             />
                        {
                            attachment.length>0 ?
                            attachment.map((val,index)=>{
                                return(
                                    <PhotoTile 
                                        key={index} 
                                        link={val}
                                        onDelete={()=>{
                                            let all = [...attachment];
                                            all = all.filter(i=>i!==val);
                                            setAttachment(all)
                                        }}
                                        
                                        />
                                )
                            })
                            : null
                        }
                    
                </div>
       
                <div onClick={()=>{
                    if(loading) {

                    } else {
                        completeGrn()
                    }
                }} className='btn btn-portlet' style={{ marginTop:20}}>
                    {
                        loading ?
                        "Loading..."
                        :
                        "Complete GRN"
                    }
                </div>
        </HalfModal>
    )
}

export default GrnFill;