import React, { useState, useEffect } from 'react';
import { showToast ,handleError} from '../../../../Common/fToast/ToastCallback';
import { TitleTopHeader,Title as Header } from '../../../../Header/TopHeader';
import { pluginsGetApi,pluginsPostApis } from '../../../../../Model/Network/Config/Axios';
import { StateButton } from '../../../../Common/fStateButton';


export default function CJDarcl() {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        pluginsGetApi('', (e, r) => {
        if (r) {
        
        }
        });
    }, []);

   

    const activate = () => {
        setLoading(true);
        pluginsPostApis('/plugins/custom/darclSap/activate', {}, (e, r) => {
            if(r) {
                setLoading(false);
                showToast({
                    type: 'success',
                    message: 'Event triggered successfully!'
                })
            } else {
                setLoading(false);
                handleError(e);
            }
        })
    }

    const uninstall = () => {
        setLoading(true);
        pluginsPostApis('/plugins/custom/darclSap/uninstall', {}, (e, r) => {
            if(r) {
                setLoading(false);
                showToast({
                    type: 'success',
                    message: 'Event triggered successfully!'
                })
            } else {
                setLoading(false);
                handleError(e);
            }
        })
    }

    return (
        <div>
            <TitleTopHeader items={[]} title="Sync" des="Syncing of data." />
            <Header title="Sync" des="Syncing of data." />
            <div
                className="title-container"
                style={{
                padding: 18,
                }}
            >
                <div>
                    {
                        loading ?
                            <StateButton /> :
                            <button onClick={activate} className="btn btn-success" style={{ marginTop: 12 }}>
                                Activate
                            </button>
                    }
                </div>
                <div>
                    {
                        loading ?
                            <StateButton /> :
                            <button onClick={uninstall} className="btn btn-danger" style={{ marginTop: 12 }}>
                                Deactivate
                            </button>
                    }
                </div>
            </div>
        </div>
    );
};
