import React from 'react';
import Props from 'prop-types';
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
import close from './close.png';
import {pluginsPostApis} from '../../../../../Model/Network/Config/Axios';
import {showToast} from '../../../../Common/fToast/ToastCallback';

class Sync extends React.Component {

    constructor(props) {
        super(props);
        let eventRequest = this.props.sync.eventRequest;
        if (typeof eventRequest === 'string') {
            eventRequest = JSON.parse(eventRequest)
        }
        this.state = {
            events: eventRequest,
            loading: false
        }
    }



    onUpdate = () => {

        this.setState({
            loading: true
        })

        let data = this.state.events;


        pluginsPostApis(`/plugins/custom/cjdarcl/sap/sync/trigger/${this.props.id}`, { data }, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Line Item synch successfully'
                })
                this.props.onUpdate();
                this.setState({
                    ...this.state,
                    loading: false
                })

            }

            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.message
                })

                this.setState({
                    ...this.state,
                    loading: false
                })
            }
        })
    }






    render() {
        return (
            <div style={{
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.1)',
                zIndex: 1111,
                width: '100%',

            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 80
                }}>
                    <div style={{
                        backgroundColor: 'var(--white-color)',
                        width: 620,
                        minHeight: 650
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: 20
                        }}>
                            <div>
                                <h1 style={{
                                    fontSize: 25,
                                    color: 'var(--text-color)',
                                    fontWeight: 800
                                }}>Update Field</h1>
                                <p style={{
                                    fontSize: 14,
                                    color: 'inherit'
                                }}>Update field to post again in sap</p>
                            </div>
                            <img src={close} alt="" style={{
                                height: 18,
                                width: 18,
                                cursor: 'pointer'
                            }} onClick={this.props.onClose} />
                        </div>
                        <JSONInput
                            onChange={e => {
                                this.setState({
                                    ...this.state,
                                    events: e.jsObject
                                })
                            }}
                            placeholder={this.state.events} // data to display
                            theme="light_mitsuketa_tribute"
                            locale={locale}
                            colors={{
                                string: "#DAA520" // overrides theme colors with whatever color value you want
                            }}
                            height="550px"
                            width="620px"

                        />

                        <div className="btn btn-portlet" style={{
                            margin: 20,
                            maxWidth: 'calc( 100% - 40px)'
                        }} onClick={this.onUpdate}>
                            Update
                    </div>

                    </div>



                </div>

            </div>
        )
    }
}

Sync.defaultProps = {
    data: {}
}

Sync.propsTypes = {
    data: Props.object
}

export default Sync;
