import React  from 'react'
import {pluginsPostApis} from "../../../../../Model/Network/Config/Axios";
import { FunctionalInput } from '../../../../Common/fInputs';
import { Modal } from '../../../../Common/fModal/Modal';
import { showToast } from '../../../../Common/fToast/ToastCallback';

class Connect extends React.Component {



	constructor(p) {
		super(p);
		this.state = {
			connect: false,
			modal:false
		}
		
	}
	

	componentDidMount() {
		if (this.props.handle) {
			this.props.handle(this);
		}
	}

	connect = () => {
		this.setState({
			connect: true
		})
	}

	_connect = () => {
		this.setState({
			...this.state,
			modal:true
		})
	}


	render() {
		let item = this.props.item;
		if (item && item.installed) {
			return (
				<div style={{
					padding: 12,
					width: "25%"
				}}>
					<div style={{
						padding: 18,
						background:"var(--white-color)",
						position: 'relative',
						borderRadius:8,
						boxShadow:"7px 4px 8px rgba(1,1,1,.05)",
						border: '1px solid var(--border-color)',
					}}>
						{
							item.status === "ERRROR" ? <p style={{
								color: 'red',
								position: 'absolute',
								right: 12,
								fontWeight: 700,
								fontSize: 11,
								top: 12
							}}>Error</p> : <p style={{
								color: 'var(--primary-color)',
								position: 'absolute',
								right: 12,
								fontWeight: 700,
								fontSize: 11,
								top: 12
							}}>{item.status}</p>
						}
						<img src={item.logo} alt={item.name} style={{
							height: 30
						}}/>
						<p style={{
							fontWeight: 700,
							fontSize: 19,
							marginTop: 15,
							color: "var(--text-color)"
						}}>
							{item.name}
						</p>
						<p style={{
							fontWeight: 400,
							fontSize: 11,
							marginTop: 12,
							height: 65,
							marginBottom: 12,
							color: "var(--light-color)"
						}}>
							{item.description}
						</p>
						<div style={{
							display: 'flex',
							marginTop: 20,
							flexDirection: 'row'
						}}>
							<p onClick={() => {
								this.props.history.push("/app/apps/buildrun")
							}} style={{
								fontSize: 14,
								fontWeight: 600,
								cursor: 'pointer',
								color: "var(--primary-color)"
							}}>
								View Plugin
							</p>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div key={item.pluginType} style={{
				padding: 12,
				width: "25%"
			}}>
				<div style={{
					padding: 18,
					background:"var(--white-color)",
					border: '1px solid var(--border-color)',
				}}>
					<img src={item.logo} alt={item.name} style={{
						height: 30
					}}/>
					<p style={{
						fontWeight: 700,
						fontSize: 19,
						marginTop: 15,
						color: "var(--text-color)"
					}}>
						{item.name}
					</p>
					<p style={{
						fontWeight: 400,
						fontSize: 11,
						marginTop: 12,
						height: 65,
						marginBottom: 12,
						color: "var(--light-color)"
					}}>
						{item.description}
					</p>
					<div style={{
						display: 'flex',
						marginTop: 20,
						flexDirection: 'row'
					}}>
						<p onClick={this._connect.bind(this)} style={{
							fontSize: 14,
							fontWeight: 600,
							cursor: 'pointer',
							color: "var(--primary-color)"
						}}>
							Install Plugin
						</p>
					</div>
				</div>
				{
					this.state.modal ?
					<Modal
						title="Enter Details to enable plugin"
						des="Fill up the buildrun details to activate plugin"
						show={true}
						close={()=>{
							this.setState({
								...this.state,
								modal:false
							})
						}}
						onSave={()=>{
							let organizationId = +this.organisation.value;
							let projectId = +this.project.value;
							let clientId = this.client.value;
							let clientSecret = this.clientSecret.value;

							if(!organizationId) {
								showToast({
									type:'error',
									message:"Please enter the organisational Id"
								})
								return;
							}
							if(!projectId) {
								showToast({
									type:"error",
									message:"Please enter the project Id"
								})
								return;
							}
							if(!clientId) {
								showToast({
									type:'error',
									message:'Please enter the client id'
								})
								return;
							}
							if(!clientSecret) {
								showToast({
									type:'error',
									message:"Please enter the client secret"
								})
								return
							}

							let data = {
								organizationId,
								projectId,
								clientId,
								clientSecret
							}
							pluginsPostApis(`plugins/buildrun/install`,data,(e,r)=>{
								if(r) {
									showToast({
										type:'success',
										message:"App Install successfully"
									})
									this.props.onRefresh()
								} else {
									showToast({
										type:'error',
										message:e.data.message
									})
								}
							})
						}}
					>
						<FunctionalInput title="Oraganisation Id" ref={ref=>this.organisation=ref} />
						<FunctionalInput title="Project Id" ref={ref=>this.project=ref} />
						<FunctionalInput title="Client Id" ref={ref=>this.client=ref} />
						<FunctionalInput title="Client Secret"  ref={ref=>this.clientSecret=ref}/>

					</Modal>
					: null
				}
			</div>
		)

	}

}

export default Connect
