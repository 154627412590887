import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { FloatInput } from '../../../../Common/Class/FloatLabelInput';
import { showToast ,handleError} from '../../../../Common/fToast/ToastCallback';
import { TitleTopHeader,Title as Header } from '../../../../Header/TopHeader';
import { pluginsGetApi,pluginsPostApis } from '../../../../../Model/Network/Config/Axios';
import { StateButton } from '../../../../Common/fStateButton';



const Index = () => {

  const [syncedWithDarwin, setSyncedWithDarwin] = useState(0);
  const [darwinError, setDarwinError] = useState({ remarks: 'No error while syncing' });
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    pluginsGetApi('plugins/custom/bajaj/hrms/info', (e, r) => {
      if (r) {
        setSyncedWithDarwin(r.syncedWithDarwin);
        setDarwinError(r.darwinError);
      }
    });
  }, []);

 

  const syncWithDarwin = () => {
	setLoading(true);
    pluginsPostApis('plugins/custom/bajaj/hrms/syncWithBajaj', {}, (e, r) => {
      if (r) {
		setLoading(false);
        showToast({
          type: 'success',
          message: 'Event triggered successfully!',
        });
      } else {
		setLoading(false);
        handleError(e);
      }
    });
  };

  return (
    <div>
      <TitleTopHeader items={[]} title="Bajaj HRMS Sync" des="Syncing of data with Bajaj HRMS box." />
      <Header title="Bajaj HRMS Sync" des="Syncing of data with Bajaj HRMS." />
      <div
        className="title-container"
        style={{
          padding: 18,
        }}
      >
        <FloatInput
          handleRef={(ref) => {}}
          type="text"
          title="Last Synced On"
          inputProps={{
            disabled: true,
            value: Moment(syncedWithDarwin).format('DD-MM-YYYY HH:MM A'),
          }}
        />
        <FloatInput
          handleRef={(ref) => {}}
          type="text"
          title="Last Error"
          inputProps={{
            disabled: true,
            value: darwinError.remarks,
          }}
        />
        <div>
		  {
			loading ?
			<StateButton />
			:
		  
          <button onClick={syncWithDarwin} className="btn btn-success" style={{ marginTop: 12 }}>
            Sync
          </button>

		}
        </div>
      </div>
    </div>
  );
};

export default Index;
