import {pluginsGetApi} from "../../../../../../Model/Network/Config/Axios";


class Model {

    init = (id, cb) => {
        pluginsGetApi(`/plugins/mailApproval/approval/${id}`, cb)
    }

}

export default Model;
