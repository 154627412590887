import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import inrInWords from '../../../../../../../Utils/INRToWords';

class Blissclub extends React.Component {


    render() {
        let list = this.props.data;
        let { orderId, billingTo, vendor, procured, details } = this.props.data;
        let totalQtty = 0, totalPrice = 0;
        return (
            <div>
                <div style={{
                    textAlign: 'right'
                }}>
                    <p style={{
                        fontWeight: 600,
                        color: 'var(--primary-color)',
                        cursor: 'pointer'
                    }} onClick={() => {
                        this.pdf.save();
                    }}>Download Now</p>
                </div>
                <PDFExport title="Order Document" padding="40" fileName={`Order-${list.orderId}.pdf`} ref={r => this.pdf = r}>
                    <div style={{ marginBottom: 50 }}>
                        <hr style={{ height: 30, background: "#283593"}}/>
                    </div>
                    <div style={{
                        marginBottom: 50
                    }}>
                        <p style={{
                            fontSize: 25,
                            color: "rgb(108 100 232)"
                        }}>Blissclub Fitness Private Limited</p>
                        <p>{procured.length? procured[0].officeName : '-'}</p>
                        <p>{procured.length ? procured[0].officeAddress : '-'}</p>
                        <p>{procured.length ? procured[0].officeCity : '-'}</p>
                    </div>

                    <p style={{
                        fontSize: 35,
                        fontWeight: "bold",
                        marginTop: 30,
                        marginBottom: 20,
                        color: "#283593"
                    }}>
                        Purchase Order
                    </p>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: 20
                    }}>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>Order Date</p>
                            <p>{Moment(details.createdAt).format('MM-DD-YYYY')}</p>
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>GST</p>
                            <p>{billingTo.gstin}</p>
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>P.O. number</p>
                            <p>{orderId}</p>
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>Delivery Date</p>
                            <p>{Moment(procured[0].deliveryDate).format('MM-DD-YYYY')}</p>
                        </div>
                        <div>
                            <p style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{
                                    fontWeight: "bold"
                                }}>Port of Loading</span>
                                <span>{vendor.address.city}</span>
                            </p>
                            <p style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <span style={{
                                    fontWeight: "bold"
                                }}>Port of Discharge</span>
                                <span>{procured[0].officeCity}</span>
                            </p>
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>Payment Terms</p>
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>Shipping Terms</p>
                        </div>
                    </div>
                    <hr />
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        marginTop: 30
                    }}>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>From (Seller)</p>
                            <p>{vendor.name}</p>
                            <p>{vendor.address.address}</p>
                            <p>{vendor.address.city}</p>
                            <p>{vendor.address.state}</p>
                            <p>{vendor.address.country} - {vendor.address.pincode}</p>
                            <p>email: {vendor.email}</p>
                            <p>Ph: {vendor.mobile}</p>
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold"
                            }}>Delivery Address (Buyer)</p>
                            <p>{details.officeName}</p>
                            <p>{details.officeAddress}</p>
                            <p>{details.officeCity}</p>
                        </div>
                    </div>
                    <hr />
                    <div style={{
                        marginTop: 30
                    }}>
                        <table style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            border: "solid 1px"
                        }}>
                            <thead>
                                <tr>
                                    <th style={{
                                        border: "1px solid",
                                        textAlign: "center",
                                        padding: "10px"
                                    }}>Item #</th>
                                    <th style={{
                                        border: "1px solid",
                                        textAlign: "center",
                                        padding: "10px"
                                    }}>Description</th>
                                    <th style={{
                                        border: "1px solid",
                                        textAlign: "center",
                                        padding: "10px"
                                    }}>Qty</th>
                                    <th style={{
                                        border: "1px solid",
                                        textAlign: "center",
                                        padding: "10px"
                                    }}>Unit Price ({vendor.currency})</th>
                                    <th style={{
                                        border: "1px solid",
                                        textAlign: "center",
                                        padding: "10px"
                                    }}>Total Price ({vendor.currency})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    procured.map(item => {
                                        totalQtty += item.qtty;
                                        totalPrice += item.amount;
                                        return (
                                            <tr key={item.id}>
                                                <td style={{
                                                    textAlign: "center",
                                                    border: "solid 1px",
                                                    padding: "10px"
                                                }}>{item.name}</td>
                                                <td style={{
                                                    textAlign: "center",
                                                    border: "solid 1px",
                                                    padding: "10px"
                                                }}>{item.description}</td>
                                                <td style={{
                                                    textAlign: "center",
                                                    border: "solid 1px",
                                                    padding: "10px"
                                                }}>{item.qtty}</td>
                                                <td style={{
                                                    textAlign: "center",
                                                    border: "solid 1px",
                                                    padding: "10px"
                                                }}>{item.price}</td>
                                                <td style={{
                                                    textAlign: "center",
                                                    border: "solid 1px",
                                                    padding: "10px"
                                                }}>{item.amount}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr style={{
                                    border: "solid 1px"
                                }}>
                                    <td></td>
                                    <td style={{
                                        padding: 5,
                                        textAlign: "right",
                                        fontWeight: "bold"
                                    }}>Total</td>
                                    <td style={{
                                        textAlign: "center",
                                        fontWeight: "bold"
                                    }}>{totalQtty}</td>
                                    <td></td>
                                    <td style={{
                                        textAlign: "center",
                                        fontWeight: "bold"
                                    }}>{totalPrice}</td>
                                </tr>
                                <tr style={{
                                    fontWeight: "bold"
                                }}>
                                    <td style={{
                                        padding: 5
                                    }}>
                                        {inrInWords(totalPrice)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{
                        marginTop: 30
                    }}>
                        <p>NAME OF AUTHORIZED SIGNATORY</p><br/>
                        <p>MINU MARGERET MENACHERY</p><br/>
                        <p>SIGNATURE</p><br/>
                        <p>{details.officeCity}</p>
                    </div>
                </PDFExport>
            </div>
        )
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Blissclub);
