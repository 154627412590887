import React from 'react';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';


const Items = (props) => {

    let header = (value,style) => {
        return(
            <div style={{
                borderRight:'1px solid var(--border-color)',
                padding:'10px 5px',
                textAlign:'center',
                fontSize:13,
                ...style
            }}>
                {value}
            </div>
        )
    }

    let single = (value1,value2) => {
        return(
         <div style={{borderBottom:'1px solid var(--border-color)',display:'flex',height:45}}>
            <div style={{borderBottom:'1px solid var(--border-color)',borderRight:'1px solid var(--border-color)',fontSize:13,textTransform:'uppercase',height:45,flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
               {value1}
            </div>
            <div style={{borderBottom:'1px solid var(--border-color)',height:45,flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                {value2}
            </div>
       </div>
        )
    }
    let {procured} = props.data;
    let base=0,total=0,tax=0;
    procured.forEach(val=>{
        base = val.base+base;
        total = total+val.amount;
        tax = tax+val.tax;
    })
    let igst=0,sgst=0,cgst=0;
    if(props.data.billingTo && props.data.billingTo.gstin && props.data.billingTo.gstin.length>2 && props.data.vendor && props.data.vendor.gstin && props.data.vendor.gstin.length>2) {
        if(props.data.vendor.gstin.slice(0,2)===props.data.billingTo.gstin.slice(0,2)){
            cgst = tax/2;
            sgst = tax/2;
        }else{
            igst = tax;
        }
    } else {
        igst=tax;
    }

    
    return(
        <>
            <div style={{
                display:'flex',
                flex:1,
                border:'1px solid var(--border-color)'
            }}>
               {header('Sno',{width:50})}
               {header('Item Code',{width:70})}
               {header('Item Description',{flex:1})}
               {header('HSN/SAC',{width:80})}
               {header('Qty',{width:80})}
               {header('Uom',{width:80})}
               {header('Rate',{width:80})}
               {header('Gst Rate',{width:80})}
               {header('Gst Amount',{width:80})}
               {header('Total',{width:70})}
            </div>
            {procured.map((val,index)=>{
                return(
                    <div key={index} style={{
                        display:'flex',
                        flex:1,
                        border:'1px solid var(--border-color)'
                    }}>
                        {header(index+1,{width:50})}
                        {header(val.id,{width:70})}
                        <div style={{
                             borderRight:'1px solid var(--border-color)',
                             padding:'10px 5px',
                             textAlign:'center',
                             fontSize:13,
                             flex:1
                        }}>
                            <p>{val.name}</p>
                            <p>Details:{val.notes}</p>
                        </div>
                        {header(val.hsn,{width:80})}
                        {header(val.qtty,{width:80})}
                        {header(val.unit,{width:80})}
                        {header(numberWithCommas(val.price.toFixed(2)),{width:80})}
                        {header(val.taxSlab,{width:80})}
                        {header(numberWithCommas(val.tax.toFixed(2)),{width:80})}
                        {header(numberWithCommas(val.amount.toFixed(2)),{width:70})}        
                    </div>
                )
            })}

            <div style={{
                display:'flex',
                flex:1
            }}>
                <div style={{flex:2}}>
                    <div style={{
                        borderBottom:'1px solid var(--border-color)',
                        height:45,
                        display:'flex',
                        alignItems:'center',
                        paddingLeft:10,
                        fontSize:13
                    }}>Terms and Condition :</div>
                    <div style={{
                        display:'flex',
                        borderBottom:'1px solid var(--border-color)',
                        padding:"0px 10px",
                        minHeight:45,
                    }}>
                        <div style={{
                            width:200,
                            fontWeight:'bold',
                            display:'flex',
                            alignItems:'center'
                        }}>
                            Payment Terms
                        </div>
                        <div style={{
                            flex:1,
                            borderLeft:'1px solid var(--border-color)',
                            fontSize:13,
                            paddingLeft:10,
                            minHeight:45,
                            display:'flex',
                            alignItems:'center'
                        }}>
                            {props.data.vendorConditions}
                            <div>
                                {
                                    props.data.conditions.map(val=>{
                                        return(
                                            <div dangerouslySetInnerHTML={{__html:val.content}} />
                                        )
                                    })
                                }
                            </div>
                            <div>
                            {props.data.payments.map(val=>{
                                return(
                                    <div dangerouslySetInnerHTML={{__html:val.content}} />
                                )
                            })}
                        </div>
                        </div>
                    </div>
                </div>
                <div style={{flex:1,border:'1px solid var(--border-color)'}}>
                    {single('Base',numberWithCommas(base.toFixed(2)))}
                    {single('Cgst',numberWithCommas(cgst.toFixed(2)))}
                    {single('Sgst',numberWithCommas(sgst.toFixed(2)))}
                    {single('Igst',numberWithCommas(igst.toFixed(2)))}
                    {single('Total',numberWithCommas(total.toFixed(2)))}
                </div>  
            </div>

        </>
    )
}

export default Items;