import React from 'react'
import PoDetails from "./index";
import {TitleTopHeader} from "../../../../Header/TopHeader";
import {useLocation} from "react-router-dom";

export default function Render() {
	let route = useLocation().pathname;
	return (
		<div>
			<TitleTopHeader items={[
				{
					name:"Item Discovery",
					link:"/app/vendor/procurement/item",
					active : route === "/app/vendor/procurement/item" || route.includes( "/app/vendor/procurement/item/request")
				},
				{
					name:"RFQ - Quotations",
					link:"/app/vendor/procurement/indents",
					active : route.includes( "/app/vendor/procurement/indents")
				},
				{
					name:"Purchase Orders",
					link:"/app/vendor/procurement/orders",
					active :route.includes( "/app/vendor/procurement/orders")
				},
				{
					name:"Catalog Management",
					link:"/app/vendor/procurement/catalog",
					active : route.includes( "/app/vendor/procurement/catalog")
				}
			]} title="Procurement" des="All of your company procurement at one place."/>
			<div id="fintrip-container">
				<div style={{flex: 1, overflowY: 'auto'}}>
					<PoDetails/>
				</div>
			</div>
		</div>
	)
}
