import React from 'react';
import './index.css';

/*
PROPS:

stages = [
    {
        title: string,
        desc: string,
        status: [false,true]
    }
]


*/

function Track(props) {
    
    let FLAG = true;
    
    const stages = (item, index)=>{
        return(
            <div key={`stage-${index}`} className="stage">
                <div className="track-point">
                    { item.pending ?
                    <div className="pending">
                    <svg className="bi bi-exclamation" width="1em" height="1em" viewBox="0 0 16 16" fill="yellow" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 1.5a.75.75 0 0 1 .75.75v6.999a.75.75 0 0 1-1.5 0V2.25A.75.75 0 0 1 8 1.5zm.002 9a.75.75 0 0 1 .748.648l.003.102a.75.75 0 0 1-.648.748l-.102.003a.75.75 0 0 1-.749-.648l-.003-.102a.75.75 0 0 1 .648-.748l.102-.003zm.748 1.829a1 1 0 1 1-1.496-1.317l.803-.536a.25.25 0 0 0-.267-.427l-.804.535a1.502 1.502 0 1 1 1.164 2.723l-.24.16z"/>
                    </svg>
                  </div>
                    :
                    item.status?<div className='point'><svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path></svg></div>
                    :FLAG?<div className='point-active'>{FLAG=false}</div>:<div className='point-pending'>{FLAG=false}</div>
                    }
                    <div className='point-text'><span>{item.title}</span><span style={{color: '#666'}}><br/>{item.desc}</span></div>
                </div>
            </div>
        );
    }

    if(!props.stages){
        return(<>No Props Passed ....</>);
    }
    return (
        <div className='timeline'>
            <div className="track"></div>
            <div>
                <div className="track-fill" style={{height: `${(100/props.stages.length)*props.active - (100 / ( 2 * props.stages.length))}%`}}></div>
            </div>
            <div>
            {props.stages?props.stages.map(stages):<></>}
            </div>
        </div>
    );
}

export default Track;