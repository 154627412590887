
class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }


    init = () => {
        this.Model.init((e, r) => {
            if (r) {
                this.View.load(r);
            }
        })
    }

}

export default Presentor;