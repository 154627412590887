import React from 'react';
import { post,get } from '../../../../Model/Network/Config/Axios';


class FindMaster extends React.Component {


    state = {
        value: '',
        suggestion: [],
        selected: undefined
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        if(this.props.defaultValue &&this.props.defaultValue.length){
            this.setState({
                ...this.state,
                selected: this.props.defaultValue
            })
        }
    }

    componentDidUpdate() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    onChange = (e) => {
        this.setState({
            value: e.target.value,
            selected: undefined
        }, () => this.search())
    }

    search = () => {
        if (this.state.value.length > 0) {  
            get(`/forms/${this.props.details.id}/master`, (e, r) => {
                if (r) {
                    let data = {};
                    let next = r.next;

                    if (r.data.length > 0) {

                        r.data.forEach(val=>{
                            data[val] = this.props.master[val];
                        })
                        post(`/forms/${this.props.details.id}/search`, {
                            q: this.state.value,
                            data
                        }, (e, r) => {
                            if (r) {
                                this.setState({
                                    ...this.state,
                                    suggestion: r.results,
                                    next
                                })
                            }
                        })
                    } else {
                        post(`/forms/${this.props.details.id}/search`, {
                            q: this.state.value,
                        }, (e, r) => {
                            if (r) {
                                this.setState({
                                    ...this.state,
                                    suggestion: r.results,
                                    next
                                })
                            }
                        })
                    }

                }
            }, {
                q: this.state.value,

            })
        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }


    }

    suggestionList = (item, index) => {
        let items = Object.keys(item).map(i => (i));
        let key = Object.keys(item)[0];
        
        return (
            <div key={index}
                onClick={() => {
                    this.props.onUpdate(item,this.state.next);
                    this.setState({
                        selected:  item[this.state.next] ?item[this.state.next]:item[key],
                        value: '',
                        suggestion: [],
                    })
                }}
                className="flex flex-h-center hover"
                style={{
                    minHeight: 55,
                    paddingLeft: 20,
                    fontSize: 16,
                    cursor: 'pointer',
                    color: 'var(--input-color)',
                    flexDirection: 'column'

                }}>
                {
                    items.map((val, index) => {
                        return (
                            <div style={{
                                marginBottom: 4,
                                display: 'flex'
                            }} key={index}>
                                <div style={{
                                    color: index !== 0 ? 'var(--inactive-color)' : 'var(--sidebar-active-color',
                                    fontSize: index !== 0 ? 13 : 15,
                                }}>{val} : </div>
                                <div style={{
                                    color: index !== 0 ? 'var(--inactive-color)' : 'var(--sidebar-active-color',
                                    fontSize: index !== 0 ? 13 : 15,
                                }}> {item[val]}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    getValue = () => {
        return this.state.selected;
    }

    render() {
        return (
            <div style={{
                marginBottom: 20,
                ...this.props.style
            }}>
                <p style={{
                    color: 'var(--title-desc-color)',
                    fontSize: 15
                }}>
                    {this.props.title}
                </p>

                <div style={{
                    position: 'relative'
                }}>
                    <div className="flex flex-h-center flex-v-center" style={{
                        borderRadius: 8,
                        height: 50,
                        marginTop: 10,
                        cursor: 'pointer',
                        border:'1px solid var(--border-color)',
                        background:"#fff",
                    }}>
                        <input
                            style={{
                                width: '98%',
                                height: '90%',
                                color: 'var(--input-color)',
                                fontSize: 16,
                                border: 'none',
                                padding:16
                            }}
                            disabled={this.props.disabled}
                            value={this.state.selected ? this.state.selected : this.state.value}
                            onChange={this.onChange}
                            placeholder={this.props.placeholder}
                            type="text"
                        />
                        {
                            this.state.suggestion.length > 0 ?
                                <div style={{
                                    position: 'absolute',
                                    height: 210,
                                    overflowY: 'auto',
                                    width: '100%',
                                    top: 51,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 0px 5px 1px #fefefe',
                                    zIndex: 10,
                                    border:'1px solid var(--border-color)',
                                    borderRadius:4

                                }}>
                                    {this.state.suggestion.map(this.suggestionList)}
                                </div> :
                                this.state.suggestion.length === 0 && this.state.value.length > 2 ?
                                    <div style={{
                                        position: 'absolute',
                                        height: 100,
                                        overflowY: 'auto',
                                        width: '100%',
                                        top: 51,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        backgroundColor: '#fff',
                                        boxShadow: '0px 0px 5px 1px #fefefe',
                                        zIndex: 10,
                                        paddingLeft: 20,
                                        borderRadius:4

                                    }}> No Result Found</div> : null
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default FindMaster;
