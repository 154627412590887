import React from 'react'
import {pluginsGetApi, pluginsPostApis} from "../../../../../../Model/Network/Config/Axios";
import {handleError, showToast} from "../../../../../Common/fToast/ToastCallback";
import Moment from 'moment'
import {FloatInput} from "../../../../../Common/Class/FloatLabelInput/index";
import {Title as Header, TitleTopHeader} from "../../../../../Header/TopHeader";

class index extends React.Component {

	constructor(props) {
		super(props);
		this.state  = {
			syncedWithDarwin: 0,
			syncedWithEka: 0,
			ekaError:{
				remarks:"No error while syncing"
			},
			darwinError:{
				remarks:"No error while syncing"
			}
		}

		pluginsGetApi(`plugins/custom/purplle/darwinbox/info`,(e,r)=>{
			if(r){
				this.setState({
					...r
				})
			}
		})
	}


	_syncWithEka = () => {
		pluginsPostApis(`plugins/custom/purplle/darwinbox/syncWithDarwin`, {}, (e, r) => {
			if (r) {
				showToast({
					type:'success',
					message:"Event triggered successfully!"
				})
			}else{
				handleError(e)
			}
		})
	}


	_syncWithDarwin = () => {
		pluginsPostApis(`plugins/custom/purplle/darwinbox/syncWithDarwin`, {}, (e, r) => {
			if (r) {
				showToast({
					type:'success',
					message:"Event triggered successfully!"
				})
			}else{
				handleError(e)
			}
		})
	}

	render() {
		return (
			<div>
				<TitleTopHeader items={[]} title="Darwin Box Sync" des="Syncing of data with darwin box."/>
				<Header title="Darwin Box Sync" des="Syncing of data with darwin box."/>
				<div className="title-container" style={{
					padding: 18
				}}>
					<FloatInput  handleRef={(ref)=>{

					}} type="text" title="Last Synced On" inputProps={{
						disabled:true,
						value:Moment(this.state.syncedWithDarwin).format("DD-MM-YYYY HH:MM A")
					}} />
					<FloatInput  handleRef={(ref)=>{

					}} type="text" title="Last Error" inputProps={{
						disabled:true,
						value:this.state.darwinError.remarks
					}} />
					<div>
						<button onClick={this._syncWithDarwin} className="btn btn-success" style={{marginTop:12}}>
							Sync With Darwin Box
						</button>
					</div>
				</div>
			</div>
		)
	}

}

export default index
