
import React, { useState } from 'react';
import { Document,Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './PdfViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const PdfViewer = ({file, onlyImage = false})=> {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale,setScale ] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page height={700*scale} width={600*scale} scale={scale} pageNumber={pageNumber} />
      </Document>
      {
        !onlyImage ? (
          <div style={{display:'flex',alignItems:'center',marginTop:10}}>
            <p>
                Page <span style={{fontWeight:'600'}}>{pageNumber}</span> of <span style={{fontWeight:600}}>{numPages}</span>
            </p>
            <p onClick={()=>{
                if(pageNumber>1) {
                    setPageNumber(pageNumber-1);
                }
            }} style={{margin:'0px 6px',border:'1px solid var(--border-color)',cursor:'pointer',fontSize:13,fontWeight:'600',padding:'3px 10px',borderRadius:4,}}>Prev</p>
            <p onClick={()=>{
                if(numPages>pageNumber) {
                    setPageNumber(pageNumber+1);
                }
            }} style={{margin:'0px 6px',border:'1px solid var(--border-color)',cursor:'pointer',fontSize:13,fontWeight:'600',padding:'3px 10px',borderRadius:4}}>Next</p>
            <p onClick={()=>{
                if(2>scale) {
                  setScale(scale+0.1);
              }
            }} style={{margin:'0px 6px',border:'1px solid var(--border-color)',cursor:'pointer',fontSize:13,fontWeight:'600',padding:'3px 10px',borderRadius:4,}}>Zoom In</p>
            <p onClick={()=>{
                if(scale>0.8) {
                  setScale(scale-0.1);
              }
            }} style={{margin:'0px 6px',border:'1px solid var(--border-color)',cursor:'pointer',fontSize:13,fontWeight:'600',padding:'3px 10px',borderRadius:4}}>Zoom Out</p>
    
          </div>
        ) : null
      }
    </div>
  );
}

export {PdfViewer}