import React from 'react';
import {PageLoader} from '../../../../../Common/Components/PageLoader';
import {Title as Header} from '../../../../../Header/TopHeader';
import {KeyValue} from '../../../../../Common/Components/KeyValue';
import ApproverList from './Helper/ApproverList';
import LineItem from './Helper/LineItem';
import {pluginsGetApi} from '../../../../../../Model/Network/Config/Axios';
import {showToast} from '../../../../../Common/fToast/ToastCallback';


class View extends React.Component {

    constructor(p) {
        super(p);
        this.presentor = p.presentor;
        this.presentor.setView(this);
        this.state = {
            loading: true
        }
    }


    getId = () => {
        return this.props.match.params.id;
    }

    getType = () => {
        return this.props.match.params.type;
    }

    componentDidMount() {
        this.presentor.init();
    }

    load = r => {
        this.setState({
            ...r,
            loading: false
        })
    }

    type = () => {
        if (this.getType() === 'pending') {
            return (
                <Header
                    title="Pending Invoices"
                    des="Single Pending Invoice Details"
                >
                    <div style={{
                        position: 'absolute',
                        right: 0,
                        top: 20
                    }}>
                        <div className="btn btn-portlet" onClick={() => {
                            pluginsGetApi(`plugins/mailApproval/trigger/pending/${this.getId()}`, (e, r) => {
                                if (r) {
                                    showToast({
                                        type: 'success',
                                        message: 'Mail Triggered Successfully'
                                    })
                                    return;
                                }
                            })
                        }} style={{
                            marginRight: 20,
                            width: 180,
                        }}>
                            Trigger Mail
                        </div>
                    </div>
                </Header>
            )

        } else if (this.getType() === 'declined') {
            return (
                <Header
                    title="Declined Invoices"
                    des="Single Declined Invoice Details"
                />
            )

        } else if (this.getType() === 'completed') {
            return (
                <Header
                    title="Completed Invoices"
                    des="Single Completed Invoice Details"
                />
            )

        }
    }

    render() {
        if (this.state.loading) {
            return <PageLoader />
        }
        let item = this.state;
        return (
            <div style={{ flex: 1 }}>
                {this.type()}
                <div style={{
                    padding: 20
                }} className="title-container">

                    <KeyValue
                        title="Initiator Name"
                        value={item.initiator}
                    />
                    <KeyValue
                        title="Mail Initialize At"
                        value={item.approvalInitializedAt}
                    />

                    <div style={{
                        marginTop: 20,
                        marginBottom: 40
                    }}>
                        <ApproverList approvers={item.approvers} />
                    </div>

                    <div style={{
                        marginTop: 40,
                        marginBottom: 40
                    }}>
                        <LineItem
                            getType={this.getType()}
                            initiator={item.initiator}
                            lineItems={item.lineItems}
                            approvers={item.approvers}
                            mailInitialized={item.approvalInitializedAt}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default View;
