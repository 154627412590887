import React from 'react';
import { FloatInputButton } from '../../../../Common/fInputs';



const IrnDetails = ({data}) => {


    return(
        <div>
            <div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>IRN Comparision Invoice Details</div>

            <div style={{display:'flex',alignItems:'center'}}>
                <FloatInputButton disabled={true} style={{width: '100%',marginRight:12}}  title={"Bill Date Check"} placeholder={data.billDateCheck?"Pass":"Fail"}/>
                <FloatInputButton disabled={true} style={{width: '100%'}}  title={"Bill Date Expected Value"} placeholder={data.billDate}/>
            </div>

            <div style={{display:'flex',alignItems:'center'}}>
                <FloatInputButton disabled={true} style={{width: '100%',marginRight:12}}  title={"Bill No Check"} placeholder={data.billNoCheck?"Pass":"Fail"}/>
                <FloatInputButton disabled={true} style={{width: '100%'}}  title={"Bill No Expected Value"} placeholder={data.billNo}/>
            </div>

            <div style={{display:'flex',alignItems:'center'}}>
                <FloatInputButton disabled={true} style={{width: '100%',marginRight:12}}  title={"Buyer GSTIN Check"} placeholder={data.buyerGstinCheck?"Pass":"Fail"}/>
                <FloatInputButton disabled={true} style={{width: '100%'}}  title={"Buyer GSTIN Expected Value"} placeholder={data.buyerGstin}/>
            </div>

            <div style={{display:'flex',alignItems:'center'}}>
                <FloatInputButton disabled={true} style={{width: '100%',marginRight:12}}  title={"Seller GSTIN Check"} placeholder={data.sellerGstinCheck?"Pass":"Fail"}/>
                <FloatInputButton disabled={true} style={{width: '100%'}}  title={"Seller GSTIN Expected Value"} placeholder={data.sellerGstin}/>
            </div>

            <div style={{display:'flex',alignItems:'center'}}>
                <FloatInputButton disabled={true} style={{width: '100%',marginRight:12}}  title={"Total Invoice Amount  Check"} placeholder={data.totalInvValCheck?"Pass":"Fail"}/>
                <FloatInputButton disabled={true} style={{width: '100%'}}  title={"Total Invoice Amount Expected Value"} placeholder={data.totalInvVal}/>
            </div>

            <div style={{display:'flex',alignItems:'center'}}>
                <FloatInputButton disabled={true} style={{width: '100%',marginRight:12}}  title={"Total Item Count Check"} placeholder={data.itemCountCheck?"Pass":"Fail"}/>
                <FloatInputButton disabled={true} style={{width: '100%'}}  title={"Total Item Count Expected Value"} placeholder={data.itemCount}/>
            </div>


        </div>
    )
}

export default IrnDetails;