import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { Modal } from "../../../../Common/fModal/Modal";
import { showToast } from "../../../../Common/fToast/ToastCallback";
import { Select } from "../../../../Common/Class/Select";
import ProductSearch from "../../Submit/ProductSearch";
import SearchHsn from "../../Submit/SearchHsn";
import { FunctionalInput } from "../../../../Common/fInputs";
import { Form } from "../../../../Common/Class/Form";
import { useVendorPaymentTerm } from "../../../../HooksUtility/Vendor/getPaymentTerm";
import { get } from "../../../../../Model/Network/Config/Axios";
import TdsLineItem from "./TdsLineItem";

const AddLineItem = ({categoryId,onClose,vendorId,onSubmit,singleLine,bill_to,tdsAtLineItem=false,tdsAtLineItemInContract=false}) =>{

	let QTTY = useRef(), PRICE = useRef(), MATERIAL = useRef(),CESS=useRef(),NAME=useRef();
    const FORM1 = useRef();
    const FORM = useRef();
    let isSingleLine = singleLine?singleLine==='single':false;
	const [gst, setGst] = useState({
		value: 5,
		label: 5
	})
    const [singleService, setSingleService] = useState({});
	const [services, setServices] = useState([]);
    const [discount,] = useState(0);
   const [tds,setTds] = useState(undefined)
	let paymentTerms = useVendorPaymentTerm();

	useEffect(() => {
		paymentTerms.fetchVendorTerms(vendorId);
		get(`vendor/config/${categoryId}/services`, (e, r) => {
			if (r) {
				setServices(r.items.map(i => {
					return {
						value: i.id,
						label: i.name,
						cess:i.cess
					}
				}))
				if (r.items.length > 0) {
					setSingleService({
						label: r.items[0].name,
						value: r.items[0].id,
						cess:r.cess
					})
				}

			}
		})
	}, [])
  
    useEffect(()=>{
      if(isSingleLine && singleService.value) FORM.current.loadForm(`vendor::${singleService.value}`)
    },[singleService])

    useEffect(()=>{
		if(!isSingleLine && categoryId) {
		  FORM1.current.loadForm(`invoice::item::${categoryId}`)
		}
	},[categoryId])

  let masterKeyValue = {};
  if(bill_to) {
    masterKeyValue.bill_to=bill_to
  }
  
return(
    <div>
         <Modal
          show={true}
          des="Add line item details to add one"
          title={"Add Line Item"}
          style={{
            save: {
              background: "var(--primary-color)",
              border: "2px solid var(--primary-color)",
              padding: "8px 15px",
              width: "100%",
              textAlign: "center",
            },
          }}
          close={onClose}
          onSave={() => {
            let product = NAME.current.getValue();
            let hsn = MATERIAL.current.getValue();
            let qtty = +QTTY.current.value;
            let price = +PRICE.current.value;
            let taxSlab = +gst.value;
            let cessValue = 0;
            if (singleService.cess) {
              cessValue = CESS.current.value ? +CESS.current.value : 0;
            }
            if (!product || !product.title) {
              showToast({
                type: "error",
                message: "Please enter Product name",
              });
              return;
            }
            if (!qtty) {
              showToast({
                type: "error",
                message: "Please enter Quantity",
              });
              return;
            }
            if (qtty < 0) {
              showToast({
                type: "error",
                message: "Please enter the valid quantity",
              });
              return;
            }
            if (!hsn) {
              showToast({
                type: "error",
                message: "Please enter Material Code",
              });
              return;
            }

            let discountSlab = discount;
            if (!discountSlab) discountSlab = 0;
            let base = price * qtty;
            let grossBase = base - base * (discountSlab / 100);
            let tax = grossBase * (taxSlab / 100);
            if(!isSingleLine){
              FORM1.current.getKeyValue((cb) => {
                let item = {
                  name:product.title,
                  qtty,
                  price,
                  hsn,
                  base,
                  grossBase,
                  gst: taxSlab,
                  taxSlab,
                  tax,
                  forms:cb,
                  total: tax + grossBase,
                  service: singleService,
                  key: `${singleService.value}||${product.title}`,
                };
                if(product.id){
                  item['productId'] = product.id;
                }
    
                if (cessValue) {
                  item.cessAmount = grossBase * (cessValue / 100);
                  item.cessPercentage = cessValue;
                  item.total = item.total + grossBase * (cessValue / 100);
                } else {
                  item.cessAmount = 0;
                  item.cessPercentage = 0;
                }
                if(tds && (tdsAtLineItem||tdsAtLineItemInContract)){
                  item.tdsCode = tds.tdsCode
                  item.tdsDesc = tds.tdsDesc
                  item.tdsSlab = tds.tdsSlab
                }
                 onSubmit(item)
               })
            }else{
              FORM.current.getKeyValue((cb) => {
                let item = {
                  name:product.title,
                  qtty,
                  price,
                  hsn,
                  base,
                  grossBase,
                  gst: taxSlab,
                  taxSlab,
                  tax,
                  forms:cb,
                  total: tax + grossBase,
                  service: singleService,
                  key: `${singleService.value}||${product.title}`,
                };
                if(product.id){
                  item['productId'] = product.id;
                }
    
                if (cessValue) {
                  item.cessAmount = grossBase * (cessValue / 100);
                  item.cessPercentage = cessValue;
                  item.total = item.total + grossBase * (cessValue / 100);
                } else {
                  item.cessAmount = 0;
                  item.cessPercentage = 0;
                }
                if(tds && tdsAtLineItem){
                  item.tdsCode = tds.tdsCode
                  item.tdsDesc = tds.tdsDesc
                  item.tdsSlab = tds.tdsSlab
                }
                 onSubmit(item)
                 })
            }
          }}
          buttonName="Add Line Item"
        >
          <Select
            value={singleService}
            items={services}
            placeholder="Select Service"
            select={setSingleService}
          />
          <ProductSearch placeholder={"Name"} ref={NAME} categoryId={categoryId} />
          <SearchHsn ref={MATERIAL} />
          <FunctionalInput type="number" title="Qtty" ref={QTTY} onChange={(e)=>{
            let value = e.target.value;
            if(value<0) {
                showToast({
                  type: "error",
                  message: "Please enter the valid Qtty",
                });
                QTTY.current.value= 0;
            }
          }} />
          <FunctionalInput type="number" title="Price per unit" ref={PRICE} onChange={(e)=>{
            let value = e.target.value;
            if(value<0) {
                showToast({
                  type: "error",
                  message: "Please enter the valid price",
                });
                PRICE.current.value= 0;
            }
          }} />
          <Select
            value={gst}
            items={[
              {
                value: 0,
                label: 0,
              },
             
            
              {
                value: 5,
                label: 5,
              },
              {
                value: 12,
                label: 12
              },
            
          
              {
                value: 18,
                label: 18,
              },
              {
                value: 28,
                label: 28,
              },
            ]}
            placeholder="Select Gst"
            select={setGst}
          />
          {(tdsAtLineItem || tdsAtLineItemInContract) &&
            <div style={{ width: '100%', paddingLeft: '0px', marginTop: 0 }}>
              <TdsLineItem
                vendorId={vendorId}
                onSelect={setTds}
                value={null}
              />
            </div>
          }
          {singleService.cess ? (
            <FunctionalInput
              onChange={(e) => {
                if (e.target.value > 99) {
                  CESS.current.value = 0;
                  showToast({
                    type: "error",
                    message: "Cess value is not more than 99",
                  });
                }
              }}
              type="number"
              ref={CESS}
              title="Enter Cess %"
              max="100"
            />
          ) : null}
           {!isSingleLine? 
            <Form 
              label="Invoice Line Item Form"
              desc="All field related to vendor form"
              masterKeyValue={masterKeyValue}
               handler={ref => {
             	  FORM1.current=ref
        	   }
          } /> : <Form 
          label="Invoice Service Form"
          desc="All field related to vendor form"
          masterKeyValue={masterKeyValue}

          handler={ref => {
             FORM.current=ref
           }
        } />}
          </Modal>
    </div>
)

}

export default AddLineItem