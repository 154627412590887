import React from 'react'
import India from './India'
import Other from './Other'


function SubmitInvoice(props) {
	if (props.currency === "INR") {
		return (<India {...props}/>)
	}else{
		return (<Other {...props}/>)
	}
}


export default SubmitInvoice;
