import React from 'react'
import { pluginsPostApis } from '../../../../../../Model/Network/Config/Axios';
import {FloatInput} from "../../../../../Common/Class/FloatLabelInput/index";
import {Modal} from "../../../../../Common/fModal/Modal";
import { showToast } from '../../../../../Common/fToast/ToastCallback';



class Connect extends React.Component {

	state = {
		connect: false
	}

	componentDidMount() {
		if (this.props.handle) {
			this.props.handle(this);
		}
	}

	connect = () => {
		this.setState({
			connect: true
		})
	}

	_connect = () => {
		let host = this._host.value();
		if (!host) {
			showToast({
				message: "Please enter a valid host name"
			})
			return;
		}
		let port = this._port.value();
		if (!port) {
			showToast({
				message: "Please enter a valid port"
			})
			return;
		}
		let database = this._database.value();
		if (!database) {
			showToast({
				message: "Please enter a valid database name"
			})
			return;
		}
		let username = this._username.value();
		if (!username) {
			showToast({
				message: "Please enter a valid username"
			})
			return;
		}
		let active = this._active.value();
		if (!active) {
			showToast({
				message: "Please enter a active dataset"
			})
			return;
		}
		let inactive = this._inactive.value();
		if (!inactive) {
			showToast({
				message: "Please enter a inactive dataset"
			})
			return;
		}
		let data = {
			baseUrl: host, email: port, secret: database, uid: username, activeDataset: active,inactiveDataset:inactive
		}
		pluginsPostApis(`plugins/custom/directi/darwinbox/install`, data, (e, r) => {
			if (r) {
				this.props.onRefresh()
			}
		})
	}


	render() {
		let item = this.props.item;
		if (item && item.installed) {
			return (
				<div style={{
					padding: 12,
					width: "25%"
				}}>
					<div style={{
						padding: 18,
						borderRadius:8,
						boxShadow:"7px 4px 8px rgba(1,1,1,.05)",
						background:"var(--white-color)",
						position: 'relative',
						border: '1px solid var(--border-color)',
					}}>
						{
							item.status === "ERRROR" ? <p style={{
								color: 'red',
								position: 'absolute',
								right: 12,
								fontWeight: 700,
								fontSize: 11,
								top: 12
							}}>Error</p> : <p style={{
								color: 'var(--primary-color)',
								position: 'absolute',
								right: 12,
								fontWeight: 700,
								fontSize: 11,
								top: 12
							}}>{item.status}</p>
						}
						<img src={item.logo} alt={item.name} style={{
							height: 30
						}}/>
						<p style={{
							fontWeight: 700,
							fontSize: 19,
							marginTop: 15,
							color: "var(--text-color)"
						}}>
							{item.name}
						</p>
						<p style={{
							fontWeight: 400,
							fontSize: 11,
							marginTop: 12,
							height: 65,
							marginBottom: 12,
							color: "var(--light-color)"
						}}>
							{item.description}
						</p>
						<div style={{
							display: 'flex',
							marginTop: 20,
							flexDirection: 'row'
						}}>
							<p onClick={() => {
								this.props.history.push("/app/apps/directi.hrms")
							}} style={{
								fontSize: 14,
								fontWeight: 600,
								cursor: 'pointer',
								color: "var(--primary-color)"
							}}>
								View Plugin
							</p>
						</div>
					</div>
				</div>
			)
		}
		return (
			<div key={item.pluginType} style={{
				padding: 12,
				width: "25%"
			}}>
				<div style={{
					padding: 18,
					background:"var(--white-color)",
					border: '1px solid var(--border-color)',
				}}>
					<img src={item.logo} alt={item.name} style={{
						height: 30
					}}/>
					<p style={{
						fontWeight: 700,
						fontSize: 19,
						marginTop: 15,
						color: "var(--text-color)"
					}}>
						{item.name}
					</p>
					<p style={{
						fontWeight: 400,
						fontSize: 11,
						marginTop: 12,
						height: 65,
						marginBottom: 12,
						color: "var(--light-color)"
					}}>
						{item.description}
					</p>
					<div style={{
						display: 'flex',
						marginTop: 20,
						flexDirection: 'row'
					}}>
						<p onClick={this.connect.bind(this)} style={{
							fontSize: 14,
							fontWeight: 600,
							cursor: 'pointer',
							color: "var(--primary-color)"
						}}>
							Install Plugin
						</p>
					</div>
				</div>
				<Modal onSave={this._connect} buttonName="Enable Darwin Box" style={{
					save: {
						background: "#717dfb",
						border: "2px solid #717dfb",
						padding: '8px 15px',
						width: '100%',
						textAlign: 'center'
					}
				}} show={this.state.connect} close={() => {
					this.setState({
						connect: false
					})
				}} title="Enable Darwin Box" des="Enable darwin box pull integration by filling below details">
					<FloatInput title="Base Url" type="text" handleRef={ref => this._host = ref}/>
					<FloatInput title="Email Id" type="text" handleRef={ref => this._port = ref}/>
					<FloatInput title="Secret" type="text" handleRef={ref => this._database = ref}/>
					<FloatInput title="UUID" type="text" handleRef={ref => this._username = ref}/>
					<FloatInput title="Active Data Set" type="text" handleRef={ref => this._active = ref}/>
					<FloatInput title="Inactive Data Set" type="text" handleRef={ref => this._inactive = ref}/>
				</Modal>
			</div>
		)
	}

}

export default Connect
