import React from 'react';
import Moment from 'moment';


const Index = (props) => {

    let {data} = props;

    let single = (key,style={}) => {
        return(
            <div style={{
                ...style,
                height:45,
                fontSize:14
            }} className="flex flex-v-center flex-h-center">
                {key}
            </div>
        )
    }

    return(
        <div style={{marginBottom:20}}>
            <p style={{textTransform:'uppercase',fontSize:14}}>{props.title}</p>
             {  Object.keys(data).length >0?
                 Object.keys(data).map((val,index)=>{
                     let item = data[val];
                     return(
                         <div key={index} style={{marginTop:20}}>
                             <p style={{fontWeight:'600'}}>{val}</p>
                             <div style={{height:45,background:'var(--off-white)',display:'flex',borderRadius:0,border:'1px solid var(--border-color)',marginTop:10}}>
                                {single("Name",{flex:1})}
                                {single("Email",{flex:1})}
                                {single("Code",{flex:1})}
                                {single("Created_at",{flex:1})}
                                {single("Approved_at",{flex:1})}
                             </div>
                                    <div style={{height:45,display:'flex',border:'1px solid var(--border-color)'}}>
                                        {single(item.name,{flex:1,borderRight:'1px solid var(--border-color)'})}
                                        {single(item.email,{flex:1,borderRight:'1px solid var(--border-color)'})}
                                        {single(item.code,{flex:1,borderRight:'1px solid var(--border-color)'})}
                                        {single(Moment(item.createdAt).format('DD-MM-YYYY hh:mm:ss a'),{flex:1,borderRight:'1px solid var(--border-color)'})}
                                {       single(Moment(item.updatedAt).format('DD-MM-YYYY hh:mm:ss a'),{flex:1,borderRight:'1px solid var(--border-color)'})}
                                    </div>
                                 
                        </div>
                     )
                 })
                 :
                 <p>No Appoval Found</p>
             }
        </div>
    )
}

export default Index;