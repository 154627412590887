import React from 'react';


const Index = (props) => {


    return(
        <div style={{padding:10}}>
            <p>Payment Terms</p>
             {
                props.data.conditions.map(val=>{
                    return(
                        <div dangerouslySetInnerHTML={{__html:val.content}} />
                    )
                })
            }
            {
                props.data.payments.map(val=>{
                                return(
                                    <div dangerouslySetInnerHTML={{__html:val.content}} />
                                )
            })}
                                    
            
        </div>
    )
}

export default Index;