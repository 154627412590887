import {useSelector} from "react-redux";
import {useCallback} from 'react'


function useScriptConfig() {
	let {config} = useSelector((state => ({config: state.auth.config})));
	let reload = useCallback(()=>{
		// get('/market/dashboard/installed',(e,r)=>{
		// 	if(r){
		// 		let element = document.getElementById("injector");
		// 		if(element)
		// 			element.parentNode.removeChild(element);
		// 		let div = document.createElement("div");
		// 		div.id = "injector";
		// 		document.body.append(div)
		// 		if(config.plugins.developmentInject){
		// 			include("http://127.0.0.1:8080/static/js/bundle.js");
		// 			include("http://127.0.0.1:8080/static/css/app.css");
		// 		}
		// 		r.apps.forEach(item=>{
		// 			try{
		// 				if(item.urls){
		// 					item.urls.forEach(value => {
		// 						include(value)
		// 					})
		// 				}
		// 			}catch (e) {
		// 				console.log(e)
		// 			}
		// 		})
		// 	}
		// })
	},[
		config
	])
	return {
		load:reload,
		reload:window.location.reload
	}
}

// function include(item){
// 	var head = document.getElementById("injector");
// 	if(item.includes('js')){
// 		let script= document.createElement('script');
// 		script.src = item;
// 		script.type = "text/javascript";
// 		script.className = "dice-libs";
// 		head.appendChild( script )
// 	}else if(item.includes('css')){
// 		let script= document.createElement('link');
// 		script.href = item;
// 		script.rel = "stylesheet";
// 		script.className = "dice-libs";
// 		head.appendChild( script )
// 	}
// }


export {useScriptConfig}
