import React from 'react'
import {SearchableSelect} from "./SearchableSelec2";
import {get} from "../../../../Model/Network/Config/Axios";

class EmployeeSearchable extends React.Component {

	state = {
		employee: undefined,
		items: []
	}

	_employeeSelect = employee => {
		if (this.props.onSelect) {
			this.props.onSelect(employee)
		}
	}

	onChange = q => {
		if (q.length > 0) {
			get(`/search/v1/employees`, (e, r) => {
				if (r) {
					this.setState({
						...this.state,
						items: r.employees.map(item => ({
							label: item.name,
							des: item.email,
							value: item.code,
							claim: item.claim,
							id: item.id,
							code: item.code
						}))
					})
				}
			}, {
				q
			})
		} else {
			this.setState({
				items: []
			})
		}
	}

	render() {
		return (
			<SearchableSelect
				disabled={this.props.disabled}
				items={this.state.items}
				onChange={this.onChange}
				label={this.props.label ? this.props.label : "Employee"}
				placeholder={this.props.placeholder ? this.props.placeholder : "Select Employee"}
				select={this._employeeSelect} value={this.props.employee} />
		)
	}

}


class VendorSearchable extends React.Component {

	state = {
		items: []
	}

	_vendorSelect = vendor => {
		if (this.props.onSelect) {
			this.props.onSelect(vendor)
		}
	}

	onChange = q => {
		get(`vendor/contacts/search`, (e, r) => {
			if (r) {
				this.setState({
					...this.state,
					items: r.contacts.map(item => ({
						label: item.title,
						value: item.id,
						id:item.id
					}))
				})
			}
		}, {
			q
		})
	}

	render() {
		return (
			<SearchableSelect
				items={this.state.items}
				onChange={this.onChange}
				label="Vendor"
				placeholder={this.props.placeholder ? this.props.placeholder : "Select Vendor"}
				select={this._vendorSelect} value={this.props.vendor} />
		)
	}

}

export {
	EmployeeSearchable, VendorSearchable
}
