import React, {useEffect, useState} from 'react';
import { TitleTopHeader } from '../../../../Header/TopHeader';
import {useLocation, useParams, Switch, Route} from 'react-router-dom'
import {get} from '../../../../../Model/Network/Config/Axios'
import { showToast } from '../../../../Common/fToast/ToastCallback';
import Deployment from './Deployment'
import Settings from './Settings'

function Index(props) {
	const {appId} = useParams()
	let route = useLocation().pathname
	const [own,setOwn] = useState(false);

	useEffect(() => {
		fetchDetails()
	}, []);

	const fetchDetails = () => {
		get(`market/${appId}/details`, (e,r)=>{
            if(r){
                setOwn(r.self)
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading details'
                })
            }
        },{})
	}

	let items = [{
		active: route === `/app/dashboard/${appId}/details`,
		name: `Details`,
		link:`/app/dashboard/${appId}/details`
	}];
	if(own) {
		items.push({
			active: route === `/app/dashboard/${appId}`,
			name: `Deployment`,
			link:`/app/dashboard/${appId}`
		})
	}


	return (<>
		<div style={{padding: '50px'}}>
			<TitleTopHeader url='/app/marketplace' title="Custom Apps" des="Third Party Apps" items={items}/>
			<div style={{padding: '0px 0px 40px 40px'}}>
				<Switch>
					<Route path="/app/dashboard/:appId/details" component={Settings}/>
					<Route path="/app/dashboard/:appId" exact component={Deployment}/>
				</Switch>
			</div>
		</div>
	</>);
}

export default Index;
