import React from 'react';
import './fInvoice.css'
import moment from 'moment';

function Invoice(props) {
    const rednerTable = (item, index) => {
        return (
            <div key={`entry-${index}`} style={{display: 'flex', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                <div style={{width: '160px'}}>{item.desc}<br/>Code: {item.hsn}</div>
                <div style={{width: '100px',}}> {(item.price).toFixed(2)}</div>
                <div style={{width: '100px',}}>{item.qtty}</div>
                <div style={{width: '100px', textAlign: 'right'}}>{item.amount?item.amount.toFixed(2):null}</div>
            </div>
        )
    }

    return (
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
            <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>INVOICE</div>

            <div style={{
                border: '1px solid var(--border-color)',
                padding: '20px',
                borderRadius: '15px',
                marginTop: '30px'
            }}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <b>Vendor</b><br/>
                        {props.data.vendor}<br/>
                        Email: {props.data.email}<br/>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        Date: {props.data.date}<br/>
                        {props.data.due?<>Due Date: {moment(props.data.due).format('DD-MM-YYYY')}<br/></>:<></>}
                        Invoice ID: {props.data.Invoice_id}<br/>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                <div style={{paddingRight: '20px'}}>
                    <b>Billing Address</b><br/>
                    {props.data.bill_add.title?<span>{props.data.bill_add.title}<br></br></span>:null}
                    {props.data.bill_add.registeredName?<span>{props.data.bill_add.registeredName}<br></br></span>:null}
                    {/* {props.data.bill_add}<br/> */}

                </div>
                <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                    <b>Shipping Address</b><br/>
                    {props.data.ship_add.title?<span>{props.data.ship_add.title}<br></br></span>:null}
                    {props.data.ship_add.registeredName?<span>{props.data.ship_add.registeredName}<br></br></span>:null}
                    {/* {props.data.ship_add}<br></br> */}


                </div>
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '30px',
                fontWeight: 'bold',
                paddingBottom: '10px',
                borderBottom: '1px solid var(--border-color)'
            }}>
                <div style={{width: '160px'}}>Desciption</div>
                <div style={{width: '100px',}}>Price</div>
                <div style={{width: '100px',}}>Qty</div>
                <div style={{width: '100px', textAlign: 'right'}}>Amount</div>
            </div>

            {
                props.data.table.map(rednerTable)
            }
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Base Amt:</div>
                    <div> {props.data.base ?(props.data.base).toFixed(2):props.data.base}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Discount Amt:</div>
                    <div> {props.data.discount?(props.data.discount).toFixed(2):props.data.discount}</div>
                </div>
            </div>
            <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
                <div className='wrap'>
                    <div>Tax Amt:</div>
                    <div> {props.data.tax}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Total Amt:</div>
                    <div> {props.data.total ?(props.data.total).toFixed(2):props.data.total}</div>
                </div>
            </div>
        </div>
    )
}

export default Invoice;
