import React from "react";
import { useEffect, useState } from "react"
import { get } from "../../../../Model/Network/Config/Axios"
import { KeyValue } from "../../../Common/Components/KeyValue";
import moment from "moment";
import { useHistory } from 'react-router-dom';

export default ({ id }) => {
    const [state, setState] = useState({
        data: undefined
    });
    const { data } = state;
    const HISTORY = useHistory();

    useEffect(() => {
        if (id) geData()
    }, [])

    function geData() {
        get(`/vendor/contracts/fetchContractDetails/${id}`, (e, r) => {
            if (r) {
                setState({ ...state, data: r })
            }
        })
    }


    if (data)
        return (
            <div id="Invoice_Format" className='FadeRight-Effect' style={{ position: 'relative', paddingBottom: 100 }}>
                <div style={{ justifyContent: 'space-between', display: 'flex', paddingBottom: '20px', alignItems: 'center' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>
                        Contract
                    </div>
                    <div style={{
                        boxShadow: 'rgb(1 1 1 / 2%) 4px 5px 3px',
                        // background:'rgb(240, 112, 24)',
                        cursor: 'pointer',
                        border: '1px solid var(--border-color)',
                        borderRadius: 8,
                        // width:120,
                        fontSize: 12,
                        textAlign: 'center',
                        padding: '10px 12px',
                        color: "#000",
                        marginTop: 20
                    }} onClick={() => { HISTORY.push(`/app/vendor/contracts/details/${id}`) }}>
                        View more details
                    </div>
                </div>
                <div style={{ marginTop: '5px' }}>Contract Name: {data.name}</div>
                <div style={{ marginTop: '5px' }}>Contract Id: {data.contractId}</div>
                <div style={{ marginTop: '5px' }}>Contract Start Date: {data ? moment(data.startDate).format("DD MMM YYYY") : "-"}</div>
                <div style={{ marginTop: '5px' }}>Contract End Date: {data ? moment(data.endDate).format("DD MMM YYYY") : "-"}</div>
                <div style={{ marginTop: '10px' }}>
                    <KeyValue title="Category" value={data.category.name} />
                    <KeyValue title="Contract Type" value={data.type} />
                    <KeyValue title="Frequency" value={data.frequency} />
                    <KeyValue title="FreePeriod" value={data.freePeriod} />
                    <KeyValue title="Payment Amount" value={data.paymentAmount} />
                    <KeyValue title="Deposit Amount" value={data.depositAmount} />
                </div>
                <div
                    style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Contract Vendor
                </div>
                <div style={{ marginTop: '10px' }}>
                    <KeyValue title="Name" value={data.vendor.name} />
                    <KeyValue title="Email" value={data.vendor.email} />
                </div>
            </div>
        )

     return <></>
}