import React from "react";
import { Modal } from "../../../Common/fModal/Modal";

const ConfirmationModal = ({onClose,onSave}) =>{
    return(
        <Modal
        title={'Acknowledge'}
        buttonName={'Acknowledge'}
        des=" are you sure wants to acknowledge?"
        close={onClose}
        onSave={onSave}
        show={true}
    >
    </Modal>
    )
}
export default ConfirmationModal