import React from 'react';


class ApproverList extends React.Component {


    state = {
        vouchers: [],
        voucherChange: false
    }




    _header = () => {
        return (
            <div className="fintrip-simple-header">
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <div className="fintrip-simple-header-title">
                        Email
                    </div>
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <div className="fintrip-simple-header-title">
                        Status
                    </div>
                </div>

                <div style={{ flex: 1, textAlign: 'center' }}>
                    <div className="fintrip-simple-header-title">
                        Action Taken
                    </div>
                </div>

            </div>
        )
    }

    _body = (val, index) => {
        let item = this.props.approvers[val];

        return (
            <div key={index} className="fintrip-simple-table-body">
                <div className="border-bottom" style={{
                    height: 50,
                    background: 'rgba(242,242,242,0.01)'

                }}>
                    <div className="linear-layout horizontal" style={{
                        flex: 1,
                        height: 'inherit'
                    }}>

                        <div className="flex-1 border-right text-center flex-v-center flex-h-center">{item.email}</div>
                        <div className="flex-1 border-right text-center flex-v-center flex-h-center">{item.approved ? 'Approved' : item.declined ? 'Declined' : 'Pending'}</div>
                        <div className="flex-1 border-right text-center flex-v-center flex-h-center">{item.timestamp ? item.timestamp : 'NA'}</div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        let { approvers } = this.props;
        return (
            <div style={{
                marginBottom: 20
            }}>
                <p style={{
                    color: 'var(--light-color)',
                    fontWeight: 600,
                    marginBottom: 15
                }}>Approver List</p>
                <div className="fintrip-simple-table">
                    {this._header()}
                    {
                        Object.keys(approvers).map((val, index) => this._body(val, index))
                    }
                </div>

            </div>
        )
    }
}


export default ApproverList;
