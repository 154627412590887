import React from "react";

export default function Index(props) {
	const {data} = props
	let {message} =data;
	return (
		<div style={{display: 'flex', width: '100%', flexDirection: 'row-reverse', justifyContent: 'center'}}>
			<p style={{
				margin: 0,
				marginBottom: 8,
				paddingLeft: 12,
				paddingRight: 12,
				color: 'var(--white-color)',
				marginTop: 18,
				maxWidth: '400px',
				background: "#ababab",
				paddingTop: 4,
				paddingBottom: 4,
				fontSize: 10
			}}>
				{message.title}
			</p>
		</div>
	)
}
