import React from 'react';
import data from './data';


const Index = () => {


    return(
        <div style={{padding:10}}>
            <p style={{textAlign:'center',fontWeight:'bold'}}>Standard Terms & Conditions of Purchase Order (PO)</p>
             {
                 data.map((val,index)=>{
                     return(
                         <div key={index} style={{marginBottom:10}}>
                            <p style={{fontWeight:'500'}}>{val.title}</p>
                            {val.list.map((i,j)=>{
                                return(
                                    <div key={j}>
                                        <p style={{marginTop:2,fontSize:14}}>{i.text}</p>
                                    </div>
                                )
                            })}
                        </div>
                     )
                 })
             }
        </div>
    )
}

export default Index;