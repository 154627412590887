import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import PhotoTile from '../../../../../../Common/fPhoto';
import { FileUpload } from '../../../../../../Common/fInputs/FunctionalFile';
import { post } from '../../../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../../../Common/fToast/ToastCallback';

function Index(props) {

    const [Previews, setPreviews] = useState([]);
    const {appId} = useParams()
    
    useEffect(() => {
        if(props.previews){
            setPreviews(props.previews)
        }
    }, [props]);

    const renderPreviews = (item, index)=>{
        return (
            <PhotoTile key={`Photo-${index}`} link={item} onDelete={()=>{deletePreview(item)}}/>
        )
    }

    const uploadPreview = (file)=>{
        post(`market/${appId}/screenshot/add`, {url:file.file}, (e,r)=>{
            if(r){
                props.reload();
                showToast({
                    type:'success',
                    message: 'Preview uploaded'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in updating'
                })
            }
        })
    }

    const deletePreview = (file)=>{
        post(`market/${appId}/screenshot/remove`, {url: file}, (e,r)=>{
            if(r){
                props.reload();
                showToast({
                    type:'success',
                    message: 'Preview deleted'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in deleting'
                })
            }
        })
    }

    return (
        <>
        <span id="Login_Heading"style={{fontSize: '12px', fontWeight: 'bold'}}>Preview Screenshots</span><br/>
        <div style={{marginLeft: '-10px', marginTop: '20px', marginBottom: '30px', display: 'flex', alignItems: 'center'}}>
            {
                Previews.map(renderPreviews)
            }
            <FileUpload onUpload={(data => {uploadPreview(data)})}/>
        </div>
        </>
    );
}

export default Index;