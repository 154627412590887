import React from 'react';
import './index.css';

/*
PROPS:

stages = [
    {
        text: string (Label Text)
    }
]
active = int (Active Stage)

*/
function ProgressBar(props) {

    const stages = (item,index)=>{
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                <div className={`progress-point ${index<=props.active-1?index===props.active-1?'point-active':'point-done':'point-inactive'}`}><span>{index+1}</span></div>
                <div style={{background: 'var(--border-color)', margin: '0rem 0.4rem', padding: '0.4rem', borderRadius: '0.5rem', fontSize: '0.6rem', textAlign: 'center'}}>{item.title}</div>
            </div>
        )
    }

    return (
        <div id="Progress" style={{padding: '15px 50px 0px 50px', width: '100%', margin: 'auto'}}>
        <div id="bar-empty" style={{height: '3px',backgroundColor: 'var(--border-color)', width: '100%', borderRadius: '50px'}}/>
        <div id="bar-fill" style={{width: `${(100/props.stages.length)*props.active - (100 / ( 2 * props.stages.length))}%`,height: '4px',backgroundColor: 'var(--primary-color)',top: '-5px', position: 'relative', borderRadius: '50px',transition: 'ease-in-out width 0.5s'}}/>
            <div id="Points" style={{display: 'flex',alignItems: 'flex-start', width: '100%'}}>
                {props.stages.map(stages)}
            </div>
        </div>
    );
}


function TableProgressBar(props) {

    const stages = (item,index)=>{
        if(item.renderer){
           if(index<=props.active-1){
               return (
                   <div key={index} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                       <div {...item.renderer.props} className={`progress-point`} style={{
                          ...item.renderer.style
                       }}><div/></div>
                       <div style={{margin: '0rem 0.4rem', textTransform:'uppercase',fontSize:8,letterSpacing:1,fontWeight:600,color:"var(--text-color)", textAlign: 'center'}}>{item.title}</div>
                   </div>
               )
           }
        }

        let last = index===props.stages.length-1;
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                {
                    last?
                        <div className={`progress-point ${index<=props.active-1?index===props.active-1?'point-dot-green':'point-dot-green':'point-dot'}`}>
                            <div/>
                        </div>:
                        <div className={`progress-point ${index<=props.active-1?index===props.active-1?'point-dot-active':'point-dot-green':'point-dot'}`}><div/></div>
                }
                <div style={{margin: '0rem 0.4rem', textTransform:'uppercase',fontSize:8,letterSpacing:1,fontWeight:600,color:"var(--text-color)", textAlign: 'center'}}>{item.title}</div>
            </div>
        )
    }

    return (
        <div id="Progress" style={{padding: '20px 0px',paddingRight:"20%", width: '100%', margin: 'auto'}}>
            <div id="bar-empty" style={{height: '2px',backgroundColor: 'var(--border-color)', width: '89%', borderRadius: '50px'}}/>
            <div id="bar-fill" style={{width: `${(100/props.stages.length)*props.active - (100 / ( 2 * props.stages.length))}%`,height: '2px',backgroundColor: props.active===1?'var(--primary-color)':"#0E8F4F",top: '-3px', position: 'relative', borderRadius: '50px',transition: 'ease-in-out width 0.5s'}}/>
            <div id="Points" style={{display: 'flex',alignItems: 'flex-start', width: '100%'}}>
                {props.stages.map(stages)}
            </div>
        </div>
    );
}

export default ProgressBar;

export {
    ProgressBar,TableProgressBar
}
