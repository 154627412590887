import AllPlugin  from "../AllPlugins";


import DarwinBoxPull from '../Custom/Bizongo/DarwinBoxPull/Connect'
import DarwinBoxPullRoute from '../Custom/Bizongo/DarwinBoxPull/index'

import DirectiDarwinBoxPull from '../Custom/Directi/DarwinBoxPull/Connect'


import CsvInstaller from '../Custom/Csv/Connect'
import CsvRoute from '../Custom/Csv/index'

import BibaInstaller from '../Custom/Biba/connect'
import BibaRoute from '../Custom/Biba/index';


import BuildRunInstaller from '../Custom/BuildRun/Connect'
import BuildRunRoute from '../Custom/BuildRun'

import TransactionRoute from '../Custom/Transaction/index';
import TransactionInstaller from '../Custom/Transaction/Connect';

import WorkLinePull from '../Custom/BlackBuck/connect';
import WorkLinePullRoute from '../Custom/BlackBuck/index';

import QuickBookPull from '../Custom/QuickBook/connect';
import QuickBookRoute from '../Custom/QuickBook/index';


import FinoPull from '../Custom/Fino/connect';
import FinoPullRoute from '../Custom/Fino/index';

import MailInstaller from '../Custom/MailApproval/connect';
import MailRoute from '../Custom/MailApproval/index';

import RamcoPull from '../Custom/DTDC/Ramco/connect';
import RamcoPullRoute from '../Custom/DTDC/Ramco/index';

import VendorUploadRoute from '../Custom/BulkVendorUpload/index';
import VendorUploadInstaller from '../Custom/BulkVendorUpload/Connect';

import LedgerInstaller from '../Custom/LedgerReport/connect';
import LedgerRoute from '../Custom/LedgerReport/index';

import SapDarclInstaller from '../Custom/SapDarcl/Connect';
import SapDarclRoute from '../Custom/SapDarcl/index';

import SapDarclInstallerClone from '../Custom/SapDarclClone/Connect';
import SapDarclRouteClone from '../Custom/SapDarclClone/index';

import EkaIRNReaderInstaller from '../Custom/IRNInvoiceRead/connect';
import EkaIRNReaderRoute from '../Custom/IRNInvoiceRead/index';

import AdvanceLedgerInstaller from '../Custom/AdvanceLedgerReport/connect';
import AdvanceLedgerRoute from '../Custom/AdvanceLedgerReport/index';

import ChaitanyInstaller from '../Custom/ChaitanyaReport/connect';
import ChaitanyaRoute from '../Custom/ChaitanyaReport/index';

import GoMechInstaller from '../Custom/Gomech/connect';
import GoMechRoute from '../Custom/Gomech/index';


import DarwinBoxPullMoengag from '../Custom/Moengag/DarwinBoxPull/Connect'
import DarwinBoxPullRouteMoengag from '../Custom/Moengag/DarwinBoxPull/index'


import ChaitanyaOracleInstaller from '../Custom/Chaitanya/Connect';
import ChaitanyaOracleRoute from '../Custom/Chaitanya/index';



import NykaaWorkLinePull from '../Custom/NykaaWorkline/connect';
import NykaaWorkLinePullRoute from '../Custom/NykaaWorkline/index';

import ChaloConnect from '../Custom/Chalo/connect'
import ChaloRoute from '../Custom/Chalo/index'

import ChaitanyaHrmsConnect from '../Custom/ChaitanyHrms/connect';
import ChaitanyaHrms from '../Custom/ChaitanyHrms/index';


import EcomHrmsConnect from '../Custom/EcomHrms/connect';
import EcomHrms from '../Custom/EcomHrms/index';

import EpifiHrmsConnect from '../Custom/Epifi/connect';
import EpifiHrms from '../Custom/Epifi/index';


import DarwinBoxPullPurplle from '../Custom/Purplle/DarwinBoxPull/Connect'
import DarwinBoxPullRoutePurplle from '../Custom/Purplle/DarwinBoxPull/index'


import TeachmintInstaller from '../Custom/Teachmint/connect'
import TeacmintRoute from '../Custom/Teachmint/index';


import CitymallInstaller from '../Custom/Citymall/connect'
import CitymallRoute from '../Custom/Citymall/index';


import CarlsBergeInstaller from '../Custom/CarlsBerg/connect';
import CarlsBergeRoute from '../Custom/CarlsBerg/index';


import IIFLInstaller from '../Custom/IIFL/Custom/Connect'
import IIFLRoute from '../Custom/IIFL/Custom/index'



import WeWorkInstaller from '../Custom/Wework/Custom/Connect'
import WeWorkRoute from '../Custom/Wework/Custom/index'

import HdfcInstaller from "../Custom/Hdfc/connect";
import HdfcRoute from "../Custom/Hdfc/index";

import KinaraInstaller from '../Custom/Kinara/connect';	
import KinaraRoute from '../Custom/Kinara/index';

import BajajInstaller from '../Custom/Bajaj/connect';	
import BajajRoute from '../Custom/Bajaj/index';

import CompassInstaller from '../Custom/Compass/connect';	
import CompassRoute from '../Custom/Compass/index';

import AtyatiInstaller from '../Custom/Atyati/connect';	
import AtyatiRoute from '../Custom/Atyati/index';

import ChaitanyaLmsInstaller from '../Custom/ChaitanyaLMS/connect';	
import ChaitanyaLmsRoute from '../Custom/ChaitanyaLMS/index';


import PiramalInstaller from '../Custom/PiramalHRMS/connect';	
import PiramalRoute from '../Custom/PiramalHRMS/index';

import CJDarclInstaller from "../Custom/CJDarcl/connect";
import CJDarclRoute from "../Custom/CJDarcl/index";

const allPlugins = {
	"CUSTOMBIZONGODARWINBOXPULL": {
		installer: DarwinBoxPull
	},
	"EMPLOYEECSV": {
		installer: CsvInstaller
	},
	"BUILDRUN":{
		installer:BuildRunInstaller
	},
	"CUSTOMBLACKBUCKWORKLINEPULL": {
		installer: WorkLinePull
	},
	"TRANSACTIONCSV": {
		installer: TransactionInstaller
	},
	"MAILAPPROVAL": {
		installer: MailInstaller
	},
	"CUSTOMDTDCRAMCOPULL": {
		installer: RamcoPull
	},
	"VENDORUPLOAD": {
		installer: VendorUploadInstaller
	},
	"LEDGERREPORT": {
		installer: LedgerInstaller
	},
	"CJDARCLSAP": {
		installer: SapDarclInstaller
	},
	"CJDARCLSAPCLONE": {
		installer: SapDarclInstallerClone
	},
	"READINVOICEQR": {
		installer: EkaIRNReaderInstaller
	},
	"CHAITANYAREPORTS": {
		installer: ChaitanyInstaller
	},
	"ADVANCELEDGERREPORT": {
		installer: AdvanceLedgerInstaller
	},
	"FINOHRMS": {
		installer: FinoPull
	},
	"GOMECHREPORTS": {
		installer: GoMechInstaller
	},
	"CUSTOMMOENGAGEDARWINBOXPULL":{
		installer:DarwinBoxPullMoengag
	},
	"CHAITANYAORACLE":{
		installer:ChaitanyaOracleInstaller
	},
	"CUSTOMNYKAAWORKLINEPULL":{
		installer:NykaaWorkLinePull
	},
	"CUSTOMCHALOKEKAHRMSPULL":{
		installer:ChaloConnect
	},
	"CHAITANYAHRMS":{
		installer:ChaitanyaHrmsConnect
	},
	"CUSTOMECOMHRMSPULL":{
		installer:EcomHrmsConnect
	},
	"CUSTOMEPIFIKEKAHRMSPULL":{
		installer:EpifiHrmsConnect
	},
	"QUICKBOOKS":{
		installer:QuickBookPull
	},
	"CUSTOMDIRECTIDARWINBOXPULL":{
		installer:DirectiDarwinBoxPull
	},
	"BIBAHRMS":{
		installer:BibaInstaller
	},
	"CUSTOMPURPLLEDARWINBOXPULL":{
		installer:DarwinBoxPullPurplle
	},
	"CUSTOMTEACHMINTKEKAHRMSPULL":{
		installer:TeachmintInstaller
	},
	"CUSTOMCITYMALLZIMYOPULL":{
		installer:CitymallInstaller
	},
	"CUSTOMCARLSBERGMASTERAUTOMATION":{
		installer:CarlsBergeInstaller
	},
	"CUSTOMIIFLHRMSPULL":
	{
		installer:IIFLInstaller
	},
	"CUSTOMWEWORKDARWINBOXPULL":
	{
		installer:WeWorkInstaller
	},
	"CUSTOMWEWORKHDFCPULL":
	{
		installer: HdfcInstaller
	},

	"CUSTOMKINARACAPITALHRMS":{
		installer:KinaraInstaller
	},
	"CUSTOMBAJAJHRMS":{
		installer:BajajInstaller
	},
	"CUSTOMCOMPASSGROUPHRMS":{
		installer:CompassInstaller
	},
	"CUSTOMATYATIPEOPLEHUMHRMS":{
		installer:AtyatiInstaller
	},
	"CHAITANYALMS":{
		installer:ChaitanyaLmsInstaller
	},
	"PIRAMALHRMS":{
		installer:PiramalInstaller
	},
	"DARCL_SAP": {
		installer: CJDarclInstaller
	},
}

const AllRouters = [
	{
		component: AllPlugin,
		path: "/app/marketplace",
		extra: {
			exact: true
		}
	},
	{
		component: DarwinBoxPullRoute,
		path: "/app/marketplace/custom/bizongo/darwin/pull"
	},
	{
		component:QuickBookRoute,
		path:"/app/marketplace/quickbook"
	},
	{
		component: CsvRoute,
		path: "/app/marketplace/csv"
	},
	{
		component: TransactionRoute,
		path: "/app/marketplace/transaction"
	},
	{
		component: WorkLinePullRoute,
		path: '/app/marketplace/workline'
	},
	{
		component: MailRoute,
		path: '/app/marketplace/mail'
	},
	{
		component: RamcoPullRoute,
		path: '/app/marketplace/ramco'
	},
	{
		component: VendorUploadRoute,
		path: '/app/marketplace/upload'
	},
	{
		component: LedgerRoute,
		path: '/app/marketplace/ledger'
	},
	{
		component: SapDarclRoute,
		path: '/app/marketplace/sap'
	},
	{
		component: SapDarclRouteClone,
		path: '/app/marketplace/clonesap'
	},
	{
		component: EkaIRNReaderRoute,
		path: '/app/marketplace/irn'
	},
	{
		component: AdvanceLedgerRoute,
		path: '/app/marketplace/advanceledger'
	},
	{
		component: ChaitanyaRoute,
		path: '/app/marketplace/chaitanya'
	},
	{
		component: FinoPullRoute,
		path: '/app/marketplace/fino'
	},
	{
		component: GoMechRoute,
		path: `/app/marketplace/gomech`
	},
	{
		component: DarwinBoxPullRouteMoengag,
		path: "/app/marketplace/custom/moengage/darwin/pull"

	},
	{
		component:ChaitanyaOracleRoute,
		path: "/app/marketplace/chaitanyaorcale"
	},
	{
		component:NykaaWorkLinePullRoute,
		path:'/app/marketplace/nykaa'
	},
	{
		component:ChaloRoute,
		path:'/app/marketplace/chalo'
	},
	{
		component:ChaitanyaHrms,
		path:'/app/marketplace/chaitanya.hrms'
	},
	{
		component:EcomHrms,
		path:'/app/marketplace/ecom.hrms'
	},
	{
		component:EpifiHrms,
		path:'/app/marketplace/epifi',
		extra: {
			exact: true
		}
	},
	{
		component:BuildRunRoute,
		path:'/app/marketplace/buildrun'
	},
	{
		component:BibaRoute,
		path:'/app/marketplace/biba'
	},
	{
		component:DarwinBoxPullRoutePurplle,
		path:'/app/apps/purplle'
	},
	{
		component:TeacmintRoute,
		path:'/app/apps/teachmint'
	},
	{
		component:CitymallRoute,
		path:`/app/apps/citymallhrms`
	},
	{
		component:CarlsBergeRoute,
		path:`/app/apps/carlsberg`
	},
	{
		component:IIFLRoute,
		path:`/app/apps/iifl`
	},
	{
		component:WeWorkRoute,
		path:`/app/apps/wework`
	},
	{
		component: HdfcRoute,
		path:`/app/apps/hdfc`
	},
	{
		component:KinaraRoute,
		path:'/app/apps/kinara'
	},
	{
		component:BajajRoute,
		path:'/app/apps/bajaj'
	},
	{
		component:CompassRoute,
		path:'/app/apps/compass'
	},
	{
		component:AtyatiRoute,
		path:'/app/apps/atyati'
	},
	{
		component:ChaitanyaLmsRoute,
		path:'/app/apps/lms'
	},
	{
		component:PiramalRoute,
		path:'/app/apps/piramal'
	},
	{
		component: CJDarclRoute,
		path:'/app/apps/cjdarcl'
	},
]

export {AllRouters,allPlugins};
