import React, {useEffect, useState} from 'react';
import {get, post} from '../../../../../../Model/Network/Config/Axios';
import {Modal} from "../../../../../Common/fModal/Modal";
import {useHistory, useParams} from 'react-router-dom';
import {showToast} from '../../../../../Common/fToast/ToastCallback';
import PhotoTile from "../../../../../Common/fPhoto";

function Index(props) {

    let HISTORY = useHistory();
    const {appId} = useParams();
    const [id, setAppId] = useState("")
    const [Previews, setPreviews] = useState([]);
    const [About, setAbout] = useState('');
    
    useEffect(() => {
        fetchDetails()
    }, []);

    const fetchDetails = () => {
        get(`market/${appId}/details`, (e, r) => {
            if (r) {
                setAppId(r.appId)
                setAbout(r.about)
                setPreviews(r.screenshots)
            }
        }, {})
    }

    const renderPreviews = (item, index)=>{
        return (
            <PhotoTile key={`Photo-${index}`} link={item}/>
        )
    }

    const [getModal, setModal] = useState(undefined);

    const uninstall = () => {
        post(`market/uninstall`, {id: parseInt(appId)}, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'App Uninstalled'
                })
                setTimeout(() => {
                    HISTORY.push('/app/marketplace');
                }, 1000);

            } else if (e) {
                showToast({
                    type: 'error',
                    message: 'Error in Uninstalling this App'
                })
            }
        })
    }

    return (<>
        <div className='CustomApp' style={{
            marginTop: 40,
            display: 'flex',
            width: '100%',
            backgroundColor: 'var(--white-color)',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 0.2rem 1rem',
            borderRadius: 20,
            justifyContent: 'space-between'
        }}>
            <div className="FadeLeft-Effect" style={{display: 'flex', width: '100%', alignItems: 'stretch'}}>
                <div style={{display: 'flex', width: '100%', alignItems: 'stretch'}}>
                    <div style={{width: '100%', padding: 40}}>
                        <div>
                            <div style={{backgroundColor: 'var(--lite-color)', borderRadius: 8, padding: '10px', display: 'inline-flex', fontSize: '12px', color: 'var(--text-color)', wordBreak: 'break-all'}}>
                                App ID :&nbsp;&nbsp;<span style={{color: '#00aff0', fontFamily: 'Courier'}}>{id}</span>
                            </div>
                            <div style={{marginTop: '30px'}}>
                                <span id="Login_Heading" style={{fontSize: '12px', fontWeight: 'bold'}}>Description<br/></span><br/>
                                <div style={{
                                    padding: '10px',
                                    border: '1px solid var(--outline-color)',
                                    borderRadius: '5px',
                                    fontSize: '12px',
                                    background: 'var(--lite-color)',
                                    display: 'inline-flex'
                                }}>
                                    {About?About:'No Descrption'}
                                </div>
                            </div>
                            <br/>
                            {
                                Previews.length?
                                <>
                                    <span id="Login_Heading" style={{fontSize: '12px', fontWeight: 'bold'}}><br/>Previews<br/></span><br/>
                                    {Previews.map(renderPreviews)}
                                    <br/><br/>
                                </>
                                :<></>
                            }
                            <span id="Login_Heading" style={{fontSize: '12px', fontWeight: 'bold'}}>Unistall your App</span><br/>
                            
                            <div className='CustomApp-tile' style={{
                                background: 'var(--primary-color)',
                                color: '#fff',
                                display: 'inline-flex',
                                justifyContent: 'start',
                                height: '50px',
                                marginTop: '30px',
                                borderRadius: '15px',
                                marginLeft: '0px'
                            }}>
                                <svg style={{fontSize: '18px', marginRight: '20px'}} className="bi bi-question-circle"
                                     xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                    <path
                                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
                                </svg>
                                Once unistalled your app cannot be recovered, remember to downlaod a copy of your app
                                before uninstalling
                            </div>
                            <div onClick={() => setModal(true)} className='CustomApp-tile' style={{
                                width: '180px',
                                height: '60px',
                                borderRadius: '8px',
                                marginLeft: '0px',
                                padding: '20px'
                            }}>
                                <svg style={{marginRight: '10px', fontSize: '16px'}} className="bi bi-trash"
                                     xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                    <path fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                                </svg>
                                Uninstall App
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        {
            getModal ?
                <Modal buttonName="Uninstall" onSave={() => {
                    uninstall(getModal)
                }} title="Uninstall App"
                       des="Are you sure you want to uninstall this app, this action cannot be undone"
                       close={() => {
                           setModal(undefined)
                       }}
                       style={{
                           save: {
                               width: '100%',
                               fontSize: 17,
                               height: 44,
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center'
                           }
                       }} show={getModal !== undefined}>

                </Modal>
                : <></>
        }
    </>);
}

export default Index;
