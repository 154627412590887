import React, {useEffect, useState} from 'react'
import {luna} from "../../../Model/Network/Api/config";
import {FirebaseUtils} from "../../../Utils/Firebase";
import Moment from "moment";
import Text from "./Renderer/Text";
import Welcome from "./Renderer/Welcome";
import CenterImageFormCard from "./Renderer/CenterImageFormCard";
import {connect} from 'react-redux'
import {post} from "../../../Model/Network/Config/Axios";
import { Portal } from '../Portal';
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';

function View(props) {
	let [messages,setMessages] = useState([]);
	let MESSAGE = React.createRef()
	let MESSAGES = React.createRef()
	let REF = React.createRef();
	REF.current = {}
	let subscribe = (roomId) => {
		let config = props.config;
		let messageUrl = `${luna}/${config.tenant}/rooms/${roomId}`;
		let activeUrl = `${luna}/${config.tenant}/active/${roomId}`;
		FirebaseUtils.fetchDb(db => {
			let message = db.ref(messageUrl);
			let active = db.ref(activeUrl);
			REF.current.__active = active;
			REF.current.__message = message;
			active.on('value',sp=>{
				let data = sp.val()
				if (data) {
					active.set({
						...data,
						count: 0,
						connected: true
					})
					REF.current.__data = data;
					active.onDisconnect().set({
						...data,
						connected: false,
						count: 0
					})
				}
			})
			message.on('value', sp => {
				let data = sp.val()
				if (data) {
					let messages = [];
					Object.values(data).forEach(value => {
						messages.push(value)
					})
					messages.sort((a, b) => {
						return a.createdAt - b.createdAt;
					})
					convertAndUpdate({
						messages
					})
				}
			})
		})
	}

	let unsubscribe = () => {
		REF.current.__message.off('value')
		REF.current.__active.off('value')
		REF.current.__active.onDisconnect().cancel();
		REF.current.__active.set({
			...REF.current.__data,
			connected: false,
			count: 0
		})
	}

	let convertAndUpdate = ({messages}) => {
			setMessages(messages)
	}

	useEffect(()=>{
		subscribe(props.id)
		return ()=>{
			unsubscribe()
		}
	},[])


	let scrollToBottom = () => {
		MESSAGES.current.scrollIntoView({behavior: "smooth"});
	}

	useEffect(()=>{
		scrollToBottom()
	},[messages])


	let item = (item, index) => {
		let length = messages.length;
		let hidden = false;
		let date = true;
		if (length > 2 && index > 1) {
			hidden = messages[index].sendor && messages[index].sendor.claim === messages[index - 1].sendor.claim;
		}
		if (length > 1 && index > 1 && index < length) {
			let date1 = Moment(messages[index - 1].createdAt).format('YYYY MM DD');
			let date2 = Moment(messages[index].createdAt).format('YYYY MM DD');
			let one = Moment(date1).isSame(date2, 'day')
			date = !one;
		} else if (index === 0) {
			date = false
		}
		switch (item.messageType) {
			case 'CenterImageFormCard':
				return <CenterImageFormCard key={index} data={item} hidden={hidden} date={date}/>
			case "Text":
				return <Text key={item.id} data={item} hidden={hidden} date={date}/>
			case "WelcomeMessage":
				return <Welcome key={item.id} data={item}/>
			default:
				break;
		}
	}

	let submit = () => {
		let message = MESSAGE.current.value;
		if (message && message.length > 0) {
			post(`/workspace/support/${props.id}/message`,{message},(e,r)=>{});
			MESSAGE.current.value = "";
		}
	}

	let handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			submit()
		}
	}

	return (
		<Portal>
		<div style={{
			position:'fixed',
			zIndex:1110,
			top:0,
			bottom:0,
			left:0,
			backgroundColor:"rgba(1,1,1,.1)"
		}}>
			<div style={{
				position:'fixed',
				zIndex:1111,
				boxShadow:"10px 0 20px 0 rgba(123,147,180,.2)",
				left:0,
				top:0,
				bottom:0,
				width:500,
				backgroundColor:'var(--white-color)'
			}}>
				<div style={{
					position:'relative'
				}}>
					<div style={{
						width:500,
						top:0,
						height:60,
						left:0,
						position:'fixed',
						zIndex:112,
						background:"var(--white-color)",
						display:'flex',
						borderBottom:'1px solid var(--border-color)'
					}}>
						<img alt="Close Button" onClick={props.close} src={require('./close.png')} style={{
							margin:22,
							cursor:'pointer',
							width:16,
							height:16
						}}/>
						<div style={{flex:2}}>
							<p style={{
								marginTop:14,
								fontWeight:600,
								fontSize:14,
								height:18,
								overflowY:'hidden'
							}}>{props.title}</p>
							<p style={{
								fontWeight:400,
								fontSize:10,
								color:'var(--light-color)'
							}}>{props.des}</p>
						</div>
						{props.closed?null:<ReadOnly><div style={{flex:1}}>
							<p onClick={()=>{
								props.onClose(props.id)
							}} style={{
								margin:18,
								color:"var(--primary-color)",
								fontWeight:700,
								cursor:'pointer',

							}}>
								Mark Closed
							</p>
						</div></ReadOnly>}
					</div>
					<div className="container" style={{
						backgroundColor: "var(--off-white)",
						borderRight: "9px solid var(--white-color)",
						borderLeft: "9px solid var(--white-color)",
						height: "calc(100vh - 70px)",
						paddingTop:60,
						overflowY: 'auto',
						background: 'var(--white-color)'
					}}>
						{messages.map(item)}
						<div style={{float: "left", clear: "both", marginBottom: 20}}
							 ref={MESSAGES}>
						</div>
					</div>
					<ReadOnly>
					{props.closed?null:<div className="sendMessage" style={{
						height: 70,
						display: 'flex',
						padding: 12,
						backgroundColor: "var(--white-color)",
						borderTop: '1px solid var(--border-color)',
					}}>
						<input style={{
							flex: 1,
							border: '1px solid var(--border-color)',
							borderRadius: 6,
							height: 45,
							padding: "8px 14px",
							fontSize: 15,
							color: "var(--text-color)"
						}} ref={MESSAGE} onKeyPress={handleKeyPress}
							   placeholder="Enter your messsage here" type="text"/>

					</div>}
					</ReadOnly>
				</div>
			</div>
		</div>
		</Portal>
	)
}


const Luna = connect(state=>({config:state.auth.config}))(View)

export {
	Luna
}
