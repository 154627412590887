import React from 'react'
import Props from 'prop-types'
import { isBoolean } from 'lodash';

function FunctionalKeyValue(props) {

	const displayValue = () => {
		if (isBoolean(props.value)) {
			return props.value ? "Yes" : "No";
		}
		return props.value ? props.value : "-";
	};

	return (
		<div style={{
			width: '100%'
		}}>
			<div style={{
				fontSize: 10,
				letterTransform: 'uppercase',
				marginTop: 20,
				color: "#bcbcbc"
			}}>
				{props.title}
			</div>
			<div style={{
				...props.style,
				marginTop: 8,
				border: '1px solid var(--border-color)',
				padding: '12px 20px',
				color: "var(--text-color)",
				borderRadius: 8,
				fontSize: '12px'
			}}>
				{displayValue()}
			</div>
		</div>
	)

}


FunctionalKeyValue.propTypes = {
	title: Props.string.isRequired,
	value: Props.string
}

export {
	FunctionalKeyValue
}
