import React from 'react'
import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'

/*
	<StackGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/

function TreeMap(props) {
	return (
		<div>
			<ReactApexChart options={{
				legend:{
                    show:true
                },
                chart:{
                    height:props.height,
                    type:'treemap',
					events:{
						dataPointSelection:(events,chartContext,config)=>{
							if(props.onClick) {
								props.onClick(config)
							}
						}
					}
                },
                title:{
                    text:props.title
                },
				plotOptions:{
					treemap:{
						distributed:true,
						enableShades:false
					}
				}
			}} series={props.data} type="treemap" height={props.height} />
		</div>
	)
}


TreeMap.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

TreeMap.defaultProps = {
	y: "Price",
	title:"Details",
	height:800
}

export {
	TreeMap
}

export default TreeMap
