import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, post } from '../../../../../Model/Network/Config/Axios';
import Title from "../../../../Common/Title";
import { Modal } from '../../../../Common/fModal/Modal';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec';
import { showToast } from '../../../../Common/fToast/ToastCallback';


const Index = ({type,init}) => {

    let {directOwnerTagging} = useSelector(state=>state.auth.config.plugins);
    let {id} = useParams();
    const [data,setData] = useState({});
    const [open,setOpen] = useState(false);
    const [access,setAccess] = useState([]);
    const [items,setItems] = useState([]);
    const [user,setUser] = useState(false);



    useEffect(()=>{
        get(`/access`,(e,r)=>{
            if(r) {
                setAccess(r.accesses.map(item=>({
                    label: item.name,
					value: item.id,
                    des:item.email
                })))
                setItems(r.accesses.map(item=>({
                    label: item.name,
					value: item.id,
                    des:item.email
                })))
            }
        })
    },[])

    useEffect(()=>{
        if(directOwnerTagging) {
            INIT();
        }
    },[directOwnerTagging])

    let INIT = () => {
        get(`vendor/config/taskOwnerMapping/getOwner`,(e,r)=>{
            if(r) {
                setData(r);
            }
        },{id,type})
    }


    if(!directOwnerTagging) {
        return <></>
    }

    return(
        <div  style={{margin: 12}}>
            <Title id="attachments" marginLeft={0} title="Delegate Access"/>
            <div className="input-container" style={{
					background: "var(--white-color)",
					margin: 0,
					padding: 20
				}}>
                    {
                        data.mapped ?
                        <>
                            <div style={{fontSize:13,borderBottom:'1px solid var(--border-color)',paddingBottom:12,color:'var(--text-light-color)',display:'flex',justifyContent:'space-between'}}>
                                <p style={{fontWeight:'bold'}}>Assigned to</p>
                                <p style={{color:'var(--secondary-color)',fontWeight:'600'}}>{data.name}</p>
                            </div>
                            <div style={{fontSize:13,borderBottom:'1px solid var(--border-color)',paddingTop:12,paddingBottom:12,color:'var(--text-light-color)',display:'flex',justifyContent:'space-between'}}>
                                <p style={{fontWeight:'bold'}}>Email</p>
                                <p style={{color:'var(--secondary-color)',fontWeight:'600'}}>{data.email}</p>
                            </div>
                            <p  onClick={()=>{
                                post(`vendor/config/taskOwnerMapping/removeOwner`,{id:+id,type},(e,r)=>{
                                    if(r) {
                                        showToast({
                                            type:'success',
                                            message:"Access Remove successfully"
                                        })
                                        init();
                                        INIT();
                                    }
                                })
                            }} style={{color:'var(--primary-color)',marginLeft:'auto',marginTop:15,fontSize:14,fontWeight:600,cursor:'pointer'}}>Remove Access</p>

                        </>
                        :
                        <p  onClick={()=>{setOpen(true)}} style={{color:'var(--primary-color)',fontSize:14,fontWeight:600,cursor:'pointer'}}>+ Forward Request</p>

                    }
				</div>
            {
                open ?
                <Modal
                    title='Search Approver'
                    des="Search Approver and assign this request"
                    close={()=>{
                        setOpen(false)
                    }}
                    onSave={()=>{
                        if(!user) {
                            showToast({
                                type:'error',
                                message:"Please select the user"
                            })
                            return;
                        }

                        post(`vendor/config/taskOwnerMapping/addOwner`,{adminId:+user.value,id:+id,type},(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Request Delegate successfully"
                                })
                                init();
                                INIT();
                                setOpen(false);
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }}
                    show={true}
                    buttonName="Delegate"
                >
                    <SearchableSelect
					    label="Access Management User"
					    placeholder="Select User"
					    value={user}
					    select={setUser}

					    onChange={val => {
						    if (val.length > 2) {
							    let mAccess = [...access];
							    mAccess = mAccess.filter(item => item.label.toLowerCase().includes(val.toLowerCase()))
							    setItems(mAccess)
						    } else {
							    setItems(access)
						    }
					    }}
					    items={items.length ? items:[]} />
                </Modal>
                :null
            }
        </div>
    )
}

export default Index;