import moment from 'moment';
import React, { useRef } from 'react';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import './fInvoice.css'
import { showToast } from '../../../../Common/fToast/ToastCallback';
import { useSelector } from 'react-redux';

function Invoice(props){

    let {debitNote} = props.data;
    let INPUT = useRef([]);
    let PRICE = useRef([]);
    let AMTINPUT = useRef([]);
    const CONFIG = useSelector(state=>state.auth.config)
    const {creditDebitNoteRateEdit} = CONFIG.plugins;

    const rednerTable = (item, index)=>{
        return(
            <div key={`entry-${index}`} style={{display: 'flex',alignItems:'center', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                <div style={{width: '160px'}}>{item.desc}<br/>Code: {item.hsn} <br /> Service : {item.service} <br></br></div>
                <div style={{width: '100px',}}> {item.price ?numberWithCommas(item.price.toFixed(2)):item.price}</div>
                {creditDebitNoteRateEdit ? <input type="number" value={item.credit_note_price} ref={(ref) => PRICE.current[index] = ref}  onChange={(e)=>{
                    let value = e.target.value;
                    if(value>item.price) {
                        PRICE.current[index].value=item.price
                        props.onChangePrice(item.price,index);
                        AMTINPUT.current[index].value=item.base_amt 
                        INPUT.current[index].value = (item.original_qtty)
                    } else if(value<0) {
                        PRICE.current[index].value=item.price
                        props.onChangePrice(item.price,index);
                        AMTINPUT.current[index].value=item.base_amt 
                        INPUT.current[index].value = (item.original_qtty)
                    } else {
                        props.onChangePrice(e.target.value,index);
                        AMTINPUT.current[index].value=0;
                        INPUT.current[index].value = 0;
                    }
                }} style={{width:'100px',height:32}} />:null}
                <div style={{width: '100px',}}> {item.original_qtty}</div>
                <input type="number" ref={(ref) => INPUT.current[index] = ref}  onChange={(e)=>{
                    if(creditDebitNoteRateEdit && Number(item.credit_note_price) === 0) {
                        showToast({
                            typr:"error",
                            message:"Please enter valid price"
                        })
                        return;
                    }
                    let value = e.target.value;
                    if(value>item.original_qtty) {
                        INPUT.current[index].value=item.original_qtty
                        props.onChangeQtty(parseFloat(item.original_qtty),index);
                        AMTINPUT.current[index].value=(item.credit_note_price * item.original_qtty)
                    } else if(value<0) {
                        INPUT.current[index].value=item.original_qtty
                        props.onChangeQtty(parseFloat(item.original_qtty),index);
                        AMTINPUT.current[index].value=(item.credit_note_price * item.original_qtty)
                    } else {
                        if(value) {
                            props.onChangeQtty(e.target.value,index);
                            let qtty = +e.target.value
                        	let base = item.credit_note_price * qtty;
                            AMTINPUT.current[index].value=base.toFixed(2);
                        }
                        
                    }
                }} style={{width:'100px',height:32}} />
                {debitNote?<div style={{width: '100px',}}> {item.debitQty}</div>:null}
                <div style={{width: '100px',}}> {item.base_amt ?numberWithCommas(item.base_amt.toFixed(2)):item.base_amt}</div>
                <input className='pd1' type="number" ref={(ref) => AMTINPUT.current[index] = ref}  onChange={(e)=>{
                    if(creditDebitNoteRateEdit && Number(item.credit_note_price) === 0) {
                        showToast({
                            typr:"error",
                            message:"Please enter valid price"
                        })
                        return;
                    }
                    let value = e.target.value;
                    if(value>item.base_amt) {
                        AMTINPUT.current[index].value=item.base_amt 
                        props.onChangeAmt(parseFloat(item.base_amt) ,index);
                        INPUT.current[index].value = (item.base_amt/item.credit_note_price)

                    } else if(value<0) {
                        AMTINPUT.current[index].value=item.base_amt
                        props.onChangeAmt(parseFloat(item.base_amt) ,index);
                        INPUT.current[index].value = (item.base_amt/item.credit_note_price)

                    } else {
                        if(value) {
                            props.onChangeAmt(parseFloat(e.target.value),index);
                            INPUT.current[index].value = (parseFloat(e.target.value)/item.credit_note_price).toFixed(2);
                        }
                        
                    }
                }} style={{border:"1px solid var(--border-color)",width:'100px',height:32,padding:"3px",background:"var(--tile-light-color)"}} />
                <div style={{width: '100px'}}> {item.cessAmount ?numberWithCommas(item.cessAmount.toFixed(2)):0}</div>
                <div style={{width: '100px'}}> {item.tax_amt ?numberWithCommas(item.tax_amt.toFixed(2)):numberWithCommas(item.tax_amt)}</div>
                <div style={{width: '100px', textAlign: 'right'}}> {item.amount ?numberWithCommas(item.amount.toFixed(2)):numberWithCommas(item.amount)}</div>
                <div onClick={()=>{
                    props.onDelete(item);
                }} style={{width:'100px',cursor:'pointer',color:'var(--red-color)',fontSize:12,textAlign:'right'}}>Delete</div>
            </div>
        )
    }
    
    let partial = 0;

    props.data.allSettlement.forEach(val=>{
         if(val.transferred) {
            partial = partial+val.amount
         }
    })



    let base = 0, cgst = 0, sgst = 0, igst = 0, total = 0, tax = 0;


    props.data.table.forEach(item => {
		base = base + parseFloat(item.base_amt);
		total = total + parseFloat(item.amount);
		tax = tax + parseFloat(item.tax_amt);
	})


    let vendor = props.data.vendor;
    let billTo = props.data.bill_to

	
	if (vendor && vendor.gstin && vendor.gstin.length > 2 && billTo.billingTo && billTo.gstin && billTo.gstin.length > 2) {
		if (vendor.gstin.slice(0, 2) === billTo.gstin.slice(0, 2)) {
			cgst = tax / 2;
			sgst = tax / 2;
		} else {
			igst = tax;
		}
	} else {
		cgst = tax / 2;
		sgst = tax / 2;
	}


 return(
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
        <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>Generate Debit Note</div>
            {
              
                props.data.discount?
                    <p style={{
                        top:10,
                        left:0,
                        padding:'12px 30px',
                        position:'absolute',
                        background:"green",
                        color:'var(--white-color)',
                        fontSize:11
                    }}>
                        Discounted at {props.data.discount} %
                    </p>:undefined
            }
            {
              
              props.data.rcVendor?
                  <p style={{
                      top:40,
                      left:0,
                      padding:'12px 30px',
                      position:'absolute',
                      background:'var(--primary-color)',
                      color:'var(--white-color)',
                      fontSize:11
                  }}>
                      RCM Invoice
                  </p>:undefined
          }
        <div style={{border: '1px solid var(--border-color)', padding: '20px', borderRadius: '15px', marginTop: '30px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <b>Vendor</b><br/>
                    {props.data.vendor}<br/>
                    Email: {props.data.email}<br/>
                    GSTIN: {props.data.vendor_id}
                </div>
                <div style={{textAlign: 'right'}}>
                Date: {props.data.date}<br/>
                {props.data.due?<>Due Date: {moment(props.data.due).format('DD-MM-YYYY')}<br/></>:<></>}
                Invoice ID: {props.data.Invoice_id}<br/>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                <div style={{paddingRight: '20px'}}>
                    <b>Billing Address</b><br/>
                    {props.data.bill_to.title?<span>{props.data.bill_to.title}<br></br></span>:null}
                    {props.data.bill_to.registeredName?<span>{props.data.bill_to.registeredName}<br></br></span>:null}
                    {props.data.bill_add}<br/>
                    {props.data.bill_to.gstin?<span>{props.data.bill_to.gstin}<br></br></span>:null}

                </div>
                <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                    <b>Shipping Address</b><br/>
                    {props.data.ship_to.title?<span>{props.data.ship_to.title}<br></br></span>:null}
                    {props.data.ship_to.registeredName?<span>{props.data.ship_to.registeredName}<br></br></span>:null}
                    {props.data.ship_add}<br></br>
                    {props.data.ship_to.gstin?<span>{props.data.ship_to.gstin}<br></br></span>:null}
                </div>
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '30px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1px solid var(--border-color)'}}>
            <div style={{width: '160px'}}>Desciption</div>
            <div style={{width: '100px',}}>Price</div>
            {creditDebitNoteRateEdit ? <div style={{width: '100px',}}>Enter Price</div>: null}
            <div style={{width: '100px',}}>Qty</div>
            <div style={{width:'100px'}}>Enter Qty</div>
            {debitNote?<div style={{width: '100px',}}>Debit Qty</div>:null}
            <div style={{width: '100px',}}>Base Amt</div>
            <div style={{width:'100px'}}>Enter Amt</div>
            <div style={{width: '100px',}}>Cess Amt</div>
            <div style={{width: '100px'}}>Tax Amt</div>
            <div style={{width: '100px', textAlign: 'right'}}>Amount (INR)</div>
            <div style={{width: '100px', textAlign: 'right'}}>Action</div>

        </div>

       {
           props.data.table.map(rednerTable)
       }
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>SGST / UTGST: </div>
                <div> {numberWithCommas(sgst.toFixed(3))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>CGST: </div>
                <div> {numberWithCommas(cgst.toFixed(3))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>IGST: </div>
                <div> {igst ?numberWithCommas(igst.toFixed(2)):numberWithCommas(igst)}</div>
            </div>
        </div>

        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Base Amt: </div>
                <div> {numberWithCommas((total).toFixed(2)-(tax).toFixed(2))}</div>
            </div>
        </div>
       
        <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
            <div className='wrap'>
                <div>Tax Amt: </div>
                <div> {numberWithCommas(tax.toFixed(2))}</div>
            </div>
        </div>
       
       
       
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Total Amt: </div>
                <div> {numberWithCommas(total.toFixed(2))}</div>
            </div>
        </div>
       
        

      
       
       

        <div style={{display: 'flex', padding: '10px 0px', fontWeight: 'bold', borderTop: '1px solid var(--border-color)', borderBottom: '1px solid var(--border-color)'}}>
            <div style={{flexGrow: 1}}>Total Vendor Payable Amount: </div>
            <div style={{width: '100px', textAlign: 'right'}}> {numberWithCommas((total).toFixed(2))}</div>
        </div>
       
       
    </div>
 )
}



export default Invoice;
