import React, {useState} from 'react'
import {Modal} from "../../fModal/Modal";
import {get} from "../../../../Model/Network/Config/Axios";
import {SearchableSelect} from "../../Class/Select/SearchableSelec2";
import { useSelector } from 'react-redux';


function SearchOffice2(props){
	let [allOffices,setOffices] =  useState([])
	let tenant = useSelector(state=>state.auth.config.tenant);

	return(
		<SearchableSelect label={props.title} placeholder={props.title}
						  value={props.office}
						  select={props.onSelect}
						  disabled={props.disabled}
						  onChange={val => {
							  if (val.length > 2) {
								let data = {q:val};
								if(props.p2p){
									data.p2p="yes"
								}
								if(props.entityId){
									data.entityId=props.entityId
								}
								if(props.officeId && tenant==='NYKAA') {
									data.officeId = props.officeId
								}
								  get(`/search/v1/offices`, (e, r) => {
									 setOffices(r.offices.map(val => ({
										 label: val.name,
										 value: val.id,
										 name:val.name,
										 gstin:val.gstin?val.gstin:undefined,
										 city:val.city ? val.city:undefined,
										 address:val.address?val.address:undefined,
										 registeredName:val.registeredName?val.registeredName:undefined,
										 des:val.registeredName?val.registeredName:undefined,

									 })))
								  }, data)

							  }
						  }}
						  items={allOffices}
						  style={{
							  color: '#e6ebf3',
							  height: 46,
							  marginBottom: 12
						  }}
		/>
	)
}

class SearchOffice extends React.Component {


	state = {
		modal: undefined,
		allOffices: [],
        office:undefined
	}





	render() {
		return (
			<div style={{
				width: '100%',
				padding: 30,
				flexDirection: 'column',
				border: '1px solid var(--border-color)',
				marginBottom: 15,
				marginTop: 15,
				display: 'flex',
				alignItems: 'center'
			}}>
				<h2 onClick={() => {
					this.setState({
						...this.state,
						modal: true
					})
				}} style={{
					fontSize: 41,
					color: "var(--text-color)",
					cursor: 'pointer',
					fontWeight: 800
				}}>
					{this.props.office?this.props.office.name:"Select office"}
				</h2>
				<p>
					{this.props.office?this.props.office.address:"Address will load here"}
				</p>
				<p>
					{this.props.office?this.props.office.gstin:"Gstin will load here"}
				</p>
				{
					this.state.modal ?
						<Modal show={true}
							   onSave={() => {
							   }}
							   title="Select office"
							   des="Please select a office to continue."
							   close={() => {
								   this.setState({
									   ...this.state,
									   modal: false
								   })
							   }}
							   hideButton={true}
						>
							<SearchableSelect label="Select office" placeholder="Select office"
											  value={this.state.office}
											  select={item => {

												this.props.onSelect({
                                                      name:item.label,
                                                      id:item.value,
                                                      gstin:item.gstin?item.gstin:undefined
                                                  })

                                                  this.setState({
                                                      ...this.state,
                                                      modal:false
                                                  })

											  }}
											  onChange={val => {
												  if (val.length > 2) {
													let data = {q:val};
													if(this.props.p2p){
														data.p2p="yes"
													}
													if(this.props.entityId){
														data.entityId=this.props.entityId
													}
													  get(`/search/v1/offices`, (e, r) => {
														  this.setState({
															  ...this.state,
															  allOffices: r.offices.map(val => ({
																  label: val.name,
																  value: val.id,
                                                                  gstin:val.gstin?val.gstin:undefined
															  }))
														  })
													  }, data)

												  }
											  }}
											  items={this.state.allOffices}
											  style={{
												  color: '#e6ebf3',
												  height: 46,
												  marginBottom: 12
											  }}
							/>

						</Modal> : undefined
				}
			</div>
		)
	}

}

export default SearchOffice


export {
	SearchOffice2
}
