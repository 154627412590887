
import React from  'react';
import { MultiSelect } from "react-multi-select-component";
import { Modal } from '../../../../Common/fModal/Modal';
import { FunctionalInput } from '../../../../Common/fInputs';


class PoCopyDropdown extends React.Component {

    constructor() {
        super();
        this.CUSTOM = React.createRef();
        this.state = {
            selected:[],
            openCustom: false,
        }
    }

    componentDidMount() {
        if(this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    getValue = () =>{
        let item = '';
        this.state.selected.forEach((val,index)=>{
            if(index+1===this.state.selected.length) {
                item+=val.value
            } else {
                item+=val.value+';';
            }
        })
        return item;
    }

    onOptionClick = (selected) => {
        this.setState({ selected })
        if(selected.findIndex(opt => opt.value === 'custom') > -1) {
            this.setState({ openCustom: true });
        }
        if(selected.length === 0) { // to reset custom email address after dropdown is cleared
            this.props.options[3].label = 'Custom';
            this.props.options[3].value = 'custom';
        }
    }

    render() {
        return(
            <>
                 <label className="floating-top-label" style={this.props.labelStyle}>
                    {
                        this.props.title
                    }:
                </label>

                <MultiSelect
                    options={this.props.options}
                    value={this.state.selected}
                    onChange={this.onOptionClick}
                    labelledBy={this.props.title}
                />

                {
                    this.state.openCustom ?
                        (
                            <Modal
                                title="Enter custom Email Id"
                                des=""
                                show={true}
                                close={()=>{
                                    this.setState({ openCustom: false })
                                }}
                                buttonName="Add"
                                onSave={() => {
                                    this.setState(prev => {
                                        prev.selected.forEach(opt => {
                                            if(opt.value === 'custom') {
                                                opt.value = this.CUSTOM.current.value.split(/\s*,\s*/);
                                                opt.label = this.CUSTOM.current.value;
                                            }
                                        })
                                        return ({ selected: prev.selected, openCustom: false });
                                    });
                                }}
                                modalStyle={{
                                    marginTop: 40
                                }}
                            >
                                <FunctionalInput type="text" title="Enter Email" ref={this.CUSTOM} />
                            </Modal>
                        ) : null
                }
            </>
        )
    }
}

export {PoCopyDropdown}
