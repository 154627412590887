import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec2';
import { get } from '../../../../../Model/Network/Config/Axios';


const SearchPO  = forwardRef(({label,placeholder},ref) => {

    const [items,setItems] = useState([]);
    const [value,setValue] = useState(undefined);


   

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return value?value.value:undefined
        },
        setValue:(value)=>{
            if(value) {
                setValue({
                    value,
                    label:value
                });
            }
           
        }
    }))


    return(
        <div>
            <SearchableSelect
                items={items}
                label={label}
                value={value}
                onChange={(input)=>{
                    if(input.length>2) {
                        get(`vendor/po/requests/searchPo`,(e,r)=>{
                            if(r) {
                                setItems(r.pos.filter(i=>i.status!=='DECLINED'|| i.status!=='CANCELLED').map(item=>{
                                    return({
                                        value:item.poNo,
                                        label:item.poNo
                                    })
                                }))
                            }
                        },{
                            q:input
                        })
                    } else {
                        setItems([])
                    }
                }}
                placeholder={placeholder}
                select={(item)=>{
                    setValue(item)
                }}
            />
        </div>
    )
})

export {SearchPO}