const AllColors = [
	"#26A69A",
	"#5C6BC0",
	"#3CB779",
	"#FF7043",
	"#29B6F6",
	"#EF5350",
	"#FFA726",
	"#78909C",
	"#AB47BC",
	"#66BB6A",
	"#6500ff",
	"#F77762",
	"#456EFE",
	"#7887C9",
	"#616161",
	"#555D8E"
]



export {
	AllColors
}
