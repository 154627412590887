import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { get } from '../../../Model/Network/Config/Axios';
import { KeyValue } from '../Components/KeyValue';
import { FloatInputButton } from '../fInputs';


const EditableCustomForm = ({type,id,onChange,data=[]}) => {

    const [editableKeys,setEditableKeys] = useState([]);

    useEffect(()=>{
        get(`forms/${type}::${id}`,(e,r)=>{
            if(r) {
                let items = [];
                r.forms.forEach(val=>{
                    if(val.editable) {  
                        items.push(val.fieldId)
                    }
                })
                setEditableKeys(items);
            }
        })
    },[])


    return(
        <div>
            {
                data.map((val,index)=>{
                    if(_.includes(editableKeys,val.id)) {
                        return (
                            <FloatInputButton key={index} style={{width: '100%',marginBottom:15}} onSubmit={(value)=>{onChange(val.id,value)}} title={val.key} placeholder={val.value}/>
                        )
                    }
                    return(
                            <KeyValue key={index} style={{width:'100%',marginBottom:15,marginRight:0}} title={val.key} value={val.value} />
                    )
                })
            }
           
        </div>
    )
}

export {EditableCustomForm};