import {get} from "../Model/Network/Config/Axios";
import {FirebaseUtils} from "./Firebase";

class Config {

	cbs = [];
	init = false;

	refreshConfig(cb){
		get('auth/config', (e, r) => {
			if (r) {
				cb(r)
			}
		})
	}

	fetchConfig(cb) {
		if (this.config) {
			cb(this.config)
		} else {
			this.cbs.push(cb)
			if (!this.init) {
				this.fetch()
			}
		}
	}

	fetch() {
		this.init = true;
		get('auth/config', (e, r) => {
			if (r) {
				this.cbs.forEach(cb => {
					cb(r)
				})
				this.config = r;
				this.cbs = null;
				this.init = false;
				FirebaseUtils.initializeApp()
			}
		})
	}

}


let fetchConfig = new Config();

export {
	fetchConfig
}
