import React, {useEffect, useState} from 'react';
import {get} from '../../../../Model/Network/Config/Axios';
import {PageLoader} from '../../../Common/Components/PageLoader';
import {allPlugins} from '../Helpers';
import Empty from '../../../Common/fCard/Empty';

const AllPlugins = (props) => {

	const [plugins, setPlugins] = useState([]);
	const [loading, setLoading] = useState(true);

	let init = () => {
		get(`/settings/apps/plugins`, (e, r) => {
			if (r) {
				setPlugins(r.apps);
				setLoading(false);
			}
		})
	}

	useEffect(() => {
		init()
	}, [])


	let plugin = (item) => {
		if (item.type === "PLUGIN") {
			let plugin = allPlugins[item.appId];
			item = {
				...item,
				logo: item.icon,
				name: item.title,
				pluginType: item.appId,
			}
			if (plugin) {
				let Component = plugin.installer;
				return (
					<Component history={props.history} onRefresh={init} key={item.pluginType} item={item}/>
				)
			}
		}

	}

	if (loading) {
		return <PageLoader/>
	}

	return (
		<div style={{position:'relative'}}>
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				marginTop: '20px',
				paddingTop: 0
			}}>
				{	plugins.length?
					plugins.map(plugin)
					:<Empty message='No Plugins'/>
				}
			</div>
		</div>
	)
}

export default AllPlugins;
