import React, {useEffect, useState} from 'react';
import Back from './back.jpg'
import { useHistory } from 'react-router-dom';
import './index.css'
import {get} from '../../../../../Model/Network/Config/Axios'
import { showToast } from '../../../../Common/fToast/ToastCallback';
import Empty from '../../../../Common/fCard/Empty';
import AppCard from '../../../../../Components/Common/fCard/AppCard'

function Index(props) {

    let HISTORY = useHistory();
    const [Apps, setApps] = useState([]);
    const [Categories, setCategories] = useState(['Dashboards']);
    const [Category, setCategory] = useState('Dashboards');
    const [FilteredApps, setFilteredApps] = useState([]);

    useEffect(() => {
        get(`market/search`,(e,r)=>{
            if(r){
                setApps(r.apps)
                setFilteredApps(r.apps)
            }else if(e){
                showToast({
                    type: 'error',
                    messsage: 'Problem in loading Apps'
                })
            }
        },{category: Category})

        get(`market/categories`,(e,r)=>{
            if(r){
                setCategories(r.catgeories)
            }else if(e){
                showToast({
                    type: 'error',
                    messsage: 'Problem in loading Categories'
                })
            }
        },{})
    }, []);

    const searchApps = (e)=>{
        if(e.target.value.length>2){
            let searched = Apps.filter((app)=>app.title.toLowerCase().includes(e.target.value.toLowerCase()))
            setFilteredApps(searched)
        }else{
            setFilteredApps(Apps)
        }
    }
    
    const topApps = (item, index)=>{
        return (
            <AppCard key={index} onClick={()=>{
                if(item.category==='Custom'){
                    if(item.type === 'User'){
                        HISTORY.push(`/app/apps/${item.id}`)
                    }else if(item.type === "Dashboards"){
                        HISTORY.push(`/app/injects/${item.id}`)
                    }
                }else{
                    HISTORY.push(`/app/marketplace/browse/${item.id}`)
                }
            }}
            name={item.title}
            description={item.description}
            category={item.category}
            type={item.type}
            icon={item.icon}
            />
        )
    }

    return (
        <div style={{paddingTop: '10px', paddingBottom: '40px'}}>       
            <div>
                <div className='FadeUp-Effect' style={{marginTop: '20px', padding: '40px', color: '#fff', width: '100%', maxWidth: '1000px', borderRadius: '20px', background: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)) center center / cover no-repeat, url("${Back}") center center / cover no-repeat`}}>
                    <div style={{fontSize: '36px', fontWeight: 'bold'}}>App Marketplace</div>
                    <div style={{fontSize: '14px'}}>
                        Collection of our most used Apps. Supercharge your team with out of the box apps and get work done even faster.
                    </div>
                    <div style={{dispaly: 'flex', alignItems: 'center',marginTop: '20px'}}>
                        <input onChange={searchApps} placeholder='Search Apps' type='text' className='search-box'/>
                        <svg style={{fontSize: '16px', marginLeft: '-30px',color:'#333', verticalAlign: 'middle'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                        </svg>
                    </div>
                </div>
                
                <div style={{display: 'flex', width: '100%', marginTop: '50px'}}>
                    <div>
                        <div className='tabs-sidebar' style={{borderRadius: '10px', overflow: 'hidden'}}>
                            {
                                Categories.map((category, index)=>{
                                    return (
                                        <div key={index} onClick={()=>setCategory(category)} className={Category==='Dashboards'?'option-active':'option'} >
                                            {category}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{marginLeft: '50px'}}>
                        <div style={{fontSize: '12px', fontWeight: 'bold'}}>Top Apps</div>
                    
                        <div className='FadeUp-Effect' style={{marginLeft: '-10px', marginTop: '20px', width: '100%', display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                            {
                                FilteredApps.length?FilteredApps.map(topApps):<Empty message='No Apps in this Category'/>
                            }              
                        </div>    
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Index;