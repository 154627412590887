import React from 'react';


const Index = (props) => {

        let details = (key,value) => {
            return(
                <div style={{
                    display:'flex',
                    flex:1,
                    borderBottom:'1px solid var(--border-color)',
                    fontSize:13
                }}>
                    <div style={{
                        width:100,
                        textAlign:'center',
                        padding:'10px 0px',

                    }}>
                        {key}
                    </div>
                    <div style={{flex:1,textAlign:'center',padding:'10px 0px',borderLeft:'1px solid var(--border-color)'}}>
                        {value}
                    </div>
                </div>
            )
        }
        let { vendor } = props;
        return (
            <div>
                <p style={{
                    fontWeight: 600,
                    textAlign:'center',
                    borderBottom:'1px solid var(--border-color)',
                    padding:'10px 0px'
                }}>Vendor Details</p>
                {details('Code',vendor.vendorId)}
                {details('Name',vendor.name)}
                {details('Address',`${vendor.address.address} , ${vendor.address.city},${vendor.address.pincode} ${vendor.address.state}` )}
                {details('Gstin',vendor.gstin)}
                {details('Pan',vendor.pan?vendor.pan:'-')}
                {details('Mobile',vendor.mobile?vendor.mobile:'-')}
                {details('Email',vendor.email?vendor.email:'-')}
            </div>
        )
}

export default Index;