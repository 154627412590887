import React from 'react'
import Props from 'prop-types'

class Item extends React.Component {

	render() {
		let {onClick, title, selected} = this.props;
		return (
			<div onClick={onClick} style={{
				cursor: 'pointer',
				display:'flex',
				alignItems:'center',
				marginRight: 20,
				marginBottom: 12,
				flex:1
			}}>
				<div style={{
					marginRight:8,
					border:'1px solid var(--border-color)',
					width:12,
					height:12,
					background:selected?"var(--primary-color)":"var(--white-color)"
				}}/>
				<p style={{
					fontSize: 13,
					color:"var(--text-color)",
					fontWeight: 600,
				}}>
					{
						title
					}
				</p>
			</div>
		)
	}

}


Item.propTypes = {
	onClick: Props.func.isRequired,
	title: Props.string.isRequired,
	selected: Props.bool.isRequired
}

export {
	Item
}
