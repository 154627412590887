import React, {useEffect, useState} from 'react'
import {get} from "../../../../Model/Network/Config/Axios";
import {SearchableSelect} from "../../Class/Select/SearchableSelec2";


const SearchVendorAddress = (props) => {


    const [address,setAddress] = useState([]);
    const [selectedAddress,setSelectedAddress] = useState([]);

    useEffect(()=>{
        get(`vendor/contacts/${props.id}/details`,(e,r)=>{
            if(r) {
                setAddress(r.addresses.map(item => ({
                        key: item.id,
                        label:item.address,
                        value: item.name,
                        des: item.city,
                        tax:item.gstin,
                        search: `${item.name} ${item.address}`.toLowerCase()
                })))


                if(r.addresses.length >0) {
                    let item = r.addresses[0];
                    props.onSelect({
                        key: item.id,
                        label:item.address,
                        value: item.name,
                        des: item.city,
                        tax:item.gstin,
                        search: `${item.name} ${item.address}`.toLowerCase()
                    })
                }
            }
        })
    },[])



    return(
		<SearchableSelect label={props.title} placeholder={props.title}
						  value={props.selfAddress}
						  select={props.onSelect}
						  onChange={val => {
							  if (val.length > 2) {
                                let items = address;
                                setSelectedAddress(items.filter(val=>{
                                    if(val.label.toLowerCase().includes(val.toLowerCase())) {
                                        return true
                                    } 
                                    return false
                                }))
							  }
						  }}
						  items={selectedAddress.length>0 ? selectedAddress: address}
						  style={{
							  color: '#e6ebf3',
							  height: 46,
							  marginBottom: 12
						  }}
		/>
	)
}

export {SearchVendorAddress}