const data = [
    {
        title:'1.0  Scope',
        list:[
            {
                text:"1.1 These conditions govern all supplies of Goods and Services to purchasing company (that is a legal entity as mentioned in the PO), its group companies subsidiaries and divisions and its successors, transferees & assigns, in India – hereafter referred as the Purchaser"
            },
            {
                text:'1.2 By accepting our order or by supplying the Goods and Services ordered, the Supplier is deemed to have accepted the present conditions.'
            },
            {
                text:"1.3 Only orders in traceable form (in writing, by Telefax, E-Mail, etc.) are binding. Verbal orders or orders by phone as well as changes and additions to our order shall be binding only if confirmed by us in traceable form. Terms at variance with our General Purchase Conditions and additional terms, including reservations regarding price or exchange rates, as well as deviating General Conditions of Sale and Delivery of the Supplier shall be valid only if accepted by us in traceable form"
            },
            {
                text:"1.4 The Supplier is required to immediately return order confirmation and to notify the exact delivery date. 1.5 The Supplier shall be liable for all costs incurred by us, as a consequence of supplier’s failure to observe our instructions or due to faulty or not validly agreed deliveries."
            }
        ]
    },
    {
        title:'2.0 Bills/Challan',
        list:[
            {
                text:'2.1 Consignment should carry four copies of delivery challan, two copies of invoice and supplier test report. PO no. should be mentioned on delivery challan and invoice. Quantity in figures and words to be mentioned on the delivery challan. Company will not accept goods if there is any manual correction / scribbling on delivery challan / invoice, at the place of delivery.'
            }
        ]
    },
    {
        title:'3.0 Order & Confirmation',
        list:[
            {
                text:'3.1 If the Supplier has not confirmed acceptance of the order (confirmation) in writing within three days of receipt of such order, it will be deemed acceptance of the order by the Purchaser. If the terms of the confirmation vary from the terms of the order, the Purchaser is only bound thereby if it agrees to such variation in writing.'
            },
            {
                text:'3.2 Any amendments or additions or alterations to the order shall only be effective if the Purchaser confirms such in writing.'
            }
        ]
    },
    {
        title:'4.0 Quality of Goods',
        list:[
            {
                text:'4.1 The goods shall be free from defect of any merchantable quality and fit for their intended purpose.'
            },
            {
                text:'4.2 In case of any discrepancy in the quality of goods supplied, the decision of the Purchaser will be considered as final and binding.'
            }
        ]
    },
    {
        title:'5.0 Inspection & Rejection of Goods',
        list:[
            {
                text:'5.1 All materials duly processed and supplied against the order should conform to Indian Standards; it should be new, merchantable quality, fit for their intended purpose and should be in line with “Quality Assurance Plan”, if any, which has to be approved in advance by the Purchaser. All such materials will be subject to inspection and approval by the Purchaser, either at the Supplier’s premises and/or at the place of delivery indicated by the Purchaser'
            },
            {
                text:'5.2 The Purchaser reserves the rights to inspect the material at any stage during manufacture or supply and reject such portion thereof as may be found defective or not in conformity with the specification or not fit for their intended purpose without invalidating the remainder of the order, if so desired by the Purchaser. All rejected material shall be removed by the Supplier at its own costs within fifteen days from the date of rejection note / intimation /Challan posted by the Purchaser to the Supplier. In case of any failure due to any reasons to remove the goods/material the Purchaser shall have all rights to remove the defective materials/goods from the Purchaser’s/its customer’s premises and discard it'
            },
            {
                text:'5.3 The Purchaser under no circumstances will be liable or held accountable for any damage, loss, deterioration of the rejected materials/goods for discarding the material/goods, or for any value for it. The Purchaser shall also be entitled to charge an amount of 5% (of the value of rejected materials) per every week of the delay towards storage charges.'
            }
        ]
    },
    {
        title:'6.0 Safety of Goods',
        list:[
            {
                text:'6.1 The Supplier must ensure the Goods supplied to the Purchaser are: (a) safe and free from risk to health and safety and compliant with all relevant laws; and (b) clearly and durably labelled identifying the Goods and providing any information in relation to use, maintenance and storage.'
            },
            {
                text:'6.2 The Supplier agrees to supply the Goods with all relevant information pertaining to the use, storage and maintenance of the Goods and any relevant technical information, including health and safety information. If the Goods supplied are hazardous substances or materials, radiation substances or biological substances, the Supplier agrees to supply the Goods with clear and durable labelling and copies of all relevant technical information sheets including Material Safety Data Sheets (MSDS), radiation safety data sheets and information data sheets (respectively). If the Goods supplied are electrical equipment the Supplier is responsible for the initial electrical safety of the new equipment and the Goods should be provided tagged as tested.'
            }
        ]
    },
    {
        title:'7.0 Quality of Service',
        list:[
            {
                text:'7.1 The Services must be supplied by the Supplier to the Purchaser: (a) diligently, faithfully and conscientiously; (b) with the same or higher level of care and skill as would reasonably be expected of a person qualified and experienced in the supply of the same or similar Services; and (c) in good faith, in the best interests of the Purchaser and in a manner which is consistent with the maintenance of the business and goodwill of the Purchaser.'
            },

        ]
    },
    {
        title:'8.0 Safety of Service',
        list:[
            {
                text:'8.1 The Supplier must ensure the Services are carried out at the relevant Purchaser site in compliance with relevant laws and in a manner, which does not pose a risk to the health and safety of others. The Supplier of Services warrants it is (and any persons engaged by the Supplier are) qualified to provide the Services to the Purchaser and will provide evidence of such qualification if requested by the Purchaser. '
            },
            {
                text:'8.2 The Supplier of Services agrees to abide by policies and procedures at the relevant Purchaser sites. The Supplier of Services agrees to supply all relevant written safety documentation for the Services to be provided including safe work method statements (SWMS), job safety analysis (JSA), work assessments, worker training and any other relevant document, where applicable. The Supplier of Services agrees that the Purchaser has a right to direct the suspension of Services which are provided in an unsafe manner.'
            },
            {
                text:'8.3 Where a service is being provided on property occupied by PURCHASER, SUPPLIER shall be responsible for the safety of all persons engaged on the work, and all persons who may be affected by activities of SUPPLIER and shall comply with all PURCHASER’s safety regulations and procedures. Losses or penalties or expenses (if any) that may take place due to ignorance or negligence in following safety norms will have to be borne by SUPPLIER.'
            }
        ]
    },
    {
        title:'9.0 Force Majeure',
        list:[
            {
                text:'9.1 The Purchaser shall be under no liability for failure to accept the deliveries of goods, if such acts of failure are due to any act of God, fire, earthquake, floods, or any natural calamities or transportation embargoes, civil commotion, riots, violence, acts of terrorists, state enemies, or any other similar reasons or circumstances beyond the control of the Purchaser.'
            }
        ]
    },
    {
        title:'10.0 Product Warranty',
        list:[
            {
                text:'10.1 The said good/services processed and delivered by the Supplier shall be made out of good quality bought out components/materials, as acceptable to the Purchaser and it should have standard/excellent workmanship and fit for their intended purpose.'
            },
            {
                text:'10.2 The said goods/services delivered by the Supplier shall be identical to the sample specification given by the Supplier and accepted by the Purchaser.  '
            },
            {
                text:'10.3 Where the said goods/services delivered by the Supplier to the Purchaser is according to sample as well as certain specification/description, if any, the said goods/services actually processed and delivered, shall match not only with the said sample, but also with the said specification/description.'
            },
            {
                text:'10.4 If deficiency is identified before or during the transfer of risk or during the Guarantee Period, the Supplier must at its own expense and at the discretion of the Purchaser either repair the deficiency or provide re-performance of the Services or replacement of delivery. This provision also applies to delivery subject to inspection by sample test. The discretion of the Purchaser shall be exercised fairly and reasonably'
            },
            {
                text:'10.5 Should the Supplier fail to rectify (i.e. repair or replacement) any deficiency within a reasonable time period set by the Purchaser, the Purchaser is entitled to: cancel the order in whole or in part without being subject to any liability for damages; or demand a reduction in price; or undertake itself any repair at the expense of the Supplier or re-performance of services or replacement of deliveries or arrange for such to be done; and claim damages in lieu of performance.'
            },
            {
                text:'10.6 If the Supplier provides subsequent performance or repairs, the warranty period shall begin to run once again from the date of repair or replacement of the defective goods or services.'
            }
        ]
    },
    {
        title:'11.0 Confidentiality',
        list:[
            {
                text:'11.1 For the purpose of this part, the party disclosing confidential information shall be referred to as the disclosing party and the party receiving confidentialinformation shall be referred to as the receiving party.'
            },
            {
                text:'11.2 The term “Confidential Information” shall mean and include any and all Proprietary and Confidential Information or Data, relating to the Purpose or the business of the Disclosing Party, disclosed to the Receiving Party, including but not limited to: a) Any Information or Data of a proprietary and confidential nature not already in the public domain. b) Any Information or Data that was disclosed by the Disclosing Party to the Receiving Party prior to the execution of this agreement, in anticipation of the Parties entering into this Agreement. c) Any information or data consisting of or relating to, without limitation working standards, reference standards, test methods or procedures, method validation documents or data, supporting information and documents and letters of access.'
            },
            {
                text:'11.3 The receiving party acknowledges that the Confidential Information is solely owned by the disclosing party and the receiving party shall not claim any right, title or interest in the Confidential Information or any part thereof.'
            },
            {
                text:'11.4 Goods/Services delivered according to Purchaser’s drawings, patents, confidential data, shall not be used by the Supplier for his own use nor may they be offered or delivered to third parties'
            }
        ]
    },
    {
        title:'12.0 Delivery',
        list:[
            {
                text:'12.1 Delivery time is the essence of this order and must be strictly adhered to. If the Supplier fails to deliver the goods in time, the Purchaser may, at its sole discretion: a) treat the order as cancelled at any time and recover any loss or damage from the Supplier; b) purchase the goods ordered or any part thereof from other sources on the Supplier’s account, in which case the Supplier shall be liable to pay the Purchaser not only the difference between the price at which such goods have been actually purchased and the price calculated at the rate set out in this order, but also any other loss or damage the Purchaser may suffer; c) without prejudice to above provision Purchaser may accept late delivery, subject to a deduction in payment of 1% of the total order price for every week or part thereof of the delay, towards liquidated damages, subject to maximum deduction of 10% of the order price.'
            },
            {
                text:'12.2 For the purpose of establishing the timeliness for deliveries involving installation, commissioning or rectification services (if any), the relevant point in time shall be the date of acceptance'
            },
            {
                text:'12.3 The supplier is required to deliver the goods ONLY as per the time-slot agreed by the Purchaser.'
            },
            {
                text:'12.4 The Supplier has to arrange for appropriate packing at his own costs and shall be liable if the goods are damaged on transport due to faulty packing.'
            }
        ]
    },
    {
        title:"13.0 Title & Risk",
        list:[
            {
                text:'13.1 The Goods shall be at the sole risk of the Supplier until delivery at which time title to and risk in the Goods shall pass to the Purchaser regardless of whether the Purchaser has paid the Supplier for the Goods, except that title to and risk in Goods rejected by the Purchaser shall remain with the Supplier.'
            }
        ]
    },
    {
        title:'14.0 Indemnity',
        list:[
            {
                text:'14.1 Without limiting any other remedy of the Purchaser, the Supplier shall at its own expense, defend, indemnify and hold harmless the Purchaser its directors, officers, employees, agents and customers from and against any and all loss, cost, expense, damages, claims, proceedings, actions, demands or liability, including legal counsel fees and expenses, incurred or suffered by the Purchaser resulting from bodily injury, sickness, disease, or death of persons, or damage to property arising out of or in connection with the Supplier’s performance of this order including but not limited to: a) Non-compliance with the Purchaser’s requirements; b) Negligence or willful misconduct of the Supplier, its employees, contractors, suppliers or agents; c) Defects in the workmanship, materials or design of the goods supplied, services or work performed by the Supplier; d) Failure to comply with central, state or local laws; or e) Breach of this order'
            },
            {
                text:'14.2 The indemnity in this clause is a continuing indemnity and survives termination or expiration of this order.'
            }
        ]
    },
    {
        title:'15.0 Tax Invoice',
        list:[
            {
                text:'15.1 GST tax invoice and delivery challan / way bill / e-way bill documents are to be submitted immediately after dispatch of the goods to Ship to location mentioned in PO.'
            },
            {
                text:'15.2 The Supplier may not claim payments being contingent on the fulfilment of his obligations, prior to the fulfilment of such obligations, unless the nonperformance shall be caused by our acts or omissions.'
            },
            {
                text:'15.3 The supplier must submit test certificate / quality certificate (ISO Standard Test certificate) along with shipment to delivery address indicated in the order.'
            },
            {
                text:'15.4 The supplier shall not receive payment of GST amount till the Credit of the tax so paid is not reflected in our Form GSTR-2. Thus, amount to an extent till GST payable would be retained till the said amount is not reflected in our respective returns'
            },
            {
                text:'15.5 Supplier is obliged to quote GST number of purchasing company as mentioned on the purchase order, in all its invoices and supporting documents, as well as in the GST returns. In case of non-fulfillment of these conditions, purchasing company reserves the right to withhold the payment of invoice and adjust the said invoice to the extent of loss suffered on account GST credits, interracialities etc. levied (if any) by the GST authorities'
            },
            {
                text:'15.6 Invoice issued by supplier should be in accordance with the format / contents prescribed under the invoice rules under GST. Supplier is obliged to attach the proof of GSTN uploaded invoice along with issuance of invoice. Purchasing company reserves the right to reject the invoice which does not comply with the format/ contents prescribed under the GST rules or is submitted without proof attached'
            }
        ]
    },
    {
        title:'16.0 Prices & Payment',
        list:[
            {
                text:'16.1 The prices governing this order shall for all purposes remain firm unless otherwise agreed to specifically in writing by the Purchaser and shall be inclusive of packing and free delivery at Purchaser’s Warehouse / go-down /works or any place specified in the order.'
            },
            {
                text:'16.2 For deliveries involving installation, commissioning or services the transfer of risk occurs on acceptance and for deliveries not involving installation or commissioning, the transfer of risk shall be upon receipt by the Purchaser at the designated place of receipt.'
            },
            {
                text:'16.3 For pricing ex-works or ex-warehouse of the Supplier, transport shall in each case be at the lowest possible cost, in so far as the Purchaser has not requested a particular method of delivery. Any supplementary costs arising from non-conformity with the transport requirements shall be borne by the Supplier. Where the price is quoted free to the recipient, the Purchaser may also determine the method of transportation. Any supplementary costs arising from the need to meet the delivery deadline by way of expedited delivery shall be borne by the Supplier.'
            },
            {
                text:'16.4 Payment of service/processing charges for goods delivered; provided they are not rejected by the Purchaser/consignee shall be made as per the terms stated in the order. Payment falls due after the stipulated/agreed credit period from the date of receipt of materials or from the date of receipt of bills, whichever is later. Bills should be submitted within four days from the date of delivery. The Purchaser shall at all point of time have all rights to deduct from any unpaid bills, debit notes falling due in case any goods/services are rejected online and/or any claims for deductions are raised on the Supplier.'
            },
            {
                text:'16.5 In so far as the Supplier is required to provide material testing, test records or quality control documents or any other documentation, such shall be a part of the requirements of the completeness of the delivery or performance.'
            }
        ]
    },
    {
        title:'17.0 Change in Law',
        list:[
            {
                text:'17.1 Any additional cost or benefit impacting the contract price resulting from a “Change in Law”, towards leviable taxes, including eligible credits, in respect of goods and services to be supplied under the Contract, then the Parties agree to an adjustment to the contract price to reflect the financial impact of such “Change in Law”. “Change in law” means occurrence of any of the following event after submission of bid and during the performance of the contract, a) The enactment of any new Indian law; b) The repeal, modification or re-enactment of any existing Indian Law; c) The commencement of any Indian Law, which has not entered into effect until the date of award of contract; d) A change in the interpretation or application of any Indian law, by a judgment of a court of record which has become final, conclusive and binding, as compared to such interpretation or'
            },
        ]
    },
    {
        title:'18.0 GST Standard Clause',
        list:[
            {
                text:'18.1 Supplier declares to raise the prescribed documentation, with prescribed details and upload returns within prescribed time to enable the Purchaser to avail credit of GST paid.'
            },
            {
                text:'18.2 In case of any default, the Purchaser will not be in a position to avail GST credit. Supplier at his own cost and effort will get the short coming rectified in the subsequent return.'
            },
            {
                text:'18.3 In this case Purchaser reserves the right to withhold payment till the non-compliance is made good.'
            },
            {
                text:'18.4 In case of repeated defaults, the contractual terms shall give Purchaser the discretionary power to suspend/cancel the contract.'
            },
            {
                text:'18.5 The PO shall stand to be void, if at any point of time the Supplier is found to be a ‘black listed dealer’ as per GSTN rating system.'
            },
            {
                text:'18.6 All necessary adjustment entries (Credit Note, Purchase Returns, Debit Note) shall be made before September of the succeeding FY by the Supplier.'
            },
            {
                text:'18.7 In case, the short coming is not rectified by the Supplier and the Purchaser ends up in reversal of credits and/or payment along with interest, the Purchaser holds the right to deduct amount of loss incurred by the Purchaser (including but not limited to tax loss, interest and penalty) from any payments due to the Supplier.'
            },
            {
                text:'18.8 In a situation where there is no payment due by the Purchaser then the Supplier will make ‘good’ the loss suffered by the Purchaser.'
            },
            {
                text:'18.9 The Purchaser reserves the right to process payment in two batches (i) Base Invoice Amount (ii) Tax Amount. Tax Amount will be paid only after Supplier provides sufficient proof/self-declaration that the GST amount charged in invoice is declared in its GSTR-1 and GSTR-3 and payment of taxes has been made.'
            },
            {
                text:'18.10 Any tax benefit arising out of the implementation of GST is mandatorily required to be passed on to the Purchaser by the Supplier.'
            },
            {
                text:'18.11 Similarly, the benefits enjoyed by Supplier’s Supplier and other players in the supply chain are also required to be passed on to the Supplier by them.'
            }
        ]
    },
    {
        title:'19.0 Sub-contracting & Assignment',
        list:[
            {
                text:'19.1 The Supplier will not assign or sub-contract the Services in whole or in part, either in part or in its entirety, to any other party, without receiving prior written approval of the Purchaser.'
            },
            
        ]
    },
    {
        title:'20.0 Termination for convenience',
        list:[
            {
                text:'20.1 The Purchaser is entitled to terminate this PO, for convenience, by providing adequate written notice to the Supplier.'
            }
        ]
    },
    {
        title:'21.0 Dispute Resolution',
        list:[
            {
                text:'21.1 In the event of any dispute between the Parties hereto, the same shall be resolved amicably. In case it is not resolved through amicable settlement, it will be referred to Arbitration under the provisions of Arbitration and Conciliation Act, 1996 before a single arbitrator as nominated by the Purchaser. The seat of the arbitration shall be in Mumbai. The language of the arbitration proceedings shall be English. The Arbitration and Conciliation Act, 1996 (as amended from time to time) shall govern the conciliation and/or the arbitration proceedings.'
            },
            {
                text:'21.2 Subject to arbitration clause above, wherever judicial intervention is possible the Court at Mumbai alone would have jurisdiction over the disputes and differences arising out of the agreement.'
            }
        ]
    },
    {
        title:'22.0 Applicable Law',
        list:[
            {
                text:'22.1 This PO, its meaning, interpretation and the relation between the Parties shall be governed by and construed in accordance with the laws applicable in India.'
            }
        ]
    },
    {
        title:'23.0 Transit Insurance (for Shortages, Damages, Pilferages, Seal Tampering, Vehicle Accident)',
        list:[
            {
                text:'23.1 Assessment – Any shortages (due to theft /robbery / pilferages / seal tampering /vehicle accident etc. or due to any of the reason whatsoever) in receipt of goods at the Purchaser’s end, will be observed based on the remarks issued by the Supplier. The Supplier may at its cost insure the consignments against any damage or loss during transit. The Purchaser shall not be liable for any loss (as defined herein) to the goods in case the Supplier does not insure the goods.'
            }
        ]
    },
    {
        title:'24.0 Conditions in the PO and Agreement',
        list:[
            {
                text:'24.1 In the event of any conflict between this PO and the terms of Agreement if any, the terms and conditions of the Agreement will prevail, unless specified in the PO.'
            },
            {
                text:'24.2 In the event of any conflict between any specific condition(s) mentioned in the ‘Special Instructions / Additional Information’ of this PO and the ‘Standard Terms & Conditions of PO’, the condition(s) mentioned in ‘Special Instructions / Additional Information’ of PO will prevail and shall be abide by all.'
            }
        ]
    }
]

export default data;