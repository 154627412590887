import { useEffect, useState } from 'react';
import { get } from '../../Model/Network/Config/Axios';

function useOCRV2() {
    const [url,setUrl] = useState('vendor/invoicing/ocr');

    useEffect(()=>{
        INIT();
    },[])

    const INIT = () => {
        get(`settings/exceptions/vendor`, (e,r)=>{
            if(r){
                if(r.ocrV2) setUrl('vendor/invoicing/ocr.v2') 
                else setUrl('vendor/invoicing/ocr') 
            }else{
                setUrl('vendor/invoicing/ocr')
            }
        })
    }
    return url;
}

export default useOCRV2