import React, { useState, useEffect } from 'react';
import { get } from "../../../../Model/Network/Config/Axios";
import { showToast } from '../../../Common/fToast/ToastCallback';
import { Spinner } from '../../../../Components/Common/fLoader'
import moment from 'moment';
import Card from '../../../../Components/Common/Card'
import PoGrns from '../../fVendor/fProcurement/fPoDetails/GRN/PoGrns';
import { useHistory } from "react-router-dom"
import { isArray } from 'lodash';
import PoItem from './PoItem';

function Index(props) {
    const ID = props.id
    const { multiPos } = props
    const [Details, setDetails] = useState(undefined);
    const [multiPoDetails, setMultiPoDetails] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [grns, setgrns] = useState([]);
    const HISTORY = useHistory()


    useEffect(() => {
        if (multiPos && isArray(multiPos)) {
            const uniqueMultiPos = multiPos.reduce((acc, current) => {
                if (!acc.some(item => item.poId === current.poId)) {
                    acc.push(current);
                }
                return acc;
            }, []);
            getMultiGrnItems(uniqueMultiPos);
        } else {
            getDetails(ID)
        }
        getGrn()
    }, []);


    const fetchAndUpdateItems = async (obj) => {
        const { id } = obj
        return new Promise((resolve, reject) => {
            get(`/vendor/po/requests/${id}/details`, (error, response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(error);
                }
            });
        });
    };


    const getMultiGrnItems = async (items) => {
        setLoading(true);
        try {
            let concatenatedItems = [];
            for (const obj of items) {
                const updatedItems = await fetchAndUpdateItems(obj);
                concatenatedItems = concatenatedItems.concat(updatedItems);
            }
            setMultiPoDetails(concatenatedItems)
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const getGrn = () => {
        get(`vendor/procurement/grn/allGrn?invoice_id=${props.invoiceId || ''}`, (e, r) => {
            if (r) {
                setgrns(r.responseItems)
            } else if (e) {
                showToast({
                    type: 'error',
                    message: 'Problem  in loading PO Details'
                })
            }
        },
            {
                offset: 0
            }
        )
    }

    let getDetails = (id) => {
        get(`vendor/po/requests/${id}/details`, (e, r) => {
            if (r) {
                setDetails(r)
                setLoading(false)
            } else if (e) {
                showToast({
                    type: 'error',
                    message: 'Problem  in loading PO Details'
                })
            }
        })
    }

    if (Loading) {
        return <Spinner />
    }

    return (
        <>
            {Details &&
                <>
                    <div style={{ padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>PO Details</div>
                            <div style={{ padding: '4px 8px', borderRadius: '5px', fontSize: '11px', color: '#fff', display: 'inline-flex', marginTop: '5px', background: Details.status === 'COMPLETED' ? '#198754' : '#0d6efd' }}>Status: {Details.status}</div>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <span onClick={() => HISTORY.push(`/app/vendor/procurement/order/${ID}/details`)} style={{ marginRight: '3px', cursor: 'pointer', fontWeight: '600', textDecoration: 'underline', lineHeight: 2 }}><span style={{ color: 'var(--light-color)' }}>ID:</span> {Details.orderId}</span><br />
                            <span style={{ marginRight: '3px' }}><span style={{ color: 'var(--light-color)' }}>Created on:</span> {moment(Details.details.createdAt).format('HH:MM a DD--MM-YYYY')}</span><br />
                            <span style={{ marginRight: '3px' }}><span style={{ color: 'var(--light-color)' }}>Gross Amount:</span> {Details.grossAmount}</span><br />
                            <span style={{ marginRight: '3px' }}><span style={{ color: 'var(--light-color)' }}>Category:</span> {Details.category.name}</span><br />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '20px', marginRight: '20px' }}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Supplier</div>
                            <div style={{ marginTop: '5px' }}>
                                <span style={{ marginRight: '3px' }}>{Details.vendor.name}</span><br />
                                <span style={{ marginRight: '3px' }}>{Details.vendor.email}</span><br />
                                <span style={{ marginRight: '3px' }}>{Details.vendor.mobile}</span><br />
                                <span style={{ marginRight: '3px' }}>Gstin: {Details.vendor.gstin ? Details.vendor.gstin : '-'}</span><br />
                            </div>
                        </div>
                        <div style={{ padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '20px' }}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Billing</div>
                            <div style={{ marginTop: '5px' }}>
                                <span style={{ marginRight: '3px' }}>{Details.billingTo.name}</span><br />
                                <span style={{ marginRight: '3px' }}>{Details.billingTo.city}, {Details.billingTo.country}</span><br />
                                <span style={{ marginRight: '3px' }}>{Details.billingTo.gstin ? Details.billingTo.gstin : "-"}</span><br />
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '20px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>PO Items</div>
                        <table style={{ border: '1px solid var(--border-color)', borderCollapse: 'collapse', marginTop: '20px' }}>
                            <tr style={{ background: 'var(--lite-color)', textAlign: 'left', }}>
                                <th colSpan={2} style={{ padding: '10px' }}>Details</th>
                                <th style={{ padding: '10px' }}>Delivery Address</th>
                                <th style={{ padding: '10px' }}>Qty</th>
                                <th style={{ padding: '10px' }}>Price/Unit</th>
                                <th style={{ padding: '10px' }}>Amount</th>
                            </tr>
                            {
                                Details.procured.map(row => {
                                    return (
                                        <tr>
                                            <td colSpan={2} style={{ padding: '10px' }}>
                                                <b>{row.name}</b><br />
                                                HSN: {row.hsn}<br />
                                            </td>
                                            <td style={{ padding: '10px' }}>{row.officeAddress}</td>
                                            <td style={{ padding: '10px' }}>{row.qtty} {row.unit}</td>
                                            <td style={{ padding: '10px' }}>{row.price} /{row.unit}</td>
                                            <td style={{ padding: '10px' }}>{row.amount}</td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <div style={{ padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', flexWrap: 'wrap', marginTop: 30, maxHeight: 500, overflow: 'scroll' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px' }}>Vendor History</div>
                        {
                            Details.invoices.map((item, index) => {
                                return (
                                    <Card key={index} style={{ marginTop: '5px', cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <span style={{ fontWeight: 'bold' }}>{item.invoiceId.toUpperCase()}</span>
                                            <div style={{ padding: '3px 7px', borderRadius: '5px', fontSize: '10px', color: '#fff', display: 'inline-flex', marginTop: '5px', background: item.status === 'COMPLETED' ? '#198754' : '#0d6efd' }}>{item.status}</div>
                                        </div>
                                        <div style={{ lineHeight: '16px', fontSize: '11px' }}>
                                            Amount: {item.currency}&nbsp;{item.currencyAmount}
                                            <br />
                                            Date: {moment(item.createdAt).format('HH:MM a DD-MM-YYYY')}
                                            <br />
                                            Due Date: {moment(item.dueDate).format('HH:MM a DD-MM-YYYY')}
                                            <br />
                                            Category: {item.category}<br />
                                        </div>
                                    </Card>
                                )
                            })
                        }
                    </div>
                </>
            }
            {multiPoDetails.length > 0 && multiPoDetails.map((item, index) => <PoItem item={item} />)}
            {grns.length > 0 && <PoGrns data={grns} /> }
        </>
    )
}

export default Index;