import React from 'react'
import PropTypes from 'prop-types'
import {FloatInput} from "../Class/FloatLabelInput";
import {Modal} from "../fModal/Modal";
import Moment from 'moment'
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';

class Comments extends React.Component {

	state = {
		add: false
	}

	render() {
		return (
			<div>
				{
					this.props.comments.map(item => {
						return (
							<div key={item.time} style={{
								borderBottom: '1px dotted var(--border-color)',
								padding: 12,
							}}>
								<p style={{ color: "var(--text-color)", fontSize: 17, fontWeight: 600 }}>
									{item.message}
								</p>
								<p style={{ color: "var(--light-color)", fontSize: 9 }}>
									Posted by {item.owner.ownerName} on {Moment(item.time).format("DD MMM YYYY hh:mm A")}
								</p>
							</div>
						)
					})
				}
				{
					this.props.comments.length === 0 ? <p style={{
						padding:12,
						fontSize:13,
						letterSpacing:1.25,
						marginTop:20,
					}}>No comment found</p> : undefined
				}

				{
					this.props.editable ?
						<ReadOnly>
							<p onClick={() => {
								this.setState({
									...this.state,
									add: true
								})
							}} style={{
								color: "var(--primary-color)",
								fontSize: 13,
								padding:12,
								letterSpacing:1.25,
								textTransform:'uppercase',
								cursor: 'pointer',
								fontWeight: 700
							}}>
								+ Add Comment
							</p>
						</ReadOnly> : undefined
				}
				{
					this.state.add ?
						<Modal style={{
							save: {
								background: "var(--primary-color)",
								width: "100%",
								border: "2px solid var(--primary-color)",
								padding: '12px 15px',
								textAlign: 'center'
							}
						}} title={"Add Comment"}
							des="Please enter a comment to add."
							onSave={() => {
								let comment = this.comment.value();
								if (comment) {
									this.setState({
										...this.state,
										add: false
									}, () => {
										this.props.add(comment)
									})
								}
							}} buttonName={'Add Comment'}
							show={true} close={() => {
								this.setState({ ...this.state, add: undefined })
							}}>
							<FloatInput title="Comment" type="text" handleRef={(ref) => this.comment = ref} />
						</Modal> : undefined
				}
			</div>
		)
	}

}

Comments.defaultProps = {
	editable: true
}

Comments.propTypes = {
	comments: PropTypes.array.isRequired,
	editable: PropTypes.bool,
	add: PropTypes.func
}

export default Comments
