import React from "react";

export default function Vendor(props) {
    const { data } = props; 

    let getAddress = (fullAddress) => {
        const { address, city, state, country, pincode } = fullAddress;
        let addressString = "";
        if(address) addressString += address;
        if(city) addressString += `, ${city}`;
        if(state) addressString += `, ${state}`;
        if(country) addressString += `, ${country}`;
        if(pincode) addressString += ` - ${pincode}`;
        return addressString;
    }

    return (
        <div style={{display: "flex", flex: 1}}>
            <div style={{flex: 1}}>
                <p style={{fontSize: 15, color: "#87adf1", marginBottom: 10}}>Vendor Details</p>
                <div style={{marginBottom: 10, fontWeight: 700}}>{data.vendor.name}</div>
                <div style={{marginBottom: 10}}>
                    PAN Number
                    <div style={{fontWeight: 700}}>{data.vendor.pan}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    GSTIN 
                    <div style={{fontWeight: 700}}>{data.vendor.gstin || " "}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    Point of Contact
                </div>
                <div style={{marginBottom: 10}}>
                    Email
                    <div style={{fontWeight: 700}}>{data.vendor.email}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    Mobile Number
                    <div style={{fontWeight: 700}}>{data.vendor.mobile}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    Location
                    <div style={{fontWeight: 700}}>{data.vendor.address.city} - {data.vendor.address.state}</div>
                </div>
            </div>
            <div style={{flex: 1}}>
                <p style={{fontSize: 15, color: "#87adf1", marginBottom: 10}}>Vendor Address</p>
                <div style={{marginBottom: 10, fontWeight: 700}}>
                    <div>{data.vendor.name}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    <div>{getAddress(data.vendor.address)}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    PAN Number
                    <div style={{fontWeight: 700}}>{data.vendor.pan}</div>
                </div>
                <div style={{marginBottom: 10}}>
                    GSTIN 
                    <div style={{fontWeight: 700}}>{data.vendor.gstin || " "}</div>
                </div>
            </div>
        </div>
    )
}
