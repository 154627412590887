import React from "react"
export default ({Details={}}) =>{
    return(
        <>
                        <div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', marginTop: '20px'}}>
                            <div style={{display: 'flex',justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{fontSize: '14px', fontWeight: 'bold'}}>Details</div>
                                <div style={{ padding: '4px 8px',borderRadius: '5px',fontSize: '11px',color: '#fff',display: 'inline-flex',marginTop: '5px', background: Details.status==='COMPLETED'?'#198754':'#0d6efd'}}>STAGE: {Details.attrs.stage}</div>
                            </div>
                            <div style={{display: 'flex',justifyContent: 'flex-end', alignItems: 'center'}}>
                            <div style={{ padding: '4px 8px',borderRadius: '5px',fontSize: '11px',color: '#fff',display: 'inline-flex',marginTop: '5px',justifyContent:'flex-end',  background: Details.status==='COMPLETED'?'#198754':'#0d6efd'}}>Status: {Details.status}</div>
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>ID:</span> {Details.attrs.reference}</span><br/>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>Partial: </span> {Details.attrs.partial}</span><br/>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>Amends:</span> {Details.attrs.amend}</span><br/>
                                <span style={{marginRight: '3px'}}><span style={{color: 'var(--light-color)'}}>Owner:</span> {Details.owner.ownerName} ({Details.owner.ownerType})</span><br/>
                                {Object.keys(Details.attrs).length && Object.keys(Details.attrs).map((key) =>(
                                        <>
                                        <span style={{marginRight: '3px'}}>
                                        <span style={{color: 'var(--light-color)'}}>{key}:</span>
                                       {Details.attrs[key]}
                                      </span><br/>
                                      </>
                                ))}
                            </div>
                        </div>
            </>
    )
}