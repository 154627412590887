import React from 'react';
import './index.css'

const Index =(props) => {

    return (
        <>
            <div className="spinner">
                <div className="bounce1" style={{backgroundColor: props.color}} />
                <div className="bounce2" style={{backgroundColor: props.color}} />
                <div className="bounce3" style={{backgroundColor: props.color}} />
            </div>

        </>
    );
}

export default Index;