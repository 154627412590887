import React from 'react'
import ApproveTimeLine from './ApprovalTimeLine';
import ApproveTimeLine1 from './ApprovalTimeLine1';


const Index = (props) => {


    let {details} = props;
    return(
        <div>
            <ApproveTimeLine title="PR TimeLine" data={details.prApprovalMap} />
            <ApproveTimeLine title="RFQ TimeLine" data={details.rfqApprovalMap} />
            <ApproveTimeLine1 title="PO TimeLine" data={details.poApprovalMap} />
        </div>
    )
}

export default Index;