import React, { useRef, useState } from 'react';
import {useParams} from 'react-router-dom';
import Title from '../../../../Common/Title';
import { Modal } from '../../../../Common/fModal/Modal';
import { FunctionalInput } from '../../../../Common/fInputs';
import { showToast } from '../../../../Common/fToast/ToastCallback';
import { post } from '../../../../../Model/Network/Config/Axios';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';


const Index = ({init,status,multipleEmailValue}) => {


    const [open,setOpen] = useState(false);
    const {id} = useParams();
    let EMAIL = useRef();

    return(
        <div  style={{margin: 12}}>
            <Title id="attachments" marginLeft={0} title="Add Multiple Email"/>
            <div className="input-container" style={{
                background: "var(--white-color)",
                margin: 0,
                padding: 20
            }}>

                {
                    multipleEmailValue ?
                    <p style={{fontSize:12,marginBottom:5}}>{multipleEmailValue}</p>
                    :null

                }

                {
                    status==='DRAFT' || status==='PENDING'?
                    <ReadOnly><p  onClick={()=>{setOpen(true)}} style={{color:'var(--primary-color)',fontSize:14,fontWeight:600,cursor:'pointer'}}>+ Add Email</p></ReadOnly>
                    :null
                }
            </div>


            {
                open ?
                <Modal
                    title='Add Email'
                    des="Add multiple email id to send po copy and po release notification"
                    show={true}
                    close={()=> setOpen(false)}
                    buttonName="Update"
                    onSave={()=>{
                        let email = EMAIL.current.value;

                        if(!email) {
                            showToast({
                                type:'error',
                                message:"Please enter the email"
                            })
                            return;
                        }

                        let requestEmail = '';

                        if(email.includes(',')) {
                            requestEmail = email.split(',').join('|')
                        } else {
                            requestEmail = email
                        }

                        post(`vendor/po/requests/${id}/updateDetails`,{
                            key:'additional_email',
                            value:requestEmail,
                            force:true
                        },(e,r)=>{
                            if(r) {
                                setOpen(false);
                                showToast({
                                    type:'success',
                                    message:"Email Updated successfully"
                                })
                                init()
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }}

                >
                    <FunctionalInput 
                        title="Enter the email id"
                        type="text"
                        ref={ref=>EMAIL.current=ref}
                        blue="Add multiple id by comman separated" 
                        />
                </Modal>
                :null
            }

        </div>
    )
}

export default Index;