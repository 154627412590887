class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }


    init = () => {
        this.Model.init(this.View.getId(), (e, r) => {
            if (r) {
                this.View.load(r);
            }
        })
    }


}

export default Presentor;