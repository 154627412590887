import React from 'react';
import {Switch, Route, useLocation} from 'react-router-dom'
import Browse from './Browse/All'
import ViewApp from './Browse/ViewApp'
import { TitleTopHeader } from '../../Header/TopHeader';
import Tabs from '../../../Components/Common/Tabs/Stateful';
import Thirdparty from './Installed'

function MarketPlace(props) {

    let route = useLocation().pathname;

    let tabs = [
        {
            active: route === `/app/marketplace`,
            name: `Installed Apps`,
            link:`/app/marketplace`
        },
        {
            active: route.includes(`/app/marketplace/browse`),
            name: `Marketplace`,
            link:`/app/marketplace/browse`
        }
    ]

    return (
    <>
    <div className="children-container">
        <div className='marketplace' style={{ flex: 1, overflowY: 'auto'}}>
            <TitleTopHeader title="Apps" des="Third Party Apps"/>
            <div style={{marginTop: '20px', marginLeft: '40px'}}>
            <Tabs tabs={tabs}/>
                <Switch>
                    <Route path="/app/marketplace/browse/:appId" exact component={ViewApp}/>
                    <Route path="/app/marketplace/browse" exact component={Browse}/>
                    <Route path="/app/marketplace" component={Thirdparty}/>
                </Switch>
            </div>
        </div>
    </div>
    </>);
}

export default MarketPlace;