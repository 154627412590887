import React from "react";
import {Link} from "react-router-dom";
import Props from "prop-types";

class Select extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			shown: false,
		}
		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	showMenu(event) {
		event.preventDefault();
		if(this.props.disabled) {
			return;
		}
		this.setState({shown: true, items: []}, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}


	closeMenu(event) {
		if (!event) {
			this.setState({shown: false}, () => {
				document.removeEventListener('click', this.closeMenu);
			});
			return
		}
		if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
			this.setState({shown: false}, () => {
				document.removeEventListener('click', this.closeMenu);
			});

		}
	}

	onClick = (click) => {
		this.setState({shown: false}, () => {
			document.removeEventListener('click', this.closeMenu);
		});
		click()
	}

	onChange = () => {
		let value = this.input.value;
		if (this.props.onChange) {
			this.props.onChange(value)
		}
	}

	select = item => {
		this.closeMenu()
		this.props.select(item)
	}

	render() {
		return (
			<div style={this.props.style.container} ref={ref => this.dropdownMenu = ref} className="selectContainer">
				<label className="floating-top-label">
					{
						this.props.placeholder
					}:
				</label>
				<div onClick={this.showMenu} className={`floating-label ${this.props.value ? 'valid' : ''}`}>
					<p>{this.props.value ? this.props.value.label : this.props.placeholder}</p>
				</div>
				{
					this.state.shown ?
						<div className="menu">
							{this.props.onChange?<div className="select-input-outer" style={this.props.style.outer}>
								<div className="select-input-wrapper" style={this.props.style.inner}>
									<img style={this.props.style.image} src={require('./search.png')} alt="search"/>
									<input ref={ref => this.input = ref} style={this.props.style.input}
										   onChange={this.onChange} type="text" placeholder={this.props.placeholder}/>
								</div>
							</div>:null}
							<div className="item-container">
								{this.props.add ? <Link to={this.props.add.link}>
									<div className="add-button item">
										<img src={require('./plus.png')} alt="plus"/>
										<p>{this.props.add.name}</p>
									</div>
								</Link> : null}
								{
									this.props.items.map(item => <div key={item.value}
																	  onClick={this.select.bind(this, item)}
																	  className="item">
										<p>{item.label}</p>
										{item.des ? <p style={{fontSize: 11, color: "var(--light-color)"}}
													   className="des">{item.des}</p> : null}
									</div>)
								}
							</div>
						</div> :
						null


				}
			</div>
		)
	}

}

Select.propTypes = {
	value: Props.object,
	add: Props.object,
	placeholder: Props.string.isRequired,
	onChange: Props.func,
	items: Props.array,
	select: Props.func.isRequired,
	style: Props.object
}

Select.defaultProps = {
	items: [],
	style: {
		outer: {},
		input: {},
		inner: {},
		image: {}
	},
	containerStyle: {},
	inputStyle: {},
	labelStyle: {}
}

export {
	Select
}
