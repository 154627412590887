import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {useParams,useHistory} from  'react-router-dom';
import {get, post,axios} from '../../../../../Model/Network/Config/Axios';
import { PoCopyDropdown as MultiselectDropDown } from './PoCopyDropdown';
import {PageLoader} from '../../../../Common/Components/PageLoader';
import { Warning } from '../../../../Common/fCard/Warning';
import { FunctionalInput } from '../../../../Common/fInputs'
import { FileUpload } from '../../../../Common/fInputs/FunctionalFile';
import { Modal } from '../../../../Common/fModal/Modal';
import PhotoTile from '../../../../Common/fPhoto';
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {RightSidebar} from "../fCommon/RightSidebar";
import Details from './Details';
import Status from "./Status";
import _ from 'lodash';

const PoAmend = (props) => {

	const [loading,setLoading] = useState(true);
	const [details,setDetails] = useState({});
	const [invoices,setInvoices] = useState([]);
	const [poCopy,setPoCopy] = useState(false);
	const [acknowledge,setAcknowledge] = useState(false);
	const [Files,setFiles] = useState([]);
	const {id} = useParams();
	const History = useHistory()
	let TYPE = useRef(),TITLE=useRef();
	let {renewalTracking} = useSelector(state=>state.auth.config.plugins);

	const [updated, setUpdated] = useState({
		content: {},
	  });

	useEffect(()=>{
		init();
	},[])

	let init = () => {
		get(`vendor/po/requests/${id}/details`, (e, r) => {
			if (r) {
				setDetails(r);
				setInvoices(r.invoices)
				setLoading(false);
			}
		})
	}

	let editable = () => {
		return details.status !== "COMPLETED" &&
			details.status !== "FAILED" &&
			details.status !== "DECLINED" &&
			details.status !== "CANCELLED"
	}

	if(loading) {
		return(<PageLoader />)
	}
	
	const withDraw = (data) =>{
		post(`vendor/po/requests/${id}/withdrawPo`,data,(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:'PO Withdrawn successfully'
				})
				init();
			} else {
				handleError(e)
			}
		})
	}

	const deletePo = (data) => {
		post(`vendor/po/requests/${id}/cancel`,data,(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:'PO deleted successfully'
				})
				init();
				
			}
		})
	}

	const reset = (data) =>{
		post(`vendor/po/requests/${id}/moveFailedToDraft`,data,(e,r)=>{
			if(r) {
				showToast({
					type:'success',
					message:'PO Reset successfully'
				})
				init();
				
			}
		})
	}
	
	const updateFunction = (key,value) =>{
		let updateObject = {...updated};
		updateObject["content"][key] = value;
		setUpdated(updateObject);
	}
	
	const onAmend = () => {
		let nLineItem = updated["content"]["items"] ? updated["content"]["items"]:[]
		let items =  []
		items = nLineItem.map(item=>(
				{
					"hsn": item.hsn || '',
					"tax": item.tax  ? item.tax : 0.00,
					"vendorName": item.vendor ? item.vendor : '',
					"vendorId":  item.vendorId ? item.vendorId : null,
					"productName":item.name|| '',
					"productId": item.productId ? item.productId :null,
					"requestId": 0,
					"quotedItemId": 0,
					"addressId": item.officeId ? item.officeId : null,
					"data": {
						"deliveryAddressDetails": "",
						"price": item.price ? item.price:0,
						"qtty": item.qtty ? item.qtty : 0,
						"gst": item.gst ? item.gst:0,
						"tax": item.tax ? item.tax : 0,
						"taxSlab": item.taxSlab ?  item.taxSlab : 0.00,
						"base": item.base ? item.base : 0,
						"amount":item.amount ? item.amount : 0,
						"invoicedQty": item.invoicedQty ? item.grnQty :0.00,
						"grnQty": item.grnQty ? item.grnQty :0.00,
						"asnQty": item.asnQty ? item.asnQty :0.00,
						"contractItemId": item.contractItemId ? item.contractItemId :0.00,
						"contractPendingPoQty":  item.contractPendingPoQty ? item.contractPendingPoQty :0.00,
						"grnNos":item.grnNos && item.grnNos.length > 0 ? item.grnNos : [],
						"shortClosure": item.shortClosure ? item.shortClosure : false,
						"shortClosureQty": item.shortClosureQtty ? item.shortClosureQtty : 0.00,
					},
					"status":item.status ? item.status:'',
					"price": item.price ? item.price:0,
					"notes": item.notes ? item.notes:'',
					"qtty": item.qtty ? item.qtty : 0,
					"approved": item.approved ? item.approved : 0,
					"deliveryDate": item.deliveryDate && Number(item.deliveryDate),
					"amends":item.amends ? item.amends : 0.00
				}
		))

		let obj = {
			...updated["content"]
		}

		if( obj["items"] && Object.keys(obj["items"]).length) {
			obj["items"] = items;
		}

		let data = {
			"reactive": false,
			"remove": false,
			"change": true,
			"content": obj
		}

		if(!_.size(obj)> 0){
			return;
		}
		
		post(`vendor/po/amendment/addAmendment/${id}`, data,
			(e, r) => {
				if (r) {
					showToast({
						type: 'success',
						message: 'PO Amendment Request send successfully'
					})
					History.push('/app/vendor/procurement/orders/amendment/pending')
				} else {
					handleError(e)
				}
			})
	
}

	return (
			<div style={{
				flex: 1,
				position: 'relative',
			}}>
				
				<div style={{
					display:'flex',
					flexDirection:'row'
				}} >
					
					<div style={{
						flex:5,
						height:'calc(100vh - 200px)',
						overflowY:'auto'
					}}>
						<Warning warning={details.warnings} />
					<div style={{
						padding: 10,
						overflow:'visible',
						width:270,
						marginLeft: 30,
						marginTop: 10,
						marginBottom: 10,
						borderRadius: 10,
						justifyContent: 'space-between',
						alignItems: 'center',
						display: 'flex',
						position: 'absolute',
						top:-80,
						right:20,
					}}>
						
						<div
							onClick={onAmend}
							style={{
								fontSize: '14px',
								background: 'var(--primary-color',
								borderRadius: '10px',
								width:'100%',
								padding: '15px 30px',
								color: '#fff',
								textAlign: 'center',
								cursor: 'pointer',
							}}>
							Save Changes
						</div>
					</div>
						<Status
						   data={details} 
						   delete={deletePo}
						   withDraw={withDraw}
						   reset={reset}
						/>
					<Details updateFunction={updateFunction} updated={updated} invoices={invoices} init={init} id={id} editable={editable} data={details} {...props} />
					<div>
				</div>
				</div>
				<RightSidebar
					multiple={true}
					fileName={details.fileNames}
					id={id} 
					ticket={true} 
					status={details.status}
					renewalTracking={renewalTracking}
					init={init}  
					type="purchase-order"
					contract={true}
					acknowledge={details.status==='VENDOR_ACK'}
				    publicComments={details.publicComments} 
					publicComment={true} 
					multipleEmail={true}
					multipleEmailValue={details.additionalEmail}
					data={details}
					privateAttachments={details.privateAttachments?details.privateAttachments:[]} 
					timeline={details.timelines} 
					publicAttachments={details.publicAttachments?details.publicAttachments:[]} 
					comments={details.comments} attachments={details.attachments} 
					poCopy={details.poDocument}
					onAcknowledge={()=>{
						setAcknowledge(true);
					}}
					openPoCopy={()=>{
						setPoCopy(true);
					}}
					removeAttachment={attachment=>{
						post(`vendor/po/requests/${id}/attachment.remove`, {attachment}, (e,r)=>{
							if(r) {
								init();
							} else {
								handleError(e);
							}
						})

					}}  
					postAsyncPublicAttachment={  async (items,cb)=>{
						let count = 0;
						for(let item in items) {
							let result = await axios({
								method: 'post',
                                url: `https://heimdall.eka.io/admin/vendor/po/requests/${id}/attachment`,
                                data: {
                                    attachment:items[item].url,
                                    name:items[item].name
                                },
                               
							}).then(()=>{
								return 1;
							})

							count = count+result;
                            if(count===items.length) {
                                cb(count);
                                init();
                            }

						}
					}}
		
		
					postAsyncPrivateAttachment={async (items,cb)=>{
						let count = 0;
						for(let item in items) {
							let result = await axios({
								method: 'post',
                                url: `https://heimdall.eka.io/admin/vendor/po/requests/${id}/attachment.private`,
                                data: {
                                    attachment:items[item].url,
                                    name:items[item].name
                                },
                               
							}).then(()=>{
								return 1;
							})
							count = count+result;
                            if(count===items.length) {
                                cb(count);
                                init();
                            }
						}
						
					}}
					postAttachment={data=>{
						post(`vendor/po/requests/${id}/attachment`, {attachment:data}, (e,r)=>{
							if(r) {
								init();
							} else {
								handleError(e);
							}
						})

					}}  
					postPrivateAttachment={attachment=>{
						post(`vendor/po/requests/${id}/attachment.private`, {
							attachment
						}, (e,r)=>{
							if(r){
								init()
							}else{
								handleError(e)
							}
						})
					}}
		
					removePrivateAttachment={attachment=>{
						post(`vendor/po/requests/${id}/attachment.private.remove`, {attachment}, (e,r)=>{
							if(r){
								init()
							}else{
								handleError(e)
							}
						})
					}}
					addComment={(comment,type)=>{
						let data ={}
						if(type==='public') {
							data.public=comment;
						} else {
							data.comment=comment;
						}
						post(`vendor/po/requests/${id}/comment`, data, (e,r)=>{
							if(r) {
								init();
							} else {
								handleError(e);
							}
						})
					}} editable={editable} />
				</div>
			
				{
					poCopy ?
					<Modal
						title="Send PO copy"
						des="Select the type"
						show={true}
						close={()=>{
							setPoCopy(false)
						}}
						buttonName="Send"
						onSave={()=>{
							let type = TYPE.current.getValue();
							let items = type.split(';');
							let count =0;
							items.forEach(i=>{
								post(`vendor/po/requests/${id}/notifyTrigger`,{key:i},(e,r)=>{
									if(r) {
										count++;
										if(count===items.length) {
											showToast({
												type:'success',
												message:"PO copy sent successfully"
											})
											setPoCopy(false);
										}
										
									}
								})
							})
							
						}}
					>
					<MultiselectDropDown
						title={"Select the Type"}
						options={[
							{value:'vendor',label:"Vendor"},
							{value:'admin',label:"Buyer"},
							{value:'employee',label:"User"}, 
							{value: "custom", label: "Custom"}
						]}
						handleRef={ref => TYPE.current = ref}
					/>
					 
					</Modal>
					:null
				}

				{
					acknowledge ?
					<Modal
						title="Acknowledge PO"
						des="Acknowledge"
						close={()=>{
							setAcknowledge(false);
						}}
						show={true}
						buttonName="Acknowledge"
						onSave={()=>{
							let title = TITLE.current.value;
							if(!title) {
								showToast({
									type:'error',
									message:"Please enter the comment"
								})
								return;
							}
							post(`vendor/po/requests/${id}/vendorAck`,{comment:title,attachments:[...Files]},(e,r)=>{
								if(r) {
									showToast({
										type:'success',
										message:"PO Acknowledge Success"
									})
									init();
									setAcknowledge(false);
								}
							})
						}}
					>
						<FunctionalInput title="Enter Comment" ref={TITLE} />
						<div style={{
							display: 'flex',
							marginLeft: '-10px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>
							<FileUpload onUpload={({file}) => {
								setFiles(prev => [...prev, file]);
							}}/>
							{
								Files.length > 0 ?
									Files.map((item, index) => {
									return (
										<PhotoTile key={`Photo-${index}`} link={item}/>
									)
								}) : <></>
							}
						</div>
					</Modal>
					:null
				}
			</div>
		)

}


export default PoAmend
