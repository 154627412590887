import React from 'react';
import {Title as Header, TitleTopHeader} from '../../../../Header/TopHeader';
import {FloatInput} from '../../../../Common/Class/FloatLabelInput';
import {DateRangePicker} from "react-date-range";
import Moment from 'moment';
import {showToast} from '../../../../Common/fToast/ToastCallback';
import {pluginsGetApi, pluginsPostApis} from '../../../../../Model/Network/Config/Axios';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            range: {
                startDate: Moment().add(-60, 'd').toDate(),
                endDate: Moment().add(1, 'd').toDate(),
                key: 'selection',
            },
            selectedSecondary: [],
            main: undefined
        }
    }

    componentDidMount() {
        pluginsGetApi(`plugins/ledgerReport/info`, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    main: r.primaryMail,
                    selectedSecondary: [...r.secondaryMails]

                })
            }
        })
    }


    handleSelect = (ranges) => {

        let startDate = Moment(ranges.selection.startDate).hours(0).minutes(0).seconds(0);
        let endDate = Moment(ranges.selection.endDate).hours(23).minutes(59).seconds(59);
        this.setState({
            ...this.state,
            range: {
                startDate: Moment(startDate).toDate(),
                endDate: Moment(endDate).toDate(),
                key: 'selection'
            },
        })
    }


    send = () => {
        let email = this.email.value();
        if (!email) {
            showToast({
                type: 'error',
                message: 'Please enter a email id'
            })
            return;
        }

        pluginsPostApis(`plugins/ledgerReport/trigger`, {
            email,
            start: Moment(this.state.range.startDate).valueOf(),
            end: Moment(this.state.range.endDate).valueOf(),
            cc: this.state.selectedSecondary
        }, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Report sent successfully'
                })
            }
        })
    }

    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <TitleTopHeader items={[]} title="Ledger Report" des="Ledger Report for compan " />
                <Header title="Ledger report" des="Ledger report for  company" />
                <div className="title-container" style={{
                    padding: 18
                }}>
                    <FloatInput type="text" handleRef={ref => this.email = ref} title="Email" />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 10

                    }}>
                        <FloatInput type="text" handleRef={ref => this.ccEmail = ref} title="Cc Email" />
                        <div style={{
                            marginLeft: 20,
                            marginTop: 25,
                            maxWidth: 150
                        }} className="btn btn-portlet" onClick={() => {
                            let selectedSecondary = this.state.selectedSecondary;
                            let value = this.ccEmail.value();
                            selectedSecondary = selectedSecondary.filter(val => val.toLowerCase() !== value.toLowerCase());

                            selectedSecondary.push(value);
                            this.setState({
                                ...this.state,
                                selectedSecondary
                            })
                        }}>
                            Add
                        </div>
                    </div>

                    {
                        this.state.main ?


                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: 30
                    }}>
                        <p>{this.state.main}</p>

                        <div style={{

                            fontWeight: '600',
                                        color: 'var(--red-color)',
                                        cursor: 'pointer'
                        }} onClick={() => {
                            this.setState({
                                ...this.state,
                                main:undefined
                            })
                        }}>
                            Delete
                        </div>
                    </div>
                    :null
    }
                    {
                        this.state.selectedSecondary.map((val, index) => {
                            return (
                                <div key={index} style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    height: 30
                                }}>
                                    <p>{val}</p>

                                    <div style={{
                                        fontWeight: '600',
                                        color: 'var(--red-color)',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        let selectedSecondary = this.state.selectedSecondary;
                                        selectedSecondary = selectedSecondary.filter(item => item.toLowerCase() !== val.toLowerCase());
                                        this.setState({
                                            ...this.state,
                                            selectedSecondary
                                        })
                                    }}>
                                        Delete
                                        </div>
                                </div>
                            )
                        })
                    }

                    <label className="floating-top-label">Select Time Range</label>
                    <div style={{ position: 'relative' }} id="no-shadow">
                        <DateRangePicker
                            color="#0062C5"
                            ranges={[this.state.range]}
                            onChange={this.handleSelect}
                        />
                    </div>

                    <div className="btn btn-portlet" style={{
                        marginTop: 30
                    }} onClick={() => {
                        this.send()
                    }}>
                        Send Ledger Report
                    </div>

                </div>
            </div>
        )
    }
}

export default Index;
