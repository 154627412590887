import React from "react";
import {Link} from "react-router-dom";
import Props from "prop-types";

class SearchableSelect3 extends React.Component {

    constructor(props) {
        super(props)
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.state = {
            shown: false,
            select: undefined,
            value: this.props.value ? this.props.value : undefined
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    setAutoFill = (item) => {
        this.setState({
            ...this.state,
            value: item.label,
            select: item
        })
    }

    showMenu(event) {
        event.preventDefault();
        if (this.props.reset)
            this.props.reset()
        this.setState({ shown: true, items: [] }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }


    closeMenu(event) {
        if (!event) {
            this.setState({ shown: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });
            return
        }
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            this.setState({ shown: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }

    onClick = (click) => {
        this.setState({ shown: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
        click()
    }

    onChange = () => {
        let value = this.input.value;
        this.setState({
            ...this.state,
            value
        })
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    onKeyPress = (e) => {
        let value = this.input.value;
        if (e.key === 'Enter') {
            this.setState({
                ...this.state,
                value,
                select: value
            })
        }
    }

    getSelected = () => {
        return this.state.select;
    }

    getValue = () => {
        return this.state.value;
    }

    setValue = () => {
        this.setState({
            ...this.state,
            select: undefined,
            value: undefined
        })
    }

    select = item => {
        if (this.props.select) {
            this.props.select(item);
        }
        this.setState({
            ...this.state,
            value: item.label,
            select: item
        }, () => {
            this.closeMenu()

        })
    }

    render() {
        return (
            <div ref={ref => this.dropdownMenu = ref} className="selectContainer">
                <div onClick={this.showMenu} className={`floating-label ${this.props.value ? 'valid' : ''}`}>
                    {
                        this.props.value ?
                            <label>
                                {
                                    this.props.label
                                }:
							</label> : null
                    }
                    <p>{this.state.value ? this.state.value : this.props.placeholder}</p>
                </div>
                {
                    this.state.shown ?
                        <div className="menu" style={{ zIndex: 111 }}>
                            <div className="select-input-outer">
                                <div className="select-input-wrapper">
                                    <img src={require('./search.png')} alt="search" />
                                    <input ref={ref => this.input = ref} onChange={this.onChange} type="text"
                                        placeholder={this.props.placeholder} onKeyPress={this.onKeyPress} />
                                </div>
                            </div>
                            <div className="item-container">
                                {this.props.add ? <Link to={this.props.add.link}>
                                    <div className="add-button item">
                                        <img src={require('./plus.png')} alt="plus" />
                                        <p>{this.props.add.name}</p>
                                    </div>
                                </Link> : null}
                                {
                                    this.props.items.map(item => <div key={item.value}
                                        onClick={this.select.bind(this, item)}
                                        className="item">
                                        <p>{item.label}</p>
                                        {item.des ? <p className="des">{item.des}</p> : null}
										{item.tax ? <p className="des">{item.tax}</p> : null}
                                    </div>)
                                }
                            </div>
                        </div> :
                        null


                }
            </div>
        )
    }

}

SearchableSelect3.propTypes = {
    value: Props.object,
    add: Props.object,
    placeholder: Props.string,
    onChange: Props.func,
    items: Props.array,
    select: Props.func
}

SearchableSelect3.defaultProps = {
    items: []
}

export {
    SearchableSelect3
}
