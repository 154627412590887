import React, {useRef, useState} from 'react';
import {Modal} from "../fModal/Modal";
import {showToast} from "../fToast/ToastCallback";
import {FloatFile} from "../Class/FloatLabelInput/FloatFile";
import PropTypes from 'prop-types'
import PhotoTile from '../fPhoto';
import { MultipleAttachmentUploader } from '../Class/FloatLabelInput/MultipleFile';
import { PhotoName } from '../fPhoto/PhotoName';
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';

function Attachments(props) {
	let [modal, setModal] = useState(false);
	let [buttonLoading, setButtonLoading] = useState(false);
	let FILE = useRef();

	
	return (
		<>
		<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				{
					props.attachments.map((item, index) => {
						return (
							<div key={index}>
								{
									item ?
										<>
										<PhotoTile key={`Photo-${index}`} link={item} onDelete={props.onDelete ? () => {
											if (props.onDelete) props.onDelete(item)
										} : undefined}/>
										<p style={{textAlign:'center',fontSize:10,fontWeight:'bold'}}># {index+1}</p>
										</>
										: null
								}
							</div>
						)
					})
				}
				{
					!props.editable && !props.attachments.length ? <div style={{
						fontSize: 13,
						padding: 8
					}}>
						No attachments found.
					</div> : undefined
				}
				
				{
				props.editable ?
					<div>
						<ReadOnly>
							<div onClick={() => {
								setModal(true)
							}} style={{
								width: 68,
								height: 68,
								borderRadius: 6,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								border: '1px dashed var(--light-color)',
								cursor: 'pointer',
								marginLeft: 4,
								marginTop:10
							}}>
								<p style={{
									fontSize: 29,
									textAlign: 'center',
									color: 'var(--light-color)'
								}}>+</p>
								<p style={{
									fontSize: 9,
									fontWeight: 700,
									textAlign: 'center',
									color: "var(--light-color)"
								}}>
									Add
								</p>
							</div>
						</ReadOnly>
					</div> : undefined
			}
			</div>

			{
				modal && props.multiple ?
					<MultipleAttachmentUploader close={()=>{
						setModal(false);
					}} onUpload={(items)=>{
						props.uploadAsyncAttachment(items,(count)=>{
							if(count===items.length) {
								setModal(false);
								showToast({
									type:'success',
									message:"All Attachment upload successfully"
								})
							}
						})
					}} />
					: null
			}
			

			{
				modal && !props.multiple ?
					<Modal show={modal}
						   title="Upload File"
						   des="Please attach the file that you want to upload "
						   close={() => {
							   setModal(false)
						   }}
						   onSave={() => {
							   let url = FILE.current.value();
							   if (!url) {
								   showToast({
									   type: 'error',
									   message: 'Please select valid file'
								   })
								   return;
							   }
							   setModal(false)
							   props.uploadAttachment(url)
						   }}
						   stateLoading={buttonLoading}
						   style={{
							   save: {
								   width: '100%',
								   fontSize: 17,
								   height: 44,
								   display: 'flex',
								   justifyContent: 'center',
								   alignItems: 'center'
							   }
						   }}
					>
						
						<FloatFile title="File" ref={FILE} onLoading={setButtonLoading}/>
					</Modal> : null
			}
		</div>
		<PhotoName attachments={props.attachments} fileNames={props.fileName} />
		
		</>
		
	)
}


Attachments.propTypes = {
	attachments: PropTypes.array.isRequired,
	uploadAttachment: PropTypes.func,
}

Attachments.defaultProps = {
	editable: true,
	multiple:false,
	fileName:{}
}

export default Attachments
